import React, { useState } from 'react'
import "../Offers/Offers.css"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import whiteFon from "../../images/whitefon.jpg"
import { options } from "../../constants"
import HeartWhite from "../../images/blurHeart.svg"
import HeartRed from "../../images/FilledHeart.svg"
import { useEffect } from 'react';
import locationIcon from "../../images/locationIcon.svg"
import { white } from 'material-ui/styles/colors';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { saveLastSearchUrl } from '../../redux/action';

const OfferCard = ({ offer }) => {
  const isMobile = useSelector(state => state.isMobile)
  const language = useSelector(state => state.language)
  const dispatch = useDispatch()
  const [url, setUrl] = useState(offer?.flat_data?.flat_data?.url?.split("/")[2])
  const [changed, setChanged] = useState(false)
  const offerType = useSelector((state) => state.offerType)
  const [isFavorite, setIsFavorite] = useState(false);
  const [imgIndex, setImgIndex] = useState(0)
  if (url === "dasinmaz-emlak") {
    setUrl("tap.az")
    setChanged(true)
  }
  useEffect(() => {
    const favoritesData = localStorage.getItem('MyFavourites');
    if (favoritesData) {
      const isObjectInFavorites =
        JSON.parse(favoritesData)?.findIndex((item) => item.id === offer.flat_data.id) !== -1;

      setIsFavorite(isObjectInFavorites);
    }
  }, [offer.flat_data.id]);
  function addOrRemoveObjectFromLocalStorage(obj) {
    const existingData = localStorage.getItem('MyFavourites');
    if (existingData) {
      const dataArray = JSON.parse(existingData);
      const existingIndex = dataArray.findIndex(item => item.id === obj.flat_data.id);
      if (existingIndex !== -1) {
        dataArray.splice(existingIndex, 1);
      } else {
        dataArray.push({ type: obj.flat_type, ...obj.flat_data });
      }
      localStorage.setItem('MyFavourites', JSON.stringify(dataArray));
    } else {
      const newDataArray = [obj.flat_data];
      localStorage.setItem('MyFavourites', JSON.stringify(newDataArray));
    }
    setIsFavorite(!isFavorite);
  }
  const addInFavorite = () => {
    addOrRemoveObjectFromLocalStorage(offer);
  }
  const getLeftImg = () => {
    if (imgIndex !== 0) {
      let index = imgIndex - 1
      setImgIndex(index)
    }
    else {
      setImgIndex(offer.flat_data.images.length - 1)
    }
  }
  const getRightImg = () => {
    if (imgIndex < offer.flat_data.images.length - 1) {
      let index = imgIndex + 1
      setImgIndex(index)
    }
    else {
      setImgIndex(0)
    }
  }
  const clickOnLink = () => {
    if (!(window.location.href.includes("/elan/") || window.location.href.includes("/secilmis-elanlar/") || window.location.href.includes("secilmis-elanlar"))) {
      dispatch(saveLastSearchUrl(window.location.href.replace("https://ebaz.az/", "")))
    }
  }
  return (
    <>
      {
        isMobile ?
        <Card className='cardB' sx={{ width: "calc(48vw - 8px)", height: "193px", maxWidth: 305, borderRadius: "10px", boxShadow: "0 4px 4px rgba(0,0,0,.25)", outline: offer.flat_data.urgent ? "4px solid #FF2B00" : offer.flat_data.priority_border ? "4px solid #0089CF" : 'none' }}>
          <CardActionArea className='card-action-area' disableRipple>
            <div className="additionalRow" style={{marginTop:"-8px", marginLeft:'5px'}}>
              <div className="additionalRowLeft">
                {/* <FaRegHeart color='white' size={25} /> */}
              </div>
              <div className="additionalRowRight">
                <img style={{width:'20px'}} src={
                  localStorage.getItem('MyFavourites') ?
                    JSON.parse(localStorage.getItem('MyFavourites'))?.findIndex(item => item.id === offer.flat_data.id) !== -1 ?
                      HeartRed :
                      HeartWhite :
                    HeartWhite
                }
                  alt='heart red' onClick={() => addInFavorite()} />
              </div>
            </div>

            <Link to={{ pathname: (window.location.pathname === "/" || window.location.pathname.includes("/elan/")) ? `/elan/${offer.flat_type ? offer.flat_type : offerType}/${offer.flat_data.id}` : `/elan/${offer.flat_type ? offer.flat_type : offerType}/${offer.flat_data.id}` }} onClick={() => clickOnLink()} >
              {offer.flat_data.images?.length > 1 &&
                <div className='middleRow'>
                  <div className='middleRow-left' onClick={(e) => {
                    e.preventDefault(); getLeftImg()
                  }}>
                    <ArrowBackIosRounded className='middleRow-left-icon' fontSize='medium' sx={{ color: white }} />
                  </div>
                  <div className='middleRow-right' onClick={(e) => {
                    e.preventDefault(); getRightImg()
                  }}>
                    <ArrowForwardIosRounded className='middleRow-right-icon' fontSize='medium' sx={{ color: white }} />
                  </div>
                </div>
              }
              {offer.flat_data.urgent && <div className='urgent-offer'>{options[language].urgentSmall}</div>}
              <CardMedia
                component="img"
                height={120}
                image={offer.flat_data.images !== null ? offer.flat_data.images[imgIndex]?.path || whiteFon : whiteFon}
                alt={offer.title ? offer.title : "elan"}
                loading='lazy'
                onError={e => {
                  // console.log(e)
                  e.target.onerror = null;
                  e.target.src = whiteFon;
                }}
              />
            </Link>
            <Link to={{ pathname: window.location.pathname === "/" || window.location.pathname.includes("/elan/") ? `/elan/${offer.flat_type ? offer.flat_type : offerType}/${offer.flat_data.id}` : `/elan/${offer.flat_type ? offer.flat_type : offerType}/${offer.flat_data.id}` }} onClick={() => clickOnLink()}>
              <CardContent sx={{ padding: "10px", height: "76px", width: "100%" }}>
                <hr className='horizontal' style={{ position: "absolute", bottom: "86px", color: "black" }} />
                <Typography variant="body3" color="text.secondary" className='typography-price' sx={{ fontSize: "18px", position: "absolute", top: `111px` }}>
                  {offer.flat_data.price?.toLocaleString('ru')} AZN{offer?.flat_type?.includes("rent") && offer?.flat_data?.rentForMonthOrDay === false ? options[language].perDays : ""}
                </Typography>
                  <Typography className='city-and-link-data city-and-link-data-url-part' variant="body2" color="text.secondary" sx={{ fontWeight: 500, fontSize: "10px", position: "absolute", top: `102px`, color: "white", justifyContent:'flex-end', right:'0' }}>
                    {changed ? <a href={"https://tap.az" + offer.flat_data.url}  rel="nofollow" target="_blank" className='offer-url'>{offer.url?.split("/")[2]}</a> : <a href={offer.url} className='offer-url'  rel="nofollow" target="_blank">{offer.flat_data.url?.split("/")[2]}</a>} {offer?.flat_data.url === null && <a  href={`https://ebaz.az/elan/${offer.type ? offer.type : offerType}/${offer.id}`} target="_blank" className='offer-url'>ebaz.az</a>}
                  </Typography>
                  <Typography className='city-and-link-data' variant="body2" color="text.secondary" sx={{ fontWeight: 500, fontSize: "10px", position: "absolute", top: `128px`, right:"8px", color: "#888888", justifyContent:'flex-end' }}>
                    {offer.flat_data.post_date ? `${new Date(offer?.flat_data.post_date).getDate() === new Date().getDate() && new Date(offer?.flat_data.post_date).getMonth() === new Date().getMonth() && new Date(offer?.flat_data.post_date).getFullYear() === new Date().getFullYear() ? `${new Date(offer?.flat_data.post_date).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).replace("24:", "00:")}` : new Date(offer?.flat_data.post_date).getDate() === new Date().getDate() - 1 && new Date(offer?.flat_data.post_date).getMonth() === new Date().getMonth() && new Date(offer?.flat_data.post_date).getFullYear() === new Date().getFullYear() ? `${options[language].yesterdaySmall}` : new Date(offer?.flat_data.post_date).toLocaleString('ru-Ru', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/20\d{2}/, /\d{2}/)}` : ""}
                  </Typography>
                  <hr className='horizontal' style={{ position: "absolute", bottom: "58px", color: "black" }} />

                <Typography className='city-and-link-data' variant="body2" color="text.secondary" sx={{ fontWeight: 500, width: "calc(100% + 0px)", fontSize: "12px", position: "absolute", bottom: "30px", color: "#222222", justifyContent: "flex-start", alignItems: "center", gap: "5px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                  <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: "calc(100% + 0px)" }}>
                    {/* {offer.flat_data.city !== null || offer.flat_data.region !== null || offer.flat_data.residential_area !== null ? <img style={{ width: "16px", height: "16px", marginTop: "-2px" }} src={locationIcon} alt='location' /> : <></>} */}
                    {offer.flat_data.city !== null ? options[language].cities[offer.flat_data.city].label : ""}
                    {offer.flat_data.region !== null && offer.flat_data.city === null ? options[language].cities[8].label : ""}
                    {offer.flat_data.region !== null ? ", " + options[language].region_options[offer.flat_data.region].label : ""}
                    {offer.flat_data.residential_area !== null && offer.flat_data.region === null ? ", " + options[language].resident_options[offer.flat_data.residential_area].region : ""}
                    {offer.flat_data.residential_area !== null ? ", " + options[language].resident_options[offer.flat_data.residential_area].label + options[language].residentsSmall : ""}
                  </div>
                </Typography>
                <Typography
                  className='room-area-and-floor-data'
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontWeight: 500,
                    fontSize: "12px",
                    position: "absolute",
                    bottom: "8px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "100%",
                    maxWidth: "calc(100% - 20px)",
                    color: "#000"
                  }}
                >
                  {offer.flat_data.room && offer.flat_data.room + options[language].WithRoomsSmall}
                  {offer.flat_data.room && offer.flat_data.area && <hr className='vertical dot-vertical' />}
                  {offer.flat_data.area && (
                    <>
                      {Number(offer.flat_data.area).toFixed(2).replace(".00", "")}
                      {offer.flat_type === "landsales" || offer.flat_type === "landrents" ? " " + options[language].sot : " " + options[language].m2}
                    </>
                  )}
                  {offer.flat_data.floor && offer.flat_data.area && <hr className='vertical dot-vertical' />}
                  {offer.flat_data.floor && (
                    <>
                      {offer.flat_data.floor}/{offer.flat_data.max_floor} {options[language].floorSmall}
                    </>
                  )}
                </Typography>
                <hr className='horizontal' style={{ position: "absolute", bottom: "38px", color: "black" }} />
              </CardContent>
            </Link>
          </CardActionArea>
        </Card> :
          <Card className='cardB' sx={{ width: "45vw", position: "relative", height: "310px", maxWidth: 305, borderRadius: "10px", boxShadow: offer.flat_data.room === 1 ? "0 4px 4px rgba(0,0,0,.25)" : "0 4px 4px rgba(0,0,0,.25)", outline: offer.flat_data.urgent ? "4px solid #FF2B00" : offer.flat_data.priority_border ? "4px solid #0089CF" : 'none' }}>
            <div className='card-action-area'>
              <div className="additionalRow">
                <div className="additionalRowLeft">
                </div>
                <div className="additionalRowRight">
                  <img src={
                    localStorage.getItem('MyFavourites') ?
                      JSON.parse(localStorage.getItem('MyFavourites'))?.findIndex(item => item.id === offer.flat_data.id) !== -1 ?
                        HeartRed :
                        HeartWhite :
                      HeartWhite
                  }
                    alt='heart red' onClick={() => addInFavorite()} />
                </div>
              </div>

              <Link to={{ pathname: (window.location.pathname === "/" || window.location.pathname.includes("/elan/")) ? `/elan/${offer.flat_type ? offer.flat_type : offerType}/${offer.flat_data.id}` : `/elan/${offer.flat_type ? offer.flat_type : offerType}/${offer.flat_data.id}` }} state={{ offer }} onClick={() => clickOnLink()} >
                {offer.flat_data.images?.length > 1 &&
                  <div className='middleRow' style={{ top: 'calc(30% - 10px)' }}>
                    <div className='middleRow-left' onClick={(e) => {
                      e.preventDefault(); getLeftImg()
                    }}>
                      <ArrowBackIosRounded className='middleRow-left-icon' fontSize='large' sx={{ color: white }} />
                    </div>
                    <div className='middleRow-right' onClick={(e) => {
                      e.preventDefault(); getRightImg()
                    }}>
                      <ArrowForwardIosRounded className='middleRow-right-icon' fontSize='large' sx={{ color: white }} />
                    </div>
                  </div>
                }
                {offer.flat_data.urgent && <div className='urgent-offer'>{options[language].urgentSmall}</div>}
                <CardMedia
                  component="img"
                  height={200}
                  image={offer.flat_data.images !== null ? offer.flat_data.images[imgIndex]?.path || whiteFon : whiteFon}
                  alt={offer.title ? offer.title : "elan"}
                  loading='lazy'
                  onError={e => {
                    // console.log(e)
                    e.target.onerror = null;
                    e.target.src = whiteFon;
                  }}
                />
              </Link>
              <Link to={{ pathname: window.location.pathname === "/" || window.location.pathname.includes("/elan/") ? `/elan/${offer.flat_type ? offer.flat_type : offerType}/${offer.flat_data.id}` : `/secilmis-elanlar/${offer.flat_type ? offer.flat_type : offerType}/${offer.flat_data.id}` }} state={{ offer }} onClick={() => clickOnLink()}>
                <CardContent sx={{ padding: "10px", height: "110px", width: "100%" }}>
                  <hr className='horizontal' style={{ position: "absolute", top: "193px", color: "black" }} />
                  <Typography variant="body3" color="text.secondary" className='typography-price' sx={{ fontSize: "18px", position: "absolute", top: `${window.innerWidth >= 550 ? "192px" : "198px"}`, color: "white" }}>
                    {offer.flat_data.price?.toLocaleString('ru')} AZN{offer?.flat_type?.includes("rent") && offer?.flat_data?.rentForMonthOrDay === false ? options[language].perDays : ""}
                  </Typography>
                  <hr className='horizontal' style={{ position: "absolute", top: "229px", color: "black" }} />
                  <Typography className='city-and-link-data' variant="body2" color="text.secondary" sx={{ fontWeight: 500, width: "calc(100% + 0px)", fontSize: "16px", position: "absolute", top: "242px", color: "#222222", justifyContent: "flex-start", alignItems: "center", gap: "5px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: "calc(100% + 0px)" }}>
                      {/* {offer.flat_data.city !== null || offer.flat_data.region !== null || offer.flat_data.residential_area !== null ? <img style={{ width: "16px", height: "16px", marginTop: "-2px" }} src={locationIcon} alt='location' /> : <></>} */}
                      {offer.flat_data.city !== null ? options[language].cities[offer.flat_data.city].label : ""}
                      {offer.flat_data.region !== null && offer.flat_data.city === null ? options[language].cities[8].label : ""}
                      {offer.flat_data.region !== null ? ", " + options[language].region_options[offer.flat_data.region].label : ""}
                      {offer.flat_data.residential_area !== null && offer.flat_data.region === null ? ", " + options[language].resident_options[offer.flat_data.residential_area].region : ""}
                      {offer.flat_data.residential_area !== null ? ", " + options[language].resident_options[offer.flat_data.residential_area].label + options[language].residentsSmall : ""}
                    </div>
                  </Typography>
                  <Typography
                    className='room-area-and-floor-data'
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontWeight: 500,
                      fontSize: "16px",
                      position: "absolute",
                      top: "275px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "100%",
                      maxWidth: "calc(100% - 20px)",
                      color: "#000"
                    }}
                  >
                    {offer.flat_data.room && offer.flat_data.room + options[language].WithRoomsSmall}
                    {offer.flat_data.room && offer.flat_data.area && <hr className='vertical dot-vertical' />}
                    {offer.flat_data.area && (
                      <>
                        {Number(offer.flat_data.area).toFixed(2).replace(".00", "")}
                        {offer.flat_type === "landsales" || offer.flat_type === "landrents" ? " " + options[language].sot : " " + options[language].m2}
                      </>
                    )}
                    {offer.flat_data.floor && offer.flat_data.area && <hr className='vertical dot-vertical' />}
                    {offer.flat_data.floor && (
                      <>
                        {offer.flat_data.floor}/{offer.flat_data.max_floor} {options[language].floorSmall}
                      </>
                    )}
                  </Typography>

                  <hr className='horizontal' style={{ position: "absolute", top: "265px", color: "black" }} />

                </CardContent>
              </Link>
              <Typography className='city-and-link-data' variant="body2" color="text.secondary" sx={{ fontWeight: 500, fontSize: "14px", position: "absolute", top: "0px", color: "black", marginLeft: "10px" }}>
                <Link to={{ pathname: window.location.pathname === "/" || window.location.pathname.includes("/elan/") ? `/elan/${offer.flat_type ? offer.flat_type : offerType}/${offer.flat_data.id}` : `/secilmis-elanlar/${offer.flat_type ? offer.flat_type : offerType}/${offer.flat_data.id}` }} onClick={() => clickOnLink()}>
                  <span style={{ position:'absolute', top:"207px", right:'0', color:'#888888' }}>
                    {offer.flat_data.post_date ? `${new Date(offer?.flat_data?.post_date).getDate() === new Date().getDate() && new Date(offer?.flat_data?.post_date).getMonth() === new Date().getMonth() && new Date(offer?.flat_data?.post_date).getFullYear() === new Date().getFullYear() ? `${options[language].todaySmall}, ${new Date(offer?.flat_data?.post_date).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).replace("24:", "00:")}` : new Date(offer?.flat_data?.post_date).getDate() === new Date().getDate() - 1 && new Date(offer?.flat_data?.post_date).getMonth() === new Date().getMonth() && new Date(offer?.flat_data?.post_date).getFullYear() === new Date().getFullYear() ? `${options[language].yesterdaySmall}, ${new Date(offer?.flat_data?.post_date).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).replace("24:", "00:")}` : new Date(offer?.flat_data?.post_date).toLocaleString('ru-Ru', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/20\d{2}/, /\d{2}/)}` : ""}
                  </span>
                </Link>
                <Typography className='city-and-link-data-url-part' variant="body2" color="text.secondary" sx={{ fontWeight: 500, fontSize: "14px", position: "absolute", top: `176px`, color: "white", justifyContent:'flex-start', right:'-10px'}}>
                {changed ? <a style={{ zIndex: "5" }} href={"https://tap.az" + offer.flat_data.url} target="_blank" rel="nofollow" className='offer-url'>{offer.flat_data.url?.split("/")[2]}</a> : <a style={{ zIndex: "5" }} href={offer.flat_data.url}  rel="nofollow" className='offer-url' target="_blank">{offer.flat_data.url?.split("/")[2]}</a>} {offer?.flat_data?.url === null && <a style={{ zIndex: "5", textDecoration: "underline" }} href={`https://ebaz.az/elan/${offer.flat_type ? offer.flat_type : offerType}/${offer.flat_data.id}`} target="_blank" className='offer-url'>ebaz.az</a>}
                </Typography>
              </Typography>
            </div>
          </Card>
      }
    </>
  )
}

export default OfferCard