import React from 'react'

const LandImage = ({ color }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.58813 20.9989L6.72843 23.4004H1.23469L3.12209 20.9989H8.58813Z'
        stroke={color}
        strokeWidth='1.2'
      />
      <mask id='path-2-inside-1_1309_4712' fill='white'>
        <path d='M10.2699 19.8242H3.30078L7.00549 15.1091H8.91063H11.2372H13.9409L10.2699 19.8242Z' />
      </mask>
      <path
        d='M3.30078 19.8242L2.35719 19.0828L0.831849 21.0242H3.30078V19.8242ZM10.2699 19.8242V21.0242H10.8564L11.2167 20.5614L10.2699 19.8242ZM13.9409 15.1091L14.8878 15.8463L16.396 13.9091H13.9409V15.1091ZM7.00549 15.1091V13.9091H6.42224L6.0619 14.3677L7.00549 15.1091ZM3.30078 21.0242H10.2699V18.6242H3.30078V21.0242ZM11.2167 20.5614L14.8878 15.8463L12.9941 14.3719L9.32303 19.087L11.2167 20.5614ZM6.0619 14.3677L2.35719 19.0828L4.24437 20.5656L7.94907 15.8504L6.0619 14.3677ZM8.91063 13.9091H7.00549V16.3091H8.91063V13.9091ZM13.9409 13.9091H11.2372V16.3091H13.9409V13.9091ZM11.2372 13.9091H8.91063V16.3091H11.2372V13.9091Z'
        fill={color}
        mask='url(#path-2-inside-1_1309_4712)'
      />
      <path
        d='M14.2712 16.7317L15.0558 15.7089H22.9532L22.3526 16.7317H14.2712Z'
        stroke={color}
        strokeWidth='1.2'
      />
      <path
        d='M9.16581 23.4003L12.9674 18.5224H21.3081L18.425 23.4003H9.16581Z'
        stroke={color}
        strokeWidth='1.2'
      />
      <path
        d='M9.47734 6.40137C7.50088 6.40137 5.90234 8.02897 5.90234 10.0414C5.90234 12.7714 9.47734 16.8014 9.47734 16.8014C9.47734 16.8014 13.0523 12.7714 13.0523 10.0414C13.0523 8.02897 11.4538 6.40137 9.47734 6.40137ZM9.47734 11.3414C8.77256 11.3414 8.20056 10.759 8.20056 10.0414C8.20056 9.32377 8.77256 8.74137 9.47734 8.74137C10.1821 8.74137 10.7541 9.32377 10.7541 10.0414C10.7541 10.759 10.1821 11.3414 9.47734 11.3414Z'
        fill={color}
        stroke='white'
        strokeWidth='0.8'
      />
    </svg>
  )
}

export default LandImage
