import React from 'react'

const CountryImage = ({color}) => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.76172 11.6081L12.5241 3.5586C12.8082 3.3461 13.1983 3.34611 13.4824 3.55863L24.243 11.6081" stroke={color} strokeWidth="1.6" strokeLinecap="round"/>
<path d="M17.2285 6.37427V3.60019C17.2285 3.37928 17.4076 3.2002 17.6285 3.2002H20.6393C20.8602 3.2002 21.0393 3.37928 21.0393 3.6002V9.50138" stroke={color} strokeWidth="0.8"/>
<path d="M3.96094 10.0176L3.96095 22.3994C3.96095 22.8412 4.31912 23.1994 4.76094 23.1994L21.2422 23.1996C21.684 23.1996 22.0422 22.8414 22.0422 22.3996L22.0422 10.0176" stroke={color} strokeWidth="1.6" strokeLinecap="round"/>
<mask id="path-4-inside-1_1309_4703" fill="white">
<path d="M24.2 22.3999H1.8C1.35817 22.3999 1 22.7581 1 23.1999C1 23.6417 1.35817 23.9999 1.8 23.9999H24.2C24.6418 23.9999 25 23.6417 25 23.1999C25 22.7581 24.6418 22.3999 24.2 22.3999Z"/>
</mask>
<path d="M1.8 23.9999H24.2V20.7999H1.8V23.9999ZM24.2 22.3999H1.8V25.5999H24.2V22.3999ZM1.8 22.3999C2.24183 22.3999 2.6 22.7581 2.6 23.1999H-0.6C-0.6 24.5254 0.474514 25.5999 1.8 25.5999V22.3999ZM23.4 23.1999C23.4 22.7581 23.7582 22.3999 24.2 22.3999V25.5999C25.5255 25.5999 26.6 24.5254 26.6 23.1999H23.4ZM24.2 23.9999C23.7582 23.9999 23.4 23.6417 23.4 23.1999H26.6C26.6 21.8744 25.5255 20.7999 24.2 20.7999V23.9999ZM1.8 20.7999C0.474516 20.7999 -0.6 21.8744 -0.6 23.1999H2.6C2.6 23.6417 2.24183 23.9999 1.8 23.9999V20.7999Z" fill={color} mask="url(#path-4-inside-1_1309_4703)"/>
<path d="M7.1207 23.2002V13.7404C7.1207 13.5195 7.29979 13.3404 7.5207 13.3404H12.3207C12.5416 13.3404 12.7207 13.5195 12.7207 13.7404V23.2002C12.7207 23.4211 12.5416 23.6002 12.3207 23.6002H7.5207C7.29979 23.6002 7.1207 23.4211 7.1207 23.2002Z" stroke={color} strokeWidth="0.8"/>
<path d="M15.2297 13.3409H17.2297H19.2297C19.4506 13.3409 19.6297 13.52 19.6297 13.7409V15.7409V17.7409C19.6297 17.9618 19.4506 18.1409 19.2297 18.1409H17.2297H15.2297C15.0088 18.1409 14.8297 17.9618 14.8297 17.7409V15.7409V13.7409C14.8297 13.52 15.0088 13.3409 15.2297 13.3409Z" stroke={color} strokeWidth="0.8"/>
</svg>

  )
}

export default CountryImage