import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import Loading from './Components/Loading/Loading'
import App from './App'

// const LazyApp = React.lazy(() => import('./App'))

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route
          path='*'
          element={
            <Suspense fallback={<Loading/>}>
              <App/>
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  </Provider>
)
reportWebVitals()
