import React from 'react'

const Kabinet = ({ color }) => {
  return (
    <svg
      width='26.5'
      height='25'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.9388 5.83366C13.9388 7.78967 12.3531 9.37533 10.3971 9.37533C8.44113 9.37533 6.85547 7.78967 6.85547 5.83366C6.85547 3.87765 8.44113 2.29199 10.3971 2.29199C12.3531 2.29199 13.9388 3.87765 13.9388 5.83366Z'
        stroke={color ? color : '#000'}
        strokeWidth='1.25'
      />
      <path
        d='M16.4388 14.2134C16.4388 14.8956 15.9444 15.6409 14.8419 16.2509C13.7576 16.8507 12.1994 17.2432 10.3946 17.2432C8.5899 17.2432 7.033 16.8507 5.95008 16.251C4.84918 15.6413 4.35547 14.8961 4.35547 14.2134C4.35547 13.5491 4.88227 12.7999 6.03076 12.1788C7.14921 11.574 8.71014 11.1836 10.3946 11.1836C12.0817 11.1836 13.6439 11.574 14.7629 12.1789C15.912 12.8 16.4388 13.5492 16.4388 14.2134Z'
        stroke={color ? color : '#000'}
        strokeWidth='1.25'
      />
    </svg>
  )
}

export default Kabinet
