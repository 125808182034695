import React, { useState, useEffect } from 'react'
import './Offers.css'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import whiteFon from '../../images/whitefon.jpg'
import { options } from '../../constants'
import { useSelector } from 'react-redux'
import HeartWhite from '../../images/blurHeart.svg'
import locationIcon from '../../images/locationIcon.svg'
import { white } from 'material-ui/styles/colors'
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded
} from '@mui/icons-material'

const OfferCard = ({ offer }) => {
  const [url, setUrl] = useState(offer?.url?.split('/')[2])
  const [, setChanged] = useState(false)
  const offerType = useSelector(state => state.offerType)
  const language = useSelector(state => state.language)
  const [isFavorite, setIsFavorite] = useState(false)
  const [imgIndex, setImgIndex] = useState(0)
  if (url === 'dasinmaz-emlak') {
    setUrl('tap.az')
    setChanged(true)
  }
  useEffect(() => {
    const favoritesData = localStorage.getItem('MyFavourites')
    if (favoritesData) {
      const isObjectInFavorites =
        JSON.parse(favoritesData)?.findIndex(item => item.id === offer?.id) !==
        -1

      setIsFavorite(isObjectInFavorites)
    }
  }, [offer?.id])
  function addOrRemoveObjectFromLocalStorage (obj) {
    const existingData = localStorage.getItem('MyFavourites')
    if (existingData) {
      const dataArray = JSON.parse(existingData)
      const existingIndex = dataArray.findIndex(item => item.id === obj.id)
      if (existingIndex !== -1) {
        dataArray.splice(existingIndex, 1)
      } else {
        dataArray.push(obj)
      }
      localStorage.setItem('MyFavourites', JSON.stringify(dataArray))
    } else {
      const newDataArray = [obj]
      localStorage.setItem('MyFavourites', JSON.stringify(newDataArray))
    }
    setIsFavorite(!isFavorite)
  }
  const addInFavorite = () => {
    addOrRemoveObjectFromLocalStorage(offer)
  }
  const getLeftImg = () => {
    if (imgIndex !== 0) {
      let index = imgIndex - 1
      setImgIndex(index)
    } else {
      setImgIndex(offer?.images.length - 1)
    }
  }
  const getRightImg = () => {
    if (imgIndex < offer?.images.length - 1) {
      let index = imgIndex + 1
      setImgIndex(index)
    } else {
      setImgIndex(0)
    }
  }
  return (
    <Card
      sx={{
        width: '42vw',
        height: '310px',
        maxWidth: 305,
        marginBottom: '26px',
        borderRadius: '15px',
        boxShadow: ' 0 4px 4px rgba(0,0,0,.25)',
        transition: 'none'
      }}
    >
      <CardActionArea
        className='card-action-area'
        disableRipple
        onClick={e => e.preventDefault()}
      >
        <div className='additionalRow'>
          <div className='additionalRowLeft'>
            {/* <FaRegHeart color='white' size={25} /> */}
          </div>
          <div className='additionalRowRight'>
            {/* <FaRegHeart color='white' size={25} fill='white' /> */}
            <img src={HeartWhite} alt='heart red' />
          </div>
        </div>

        {offer?.selectedImage.length > 1 && (
          <div className='middleRow'>
            <div
              className='middleRow-left'
              onClick={e => {
                e.preventDefault()
                getLeftImg()
              }}
              role='button'
            >
              <ArrowBackIosRounded
                className='middleRow-left-icon'
                fontSize='large'
                sx={{ color: white }}
              />
            </div>
            <div
              className='middleRow-right'
              onClick={e => {
                e.preventDefault()
                getRightImg()
              }}
            >
              <ArrowForwardIosRounded
                className='middleRow-right-icon'
                fontSize='large'
                sx={{ color: white }}
              />
            </div>
          </div>
        )}
        <CardMedia
          component='img'
          height='200'
          image={
            offer?.selectedImage.length
              ? typeof offer?.selectedImage[0] === 'string'
                ? offer?.selectedImage[0]
                : URL.createObjectURL(offer?.selectedImage[0])
              : whiteFon
          }
          alt={offer.title ? offer.title : "elan"}
          loading='lazy'
          onError={e => {
            e.target.src = whiteFon
          }}
        />
        <CardContent
          sx={{ padding: '10px', height: '110px', width: '100%' }}
          disableTouchRipple={false}
        >
          <Typography
            variant='body3'
            color='text.secondary'
            className='typography-price'
            sx={{
              fontSize: '18px',
              position: 'absolute',
              top: '154px',
              color: 'white'
            }}
          >
            {offer?.price?.toLocaleString('ru')} AZN{offer?.type?.includes("rent") && offer?.rentForMonthOrDay === false ? options[language].perDays : ""}
          </Typography>
          <Typography
            className='city-and-link-data'
            variant='body2'
            color='text.secondary'
            sx={{
              fontWeight: 500,
              width: 'calc(100% + 0px)',
              fontSize: '16px',
              position: 'absolute',
              top: '210px',
              color: '#222222',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '5px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: 'calc(100% + 0px)',
                marginLeft: '-2px'
              }}
            >
              {offer?.city
                ? offer?.city?.label
                  ? ' '
                  : options[language].cityName
                : options[language].cityName}
              {offer?.region && offer?.city ? options[language].cities[8]?.label : ''}
              {offer?.region ? ', ' + offer?.region?.label : ''}
              {offer?.resident && offer?.region
                ? ', ' + offer?.resident?.label + options[language].residentsSmall
                : ''}
            </div>
          </Typography>
          <Typography
            className='room-area-and-floor-data'
            variant='body2'
            color='text.secondary'
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              position: 'absolute',
              top: '235px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '100%',
              maxWidth: 'calc(100% - 20px)'
            }}
          >
            {offer?.rooms && offer?.rooms + options[language].WithRoomsSmall}
            {offer?.rooms && offer?.area && <hr className='vertical dot-vertical' />}
            {offer?.area && (
              <>
                {Number(offer?.area).toFixed(2).replace('.00', '')}
                {offer?.type === 'landsales' || offer?.type === 'landrents' ?
                   " " + options[language].sot : " " + options[language].m2}
              </>
            )}
            {offer?.floor && offer?.area && <hr className='vertical dot-vertical' />}
            {offer?.floor && (
              <>
                {offer?.floor}/{offer?.max_floor} {options[language].floorSmall}
              </>
            )}
          </Typography>

          <hr
            className='horizontal'
            style={{ position: 'absolute', top: '265px', color: 'black' }}
          />
          <Typography
            className='city-and-link-data'
            variant='body2'
            color='text.secondary'
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              position: 'absolute',
              bottom: '10px',
              color: 'black'
            }}
          >
            {`${options[language].todaySmall}, ${new Date()
              .toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
              })
              .replace('24:', '00:')}`}
            {
              <a
                href={`https://ebaz.az/elan/${
                  offer?.type ? offer?.type : offerType
                }/${offer?.id}`}
                target='_blank'
                className='tap ataglength'
                style={{ textDecoration: 'underline' }}
              >
                ebaz.az
              </a>
            }
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default OfferCard
