import React from "react";
import "./MiniBanner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/autoplay";
import "swiper/css";
import "swiper/css/effect-fade";
import { Autoplay, EffectFade } from "swiper/modules";
import { useSelector } from "react-redux";
import { options } from "../../constants";

const MiniBanner = () => {
  const isMobile = useSelector((state) => state.isMobile);
  const language = useSelector((state) => state.language);
  return (
    <div className="mini-banner">
      <Swiper
        spaceBetween={0}
        slidesPerView={isMobile ? 1 : 1}
        speed={1000}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        style={{
          height: "100%",
        }}
        loop={true}
      >
        {options?.[language]?.miniBannerWords?.map((word, index) => {
          return (
            <SwiperSlide
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                color: "white",
                fontFamily: "Inter",
              }}
            >
              {/* <span>{word}</span> */}
              <span>{word}</span>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default MiniBanner;
