import React, { useState } from "react";
import "./MobileHeader.css";
import { useDispatch, useSelector } from "react-redux";
import EbazLogo from "../images/ebaz_logo.png";
import { options } from "../../constants";
import { useNavigate } from "react-router-dom";
import { openSearchByUrlPopUp } from "../../redux/action";
import LinkIcon from "./link.svg";
import DownV from "./downV.svg";
import Globus from "./globus.svg";

const MobileHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchByUrlPopUp = useSelector((state) => state.searchByUrlPopUp);
  const language = useSelector((state) => state.language);
  const [languageBar, openLanguageBar] = useState(false);
  const changeLanguage = (language) => {
    openLanguageBar(!languageBar);
    if (localStorage.getItem("language") !== language) {
      const url = new URL(window.location.href);
      url.searchParams.delete("ru");
      url.searchParams.delete("az");
      url.searchParams.delete("en");
      url.searchParams.set(language, "");
      window.localStorage.setItem("language", language);
      window.location.href = url.toString().replace(/=$|=(?=&)/g, "");
      // dispatch(changeSiteLanguage(language))
      //   window.location.reload()
    }
  };
  return (
    <header className="header">
      <div className="header-content">
        <div className="header-logo-part">
          <a className="navbar-brand" href="/">
            <img
              src={EbazLogo}
              alt="Ebaz Logo"
              style={{ width: "85px", height: "28px" }}
              onClick={() => navigate("/")}
            />
          </a>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height:'100%',
            gap:'20px',
            marginTop:'10px',
            marginBottom:'-2px',
          }}
        >
          <div
            className="nav-item d-none d-md-block"
            onClick={() => openLanguageBar(!languageBar)}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <img className="imagesizest" alt="Green Heart" src={Globus} style={{
                marginRight:"3px"
            }}/>
            {language.toUpperCase()}
            <img
              className={`dropdown  ${languageBar ? "dropOpen" : "dropClose"}`}
              alt=" Green Heart"
              src={DownV}
              style={{
                width: "10px",
                transform: languageBar ? "rotate(180deg)" : "none",
                transition: "transform 0.5s ease",
              }}
            />
            <div
              className={`languageDropDown mobile  ${
                languageBar ? "languageOpen" : "languageClose"
              }`}
              style={{ position: "absolute" }}
            >
              <span onClick={() => changeLanguage("az")}>AZ</span>
              <span onClick={() => changeLanguage("ru")}>RU</span>
              {/* <span onClick={() => changeLanguage('en')}>EN</span> */}
            </div>
          </div>
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => dispatch(openSearchByUrlPopUp(!searchByUrlPopUp))}
          >
            <img className="imagesizest" alt=" Green Heart" src={LinkIcon} />
            {options[language].findOffer}
          </span>
        </div>
      </div>
    </header>
  );
};
export default MobileHeader;
