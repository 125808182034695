import React, { useEffect, useState, useLayoutEffect } from "react";
import "./Offer.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import OfferCard from "../../Components/Offers/OfferCard";
import axios from "axios";
import { FaDownLong } from "react-icons/fa6";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CircularProgress from "@mui/material/CircularProgress";
import CallIcon from "@mui/icons-material/Call";
import ImageGallery from "react-image-gallery";
import GoogleMapsOnePoint from "./GoogleMapsOnePoint";
import { useDispatch, useSelector } from "react-redux";
import {
  clickOnPhoneNum,
  getByIdAction,
  loadingBoostButtonAction,
  loadingPremiumButtonAction,
  makeBoostOfferApi,
  makePremiumOfferApi,
  searchSimilar,
  setShowModal,
} from "../../redux/action";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HeartWhite from "../../images/blurHeart.svg";
import BoostIcon from "../../img/boostSVG.svg";
import PremiumIcon from "../../img/premiumSVG.svg";
import whiteFon from "../../images/whitefon.jpg";
import { options } from "../../constants";
import Loading from "../../Components/Loading/Loading";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Frame from "./Frame";
import Urgent from "./Urgent";
import PremiumIcon2 from "./premiumIcon.svg";
import CrownIcon from "../../Components/images/CrownCard.svg";
import mobileHeaderBack from "./backToMobile.svg";
import mobileHeaderLogo from "./mobileHeaderLogo.svg";
import mobileHeaderShare from "./mobileHeaderShare.svg";
import mobileHeaderHeart from "./mobileHeaderHeart.svg";
import mobileHeaderHeartRed from "./mobileHeaderHeartRed.svg";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { IconButton, Snackbar } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import XIconBlack from "../../images/xBlack.svg";
import XIcon from "../../images/x.svg";
import Top from "./Top";
import Predict from "../../Components/PredictGraph/Predict";
const Offer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const offerDetail = useSelector((state) => state.offerDetail);
  const isMobile = useSelector((state) => state.isMobile);
  const loadingPremiumButton = useSelector(
    (state) => state.loadingClickOnMakePremiumButton
  );
  const loadingBoostButton = useSelector(
    (state) => state.loadingClickOnMakeBoostButton
  );
  const language = useSelector((state) => state.language);
  const [url, setUrl] = useState();
  const [changed, setChanged] = useState(false);
  const [filteredArray, setFilteredArray] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [open, setOpen] = useState(false);
  const [predictData, setPredictData] = useState([]);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [showFullScreenVideo, setShowFullScreenVideo] = useState(false);
  const [phoneNumClicked, setPhoneNumClicked] = useState(false);
  const lastSearchUrl = useSelector((state) => state.lastSearchUrl);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [typeOfReklam, setTypeOfReklam] = useState("boost");
  const [selectedTarif, setSelectedTarif] = useState("1");
  const showMap = useSelector((state) => state.showOrCloseMap);
  const [additionalsForPremium, SetAdditionalsForPremium] = useState("");
  const handleClickToCopy = () => {
    setOpen(true);
    navigator?.clipboard?.writeText(
      window?.location?.toString()?.replace("secilmis-elanlar", "elan")
    );
  };
  const closeFullscreen = (e) => {
    if (e.target.className !== "image-gallery-image") {
      setShowFullScreen(false);
    }
  };
  useLayoutEffect(() => {
    if (offerDetail.id) {
      let ogTitle = document.getElementById("ogTitle");
      let ogDescription = document.getElementById("ogDescription");
      let ogImage = document.getElementById("ogImage");
      let offerDetail2 = {
        title: `${offerDetail?.title}`,
        details: `${offerDetail?.details}`,
        imageUrl: `${
          filteredArray.length ? filteredArray[0].original : whiteFon
        }`,
      };
      ogTitle.setAttribute("content", `Ebaz.az - ${offerDetail2.title}`);
      ogDescription.setAttribute("content", `${offerDetail2.details}`);
      ogImage.setAttribute("content", `${offerDetail2.imageUrl}`);
      document.title = `Ebaz.az - ${offerDetail?.title}`;
    }
  }, [offerDetail, filteredArray, whiteFon]);
  useEffect(() => {
    if (
      window.location.pathname.split("/")[2] &&
      window.location.pathname.split("/")[3]
    )
      dispatch(
        getByIdAction(
          window.location.pathname.split("/")[2],
          window.location.pathname.split("/")[3]
        )
      );
  }, []);
  useEffect(() => {
    if (
      window.location.pathname.split("/")[2] &&
      window.location.pathname.split("/")[3]
    )
      dispatch(
        getByIdAction(
          window.location.pathname.split("/")[2],
          window.location.pathname.split("/")[3]
        )
      );
  }, [window.location.pathname]);
  useEffect(() => {
    // PREDICT FETCH const fetchData = async (
    //   flatType,
    //   offerType,
    //   city,
    //   province,
    //   residents
    // ) => {
    //   axios
    //     .post('https://api.ebaz.az/predictPrice', {
    //       flat_type: flatType,
    //       offer_type: offerType,
    //       city: city ? city - 0 : 8,
    //       region:
    //         city === '8' || city || city === 8
    //           ? province
    //             ? province - 0
    //             : null
    //           : null,
    //       resident:
    //         city === '8' || city || city === 8
    //           ? residents
    //             ? residents - 1
    //             : null
    //           : null
    //     })
    //     .then(response => {
    //       setPredictData(response.data)
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // }
    if (offerDetail?.images) {
      const mappedArray = offerDetail.images.map((image) => {
        return {
          original: image.path,
          thumbnail: image.path,
        };
      });
      mappedArray?.length && setFilteredArray(mappedArray);
    }
    if (offerDetail.length !== 0) {
      dispatch(
        searchSimilar(
          window.location.pathname.split("/")[2],
          offerDetail?.city,
          offerDetail?.price,
          offerDetail?.id
        )
      );
      // PREDICT FETCH fetchData(
      //   window.location.pathname
      //     ?.split('/')[2]
      //     ?.replace('sales', '')
      //     ?.replace('rents', ''),
      //   window.location.pathname.split('/')[2]?.includes('sales')
      //     ? 'sales'
      //     : 'rents',
      //   offerDetail?.city,
      //   offerDetail?.region,
      //   offerDetail?.residential_area
      // )
    }
  }, [offerDetail]);
  useEffect(() => {
    try {
      setUrl(offerDetail.url.split("/")[2]);
      if (url === "dasinmaz-emlak") {
        setUrl("tap.az");
        setChanged(true);
      }
    } catch {}
  }, [offerDetail.url]);

  const makeBoostOfferAction = () => {
    setShowPaymentModal(true);
  };
  const makePremiumOfferAction = () => {
    setShowPremiumModal(true);
  };
  const showFullscreenVideoF = () => {
    offerDetail?.status === 1 && setShowFullScreenVideo(true);
  };
  const premiumOffer = (offerId) => {
    dispatch(loadingPremiumButtonAction(true));
    let premiumPrice = options[language].packets["premium"].find(
      (option) => option.dayOrTime === selectedTarif
    )?.price;
    dispatch(
      makePremiumOfferApi(
        additionalsForPremium === "frame"
          ? (Number(premiumPrice) + 0.99).toFixed(2)
          : additionalsForPremium === "urgent"
          ? (Number(premiumPrice) + 1.99).toFixed(2)
          : Number(premiumPrice),
        offerId,
        localStorage.getItem("id"),
        selectedTarif,
        additionalsForPremium === "frame" ? true : false,
        additionalsForPremium === "urgent" ? true : false,
        window.location.pathname.split("/")[2]
      )
    );
  };
  const calculatePercantage = (data) => {
    return (
      ((data[data?.length - 1]?.avg_price_per_m2 -
        data[data?.length - 2]?.avg_price_per_m2) /
        data[data?.length - 2]?.avg_price_per_m2) *
      100
    ).toFixed(2);
  };
  const boostOffer = (offerId) => {
    dispatch(loadingBoostButtonAction(true));
    let premiumPrice = options[language].packets[typeOfReklam].find(
      (option) => option.dayOrTime === selectedTarif
    )?.price;
    dispatch(
      makeBoostOfferApi(
        premiumPrice,
        offerId,
        localStorage.getItem("id"),
        selectedTarif,
        window.location.pathname.split("/")[2],
        typeOfReklam
      )
    );
  };
  const addInFavorite = () => {
    addOrRemoveObjectFromLocalStorage(offerDetail);
  };
  function addOrRemoveObjectFromLocalStorage(obj) {
    const existingData = localStorage.getItem("MyFavourites");
    if (existingData) {
      const dataArray = JSON.parse(existingData);
      const existingIndex = dataArray.findIndex((item) => item.id === obj.id);
      if (existingIndex !== -1) {
        dataArray.splice(existingIndex, 1);
      } else {
        dataArray.push(obj);
      }
      localStorage.setItem("MyFavourites", JSON.stringify(dataArray));
    } else {
      const newDataArray = [obj];
      localStorage.setItem("MyFavourites", JSON.stringify(newDataArray));
    }
    setIsFavorite(!isFavorite);
  }
  const handleImageClick = async () => {
    dispatch(setShowModal(false));
    if (window.location.href.includes("secilmis-elanlar")) {
      navigate("/secilmis-elanlar");
    } else if (lastSearchUrl !== "/" && lastSearchUrl !== undefined) {
      await navigate("/");
      navigate(`/${lastSearchUrl}`);
    } else {
      navigate("/");
      window.location.reload();
    }
  };
  const offers = useSelector((state) => state.defaultSimilarOffers);
  const defaultOffers = Object.values(offers).map((offer, index) => (
    <OfferCard key={index} offer={offer} />
  ));
  return (
    <div
      className="offer"
      style={{
        position: "relative",
        overflow: showPaymentModal || showPremiumModal ? "hidden" : "auto",
        borderRadius: showPaymentModal || showPremiumModal ? "0" : "20px",
      }}
    >
      {offerDetail.id ? (
        <>
          {!showFullScreen ? (
            !isMobile ? (
              <div
                style={{
                  position: "sticky",
                  top: "0",
                  width: "100%",
                  backgroundColor: "white",
                  zIndex: "2",
                  padding: "0 3%",
                }}
              >
                <img
                  onClick={() => handleImageClick()}
                  src={XIconBlack}
                  alt="close icon"
                  style={{
                    height: "40px",
                    position: "absolute",
                    right: "0px",
                    top: "8px",
                    cursor: "pointer",
                    zIndex: "1",
                  }}
                />
                <div className="road" style={{ position: "relative" }}>
                  {window.location.pathname.split("/")[2]?.includes("sale")
                    ? options[language].sale
                    : options[language].rent}
                  {window.location.pathname
                    .split("/")[2]
                    ?.replace("sales", "")
                    .replace("rents", "") === "flat"
                    ? " / " + options[language].flat_type_options[0].label
                    : ""}
                  {window.location.pathname
                    .split("/")[2]
                    ?.replace("sales", "")
                    .replace("rents", "") === "country"
                    ? " / " + options[language].flat_type_options[1].label
                    : ""}
                  {/* {window.location.pathname.split("/")[2]?.replace("sales", "").replace("rents", "") === "villa" ? " / Villa " : ""} */}
                  {window.location.pathname
                    .split("/")[2]
                    ?.replace("sales", "")
                    .replace("rents", "") === "office"
                    ? " / " + options[language].flat_type_options[2].label
                    : ""}
                  {window.location.pathname
                    .split("/")[2]
                    ?.replace("sales", "")
                    .replace("rents", "") === "garage"
                    ? " / " + options[language].flat_type_options[3].label
                    : ""}
                  {window.location.pathname
                    .split("/")[2]
                    ?.replace("sales", "")
                    .replace("rents", "") === "land"
                    ? " / " + options[language].flat_type_options[4].label
                    : ""}
                  {window.location.pathname
                    .split("/")[2]
                    ?.replace("sales", "")
                    .replace("rents", "") === "commercial"
                    ? " / " + options[language].flat_type_options[5].label
                    : ""}
                  {offerDetail?.city
                    ? " / " + options[language].cities[offerDetail?.city]?.label
                    : ""}
                </div>
                <hr className="roadHr" />
              </div>
            ) : (
              <>
                <div
                  style={{
                    position: "sticky",
                    top: "0",
                    width: "100%",
                    backgroundColor: "white",
                    zIndex: "2",
                    padding: "5px 3%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      width: "55px",
                      height: "20px",
                    }}
                  >
                    <img
                      src={mobileHeaderBack}
                      alt="back to offers"
                      onClick={() => handleImageClick()}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      height: "36px",
                    }}
                  >
                    <img
                      src={mobileHeaderLogo}
                      alt="logo"
                      onClick={() => handleImageClick()}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      width: "55px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={mobileHeaderShare}
                      alt="share"
                      onClick={handleClickToCopy}
                    />
                    <img
                      src={
                        localStorage.getItem("MyFavourites")
                          ? JSON.parse(
                              localStorage.getItem("MyFavourites")
                            )?.findIndex(
                              (item) => item.id === offerDetail.id
                            ) !== -1
                            ? mobileHeaderHeartRed
                            : mobileHeaderHeart
                          : mobileHeaderHeart
                      }
                      alt="heart"
                      onClick={() => addInFavorite()}
                    />
                  </div>
                </div>
                <div
                  className="road"
                  style={{
                    fontSize: "14px",
                    width: "95%",
                    marginLeft: "2.5%",
                    padding: "5px 0",
                  }}
                >
                  {window.location.pathname.split("/")[2]?.includes("sale")
                    ? options[language].sale
                    : options[language].rent}
                  {window.location.pathname
                    .split("/")[2]
                    ?.replace("sales", "")
                    .replace("rents", "") === "flat"
                    ? " / " + options[language].flat_type_options[0].label
                    : ""}
                  {window.location.pathname
                    .split("/")[2]
                    ?.replace("sales", "")
                    .replace("rents", "") === "country"
                    ? " / " + options[language].flat_type_options[1].label
                    : ""}
                  {/* {window.location.pathname.split("/")[2]?.replace("sales", "").replace("rents", "") === "villa" ? " / Villa " : ""} */}
                  {window.location.pathname
                    .split("/")[2]
                    ?.replace("sales", "")
                    .replace("rents", "") === "office"
                    ? " / " + options[language].flat_type_options[2].label
                    : ""}
                  {window.location.pathname
                    .split("/")[2]
                    ?.replace("sales", "")
                    .replace("rents", "") === "garage"
                    ? " / " + options[language].flat_type_options[3].label
                    : ""}
                  {window.location.pathname
                    .split("/")[2]
                    ?.replace("sales", "")
                    .replace("rents", "") === "land"
                    ? " / " + options[language].flat_type_options[4].label
                    : ""}
                  {window.location.pathname
                    .split("/")[2]
                    ?.replace("sales", "")
                    .replace("rents", "") === "commercial"
                    ? " / " + options[language].flat_type_options[5].label
                    : ""}
                  {offerDetail?.city
                    ? " / " + options[language].cities[offerDetail?.city]?.label
                    : ""}
                </div>
              </>
            )
          ) : (
            <></>
          )}
          <div className="offer-content">
            {!isMobile && (
              <div className="offer-content-top">
                <div className="offer-content-title">{offerDetail?.title}</div>
                <div className="offer-content-helper">
                  <div className="offer-content-helper-heart">
                    <div
                      className="boostOfferButton"
                      onClick={() => makeBoostOfferAction()}
                    >
                      <img src={BoostIcon} alt="boost" />
                      {options[language].boostNameSmall}
                    </div>
                    <div
                      className="premiumOfferButton"
                      onClick={() => makePremiumOfferAction()}
                    >
                      <img src={PremiumIcon} alt="boost" />
                      {options[language].makePremiumNameSmall}
                    </div>
                  </div>
                  <div
                    className="offer-content-helper-block heartblockwidth"
                    style={{ cursor: "pointer", opacity: "1" }}
                    onClick={() => addInFavorite()}
                  >
                    {localStorage.getItem("MyFavourites") ? (
                      JSON.parse(
                        localStorage.getItem("MyFavourites")
                      )?.findIndex((item) => item.id === offerDetail.id) !==
                      -1 ? (
                        <div>
                          <FavoriteIcon size={20} sx={{ color: "#ff2b00" }} />{" "}
                          {options[language].deleteFromFavouritesNameSmall}
                        </div>
                      ) : (
                        <div>
                          <FavoriteBorderIcon size={20} />{" "}
                          {options[language].addToFavouritesNameSmall}
                        </div>
                      )
                    ) : (
                      <div>
                        <FavoriteBorderIcon size={20} />{" "}
                        {options[language].addToFavouritesNameSmall}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {filteredArray.length ? (
              !isMobile ? (
                <div className="offer-content-average-left-images">
                  <div className="images-left">
                    <div
                      className={`images-left-div ${
                        offerDetail?.status === 1 ? "" : "deleted"
                      }`}
                      style={{
                        position: "relative",
                      }}
                      onClick={() =>
                        offerDetail?.status === 1 && setShowFullScreen(true)
                      }
                    >
                      <img
                        src={filteredArray[0].original}
                        alt={offerDetail.title ? offerDetail.title : "elan"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = whiteFon;
                        }}
                      />
                      {offerDetail?.status !== 1 && (
                        <div className="isofferDeletedDiv">
                          {
                            options[language][
                              window.location.pathname
                                .split("/")[2]
                                ?.includes("sale")
                                ? "saleDeleted"
                                : "rentDeleted"
                            ]
                          }
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="images-right">
                    {filteredArray.map((image, index) => {
                      if (index <= 3 && index < filteredArray.length - 1) {
                        return (
                          <div
                            className={`images-right-div ${
                              offerDetail?.status === 1 ? "" : "deleted"
                            }`}
                            onClick={() =>
                              offerDetail?.status === 1 &&
                              setShowFullScreen(true)
                            }
                            key={index + 1}
                          >
                            <img
                              src={filteredArray[index + 1]?.original}
                              alt={
                                offerDetail.title ? offerDetail.title : "elan"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = whiteFon;
                              }}
                            />

                            {offerDetail?.status !== 1 && (
                              <div className="isofferDeletedDiv"></div>
                            )}
                            {index === 3 && (
                              <div className="images-right-div-more">
                                <span>
                                  {window.innerWidth >= 640 &&
                                    options[language].moreSmall + " "}
                                  +{filteredArray.length - 4}
                                </span>
                              </div>
                            )}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              ) : (
                <div className="offer-content-average-left-images mobile-images">
                  <ImageGallery
                    items={filteredArray}
                    showNav={isMobile ? false : true}
                    showThumbnails={true}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    disableThumbnailSwipe={false}
                    disableSwipe={false}
                    lazyLoad={true}
                    slideDuration={isMobile ? 300 : 0}
                    disableThumbnailScroll={false}
                    onClick={(e) => setShowFullScreen(true)}
                    onImageError={(e) => {
                      e.target.onerror = null;
                      e.target.src = whiteFon;
                    }}
                    onThumbnailError={(e) => {
                      e.target.onerror = null;
                      e.target.src = whiteFon;
                    }}
                  />
                </div>
              )
            ) : (
              <></>
            )}
            <div
              className="offer-content-average"
              style={{ marginTop: isMobile && "30px" }}
            >
              <div className="offer-content-average-left">
                {offerDetail?.video !== null && (
                  <div
                    className="offer-content-watch-video"
                    style={{
                      marginBottom: isMobile && "20px",
                    }}
                    onClick={(e) => showFullscreenVideoF()}
                  >
                    {options[language].watchVideo}
                  </div>
                )}
                <hr
                  style={{ margin: isMobile ? "-17px 0 17px 0" : "20px 0" }}
                />
                {!isMobile ? (
                  <div className="offer-content-average-left-tags">
                    <div className="offer-content-average-left-tags-l">
                      <div className="offer-content-average-left-tags-l-tags">
                        {offerDetail?.city !== null && (
                          <span>{options[language].cityName}</span>
                        )}
                        {offerDetail?.region !== null && (
                          <span>{options[language].regionName}</span>
                        )}
                        <span>
                          {options[language].newOffer_offerType.replace(
                            ", ",
                            ""
                          )}
                        </span>
                        {/* <span>Binanın tipi</span> */}
                      </div>
                      <div className="offer-content-average-left-tags-l-desc">
                        {offerDetail?.city !== null && (
                          <span>
                            {options[language].cities[offerDetail?.city]?.label}
                          </span>
                        )}
                        {offerDetail?.region !== null && (
                          <span>
                            {
                              options[language].region_options[
                                offerDetail?.region
                              ]?.label
                            }
                          </span>
                        )}
                        <span>
                          {window.location.pathname
                            .split("/")[2]
                            ?.includes("sale")
                            ? options[language].sale
                            : options[language].rent}
                        </span>
                        {/* <span>Yeni tikili</span> */}
                      </div>
                    </div>
                    <div className="offer-content-average-left-tags-r">
                      <div className="offer-content-average-left-tags-r-tags">
                        {offerDetail?.area !== null && (
                          <span>
                            {window.location.pathname
                              .split("/")[2]
                              ?.replace("sales", "")
                              .replace("rents", "") === "land"
                              ? options[language].newOffer_AreaSot.replace(
                                  ", ",
                                  ""
                                )
                              : options[language].newOffer_AreaM2.replace(
                                  ", ",
                                  ""
                                )}
                          </span>
                        )}
                        {offerDetail?.room && (
                          <span>{options[language].roomsCountName}</span>
                        )}
                        {offerDetail?.residential_area !== null && (
                          <span>{options[language].positionOfOffer}</span>
                        )}
                        {(offerDetail.max_floor || offerDetail.floor) && (
                          <span>
                            {options[language].floorName.replace(":", "")}
                          </span>
                        )}
                      </div>
                      <div className="offer-content-average-left-tags-r-desc">
                        {offerDetail?.area !== null && (
                          <span>
                            {Number(offerDetail?.area)
                              .toFixed(2)
                              .replace(".00", "")}
                          </span>
                        )}
                        {offerDetail?.room !== null && (
                          <span>{offerDetail?.room}</span>
                        )}
                        {offerDetail?.residential_area !== null && (
                          <span>
                            {
                              options[language].resident_options[
                                offerDetail?.residential_area
                              ]?.label
                            }
                          </span>
                        )}
                        {(offerDetail.max_floor || offerDetail.floor) && (
                          <span>
                            {offerDetail?.floor}{" "}
                            {offerDetail.floor && offerDetail.max_floor
                              ? "/"
                              : ""}{" "}
                            {offerDetail.max_floor}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="offer-content-average-left-tags">
                    <div
                      className="offer-content-average-left-tags-l"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0px",
                        marginTop: "-10px",
                      }}
                    >
                      <span style={{ fontSize: "24px", fontWeight: "600" }}>
                        {offerDetail.price
                          ? offerDetail.price.toLocaleString("ru") + " AZN"
                          : options[language].priceByAgreement}
                      </span>
                      <span style={{ fontSize: "12px" }}>
                        {offerDetail.m2 ? (
                          <>
                            {offerDetail.m2.toFixed(2)?.replace(".00", "")} AZN/
                            {window.location.pathname
                              .split("/")[2]
                              ?.replace("sales", "")
                              .replace("rents", "") === "land"
                              ? options[language].sot
                              : options[language].m2}
                          </>
                        ) : (
                          options[language].priceByAgreement
                        )}
                      </span>
                    </div>
                    <div
                      className="offer-content-average-left-tags-l"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        gap: "0",
                      }}
                    >
                      {(offerDetail?.city !== null ||
                        offerDetail?.region !== null ||
                        offerDetail?.residential_area !== null) && (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span style={{ fontSize: "18px", fontWeight: "500" }}>
                            {offerDetail?.residential_area !== null
                              ? options[language].resident_options[
                                  offerDetail?.residential_area
                                ]?.label
                              : options[language].cities[offerDetail?.city]
                                  ?.label}
                          </span>
                          <span style={{ fontSize: "14px", marginTop: "-5px" }}>
                            {
                              options[language].region_options[
                                offerDetail?.region
                              ]?.label
                            }
                          </span>
                        </div>
                      )}
                      {offerDetail?.room && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "62px",
                          }}
                        >
                          <span style={{ fontSize: "18px", fontWeight: "500" }}>
                            {offerDetail?.room}
                            {options[language].WithRoomsSmall}
                          </span>
                          <span style={{ fontSize: "14px", marginTop: "-5px" }}>
                            {options[language].roomsCountName?.toLowerCase()}
                          </span>
                        </div>
                      )}
                      {offerDetail?.area !== null && (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span style={{ fontSize: "18px", fontWeight: "500" }}>
                            {Number(offerDetail?.area)
                              .toFixed(2)
                              .replace(".00", "")}{" "}
                            {window.location.pathname
                              .split("/")[2]
                              ?.replace("sales", "")
                              .replace("rents", "") === "land"
                              ? options[language].sot
                              : options[language].m2}
                          </span>
                          <span style={{ fontSize: "14px", marginTop: "-5px" }}>
                            {options[language].newOffer_AreaM2
                              .replace(", ", "")
                              .replace("m²", "")
                              .toLowerCase()}
                          </span>
                        </div>
                      )}
                      {(offerDetail.max_floor || offerDetail.floor) && (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span style={{ fontSize: "18px", fontWeight: "500" }}>
                            {offerDetail?.floor}{" "}
                            {offerDetail.floor && offerDetail.max_floor
                              ? "/"
                              : ""}{" "}
                            {offerDetail.max_floor}
                          </span>
                          <span style={{ fontSize: "14px", marginTop: "-5px" }}>
                            {options[language].floorSmall}
                          </span>
                        </div>
                      )}
                    </div>
                    <hr
                      style={{ margin: isMobile ? "20px 0 10px 0" : "20px 0" }}
                    />
                    <div
                      className="offer-content-helper-heart"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="boostOfferButton"
                        onClick={() => makeBoostOfferAction()}
                      >
                        <img src={BoostIcon} alt="boost" />
                        {options[language].boostNameSmall}
                      </div>
                      <div
                        className="premiumOfferButton"
                        onClick={() => makePremiumOfferAction()}
                      >
                        <img src={PremiumIcon} alt="boost" />
                        {options[language].makePremiumNameSmall}
                      </div>
                    </div>
                    <hr
                      style={{ margin: isMobile ? "10px 0 10px 0" : "20px 0" }}
                    />
                    <div
                      className="offer-content-average-left-description"
                      style={{ margin: "0 0 10px 0" }}
                    >
                      {offerDetail?.title ? (
                        <span style={{ fontSize: "18px" }}>
                          {offerDetail?.title}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
                {!isMobile && <hr style={{ margin: "20px 0" }} />}
                <div
                  className="offer-content-average-left-description"
                  style={{ fontSize: isMobile ? "14px" : "17px" }}
                >
                  {offerDetail?.details ? (
                    <span>{offerDetail?.details}</span>
                  ) : (
                    <></>
                  )}
                </div>
                <hr />
                <div className="offer-content-average-left-statistica">
                  <span>
                    {changed ? (
                      <a
                        href={"https://tap.az" + offerDetail?.url}
                        target="_blank"
                        rel="nofollow"
                        className="tap ataglength"
                        style={{ textDecoration: "underline" }}
                      >
                        {url}
                      </a>
                    ) : (
                      <a
                        href={offerDetail?.url}
                        rel="nofollow"
                        className={`${
                          offerDetail.url?.split("/")[2].split(".")[0]
                        } ataglength`}
                        style={{ textDecoration: "underline" }}
                        target="_blank"
                      >
                        {url}
                      </a>
                    )}
                  </span>
                  <span>
                    {offerDetail?.url === null && (
                      <a
                        href={window.location.href}
                        target="_blank"
                        className="evbu ataglength"
                        style={{ textDecoration: "underline" }}
                      >
                        ebaz.az
                      </a>
                    )}
                  </span>
                  <span>
                    {new Date(offerDetail?.post_date).toLocaleString("ru-Ru", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })}
                  </span>
                  {/* <span> - </span>
                <span>Baxışların sayı: 47</span> */}
                </div>
                <hr />
                {offerDetail &&
                  offerDetail.location &&
                  offerDetail.location.length >= 2 && (
                    <>
                      <div className="offer-content-average-left-map">
                        <GoogleMapsOnePoint
                          latLng={[
                            offerDetail.location[0],
                            offerDetail.location[1],
                          ]}
                        />
                      </div>
                      <hr />
                    </>
                  )}
                {/* PREDICT GRAPH */}
                {/* {predictData?.length >= 2 && (
                  <>
                    <div className='offer-content-average-left-map'>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span style={{ fontSize: '18px', fontWeight: '500' }}>
                        {options[language].analytics}
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end'
                          }}
                        >
                          <span style={{
                            fontSize: '20px', fontWeight: '500'
                          }}>
                            <FaDownLong style = {{transform: calculatePercantage(predictData) >= 0 ? 'rotate(180deg)' : 'rotate(0deg)', marginBottom: calculatePercantage(predictData) >= 0 ? '5px' : '0' }} color={calculatePercantage(predictData) >= 0 ? 'green' : 'red'}/>{calculatePercantage(predictData).replace('-', '')}%
                          </span>
                          <span style={{
                            fontSize: '16px', fontWeight: '500'
                          }}>
                            {options[language].predicted}{' '}
                            {calculatePercantage(predictData) >= 0
                              ? options[language].predictedHigh
                              : options[language].predictedLow}
                          </span>
                        </div>
                      </div>
                      <Predict data={predictData} />
                    </div>
                    <hr />
                  </>
                )} */}
              </div>
              <div className="offer-content-average-right">
                <div className="offer-content-average-right-top">
                  {offerDetail?.status === 1 ? (
                    <>
                      <div className="offer-content-average-right-price">
                        {offerDetail.price
                          ? offerDetail.price.toLocaleString("ru") + " AZN"
                          : options[language].priceByAgreement}
                      </div>
                      <hr />
                      <div className="offer-content-average-right-user">
                        <div>{offerDetail.user}</div>
                        <AccountCircleIcon
                          color="primary"
                          sx={{ fontSize: 40 }}
                        />
                      </div>
                      <hr />
                      {offerDetail.phone ? (
                        <div
                          className="offer-content-average-right-phone-number"
                          onClick={() => {
                            dispatch(
                              clickOnPhoneNum(
                                window.location.pathname.split("/")[2],
                                window.location.pathname.split("/")[3]
                              )
                            );
                            setPhoneNumClicked(true);
                          }}
                        >
                          <div>{options[language].phoneNumberName}</div>
                          {offerDetail.phone.split(",").map((number) => (
                            <a href={`tel:${number}`} key={number}>
                              <div style={{ display: "flex", gap: "5px" }}>
                                <CallIcon />
                                {/* {offerDetail.phone && `+994(${offerDetail.phone?.replace(/\D/g, '').slice(3, 5)})${offerDetail.phone?.replace(/\D/g, '').slice(5, 8)}-${offerDetail.phone?.replace(/\D/g, '').slice(8, 10)}-${offerDetail.phone?.replace(/\D/g, '').slice(10, 12)}`} */}
                                {phoneNumClicked
                                  ? number
                                  : number.replace(/.{0,4}$/, "****")}
                              </div>
                            </a>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {offerDetail.phone ? (
                        <div className="offer-content-average-right-phone-number">
                          <div>
                            {
                              options[language][
                                window.location.pathname
                                  .split("/")[2]
                                  ?.includes("sale")
                                  ? "saleDeleted"
                                  : "rentDeleted"
                              ]
                            }
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
                <div className="offer-content-average-right-share-part">
                  <IconButton onClick={handleClickToCopy} color="inherit">
                    <ShareIcon />
                    {/* <CopyAll/> */}
                  </IconButton>
                  <Snackbar
                    sx={{ paddingTop: "55px" }}
                    message={options[language].linkCopied}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    autoHideDuration={1500}
                    onClose={() => setOpen(false)}
                    open={open}
                  />
                  <WhatsappShareButton
                    title={
                      offerDetail?.title
                        ? offerDetail?.title
                        : options[language].offersFound
                    }
                    description="https://ebaz.az"
                    url={window.location
                      .toString()
                      .replace("secilmis-elanlar", "elan")}
                  >
                    <WhatsappIcon size={32} round={true}></WhatsappIcon>
                  </WhatsappShareButton>
                  <TelegramShareButton
                    title={
                      offerDetail?.title
                        ? offerDetail?.title
                        : options[language].offersFound
                    }
                    description="https://ebaz.az"
                    url={window.location
                      .toString()
                      .replace("secilmis-elanlar", "elan")}
                  >
                    <TelegramIcon size={32} round={true}></TelegramIcon>
                  </TelegramShareButton>
                  <FacebookShareButton
                    title={
                      offerDetail?.title
                        ? offerDetail?.title
                        : options[language].offersFound
                    }
                    description="https://ebaz.az"
                    url={window.location
                      .toString()
                      .replace("secilmis-elanlar", "elan")}
                  >
                    <FacebookIcon size={32} round={true}></FacebookIcon>
                  </FacebookShareButton>
                  <EmailShareButton
                    title={
                      offerDetail?.title
                        ? offerDetail?.title
                        : options[language].offersFound
                    }
                    description="https://ebaz.az"
                    url={window.location
                      .toString()
                      .replace("secilmis-elanlar", "elan")}
                  >
                    <EmailIcon size={32} round={true}></EmailIcon>
                  </EmailShareButton>
                </div>
              </div>
            </div>
          </div>
          {defaultOffers.length !== 0 ? (
            <div
              className="offer-content-bottom"
              style={{
                backgroundColor: "#f5f5f5",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="offer-content-average-left-similar"
                style={{
                  backgroundColor: "#f5f5f5",
                  width: "95%",
                  maxWidth: "1300px",
                }}
              >
                <span>{options[language].similarOffers}</span>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            className="offers-content-cards"
            style={{ backgroundColor: "#f5f5f5", maxWidth: "none" }}
          >
            {defaultOffers}
          </div>
        </>
      ) : (
        <Loading />
      )}
      {showFullScreen && offerDetail.id && (
        <div className="fullscreenImage">
          <div className="fullscreenImage-header" style={{ zIndex: "5" }}>
            {window.innerWidth < 700 && (
              <div className="fullscreenImage-header-left">
                <span style={{ fontSize: "16px" }}>{offerDetail?.title}</span>
                <hr />
                <span style={{ fontSize: "18px", marginTop: "5px" }}>
                  {offerDetail.price
                    ? offerDetail.price.toLocaleString("ru") + " AZN"
                    : options[language].priceByAgreement}
                </span>
              </div>
            )}
            {window.innerWidth >= 700 && (
              <div className="fullscreenImage-header-left">
                <span>{offerDetail?.title}</span>
                <hr />
                <span>
                  {offerDetail.price
                    ? offerDetail.price.toLocaleString("ru") + " AZN"
                    : options[language].priceByAgreement}
                </span>
              </div>
            )}
            <div className="fullscreenImage-header-right">
              {window.innerWidth >= 700 && (
                <>
                  {" "}
                  {offerDetail.phone && (
                    <div
                      onClick={() => {
                        dispatch(
                          clickOnPhoneNum(
                            window.location.pathname.split("/")[2],
                            window.location.pathname.split("/")[3]
                          )
                        );
                        setPhoneNumClicked(true);
                      }}
                      style={{
                        backgroundColor: "#0089CF",
                        padding: "8px",
                        borderRadius: "5px",
                      }}
                    >
                      {offerDetail.phone.split(",").map((number) => {
                        return (
                          <a href={`tel:${number}`} key={number}>
                            <div style={{ display: "flex", gap: "5px" }}>
                              <CallIcon />
                              {/* {offerDetail.phone && `+994(${offerDetail.phone?.replace(/\D/g, '').slice(3, 5)})${offerDetail.phone?.replace(/\D/g, '').slice(5, 8)}-${offerDetail.phone?.replace(/\D/g, '').slice(8, 10)}-${offerDetail.phone?.replace(/\D/g, '').slice(10, 12)}`} */}
                              {phoneNumClicked
                                ? number
                                : number.replace(/.{0,4}$/, "****")}
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  )}
                  <span
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="offer-content-helper-block heartblockwidth2"
                      style={{ cursor: "pointer" }}
                      onClick={() => addInFavorite()}
                    >
                      {localStorage.getItem("MyFavourites") ? (
                        JSON.parse(
                          localStorage.getItem("MyFavourites")
                        )?.findIndex((item) => item.id === offerDetail.id) !==
                        -1 ? (
                          <div>
                            <FavoriteIcon size={20} sx={{ color: "#ff2b00" }} />{" "}
                            {options[language].deleteFromFavouritesNameSmall}
                          </div>
                        ) : (
                          <div>
                            <FavoriteBorderIcon size={20} />{" "}
                            {options[language].addToFavouritesNameSmall}
                          </div>
                        )
                      ) : (
                        <div>
                          <FavoriteBorderIcon size={20} />{" "}
                          {options[language].addToFavouritesNameSmall}
                        </div>
                      )}
                    </div>
                  </span>
                </>
              )}
              <span
                style={{
                  cursor: "pointer",
                  height: "100%",
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  top: isMobile ? "-25px" : "0px",
                  right: isMobile ? "5px" : "20px",
                }}
                onClick={() => setShowFullScreen(false)}
              >
                <img src={XIcon} alt="x icon" style={{ height: "40px" }} />
              </span>
            </div>
          </div>
          {filteredArray.length ? (
            <ImageGallery
              items={filteredArray}
              showNav={isMobile ? false : true}
              showThumbnails={true}
              showPlayButton={false}
              showFullscreenButton={false}
              disableThumbnailSwipe={false}
              disableSwipe={false}
              lazyLoad={true}
              slideDuration={isMobile ? 300 : 0}
              disableThumbnailScroll={false}
              onClick={(e) => closeFullscreen(e)}
              onImageError={(e) => {
                e.target.onerror = null;
                e.target.src = whiteFon;
              }}
              onThumbnailError={(e) => {
                e.target.onerror = null;
                e.target.src = whiteFon;
              }}
            />
          ) : (
            <></>
          )}
        </div>
      )}
      {showFullScreenVideo && offerDetail.id && (
        <div className="fullscreenImage">
          <div className="fullscreenImage-header" style={{ zIndex: "5" }}>
            {window.innerWidth < 700 && (
              <div className="fullscreenImage-header-left">
                <span style={{ fontSize: "16px" }}>{offerDetail?.title}</span>
                <hr />
                <span style={{ fontSize: "18px", marginTop: "5px" }}>
                  {offerDetail.price
                    ? offerDetail.price.toLocaleString("ru") + " AZN"
                    : options[language].priceByAgreement}
                </span>
              </div>
            )}
            {window.innerWidth >= 700 && (
              <div className="fullscreenImage-header-left">
                <span>{offerDetail?.title}</span>
                <hr />
                <span>
                  {offerDetail.price
                    ? offerDetail.price.toLocaleString("ru") + " AZN"
                    : options[language].priceByAgreement}
                </span>
              </div>
            )}
            <div className="fullscreenImage-header-right">
              {window.innerWidth >= 700 && (
                <>
                  {" "}
                  {offerDetail.phone && (
                    <div
                      onClick={() => {
                        dispatch(
                          clickOnPhoneNum(
                            window.location.pathname.split("/")[2],
                            window.location.pathname.split("/")[3]
                          )
                        );
                        setPhoneNumClicked(true);
                      }}
                      style={{
                        backgroundColor: "#0089CF",
                        padding: "8px",
                        borderRadius: "5px",
                      }}
                    >
                      {offerDetail.phone.split(",").map((number) => {
                        return (
                          <a href={`tel:${number}`} key={number}>
                            <div style={{ display: "flex", gap: "5px" }}>
                              <CallIcon />
                              {/* {offerDetail.phone && `+994(${offerDetail.phone?.replace(/\D/g, '').slice(3, 5)})${offerDetail.phone?.replace(/\D/g, '').slice(5, 8)}-${offerDetail.phone?.replace(/\D/g, '').slice(8, 10)}-${offerDetail.phone?.replace(/\D/g, '').slice(10, 12)}`} */}
                              {phoneNumClicked
                                ? number
                                : number.replace(/.{0,4}$/, "****")}
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  )}
                  <span
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="offer-content-helper-block heartblockwidth2"
                      style={{ cursor: "pointer" }}
                      onClick={() => addInFavorite()}
                    >
                      {localStorage.getItem("MyFavourites") ? (
                        JSON.parse(
                          localStorage.getItem("MyFavourites")
                        )?.findIndex((item) => item.id === offerDetail.id) !==
                        -1 ? (
                          <div>
                            <FavoriteIcon size={20} sx={{ color: "#ff2b00" }} />{" "}
                            {options[language].deleteFromFavouritesNameSmall}
                          </div>
                        ) : (
                          <div>
                            <FavoriteBorderIcon size={20} />{" "}
                            {options[language].addToFavouritesNameSmall}
                          </div>
                        )
                      ) : (
                        <div>
                          <FavoriteBorderIcon size={20} />{" "}
                          {options[language].addToFavouritesNameSmall}
                        </div>
                      )}
                    </div>
                  </span>
                </>
              )}
              <span
                style={{
                  cursor: "pointer",
                  height: "100%",
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  top: isMobile ? "-25px" : "0px",
                  right: isMobile ? "5px" : "20px",
                }}
                onClick={() => setShowFullScreenVideo(false)}
              >
                <img src={XIcon} alt="x icon" style={{ height: "40px" }} />
              </span>
            </div>
          </div>
          {offerDetail?.video !== null ? (
            <div
              className="offer-video-part"
              onClick={(e) =>
                e.target.className === "offer-video-part" &&
                setShowFullScreenVideo(false)
              }
            >
              <video className="offer-video" controls autoPlay>
                <source src={offerDetail?.video} type={"video/mp4"} />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {showPaymentModal && (
        <div
          className="payment-modal"
          onClick={(e) =>
            e.target.className === "payment-modal" && setShowPaymentModal(false)
          }
          style={{
            position: "absolute",
            width: "100%",
            borderRadius: "0",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, .7)",
            top: "0",
            zIndex: "10",
          }}
        >
          <div className="payment-modal-content">
            {/* <img className='close-button' onClick={() => setShowPaymentModal(false)} src={XIconBlack} alt='x' /> */}
            <img
              onClick={() => setShowPaymentModal(false)}
              src={XIconBlack}
              alt="close icon"
              style={{
                height: "40px",
                position: "absolute",
                right: "0px",
                top: "3px",
                cursor: "pointer",
                zIndex: "1",
              }}
            />
            <div className="payment-types">
              <div
                className="payment-type-boost"
                onClick={() => setTypeOfReklam("boost")}
                style={{
                  color: typeOfReklam === "boost" ? "white" : "#888888",
                  borderColor: typeOfReklam === "boost" ? "#0EB24B" : "#888888",
                  backgroundColor:
                    typeOfReklam === "boost" ? "#0EB24B" : "#fff",
                  position: "relative",
                }}
              >
                <Top color={typeOfReklam === "boost" ? "#fff" : "#0EB24B"} />
                {options[language].boostOffer}
                <div className="frame-additional">
                  <span style={{ color: "#0D7CBA" }}>
                    {options[language].fromPrice1}
                  </span>
                </div>
              </div>
              <div
                className="payment-type-frame"
                onClick={() => setTypeOfReklam("frame")}
                style={{
                  color: typeOfReklam === "frame" ? "white" : "#888888",
                  borderColor: typeOfReklam === "frame" ? "#0D7CBA" : "#888888",
                  backgroundColor:
                    typeOfReklam === "frame" ? "#0D7CBA" : "#fff",
                  position: "relative",
                }}
              >
                <Frame color={typeOfReklam === "frame" ? "#fff" : "#0D7CBA"} />
                {options[language].frameOffer}
                <div className="frame-additional">
                  <span style={{ color: "#0D7CBA" }}>
                    {options[language].fromPrice2}
                  </span>
                </div>
              </div>
              <div
                className="payment-type-urgent"
                onClick={() => setTypeOfReklam("urgent")}
                style={{
                  color: typeOfReklam === "urgent" ? "white" : "#888888",
                  borderColor:
                    typeOfReklam === "urgent" ? "#FF2B00" : "#888888",
                  backgroundColor:
                    typeOfReklam === "urgent" ? "#FF2B00" : "#fff",
                  position: "relative",
                }}
              >
                <Urgent
                  color={typeOfReklam === "urgent" ? "#fff" : "#FF2B00"}
                />
                {options[language].urgentSmall}
                <div className="urgent-additional">
                  <span style={{ color: "#0D7CBA" }}>
                    {options[language].fromPrice3}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="payment-types-description"
              style={{ marginTop: "28px" }}
            >
              <span>
                {typeOfReklam === "boost"
                  ? options[language].boostOfferDescription
                  : typeOfReklam === "frame"
                  ? options[language].frameOfferDescription
                  : typeOfReklam === "urgent"
                  ? options[language].urgentOfferDescription
                  : ""}
              </span>
            </div>
            {offerDetail.ads?.length !== 0 && typeOfReklam === "boost" ? (
              <span
                style={{
                  fontSize: "14px",
                  color: "#000",
                  fontWeight: "400",
                  marginTop: "0px",
                }}
              >
                {new Intl.DateTimeFormat("en-GB", {
                  day: "numeric",
                  month: "2-digit", // Use 'long' for full month name
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })?.format(new Date(offerDetail?.boostAds?.[0]?.end_date))}{" "}
                {options[language].paidUntill}
              </span>
            ) : typeOfReklam === "frame" &&
              offerDetail?.boostAds?.[0]?.color === 1 ? (
              <span
                style={{
                  fontSize: "14px",
                  color: "#000",
                  fontWeight: "400",
                  marginTop: "0px",
                }}
              >
                {new Intl.DateTimeFormat("en-GB", {
                  day: "numeric",
                  month: "2-digit", // Use 'long' for full month name
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })?.format(new Date(offerDetail?.boostAds?.[0]?.end_date))}{" "}
                {options[language].paidUntill}
              </span>
            ) : typeOfReklam === "urgent" &&
              offerDetail?.boostAds?.[0]?.color === 2 ? (
              <span
                style={{
                  fontSize: "14px",
                  color: "#000",
                  fontWeight: "400",
                  marginTop: "0px",
                }}
              >
                {new Intl.DateTimeFormat("en-GB", {
                  day: "numeric",
                  month: "2-digit", // Use 'long' for full month name
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })?.format(new Date(offerDetail?.boostAds?.[0]?.end_date))}{" "}
                {options[language].paidUntill}
              </span>
            ) : (
              <div style={{ height: "24px", width: "19px" }}></div>
            )}
            <div
              className="payment-types-price-and-template"
              style={{ justifyContent: "space-between" }}
            >
              <div className="payment-types-price">
                <span
                  style={{
                    fontSize: "20px",
                    color: "#000",
                    fontWeight: "500",
                    marginTop: "15px",
                  }}
                >
                  {options[language].serviceTime}
                </span>
                <div className="horizontal-payment-types"></div>
                {options[language].packets[typeOfReklam].map(
                  (reklamPr, index) => (
                    <>
                      <div
                        className="payment-types-price-day"
                        onClick={() => setSelectedTarif(reklamPr.dayOrTime)}
                      >
                        <div
                          className="cycle"
                          style={{
                            borderColor:
                              selectedTarif === reklamPr.dayOrTime
                                ? "#0D7CBA"
                                : "#888888",
                          }}
                        >
                          {selectedTarif === reklamPr.dayOrTime && (
                            <div className="cycle-selected"></div>
                          )}
                        </div>
                        <span>
                          {reklamPr.dayOrTime + options[language].times} /{" "}
                          {reklamPr.price + " AZN"}{" "}
                          {index !== 0 &&
                            typeOfReklam === "boost" &&
                            options[language].boostFor24Hours}
                          {typeOfReklam === "frame" || typeOfReklam === "urgent"
                            ? index !== 0
                              ? options[language].boostFor24Hours
                              : options[language].boostName
                            : ""}
                        </span>
                      </div>
                      <div className="horizontal-payment-types"></div>
                    </>
                  )
                )}
                <span
                  style={{
                    fontSize: "20px",
                    color: "#000",
                    fontWeight: "500",
                    marginTop: "15px",
                  }}
                >
                  {options[language].paymentMethod}
                </span>
                <div className="horizontal-payment-types"></div>
                <div className="payment-types-price-day">
                  <div className="cycle" style={{ borderColor: "#0D7CBA" }}>
                    <div className="cycle-selected"></div>
                  </div>
                  <span>{options[language].bankCard}</span>
                </div>
                <div className="horizontal-payment-types"></div>
              </div>
              <div className="payment-types-template">
                <Card
                  className="cardB"
                  sx={{
                    width: "45vw",
                    position: "relative",
                    height: isMobile ? "200px" : "270px",
                    maxWidth: 270,
                    minWidth: 210,
                    borderRadius: "10px",
                    marginTop: "25px",
                    boxShadow:
                      typeOfReklam === "frame"
                        ? "0 0 0 2px #0D7CBA"
                        : typeOfReklam === "urgent"
                        ? "0 0 0 2px #FF2B00"
                        : "0 4px 4px rgba(0,0,0,.25)",
                  }}
                >
                  <CardActionArea className="card-action-area">
                    <div
                      className="additionalRow"
                      style={{
                        marginTop: isMobile ? "-8px" : "none",
                        marginLeft: isMobile ? "5px" : "none",
                      }}
                    >
                      <div className="additionalRowLeft"></div>
                      <div className="additionalRowRight">
                        <img
                          style={{ width: isMobile ? "20px" : "30px" }}
                          src={HeartWhite}
                          alt="heart red"
                        />
                      </div>
                    </div>
                    {typeOfReklam === "urgent" && (
                      <div className="urgent-offer">
                        {options[language].urgentSmall}
                      </div>
                    )}
                    <CardMedia
                      component="img"
                      height={isMobile ? 110 : 170}
                      image={
                        offerDetail?.images !== null
                          ? offerDetail.images[0]?.path || whiteFon
                          : whiteFon
                      }
                      alt={offerDetail.title ? offerDetail.title : "elan"}
                      loading="lazy"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = whiteFon;
                      }}
                    />
                    <CardContent
                      sx={{
                        padding: "10px",
                        height: isMobile ? "90px" : "100px",
                        width: "100%",
                      }}
                    >
                      <hr
                        className="horizontal"
                        style={{
                          position: "absolute",
                          top: isMobile ? "103px" : "163px",
                          color: "black",
                        }}
                      />
                      <Typography
                        variant="body3"
                        color="text.secondary"
                        className="typography-price"
                        sx={{
                          fontSize: "18px",
                          position: "absolute",
                          top: isMobile ? "100px" : "160px",
                          color: "white",
                        }}
                      >
                        {offerDetail?.price !== null
                          ? offerDetail.price.toLocaleString("ru") + " AZN"
                          : options[language].priceName.replace(":", "")}
                      </Typography>
                      <hr
                        className="horizontal"
                        style={{
                          position: "absolute",
                          top: isMobile ? "130px" : "195px",
                          color: "black",
                        }}
                      />

                      <Typography
                        className="city-and-link-data"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontWeight: 500,
                          width: "calc(100% + 0px)",
                          fontSize: "16px",
                          position: "absolute",
                          bottom: isMobile ? "36px" : "38px",
                          color: "#212529",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "5px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "calc(100% + 0px)",
                          }}
                        >
                          {/* {offerDetail?.city !== null || offerDetail?.region !== null || offerDetail?.residential_area !== null ? <img style={{ width: "16px", height: "16px", marginTop: "-2px" }} src={locationIcon} alt='location' /> : <><img style={{ width: "16px", height: "16px", marginTop: "-2px" }} src={locationIcon} alt='location' /> {options[language].addressSmall}</>} */}
                          {offerDetail?.city !== null
                            ? options[language].cities[offerDetail?.city].label
                            : ""}
                          {offerDetail?.region !== null &&
                          offerDetail?.city === null
                            ? options[language].cities[8].label
                            : ""}
                          {offerDetail?.region !== null
                            ? ", " +
                              options[language].region_options[
                                offerDetail?.region
                              ].label
                            : ""}
                          {offerDetail?.residential_area !== null &&
                          offerDetail?.region === null
                            ? ", " +
                              options[language].resident_options[
                                offerDetail?.residential_area
                              ].region
                            : ""}
                          {offerDetail?.residential_area !== null
                            ? ", " +
                              options[language].resident_options[
                                offerDetail?.residential_area
                              ].label +
                              options[language].residentsSmall
                            : ""}
                        </div>
                      </Typography>
                      <Typography
                        className="room-area-and-floor-data"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontWeight: 500,
                          fontSize: "16px",
                          position: "absolute",
                          bottom: isMobile ? "5px" : "1px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          width: "100%",
                          maxWidth: "calc(100% - 20px)",
                          color: "#000",
                        }}
                      >
                        {offerDetail?.room &&
                          offerDetail?.room + options[language].WithRoomsSmall}
                        {offerDetail?.room && offerDetail?.area && (
                          <hr className="vertical dot-vertical" />
                        )}
                        {offerDetail?.area && (
                          <>
                            {Number(offerDetail?.area)
                              .toFixed(2)
                              .replace(".00", "")}
                            {offerDetail?.type === "landsales" ||
                            offerDetail?.type === "landrents"
                              ? " " + options[language].sot
                              : " " + options[language].m2}
                          </>
                        )}
                        {offerDetail?.floor && offerDetail?.area && (
                          <hr className="vertical dot-vertical" />
                        )}
                        {offerDetail?.floor && (
                          <>
                            {offerDetail?.floor}/{offerDetail?.max_floor}{" "}
                            {options[language].floorSmall}
                          </>
                        )}
                      </Typography>
                      <hr
                        className="horizontal"
                        style={{
                          position: "absolute",
                          bottom: "42px",
                          color: "black",
                        }}
                      />
                    </CardContent>
                    <Typography
                      className="city-and-link-data"
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        position: "absolute",
                        top: isMobile ? "0px" : "0px",
                        color: "black",
                        marginLeft: "10px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: isMobile ? "113px" : "176px",
                          right: "0",
                          color: "#888888",
                        }}
                      >
                        {offerDetail?.post_date
                          ? `${
                              new Date(offerDetail?.post_date).getDate() ===
                                new Date().getDate() &&
                              new Date(offerDetail?.post_date).getMonth() ===
                                new Date().getMonth() &&
                              new Date(offerDetail?.post_date).getFullYear() ===
                                new Date().getFullYear()
                                ? `${new Date(offerDetail?.post_date)
                                    .toLocaleString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: false,
                                    })
                                    .replace("24:", "00:")}`
                                : new Date(offerDetail?.post_date).getDate() ===
                                    new Date().getDate() - 1 &&
                                  new Date(
                                    offerDetail?.post_date
                                  ).getMonth() === new Date().getMonth() &&
                                  new Date(
                                    offerDetail?.post_date
                                  ).getFullYear() === new Date().getFullYear()
                                ? `${options[language].yesterdaySmall}`
                                : new Date(offerDetail?.post_date)
                                    .toLocaleString("ru-Ru", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "2-digit",
                                    })
                                    .replace(/20\d{2}/, /\d{2}/)
                            }`
                          : "Tarix"}
                      </span>
                      {/* <a style={{ textDecoration: "underline" }} className='evbu ataglength tap'>ebaz.az</a> */}
                      <Typography
                        className="city-and-link-data-url-part"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                          position: "absolute",
                          top: isMobile ? "86px" : `146px`,
                          color: "white",
                          justifyContent: "flex-start",
                          right: "-10px",
                        }}
                      >
                        {changed ? (
                          <a
                            rel="nofollow"
                            href={"https://tap.az" + offerDetail?.url}
                            target="_blank"
                            className="offer-url"
                          >
                            {url}
                          </a>
                        ) : (
                          <a
                            rel="nofollow"
                            href={offerDetail?.url}
                            className="offer-url"
                            target="_blank"
                          >
                            {url}
                          </a>
                        )}
                        {offerDetail?.url === null && (
                          <a
                            rel="nofollow"
                            href={window.location.href}
                            target="_blank"
                            className="offer-url"
                          >
                            ebaz.az
                          </a>
                        )}
                      </Typography>
                    </Typography>
                  </CardActionArea>
                </Card>
              </div>
            </div>
            <div className="payment-buttons">
              <div
                className="payment-button close"
                onClick={() => setShowPaymentModal(false)}
              >
                {options[language].cancelPayment}
              </div>
              <div
                className="payment-button pay"
                onClick={() => boostOffer(offerDetail?.id)}
              >
                {loadingBoostButton ? (
                  <CircularProgress
                    size={20}
                    fontSize="small"
                    color="inherit"
                  />
                ) : (
                  options[language].makePayment
                )}
              </div>
            </div>
            <div className="payment-last-words">
              <span>{options[language].paymentAgreement}</span>
            </div>
          </div>
        </div>
      )}
      {showPremiumModal && (
        <div
          className="premium-modal"
          onClick={(e) =>
            e.target.className === "premium-modal" && setShowPremiumModal(false)
          }
          style={{
            position: "absolute",
            width: "100%",
            borderRadius: "0",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, .7)",
            top: "0",
            zIndex: "10",
          }}
        >
          <div className="payment-modal-content">
            <img
              onClick={() => setShowPremiumModal(false)}
              src={XIconBlack}
              alt="close icon"
              style={{
                height: "40px",
                position: "absolute",
                right: "0px",
                top: "3px",
                cursor: "pointer",
                zIndex: "1",
              }}
            />
            <div className="payment-types-title" style={{ marginTop: "10px" }}>
              <img src={PremiumIcon2} alt="premium" />
              <span>{options[language].makeOfferPremiumName}</span>
            </div>
            <div
              className="payment-types-description"
              style={{ marginTop: "10px" }}
            >
              <span>{options[language].makePremiumDescription}</span>
            </div>
            {/* {offerDetail.ads?.length !== 0 ?
            <span style={{ fontSize: "14px", color: "#000", fontWeight: "400", marginTop: "0px" }}>
              {new Intl.DateTimeFormat('en-GB', {
                day: 'numeric',
                month: '2-digit', // Use 'long' for full month name
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              }).format(new Date(offerDetail.ads?.[0]?.end_date))} {options[language].paidUntill}
            </span>
            : <div style={{ height: "24px", width: "19px" }}></div>
          } */}
            <div
              className="payment-types-additionals"
              style={{ marginTop: "10px" }}
            >
              <div
                className="payment-types-additional frame"
                style={{
                  color:
                    additionalsForPremium === "frame" ? "#0D7CBA" : "black",
                }}
                onClick={() => {
                  SetAdditionalsForPremium(
                    additionalsForPremium === "frame" ? "" : "frame"
                  );
                }}
              >
                <div
                  className="payment-types-additional-rectagle"
                  style={{
                    borderColor:
                      additionalsForPremium === "frame" ? "#0D7CBA" : "#888888",
                  }}
                >
                  {additionalsForPremium === "frame" && (
                    <div className="rectangle"></div>
                  )}
                </div>
                {options[language].frameOffer} + 0.99 AZN
              </div>
              <div
                className="payment-types-additional urgent"
                style={{
                  color:
                    additionalsForPremium === "urgent" ? "#0D7CBA" : "black",
                }}
                onClick={() =>
                  SetAdditionalsForPremium(
                    additionalsForPremium === "urgent" ? "" : "urgent"
                  )
                }
              >
                <div
                  className="payment-types-additional-rectagle"
                  style={{
                    borderColor:
                      additionalsForPremium === "urgent"
                        ? "#0D7CBA"
                        : "#888888",
                  }}
                >
                  {additionalsForPremium === "urgent" && (
                    <div className="rectangle"></div>
                  )}
                </div>
                {options[language].urgentSmall} + 1.99 AZN
              </div>
            </div>
            <div
              className="payment-types-price-and-template"
              style={{ justifyContent: "space-between" }}
            >
              <div className="payment-types-price">
                <span
                  style={{
                    fontSize: "20px",
                    color: "#000",
                    fontWeight: "500",
                    marginTop: "15px",
                  }}
                >
                  {options[language].serviceTime}
                </span>
                <div className="horizontal-payment-types"></div>
                {options[language].packets["premium"].map((reklamPr) => (
                  <>
                    <div
                      className="payment-types-price-day"
                      onClick={() => setSelectedTarif(reklamPr.dayOrTime)}
                    >
                      <div
                        className="cycle"
                        style={{
                          borderColor:
                            selectedTarif === reklamPr.dayOrTime
                              ? "#0D7CBA"
                              : "#888888",
                        }}
                      >
                        {selectedTarif === reklamPr.dayOrTime && (
                          <div className="cycle-selected"></div>
                        )}
                      </div>
                      <span>
                        {reklamPr.dayOrTime + options[language].days} /{" "}
                        {additionalsForPremium === "frame"
                          ? (Number(reklamPr.price) + 0.99).toFixed(2) + " AZN"
                          : additionalsForPremium === "urgent"
                          ? (Number(reklamPr.price) + 1.99).toFixed(2) + " AZN"
                          : reklamPr.price + " AZN"}
                        {additionalsForPremium === "frame" ||
                        additionalsForPremium === "urgent" ||
                        additionalsForPremium === "boost" ? (
                          <span style={{ color: "#000" }}>
                            {options[language].boostName}
                          </span>
                        ) : (
                          <></>
                        )}
                      </span>
                    </div>
                    <div className="horizontal-payment-types"></div>
                  </>
                ))}
                <span
                  style={{
                    fontSize: "20px",
                    color: "#000",
                    fontWeight: "500",
                    marginTop: "15px",
                  }}
                >
                  {options[language].paymentMethod}
                </span>
                <div className="horizontal-payment-types"></div>
                <div className="payment-types-price-day">
                  <div className="cycle" style={{ borderColor: "#0D7CBA" }}>
                    <div className="cycle-selected"></div>
                  </div>
                  <span>{options[language].bankCard}</span>
                </div>
                <div className="horizontal-payment-types"></div>
              </div>
              <div className="payment-types-template">
                <Card
                  className="cardB"
                  sx={{
                    width: "45vw",
                    position: "relative",
                    height: isMobile ? "200px" : "270px",
                    maxWidth: 270,
                    minWidth: 210,
                    borderRadius: "10px",
                    marginTop: "25px",
                    boxShadow:
                      additionalsForPremium === "frame"
                        ? "0 0 0 2px #0D7CBA"
                        : additionalsForPremium === "urgent"
                        ? "0 0 0 2px #FF2B00"
                        : "0 4px 4px rgba(0,0,0,.25)",
                  }}
                >
                  <CardActionArea className="card-action-area" disableRipple>
                    <div
                      className="additionalRow"
                      style={{
                        marginTop: isMobile ? "-8px" : "none",
                        marginLeft: isMobile ? "5px" : "none",
                      }}
                    >
                      <div className="additionalRowLeft"></div>
                      <div className="additionalRowRight">
                        <img
                          style={{ width: isMobile ? "20px" : "30px" }}
                          className="crown-on-image"
                          src={CrownIcon}
                          alt="heart red"
                        />
                        <img
                          style={{ width: isMobile ? "20px" : "30px" }}
                          src={HeartWhite}
                          alt="heart red"
                        />
                      </div>
                    </div>
                    {additionalsForPremium === "urgent" && (
                      <div className="urgent-offer">
                        {options[language].urgentSmall}
                      </div>
                    )}
                    <CardMedia
                      component="img"
                      height={isMobile ? 110 : 170}
                      image={
                        offerDetail?.images !== null
                          ? offerDetail.images[0]?.path || whiteFon
                          : whiteFon
                      }
                      alt={offerDetail.title ? offerDetail.title : "elan"}
                      loading="lazy"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = whiteFon;
                      }}
                    />
                    <CardContent
                      sx={{
                        padding: "10px",
                        height: isMobile ? "90px" : "100px",
                        width: "100%",
                      }}
                    >
                      <hr
                        className="horizontal"
                        style={{
                          position: "absolute",
                          top: isMobile ? "103px" : "163px",
                          color: "black",
                        }}
                      />
                      <Typography
                        variant="body3"
                        color="text.secondary"
                        className="typography-price"
                        sx={{
                          fontSize: "18px",
                          position: "absolute",
                          top: isMobile ? "100px" : "160px",
                          color: "white",
                        }}
                      >
                        {offerDetail?.price !== null
                          ? offerDetail.price.toLocaleString("ru") + " AZN"
                          : options[language].priceName.replace(":", "")}
                      </Typography>
                      <hr
                        className="horizontal"
                        style={{
                          position: "absolute",
                          top: isMobile ? "130px" : "195px",
                          color: "black",
                        }}
                      />

                      <Typography
                        className="city-and-link-data"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontWeight: 500,
                          width: "calc(100% + 0px)",
                          fontSize: "16px",
                          position: "absolute",
                          bottom: isMobile ? "37px" : "40px",
                          color: "#212529",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "5px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "calc(100% + 0px)",
                          }}
                        >
                          {/* {offerDetail?.city !== null || offerDetail?.region !== null || offerDetail?.residential_area !== null ? <img style={{ width: "16px", height: "16px", marginTop: "-2px" }} src={locationIcon} alt='location' /> : <><img style={{ width: "16px", height: "16px", marginTop: "-2px" }} src={locationIcon} alt='location' /> {options[language].addressSmall}</>} */}
                          {offerDetail?.city !== null
                            ? options[language].cities[offerDetail?.city].label
                            : ""}
                          {offerDetail?.region !== null &&
                          offerDetail?.city === null
                            ? options[language].cities[8].label
                            : ""}
                          {offerDetail?.region !== null
                            ? ", " +
                              options[language].region_options[
                                offerDetail?.region
                              ].label
                            : ""}
                          {offerDetail?.residential_area !== null &&
                          offerDetail?.region === null
                            ? ", " +
                              options[language].resident_options[
                                offerDetail?.residential_area
                              ].region
                            : ""}
                          {offerDetail?.residential_area !== null
                            ? ", " +
                              options[language].resident_options[
                                offerDetail?.residential_area
                              ].label +
                              options[language].residentsSmall
                            : ""}
                        </div>
                      </Typography>

                      <Typography
                        className="room-area-and-floor-data"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontWeight: 500,
                          fontSize: "16px",
                          position: "absolute",
                          bottom: isMobile ? "5px" : "1px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          width: "100%",
                          maxWidth: "calc(100% - 20px)",
                          color: "#000",
                        }}
                      >
                        {offerDetail?.room &&
                          offerDetail?.room + options[language].WithRoomsSmall}
                        {offerDetail?.room && offerDetail?.area && (
                          <hr className="vertical dot-vertical" />
                        )}
                        {offerDetail?.area && (
                          <>
                            {Number(offerDetail?.area)
                              .toFixed(2)
                              .replace(".00", "")}
                            {offerDetail?.type === "landsales" ||
                            offerDetail?.type === "landrents"
                              ? " " + options[language].sot
                              : " " + options[language].m2}
                          </>
                        )}
                        {offerDetail?.floor && offerDetail?.area && (
                          <hr className="vertical dot-vertical" />
                        )}
                        {offerDetail?.floor && (
                          <>
                            {offerDetail?.floor}/{offerDetail?.max_floor}{" "}
                            {options[language].floorSmall}
                          </>
                        )}
                      </Typography>
                      <hr
                        className="horizontal"
                        style={{
                          position: "absolute",
                          bottom: "42px",
                          color: "black",
                        }}
                      />
                    </CardContent>
                    <Typography
                      className="city-and-link-data"
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        position: "absolute",
                        top: isMobile ? "0px" : "0px",
                        color: "black",
                        marginLeft: "10px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: isMobile ? "113px" : "176px",
                          right: "0",
                          color: "#888888",
                        }}
                      >
                        {offerDetail?.post_date
                          ? `${
                              new Date(offerDetail?.post_date).getDate() ===
                                new Date().getDate() &&
                              new Date(offerDetail?.post_date).getMonth() ===
                                new Date().getMonth() &&
                              new Date(offerDetail?.post_date).getFullYear() ===
                                new Date().getFullYear()
                                ? `${new Date(offerDetail?.post_date)
                                    .toLocaleString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: false,
                                    })
                                    .replace("24:", "00:")}`
                                : new Date(offerDetail?.post_date).getDate() ===
                                    new Date().getDate() - 1 &&
                                  new Date(
                                    offerDetail?.post_date
                                  ).getMonth() === new Date().getMonth() &&
                                  new Date(
                                    offerDetail?.post_date
                                  ).getFullYear() === new Date().getFullYear()
                                ? `${options[language].yesterdaySmall}`
                                : new Date(offerDetail?.post_date)
                                    .toLocaleString("ru-Ru", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "2-digit",
                                    })
                                    .replace(/20\d{2}/, /\d{2}/)
                            }`
                          : "Tarix"}
                      </span>
                      <Typography
                        className="city-and-link-data-url-part"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                          position: "absolute",
                          top: isMobile ? "86px" : `146px`,
                          color: "white",
                          justifyContent: "flex-start",
                          right: "-10px",
                        }}
                      >
                        {changed ? (
                          <a
                            rel="nofollow"
                            href={"https://tap.az" + offerDetail?.url}
                            target="_blank"
                            className="offer-url"
                          >
                            {url}
                          </a>
                        ) : (
                          <a
                            rel="nofollow"
                            href={offerDetail?.url}
                            className="offer-url"
                            target="_blank"
                          >
                            {url}
                          </a>
                        )}
                        {offerDetail?.url === null && (
                          <a
                            rel="nofollow"
                            href={window.location.href}
                            target="_blank"
                            className="offer-url"
                          >
                            ebaz.az
                          </a>
                        )}
                      </Typography>
                    </Typography>
                  </CardActionArea>
                </Card>
              </div>
            </div>
            <div className="payment-buttons" style={{ marginTop: "20px" }}>
              <div
                className="payment-button close"
                onClick={() => setShowPremiumModal(false)}
              >
                {options[language].cancelPayment}
              </div>
              <div
                className="payment-button pay"
                onClick={() => premiumOffer(offerDetail?.id)}
              >
                {loadingPremiumButton ? (
                  <CircularProgress
                    size={20}
                    fontSize="small"
                    color="inherit"
                  />
                ) : (
                  options[language].makePayment
                )}
              </div>
            </div>
            <span className="payment-last-words">
              {options[language].paymentAgreement}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default Offer;
