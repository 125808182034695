import React from "react";
import "./InitialPageOffers.css";
import OfferCard from "../Offers/OfferCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import Crown from "../images/Crown.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllVipsAction } from "../../redux/action";
import { options } from "../../constants";
import { useNavigate } from "react-router-dom";

const InitialPageOffers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.language);
  const initialOffersVips = useSelector((state) => state.initialOffersVips);
  const initialOffersFlat = useSelector((state) => state.initialOffersFlat);
  const initialOffersCountry = useSelector(
    (state) => state.initialOffersCountry
  );
  const initialOffersOffice = useSelector((state) => state.initialOffersOffice);
  const initialOffersGarage = useSelector((state) => state.initialOffersGarage);
  const initialOffersLand = useSelector((state) => state.initialOffersLand);
  const initialOffersObyekt = useSelector((state) => state.initialOffersObyekt);
  const isMobile = useSelector((state) => state.isMobile);
  const showAllVips = () => {
    dispatch(getAllVipsAction());
    navigate("/butun-premium-elanlar");
  };
  const showAllOffers = (offerType, flatType) => {
    const url = new URL(window.location.href);
    offerType === "s"
      ? url.searchParams.set("satis", "")
      : url.searchParams.set("satis", "kiraye");
    url.searchParams.set("emlak", flatType);
    window.location.href = url.toString();
  };
  return (
    <>
      <div className="InitialPageOffers">
        <div className="InitialPageOffers-content">
          {initialOffersVips?.length !== 0 && (
            <>
              <div
                className="InitialPageOffers-content-row InitialPageOffers-content-vip"
                  // style={{
                  //   marginLeft:'-7px !important'
                  // }}
              >
                <div
                  className="InitialPageOffers-content-row-title"
                  style={{
                    maxWidth: "1300px",
                    width: isMobile
                      ? "100%"
                      : window.innerWidth >= 1400
                      ? "100%"
                      : "95%",
                    marginBottom: isMobile ? "0px" : "15px",
                  }}
                >
                  <span style={{ display: "flex", gap: "7px" }}>
                    <img
                      style={{ width: isMobile ? "25px" : "30px" }}
                      src={Crown}
                      alt="crown"
                    />
                    {options[language].premiumOffers}
                  </span>
                  <span onClick={() => showAllVips()}>
                    {options[language].showAll}
                  </span>
                </div>

                {/* marginLeft: isMobile ? '2.5%' : window.innerWidth >= 1400 ? '10px' : "0",
                  width: isMobile ? '95%' : window.innerWidth >= 1400 ? '100%' : "95%", */}
                <Swiper
                  style={{
                    zIndex: "0",
                    width: "100%",
                  }}
                  modules={[Autoplay]}
                  speed={2000}
                  autoplay={{
                    delay: 1500,
                    pauseOnMouseEnter: true,
                  }}
                  loop={true}
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    480: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    840: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1220: {
                      slidesPerView: 4,
                      spaceBetween: 31,
                    },
                  }}
                  scrollbar={{ draggable: true }}
                >
                  {initialOffersVips?.map((offer, index) => (
                    <SwiperSlide
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: isMobile ? "2px" : "2px",
                        // paddingRight: isMobile ? "5px" : "11px",
                        width: "42vw",
                        maxWidth: "305px",
                      }}
                      key={index}
                    >
                      <OfferCard key={index} offer={offer} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </>
          )}
          {initialOffersFlat?.flatSale?.length !== 0 && (
            <>
              <hr className="InitialPageOffers-content-hr" />
              <div className="InitialPageOffers-content-row InitialPageOffers-content-flatsales">
                <div className="InitialPageOffers-content-row-title">
                  <span>{options[language].saleFlats}</span>
                  <span onClick={() => showAllOffers("s", "menzil")}>
                    {options[language].showAll}
                  </span>
                </div>
                {initialOffersFlat?.flatSale?.map((offer, index) => (
                  <OfferCard key={index} offer={offer} />
                ))}
              </div>
              {/* {isMobile && (
                <a
                  href="https://bid.az"
                  target="_blank"
                  rel="nofollow"
                  style={{
                    width: "100%",
                    height: "130px",
                  }}
                >
                  <div className="mobile-project-about1"></div>
                </a>
              )} */}
            </>
          )}
          {initialOffersFlat.flatRent?.length !== 0 && (
            <>
              <hr className="InitialPageOffers-content-hr" />
              <div className="InitialPageOffers-content-row InitialPageOffers-content-flatrents">
                <div className="InitialPageOffers-content-row-title">
                  <span>{options[language].rentFlats}</span>
                  <span onClick={() => showAllOffers("k", "menzil")}>
                    {options[language].showAll}
                  </span>
                </div>
                {initialOffersFlat.flatRent?.map((offer, index) => (
                  <OfferCard key={index} offer={offer} />
                ))}
              </div>
              {/* {isMobile && (
                <a
                  href="https://gezz.az"
                  target="_blank"
                  rel="nofollow"
                  style={{
                    width: "100%",
                    height: "130px",
                  }}
                >
                  <div className="mobile-project-about2"></div>
                </a>
              )} */}
            </>
          )}
          {initialOffersCountry.countrySale?.length !== 0 && (
            <>
              <hr className="InitialPageOffers-content-hr" />
              <div className="InitialPageOffers-content-row InitialPageOffers-content-countrysales">
                <div className="InitialPageOffers-content-row-title">
                  <span>{options[language].saleCountry}</span>
                  <span onClick={() => showAllOffers("s", "heyet-evi-bag")}>
                    {options[language].showAll}
                  </span>
                </div>
                {initialOffersCountry.countrySale?.map((offer, index) => (
                  <OfferCard key={index} offer={offer} />
                ))}
              </div>
              {/* {isMobile && (
                <a
                  href="https://bid.az"
                  target="_blank"
                  rel="nofollow"
                  style={{
                    width: "100%",
                    height: "130px",
                  }}
                >
                  <div className="mobile-project-about3"></div>
                </a>
              )} */}
            </>
          )}
          {initialOffersCountry.countryRent?.length !== 0 && (
            <>
              <hr className="InitialPageOffers-content-hr" />
              <div className="InitialPageOffers-content-row InitialPageOffers-content-countryrents">
                <div className="InitialPageOffers-content-row-title">
                  <span>{options[language].rentCountry}</span>
                  <span onClick={() => showAllOffers("k", "heyet-evi-bag")}>
                    {options[language].showAll}
                  </span>
                </div>
                {initialOffersCountry.countryRent?.map((offer, index) => (
                  <OfferCard key={index} offer={offer} />
                ))}
              </div>
              {/* {isMobile && (
                <a
                  href="https://bid.az"
                  target="_blank"
                  rel="nofollow"
                  style={{
                    width: "100%",
                    height: "130px",
                  }}
                >
                  <div className="mobile-project-about1"></div>
                </a>
              )} */}
            </>
          )}
          {initialOffersOffice.officeSale?.length !== 0 && (
            <>
              <hr className="InitialPageOffers-content-hr" />
              <div className="InitialPageOffers-content-row InitialPageOffers-content-officesales">
                <div className="InitialPageOffers-content-row-title">
                  <span>{options[language].saleOffice}</span>
                  <span onClick={() => showAllOffers("s", "ofis")}>
                    {options[language].showAll}
                  </span>
                </div>
                {initialOffersOffice.officeSale?.map((offer, index) => (
                  <OfferCard key={index} offer={offer} />
                ))}
              </div>
              {/* {isMobile && (
                <a
                  href="https://gezz.az"
                  target="_blank"
                  rel="nofollow"
                  style={{
                    width: "100%",
                    height: "130px",
                  }}
                >
                  <div className="mobile-project-about2"></div>
                </a>
              )} */}
            </>
          )}
          {initialOffersOffice.officeRent?.length !== 0 && (
            <>
              <hr className="InitialPageOffers-content-hr" />
              <div className="InitialPageOffers-content-row InitialPageOffers-content-officerents">
                <div className="InitialPageOffers-content-row-title">
                  <span>{options[language].rentOffice}</span>
                  <span onClick={() => showAllOffers("k", "ofis")}>
                    {options[language].showAll}
                  </span>
                </div>
                {initialOffersOffice.officeRent?.map((offer, index) => (
                  <OfferCard key={index} offer={offer} />
                ))}
              </div>
              {/* {isMobile && (
                <a
                  href="https://bid.az"
                  target="_blank"
                  rel="nofollow"
                  style={{
                    width: "100%",
                    height: "130px",
                  }}
                >
                  <div className="mobile-project-about3"></div>
                </a>
              )} */}
            </>
          )}
          {!isMobile && (
            <>
              {initialOffersGarage.garageSale?.length !== 0 && (
                <>
                  <hr className="InitialPageOffers-content-hr" />
                  <div className="InitialPageOffers-content-row InitialPageOffers-content-garagesales">
                    <div className="InitialPageOffers-content-row-title">
                      <span>{options[language].saleGarage}</span>
                      <span onClick={() => showAllOffers("s", "qaraj")}>
                        {options[language].showAll}
                      </span>
                    </div>
                    {initialOffersGarage.garageSale?.map((offer, index) => (
                      <OfferCard key={index} offer={offer} />
                    ))}
                  </div>
                  {/* {isMobile && (
                    <a
                      href="https://bid.az"
                      target="_blank"
                      rel="nofollow"
                      style={{
                        width: "100%",
                        height: "130px",
                      }}
                    >
                      <div className="mobile-project-about1"></div>
                    </a>
                  )} */}
                </>
              )}
              {initialOffersGarage.garageRent?.length !== 0 && (
                <>
                  <hr className="InitialPageOffers-content-hr" />
                  <div className="InitialPageOffers-content-row InitialPageOffers-content-garagerents">
                    <div className="InitialPageOffers-content-row-title">
                      <span>{options[language].rentGarage}</span>
                      <span onClick={() => showAllOffers("k", "qaraj")}>
                        {options[language].showAll}
                      </span>
                    </div>
                    {initialOffersGarage.garageRent?.map((offer, index) => (
                      <OfferCard key={index} offer={offer} />
                    ))}
                  </div>
                  {/* {isMobile && (
                    <a
                      href="https://gezz.az"
                      target="_blank"
                      rel="nofollow"
                      style={{
                        width: "100%",
                        height: "130px",
                      }}
                    >
                      <div className="mobile-project-about2"></div>
                    </a>
                  )} */}
                </>
              )}
              {initialOffersLand.landSale?.length !== 0 && (
                <>
                  <hr className="InitialPageOffers-content-hr" />
                  <div className="InitialPageOffers-content-row InitialPageOffers-content-landsales">
                    <div className="InitialPageOffers-content-row-title">
                      <span>{options[language].saleLand}</span>
                      <span onClick={() => showAllOffers("s", "torpaq")}>
                        {options[language].showAll}
                      </span>
                    </div>
                    {initialOffersLand.landSale?.map((offer, index) => (
                      <OfferCard key={index} offer={offer} />
                    ))}
                  </div>
                  {/* {isMobile && (
                    <a
                      href="https://bid.az"
                      target="_blank"
                      rel="nofollow"
                      style={{
                        width: "100%",
                        height: "130px",
                      }}
                    >
                      <div className="mobile-project-about3"></div>
                    </a>
                  )} */}
                </>
              )}
              {initialOffersLand.landRent?.length !== 0 && (
                <>
                  <hr className="InitialPageOffers-content-hr" />
                  <div className="InitialPageOffers-content-row InitialPageOffers-content-landrents">
                    <div className="InitialPageOffers-content-row-title">
                      <span>{options[language].rentLand}</span>
                      <span onClick={() => showAllOffers("k", "torpaq")}>
                        {options[language].showAll}
                      </span>
                    </div>
                    {initialOffersLand.landRent?.map((offer, index) => (
                      <OfferCard key={index} offer={offer} />
                    ))}
                  </div>
                  {/* {isMobile && (
                    <a
                      href="https://bid.az"
                      target="_blank"
                      rel="nofollow"
                      style={{
                        width: "100%",
                        height: "130px",
                      }}
                    >
                      <div className="mobile-project-about1"></div>
                    </a>
                  )} */}
                </>
              )}
              {initialOffersObyekt.commercialSale?.length !== 0 && (
                <>
                  <hr className="InitialPageOffers-content-hr" />
                  <div className="InitialPageOffers-content-row InitialPageOffers-content-commercialsales">
                    <div className="InitialPageOffers-content-row-title">
                      <span>{options[language].saleCommercial}</span>
                      <span onClick={() => showAllOffers("s", "obyekt")}>
                        {options[language].showAll}
                      </span>
                    </div>
                    {initialOffersObyekt.commercialSale?.map((offer, index) => (
                      <OfferCard key={index} offer={offer} />
                    ))}
                  </div>
                  {/* {isMobile && (
                    <a
                      href="https://gezz.az"
                      target="_blank"
                      rel="nofollow"
                      style={{
                        width: "100%",
                        height: "130px",
                      }}
                    >
                      <div className="mobile-project-about2"></div>
                    </a>
                  )} */}
                </>
              )}
              {initialOffersObyekt.commercialRent?.length !== 0 && (
                <>
                  <hr className="InitialPageOffers-content-hr" />
                  <div className="InitialPageOffers-content-row InitialPageOffers-content-commercialrents">
                    <div className="InitialPageOffers-content-row-title">
                      <span>{options[language].rentCommercial}</span>
                      <span onClick={() => showAllOffers("k", "obyekt")}>
                        {options[language].showAll}
                      </span>
                    </div>
                    {initialOffersObyekt.commercialRent?.map((offer, index) => (
                      <OfferCard key={index} offer={offer} />
                    ))}
                  </div>
                  {/* {isMobile && (
                    <a
                      href="https://bid.az"
                      target="_blank"
                      rel="nofollow"
                      style={{
                        width: "100%",
                        height: "130px",
                      }}
                    >
                      <div className="mobile-project-about3"></div>
                    </a>
                  )} */}
                </>
              )}{" "}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InitialPageOffers;
