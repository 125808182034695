import * as React from "react";
import "./LoginRegistration.css";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import { options } from "../../constants";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  isMobileNumberUsed,
  isPasswordCorrect,
  isUserExist,
  loginAction,
  loginBid,
  loginSendOtp,
  setCounter,
} from "../../redux/action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Header from "../../Components/Header/Header";
import OtpInput from "react-otp-input";
import MiniBanner from "../../Components/MiniBanner/MiniBanner";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}Ebaz {new Date().getFullYear()}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function Login() {
  const [phoneNumber, setPhoneNumber] = useState("+994(");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.language);
  const userInfo = useSelector((state) => state.userInfo);
  const isUsrExist = useSelector((state) => state.isUserExist);
  const isPwdCorrect = useSelector((state) => state.isPasswordCorrect);
  const [isPhonenumCorrectWrote, setIsPhonenumCorrectWrote] = useState(true);
  const isOTPok = useSelector((state) => state.isOTPok);
  const openOTP = useSelector((state) => state.openOTP);
  const [otp, setOtp] = useState("");
  const [isOtpWrote, setIsOtpWrote] = useState(true);
  const counterAction = useSelector((state) => state.timer);
  const [counter, setCounter2] = useState(counterAction);
  useEffect(() => {
    userInfo?.accessToken !== undefined && navigate("/");
    userInfo?.accessToken !== undefined &&
      localStorage.setItem("token", userInfo?.accessToken);
    userInfo?.mobileNum !== undefined &&
      localStorage.setItem("fleetId", userInfo?.mobileNum);
  }, [userInfo]);
  React.useLayoutEffect(() => {
    document.title = options[language].loginTitle;
  }, []);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const LoginBID = (e) => {
    dispatch(isMobileNumberUsed(false));
    dispatch(isUserExist(true));
    otp.length === 4 ? setIsOtpWrote(true) : setIsOtpWrote(false);
    phoneNumber
      .replaceAll(" ", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "").length < 13
      ? setIsPhonenumCorrectWrote(false)
      : setIsPhonenumCorrectWrote(true);
    if (
      phoneNumber
        .replaceAll(" ", "")
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll("-", "").length === 13 &&
      otp.length === 4
    ) {
      dispatch(loginAction(phoneNumber, otp));
    }
  };
  const sendOtpCode = () => {
    dispatch(isMobileNumberUsed(false));
    dispatch(isUserExist(true));
    dispatch(setCounter(0));
    phoneNumber
      .replaceAll(" ", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "").length < 13
      ? setIsPhonenumCorrectWrote(false)
      : setIsPhonenumCorrectWrote(true);
    if (
      phoneNumber
        .replaceAll(" ", "")
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll("-", "").length === 13
    ) {
      dispatch(loginSendOtp(phoneNumber));
    }
  };
  const handlePhoneChange = (e) => {
    var len = e.target.value?.length;
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      if (len === 14) {
        return setPhoneNumber(e.target.value.slice(0, 13));
      } else if (len === 11) {
        return setPhoneNumber(e.target.value.slice(0, 10));
      } else if (len === 7) {
        return setPhoneNumber(e.target.value.slice(0, 6));
      }
    }
    if (
      e.target.value?.length < 18 &&
      !isNaN(
        e.target.value
          .replaceAll("-", "")
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("+", "")
      )
    ) {
      if (
        e.target.value?.slice(0, 5) !== "+994(" &&
        e.target.value?.slice(0, 4) !== "+994" &&
        e.target.value?.slice(0, 3) !== "+99" &&
        e.target.value?.slice(0, 2) !== "+9" &&
        e.target.value?.slice(0, 1) !== "+"
      ) {
        setPhoneNumber("+994(" + e.target.value);
      } else if (
        len === 4 &&
        !phoneNumber.endsWith("-") &&
        !phoneNumber.endsWith("(") &&
        !phoneNumber.endsWith(")")
      ) {
        setPhoneNumber(e.target.value + "(");
      } else if (
        len === 7 &&
        !phoneNumber.endsWith("-") &&
        !phoneNumber.endsWith("(") &&
        !phoneNumber.endsWith(")")
      ) {
        setPhoneNumber(e.target.value + ")");
      } else if (
        (len === 11 || len === 14) &&
        !phoneNumber.endsWith("-") &&
        !phoneNumber.endsWith("(") &&
        !phoneNumber.endsWith(")")
      ) {
        setPhoneNumber(e.target.value + "-");
      } else if (len < 18 && len > 4) {
        setPhoneNumber(e.target.value);
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter2(counter - 1), 1000);
  }, [counter]);
  useEffect(() => {
    setCounter2(counterAction);
  }, [counterAction]);
  return (
    <>
      <MiniBanner />
      <Header />
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#0089CF" }}>
              <LockOutlinedIcon color="#000" />
            </Avatar>
            <Typography component="h1" variant="h5">
              {options[language].login}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                value={phoneNumber}
                onChange={(e) => handlePhoneChange(e)}
                label={options[language].phoneNumberName}
                name="tel"
                autoComplete="tel"
                autoFocus
              />
              {!isPhonenumCorrectWrote && (
                <div className="forgot-password error-css">
                  {options[language].writePhoneNumber}
                </div>
              )}
              {openOTP && (
                <div className="otp-part">
                  <span className="otp-part-span">
                    {options[language].sendSMStoPhone}
                  </span>
                  <div className="otpdiv">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => (
                        <input type="number" {...props} />
                      )}
                      inputType="number"
                    />
                  </div>
                  {counter !== 0 ? (
                    <span className="otp-part-span2">
                      {options[language].sendRepeatSMStoPhone} 00:
                      {counter < 10 ? "0" : ""}
                      {counter}{" "}
                    </span>
                  ) : (
                    <span
                      className="otp-part-span3"
                      onClick={() => sendOtpCode()}
                    >
                      {options[language].sendRepeatSMStoPhone}
                    </span>
                  )}
                </div>
              )}
              {!isOtpWrote && (
                <div className="forgot-password error-css">
                  {options[language].pleaseWriteSMSCode}
                </div>
              )}
              {!isOTPok && (
                <div className="forgot-password error-css">
                  {options[language].pleaseWriteSMSCodeCorrect}
                </div>
              )}
              <FormControlLabel
                sx={{ color: "black" }}
                control={<Checkbox value="remember" color="primary" />}
                label={options[language].saveUser}
              />
              {!isUsrExist && (
                <div className="forgot-password error-css">
                  {options[language].userNotFound}
                </div>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: "#0089CF" }}
                onClick={() => (openOTP ? LoginBID() : sendOtpCode())}
              >
                {options[language].loginWithBigI}
              </Button>
              <Grid container>
                <Grid item xs>
                  {/* <Link href="#" variant="body2">
                  Şifrəni unutmusuz?
                </Link> */}
                </Grid>
                {/* <Grid item>
                  <Link href='/qeydiyyat' variant='body2'>
                    {options[language].notProfileRegistration}
                  </Link>
                </Grid> */}
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </>
  );
}
