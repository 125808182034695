import React, { useState } from 'react'
import './Morebar.css'
import { changeSiteLanguage, setShowMorebar } from '../../redux/action'
import { useDispatch, useSelector } from 'react-redux'
import Profile from '../images/profile.svg'
import Right from '../images/right.svg'
import Done from '../images/done.svg'
import Globus from '../images/globus.svg'
import Document from '../images/document.svg'
import Security from '../images/Security.svg'
import Blog from '../DesktopHeader/blog.png'
import { options } from '../../constants'
import X from '../images/x.svg'
import heartSVG2 from '../images/heartSVG2.svg'
import { useNavigate } from 'react-router-dom'

const Morebar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showLanguages, setShowLanguages] = useState(false)
  const language = useSelector(state => state.language)
  const handleClickOnProfil = () => {
    dispatch(setShowMorebar(false))
    localStorage.getItem('token') ? navigate('/profil') : navigate('/daxil-ol')
  }
  const handleClickOnFavourites = () => {
    dispatch(setShowMorebar(false))
    navigate('/secilmis-elanlar')
  }
  const handleClickOnBlog = () => {
    dispatch(setShowMorebar(false))
    navigate('/bloglar')
  }
  const handleClickOnLanguage = () => {
    setShowLanguages(!showLanguages)
  }
  const openUsersAgreePage = () => {
    window.open('/istifadeci-razilasmasi.html', '_blank')
  }
  const openPrivacyPage = () => {
    window.open('/mexfiliq-siyaseti.html', '_blank')
  }
  const changeLanguage = language => {
    setShowLanguages(!showLanguages)
    if (localStorage.getItem('language') !== language) {
      const url = new URL(window.location.href)
      url.searchParams.delete('ru')
      url.searchParams.delete('az')
      url.searchParams.delete('en')
      url.searchParams.set(language, '')
      window.localStorage.setItem('language', language)
      window.location.href = url.toString().replace(/=$|=(?=&)/g, '')
      // dispatch(changeSiteLanguage(language))
      //   window.location.reload()
    }
  }
  return (
    <div
      className='morebar'
      onClick={e =>
        e.target.className === 'morebar' && dispatch(setShowMorebar(false))
      }
    >
      <div className='morebar-content'>
        <div className='morebar-content-title'>
          <span>{options[language].menuSmall}</span>
          <img
            onClick={() => dispatch(setShowMorebar(false))}
            src={X}
            alt='x'
            style={{
              position: 'absolute',
              right: '16px',
              height: '20px',
              top: '20px'
            }}
          />
        </div>
        <hr className='morebar-content-hr' />
        <div className='morebar-content-items'>
          <div
            className='morebar-content-item'
            onClick={() => handleClickOnProfil()}
          >
            <div className='morebar-content-item-left'>
              <img src={Profile} alt='profil' />
              <span>
                {localStorage.getItem('token')
                  ? options[language].cabinetSmall
                  : options[language].signIn}
              </span>
            </div>
            <div className='morebar-content-item-right'>
              <img src={Right} alt='right' />
            </div>
          </div>
          {/* <hr className='morebar-content-hr' /> */}
          <div
            className='morebar-content-item'
            onClick={() => handleClickOnFavourites()}
          >
            <div className='morebar-content-item-left'>
              <img
                src={heartSVG2}
                alt='profil'
                style={{ height: '19px', marginTop: '3px' }}
              />
              <span>{options[language].favourites}</span>
            </div>
            <div className='morebar-content-item-right'>
              <img src={Right} alt='right' />
            </div>
          </div>
          {/* <div
            className='morebar-content-item'
            onClick={() => handleClickOnBlog()}
          >
            <div className='morebar-content-item-left'>
              <img
                src={Blog}
                alt='blog'
                style={{ height: '23px', marginTop: '3px' }}
              />
              <span>{options[language].blog}</span>
            </div>
            <div className='morebar-content-item-right'>
              <img src={Right} alt='right' />
            </div>
          </div> */}
          {/* <hr className='morebar-content-hr' />
                    <div className='morebar-content-item' onClick={() => handleClickOnSupport()}>
                        <div className='morebar-content-item-left'>
                            <img src={Support} alt='profil' />
                            <span>Dəstək</span>
                        </div>
                        <div className='morebar-content-item-right'>
                            <img src={Right} alt='right' />
                        </div>
                    </div> */}
          {/* <hr className='morebar-content-hr' />
                    <div className='morebar-content-item' onClick={() => handleClickOnLanguage()}>
                        <div className='morebar-content-item-left'>
                            <img src={Posts} alt='profil' />
                            <span>Saytda Reklam</span>
                        </div>
                        <div className='morebar-content-item-right'>
                            <img src={Right} alt='right' />
                        </div>
                    </div> */}
          {/* <hr className='morebar-content-hr' /> */}
          <div
            className='morebar-content-item'
            onClick={() => openUsersAgreePage()}
          >
            <div className='morebar-content-item-left'>
              <img src={Document} alt='profil' />
              <span>{options[language].usersAgree}</span>
            </div>
            <div className='morebar-content-item-right'>
              <img src={Right} alt='right' />
            </div>
          </div>
          {/* <hr className='morebar-content-hr' /> */}
          <div
            className='morebar-content-item'
            onClick={() => openPrivacyPage()}
          >
            <div className='morebar-content-item-left'>
              <img src={Security} alt='profil' />
              <span>{options[language].privicy}</span>
            </div>
            <div className='morebar-content-item-right'>
              <img src={Right} alt='right' />
            </div>
          </div>
          {/* <hr className='morebar-content-hr' /> */}
          <div
            className='morebar-content-item'
            onClick={() => handleClickOnLanguage()}
          >
            <div className='morebar-content-item-left'>
              <img src={Globus} alt='profil' />
              <span>
                {options[language].languageSmall}:{' '}
                {options[language].languageBig}
              </span>
            </div>
            <div className='morebar-content-item-right'>
              <img src={Right} alt='right' />
            </div>
          </div>
          {/* <hr className='morebar-content-hr' /> */}
        </div>
        {showLanguages ? (
          <div
            className='morebar-language'
            onClick={e =>
              e.target.className === 'morebar-language' &&
              setShowLanguages(false)
            }
          >
            <div className='morebar-language-content'>
              <div className='morebar-language-content-title'>
                {options[language].languageBig}
                <img
                  onClick={() => setShowLanguages(false)}
                  src={X}
                  alt='x'
                  style={{
                    position: 'absolute',
                    right: '0',
                    height: '18px',
                    top: '13px'
                  }}
                />
              </div>
              <hr />
              <div className='morebar-language-content-languages'>
                <div
                  className='morebar-language-content-language'
                  onClick={() => changeLanguage('az')}
                >
                  <span>{options[language].languageAZ}</span>
                  <img src={Done} alt='done' />
                </div>
                <div
                  className='morebar-language-content-language'
                  onClick={() => changeLanguage('ru')}
                >
                  <span>{options[language].languageRU}</span>
                  <img src={Done} alt='done' />
                </div>
                {/* <div
                  className='morebar-language-content-language'
                  onClick={() => changeLanguage('en')}
                >
                  <span>{options[language].languageEN}</span>
                  <img src={Done} alt='done' />
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default Morebar
