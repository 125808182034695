import React from 'react'

const Favourite = ({ color }) => {
  return (
    <svg
    width='26.5'
    height='25'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.4103 16.2763C10.8406 16.7976 9.9634 16.7976 9.39362 16.2687L9.31115 16.1932C5.37516 12.6042 2.80365 10.2544 2.90111 7.32287C2.9461 6.03841 3.59835 4.80685 4.65544 4.08151C6.63468 2.7215 9.07874 3.35617 10.3982 4.91263C11.7177 3.35617 14.1618 2.71394 16.141 4.08151C17.1981 4.80685 17.8504 6.03841 17.8953 7.32287C18.0003 10.2544 15.4213 12.6042 11.4853 16.2083L11.4103 16.2763Z'
        stroke={color ? color : '#000'}
        strokeWidth='1.25'
      />
    </svg>
  )
}

export default Favourite
