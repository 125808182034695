import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import mark2 from "../../images/mark2.svg";
import OfferCardForMap from "./OfferCardForMap";
import "./Map.css";

export default function MapComponent() {
  const center = useSelector((state) => state.center);
  const zoom = useSelector((state) => state.zoom);
  const dataCommon = useSelector((state) => state.defaultOffersWithLocations);
  const [data, setData] = useState(
    dataCommon ? dataCommon.filter((item) => item.location !== null) : null
  );

  useEffect(() => {
    setData(
      dataCommon ? dataCommon.filter((item) => item.location !== null) : null
    );
  }, [dataCommon]);

  const azerbaijanBounds = {
    north: 43.899888,
    south: 36.392836,
    east: 51.370991,
    west: 44.776968,
  };

  const customIcon = L.icon({
    iconUrl: mark2,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  return (
    <MapContainer
      center={[center.lat, center.lng]}
      zoom={zoom}
      maxZoom={18}
      scrollWheelZoom={true}
      className="map-container"
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {data &&
        data.map((loc) => (
          <Marker
            key={loc.id}
            position={[loc?.location[0], loc?.location[1]]}
            icon={customIcon} 
          >
            <Popup>
              <OfferCardForMap key={loc.id} offer={loc} />
            </Popup>
          </Marker>
        ))}
    </MapContainer>
  );
}
