import React from 'react'
import EbazLogo from '../../Components/images/ebazlogo1.png'
import Header from '../../Components/Header/Header'
import { options } from '../../constants'
import { useSelector } from 'react-redux'
import MiniBanner from '../../Components/MiniBanner/MiniBanner'
const NotFound = () => {
  const language = useSelector(state => state.language)
  React.useLayoutEffect(() => {
    document.title = options[language].notFoundTitle
  }, [])

  return (
    <>
        <MiniBanner/>
      <Header />
      <div className='success-page'>
        <div className='success-page-content'>
          <div className='success-page-content-title'>
            <img src={EbazLogo} alt='Ebaz Logo' style={{ width: '80px' }} />
          </div>
          <div className='success-page-content-body'>
            <div className='success-page-content-body-icon'>
              <span style={{ fontSize: '50px', color: '#2196f3' }}>404</span>
            </div>
          </div>
          <div className='success-page-content-desc'>
            <div className='success-page-content-desc-t'>
              <span style={{ color: '#111' }}>{options[language].pageNotFoundSmall}</span>
            </div>
            <div className='success-page-content-desc-d'>
              <a href='/'>{options[language].mainPageSmall}</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
