import React, { useState } from 'react'
import "./MobileNavBar.css"
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { options } from "../../constants"
import PlusIcon from '../images/plusSVG.svg'
import { useNavigate } from 'react-router-dom'
import { saveLastSearchUrl, setShowMorebar } from '../../redux/action'
import Favourite from './images/Favourite'
import Home from './images/Home'
import Kabinet from './images/Kabinet'
import More from './images/More'

const MobileNavBar = () => {
    const [showMobIcons, setShowMobIcons] = useState(false)
    const [isLogged, setIsLogged] = useState(false)
    const lastSearchUrl = useSelector(state => state.lastSearchUrl)
    const language = useSelector(state => state.language)
    const dispatch = useDispatch()
    const showMorebarAction = useSelector(state => state.showMorebar)
    const navigate = useNavigate()
    useEffect(() => {
        localStorage.getItem("token") ? setIsLogged(true) : setIsLogged(false)
    }, [])
    useEffect(() => {
        localStorage.getItem("token") ? setIsLogged(true) : setIsLogged(false)
    }, [localStorage.getItem("token")])
    const clickOnSearch = () => {
        if (lastSearchUrl !== "/" && lastSearchUrl !== undefined) {
            navigate(`/${lastSearchUrl}`);
        }
        else {
            navigate('/')
        }
        dispatch(setShowMorebar(false))
    }
    const clickOnFav = () => {
        if (!(window.location.href.includes("/elan/") ||
            window.location.href.includes("/secilmis-elanlar/") ||
            window.location.href.includes("secilmis-elanlar") ||
            window.location.href.includes("/profil/") ||
            window.location.href.includes("profil") ||
            window.location.href.includes("/daxil-ol/") ||
            window.location.href.includes("daxil-ol") ||
            window.location.href.includes("/elan-yerlesdir/") ||
            window.location.href.includes("elan-yerlesdir") ||
            window.location.href.includes("/qeydiyyat/") ||
            window.location.href.includes("qeydiyyat") ||
            window.location.href.includes("elani-yenile") ||
            window.location.href.includes("daha-cox")
        )) {
            dispatch(saveLastSearchUrl(window.location.href.replace("https://ebaz.az/", "")))
        }
        navigate("/secilmis-elanlar")
        dispatch(setShowMorebar(false))

    }
    const clickOnPlus = () => {
        if (!(window.location.href.includes("/elan/") ||
            window.location.href.includes("/secilmis-elanlar/") ||
            window.location.href.includes("secilmis-elanlar") ||
            window.location.href.includes("/profil/") ||
            window.location.href.includes("profil") ||
            window.location.href.includes("/daxil-ol/") ||
            window.location.href.includes("daxil-ol") ||
            window.location.href.includes("/elan-yerlesdir/") ||
            window.location.href.includes("elan-yerlesdir") ||
            window.location.href.includes("/qeydiyyat/") ||
            window.location.href.includes("qeydiyyat") ||
            window.location.href.includes("elani-yenile") ||
            window.location.href.includes("daha-cox")
        )) {
            dispatch(saveLastSearchUrl(window.location.href.replace("https://ebaz.az/", "")))
        }
        localStorage.getItem("token") ? navigate("/elan-yerlesdir") : navigate("/daxil-ol")
        dispatch(setShowMorebar(false))
    }
    const clickOnProfile = () => {
        if (!(window.location.href.includes("/elan/") ||
            window.location.href.includes("/secilmis-elanlar/") ||
            window.location.href.includes("secilmis-elanlar") ||
            window.location.href.includes("/profil/") ||
            window.location.href.includes("profil") ||
            window.location.href.includes("/daxil-ol/") ||
            window.location.href.includes("daxil-ol") ||
            window.location.href.includes("/elan-yerlesdir/") ||
            window.location.href.includes("elan-yerlesdir") ||
            window.location.href.includes("/qeydiyyat/") ||
            window.location.href.includes("qeydiyyat") ||
            window.location.href.includes("elani-yenile") ||
            window.location.href.includes("daha-cox")
        )) {
            dispatch(saveLastSearchUrl(window.location.href.replace("https://ebaz.az/", "")))
        }
        localStorage.getItem("token") ? navigate("/profil") : navigate("/daxil-ol")
        dispatch(setShowMorebar(false))
    }
    const clickOnMore = () => {
        if (!(window.location.href.includes("/elan/") ||
            window.location.href.includes("/secilmis-elanlar/") ||
            window.location.href.includes("secilmis-elanlar") ||
            window.location.href.includes("/profil/") ||
            window.location.href.includes("profil") ||
            window.location.href.includes("/daxil-ol/") ||
            window.location.href.includes("daxil-ol") ||
            window.location.href.includes("/elan-yerlesdir/") ||
            window.location.href.includes("elan-yerlesdir") ||
            window.location.href.includes("/qeydiyyat/") ||
            window.location.href.includes("qeydiyyat") ||
            window.location.href.includes("elani-yenile") ||
            window.location.href.includes("daha-cox")
        )) {
            dispatch(saveLastSearchUrl(window.location.href.replace("https://ebaz.az/", "")))
        }
        dispatch(setShowMorebar(!showMorebarAction))
    }
    return (
        <div className='mobile-nav-bar'>
            <div className='mobile-nav-bar-content'>
                <div className='mobile-nav-bar-content-element' onClick={() => clickOnSearch()}>
                    <div className='mobile-nav-bar-content-element-img'>
                        <Home color={window.location.pathname === "/" && !showMorebarAction ? "#0089CF" : "#000"} />
                    </div>
                    <span style={{ color: window.location.pathname === "/"&& !showMorebarAction ? "#0089CF" : "#000" }}>{options[language].searchSmall}</span>
                </div>
                <div className='mobile-nav-bar-content-element' onClick={() => clickOnFav()}>
                    <div className='mobile-nav-bar-content-element-img'>
                        <Favourite color={window.location.pathname === "/secilmis-elanlar" && !showMorebarAction ? "#0089CF" : "#000"} />
                    </div>
                    <span style={{ color: window.location.pathname === "/secilmis-elanlar" && !showMorebarAction ? "#0089CF" : "#000" }}>{options[language].favourites}</span>
                </div>
                <div className='mobile-nav-bar-content-element' onClick={() => clickOnPlus()} style={{ marginTop: "-16px" }}>
                    <div className="plus-icon">
                        <img src={PlusIcon} alt='profile' style={{ marginLeft: "0px", width:'100%',height:'19px' }} />
                    </div>
                    <span>{options[language].newOfferSmall}</span>
                </div>
                <div className='mobile-nav-bar-content-element' onClick={() => clickOnProfile()}>
                    <div className='mobile-nav-bar-content-element-img'>
                        <Kabinet color={window.location.pathname === "/profil" && !showMorebarAction ? "#0089CF" : "#000"} />
                    </div>
                    <span style={{ color: window.location.pathname === "/profil" && !showMorebarAction ? "#0089CF" : "#000" }}>{options[language].cabinetSmall}</span>
                </div>
                <div className='mobile-nav-bar-content-element' onClick={() => clickOnMore()}>
                    <div className='mobile-nav-bar-content-element-img' >
                        <More color={showMorebarAction ? "#0089CF" : "#000"} />
                    </div>
                    <span style={{ color: showMorebarAction ? "#0089CF" : "#000" }}>{options[language].moreSmall}</span>
                </div>
            </div>
        </div>
    )
}

export default MobileNavBar