import React from 'react'

const More = ({ color }) => {
  return (
    <svg
      width='26.5'
      height='25'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.89844 5H17.8984'
        stroke={color ? color : '#000'}
        strokeWidth='1.25'
        strokeLinecap='round'
      />
      <path
        d='M2.89844 10.0098H17.8984'
        stroke={color ? color : '#000'}
        strokeWidth='1.25'
        strokeLinecap='round'
      />
      <path
        d='M2.89844 14.5537H17.8984'
        stroke={color ? color : '#000'}
        strokeWidth='1.25'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default More
