import React from 'react'

const ObyektImage = ({ color }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.22773 10.2922V10.2922H20.767V23.1997H17.5637H13.7426H3.22773L3.22773 10.2922Z'
        stroke={color}
        strokeWidth='1.6'
      />
      <path
        d='M23.1356 10.2165H0.864425C0.962806 9.31013 1.12444 8.8479 1.34384 8.38653C1.41017 8.24705 1.49325 8.08755 1.58858 7.90454C1.80676 7.48567 2.08908 6.94367 2.3815 6.2367C2.38534 6.22741 2.39104 6.22141 2.39559 6.21829C2.39715 6.21722 2.39825 6.21671 2.39876 6.2165H21.6004C21.601 6.21671 21.6021 6.21722 21.6036 6.21829C21.6082 6.22141 21.6139 6.22742 21.6177 6.23671C21.9103 6.94401 22.1928 7.48606 22.4111 7.90497C22.5065 8.08784 22.5895 8.24724 22.6559 8.38668C22.8754 8.84805 23.0371 9.31023 23.1356 10.2165Z'
        stroke={color}
        strokeWidth='1.6'
      />
      <path
        d='M13.0723 13.6089V23.4675H18.4158V13.6089H13.0723Z'
        stroke={color}
        strokeWidth='0.8'
      />
      <path
        d='M5.87305 13.6089V18.4089H11.0637V13.6089H5.87305Z'
        stroke={color}
        strokeWidth='0.8'
      />
      <mask id='path-5-inside-1_1309_4721' fill='white'>
        <path d='M23.2 22.3994H0.8C0.358172 22.3994 0 22.7576 0 23.1994C0 23.6412 0.358172 23.9994 0.799999 23.9994H23.2C23.6418 23.9994 24 23.6412 24 23.1994C24 22.7576 23.6418 22.3994 23.2 22.3994Z' />
      </mask>
      <path
        d='M0.8 23.9994H23.2V20.7994H0.8V23.9994ZM23.2 22.3994H0.799999V25.5994H23.2V22.3994ZM0.799999 22.3994C1.24183 22.3994 1.6 22.7576 1.6 23.1994H-1.6C-1.6 24.5249 -0.525486 25.5994 0.799999 25.5994V22.3994ZM22.4 23.1994C22.4 22.7576 22.7582 22.3994 23.2 22.3994V25.5994C24.5255 25.5994 25.6 24.5249 25.6 23.1994H22.4ZM23.2 23.9994C22.7582 23.9994 22.4 23.6412 22.4 23.1994H25.6C25.6 21.8739 24.5255 20.7994 23.2 20.7994V23.9994ZM0.8 20.7994C-0.525484 20.7994 -1.6 21.8739 -1.6 23.1994H1.6C1.6 23.6412 1.24183 23.9994 0.8 23.9994V20.7994Z'
        fill={color}
        mask='url(#path-5-inside-1_1309_4721)'
      />
    </svg>
  )
}

export default ObyektImage
