import React from 'react'

const Home = ({ color }) => {
  return (
    <svg
      width='26.5'
      height='25'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.6548 16.0731L14.8215 13.248C15.7356 12.0834 16.2317 10.6453 16.2298 9.16472C16.2298 7.84617 15.8388 6.55724 15.1063 5.46091C14.3737 4.36459 13.3326 3.5101 12.1144 3.00552C10.8962 2.50094 9.55576 2.36891 8.26255 2.62615C6.96935 2.88338 5.78146 3.51832 4.84911 4.45067C3.91676 5.38302 3.28182 6.57091 3.02459 7.86411C2.76735 9.15732 2.89937 10.4978 3.40396 11.7159C3.90854 12.9341 4.76302 13.9753 5.85935 14.7078C6.95568 15.4404 8.24461 15.8314 9.56315 15.8314C11.0437 15.8332 12.4819 15.3372 13.6465 14.423L16.4715 17.2564C16.549 17.3345 16.6411 17.3965 16.7427 17.4388C16.8442 17.4811 16.9531 17.5029 17.0632 17.5029C17.1732 17.5029 17.2821 17.4811 17.3836 17.4388C17.4852 17.3965 17.5774 17.3345 17.6548 17.2564C17.7329 17.1789 17.7949 17.0867 17.8372 16.9852C17.8795 16.8836 17.9013 16.7747 17.9013 16.6647C17.9013 16.5547 17.8795 16.4458 17.8372 16.3442C17.7949 16.2427 17.7329 16.1505 17.6548 16.0731ZM4.56315 9.16472C4.56315 8.17581 4.8564 7.20911 5.40581 6.38687C5.95521 5.56462 6.73611 4.92376 7.64974 4.54532C8.56337 4.16688 9.5687 4.06786 10.5386 4.26079C11.5085 4.45372 12.3994 4.92992 13.0987 5.62918C13.798 6.32845 14.2742 7.21936 14.4671 8.18927C14.66 9.15917 14.561 10.1645 14.1826 11.0781C13.8041 11.9918 13.1633 12.7727 12.341 13.3221C11.5188 13.8715 10.5521 14.1647 9.56315 14.1647C8.23707 14.1647 6.9653 13.6379 6.02762 12.7003C5.08994 11.7626 4.56315 10.4908 4.56315 9.16472Z'
        fill={color ? color : '#000'}
      />
    </svg>
  )
}

export default Home
