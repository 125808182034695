import React from 'react';
import { CDBModalFooter, CDBLink, CDBBtn, CDBIcon, CDBBox } from 'cdbreact';
import siteIcon from "./logo_white.png"
import { options } from "../../constants"
import { useSelector } from 'react-redux';

export const Footer = () => {
  const language = useSelector(state => state.language)
  return (
    <CDBModalFooter className="shadow" style={{ backgroundColor: "#0089CF", color: "#fff", marginTop: "0px" }}>
      <CDBBox display="flex" flex="column" className="mx-auto py-5" style={{ width: '90%', maxWidth: "1300px" }}>
        <CDBBox display="flex" justifyContent="between" className="flex-wrap" marginBottom="50px">
          <CDBBox>
            <a href="/" className="d-flex align-items-center p-0 text-dark">
              <img alt="logo" src={siteIcon} width="106px" />
            </a>
            <p className="my-3" style={{ width: '250px' }}>
              {options[language].sloqan}
            </p>
          </CDBBox>
          <CDBBox>
            <p className="h5 mb-4" style={{ fontWeight: '600' }}>
              Ebaz
            </p>
            <CDBBox flex="column" display="flex" style={{ cursor: 'pointer', padding: '0' }}>
              {/* <CDBLink href="/">Haqqımızda</CDBLink> */}
              <a href="https://ebaz.az/istifadeci-razilasmasi.html">{options[language].usersAgree}</a>
              <a href="https://ebaz.az/mexfiliq-siyaseti.html">{options[language].privicy}</a>
              <a href="https://ebaz.az/sitemap.xml">{options[language].siteMap}</a>
              {/* <CDBLink href="/">Sual-cavablar</CDBLink> */}
            </CDBBox>
          </CDBBox>
          <CDBBox>
            <p className="h5 mb-4" style={{ fontWeight: '600' }}>
            {options[language].services}
            </p>
            <CDBBox display="flex" flex="column" style={{ cursor: 'pointer', padding: '0' }}>
              <a href="/daxil-ol">{options[language].signIn}</a>
              <a href="/secilmis-elanlar">{options[language].favourites}</a>
              <a href="/elan-yerlesdir">{options[language].uploadOffer}</a>
              {/* <CDBLink href="/">Saytda reklam</CDBLink> */}
            </CDBBox>
          </CDBBox>
          <CDBBox>
            <p className="h5 mb-4" style={{ fontWeight: '600' }}>
            {options[language].connect}
            </p>
            <CDBBox display="flex" flex="column" style={{ cursor: 'pointer', padding: '0' }}>
              <CDBBox display="flex" flex="row" style={{ cursor: 'pointer', padding: '0', gap: "5px" }}>
                <CDBBtn flat color="dark" className="p-2" href="https://www.facebook.com/ebaz.aze" target="_blank">
                  <CDBIcon fab icon="facebook-f" />
                </CDBBtn>
                <CDBBtn flat color="dark" className="p-2" href="https://www.instagram.com/ebaz.az" target="_blank" >
                  <CDBIcon fab icon="instagram" />
                </CDBBtn>
              </CDBBox>
              <a href="/">info@ebaz.az</a>
              {/* <a href="/">+994 55 123 45 67</a> */}
            </CDBBox>
          </CDBBox>
        </CDBBox>
        <CDBBox
          display="flex"
          alignItems="center"
          style={{ width: '100%', gap: "40px"}}
          className="mx-auto mt-4"
        >
          <small>
            &copy; Ebaz, {new Date().getFullYear()}. {options[language].allRightsReserved}
          </small>
          <small>
          <CDBLink href="/">{options[language].disclaimer}</CDBLink>
          </small>
        </CDBBox>
      </CDBBox>
    </CDBModalFooter>
  );
}
export default Footer;