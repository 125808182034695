import React, { useLayoutEffect } from "react";
import Filter from "../../Components/Filter/Filter";
import Offers from "../../Components/Offers/Offers";
import Header from "../../Components/Header/Header";
import MobileFilter from "../../Components/MobileFilter/MobileFilter";
import { useSelector } from "react-redux";
import OffersWithPremiumOffers from "../../Components/Offers/OffersWithPremiumOffers";
import { options } from "../../constants";
import MiniBanner from "../../Components/MiniBanner/MiniBanner";

const Home = () => {
  const language = useSelector((state) => state.language);
  useLayoutEffect(() => {
    document.title = options[language].allPremiumOffersTitle;
  }, []);
  const isMobile = useSelector((state) => state.isMobile);
  return (
    <div className="home">
      <MiniBanner />
      <Header />
      {isMobile ? <MobileFilter /> : <Filter />}
      <OffersWithPremiumOffers />
    </div>
  );
};
export default Home;
