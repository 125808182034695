import React from 'react';
import './LoadingMap.css'; 

const LoadingMap = () => {
  return (
    <div className="loading-containerM">
      <div className="loading-spinnerM" style={{ background: '#0089CF' }}></div>
      <div className="loading-spinnerM" style={{ background: '#DCDCDC' }}></div>
    </div>
  );
};

export default LoadingMap;
