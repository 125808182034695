import React, { useEffect, useMemo, useRef, useState } from "react";
import "./MobileFilter.css";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import { options } from "../../constants";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useSearchParams } from "react-router-dom";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import FlatImage from "./FlatImage";
import CountryImage from "./CountryImage";
import LandImage from "./LandImage";
import ObyektImage from "./ObyektImage";
import OfisImage from "./OfisImage";
import GarageImage from "./GarageImage";
import closeSiteSelection from "./closeSiteSelection.svg";
import showSites from "./showSites.svg";
import checkedIcon from "./checked.svg";
import searchIcon from "./searchIcon.svg";
import {
  changeFilter,
  changeSearchRow,
  getPage,
  requestOffersWithLocations,
  searchOffer,
  setShouldRunSecondEffect,
  getInitial,
  setShowMobileFilter,
  getCountOfOffers,
} from "../../redux/action";
const styles = {
  indicatorSeparator: () => ({ display: "none" }),
  valueContainer: (base) => ({
    ...base,
    maxHeight: 36,
    overflowY: "auto",
    borderRadius: "10px",
    paddingLeft: "0",
  }),
  control: (styles, state) => ({
    ...styles,
    maxHeight: 29,
    overflowY: "auto",
    borderRadius: "0px",
    cursor: "pointer",
    boxShadow: "none",
    borderColor: "transparent",
    "&:hover": {
      borderColor: "transparent",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#0089CF",
    "&:hover": {
      color: "#0089CF",
    },
  }),
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected ? isSelected : false}
      getStyles={getStyles}
      innerProps={props}
    >
      <input
        type="checkbox"
        className="checkBoxSelect"
        checked={isSelected}
        style={{ marginRight: "5px" }}
        onChange={() => console.log("")}
      />
      {children}
    </components.Option>
  );
};

const MobileFilter = () => {
  const showMobileFilter = useSelector((state) => state.showMobileFilter);
  const [citySearch, setCitySearch] = useState("");
  const [selectedSearchInRegion, setSelectedSearchInRegion] =
    useState("region");
  const [openOrCloseSelectionOfSites, setOpenOrCloseSelectionOfSites] =
    useState(false);
  const [openOrCloseSelectionOfCities, setOpenOrCloseSelectionOfCities] =
    useState(false);
  const [openOrCloseSelectionOfRegions, setOpenOrCloseSelectionOfRegions] =
    useState(false);
  const [clickedEnter, setClickedEnter] = useState(0);
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        let count = clickedEnter + 1;
        setClickedEnter(count);
      }
    };
    document.addEventListener("keyup", keyDownHandler);
    return () => {
      document.removeEventListener("keyup", keyDownHandler);
    };
  });
  const historyRef = useRef(null);
  const myElementRef = useRef(null);
  const dispatch = useDispatch();
  const offerTypeAction = useSelector((state) => state.offerType);
  const loadingOfCount = useSelector((state) => state.loadingOfCount);
  const countOfOffers = useSelector((state) => state.countOfOffers);
  const filter = useSelector((state) => state.filter);
  const language = useSelector((state) => state.language);
  const fTypeReducer = useSelector((state) => state.flatType);
  const [flatType, setFlatType] = useState(fTypeReducer);
  const [offerType, setOfferType] = useState("s");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minPricePerM2, setMinPricePerM2] = useState("");
  const [maxPricePerM2, setMaxPricePerM2] = useState("");
  const [withGoodsTrue, SetWithGoodsTrue] = useState(false);
  const [withGoodsFalse, SetWithGoodsFalse] = useState(false);
  const [withCredit, SetWithCredit] = useState(false);
  const [documentTrue, setDocumentTrue] = useState(false);
  const [documentFalse, setDocumentFalse] = useState(false);
  const [repairTrue, setRepairTrue] = useState(false);
  const [repairFalse, setRepairFalse] = useState(false);
  const [lastRequest, setLastRequest] = useState("");
  const [showComplexBtn, setShowComplexBtn] = useState(true);
  const [province, setProvince] = useState([]);
  const [province_opt, setProvince_opt] = useState(
    options[language].region_options
  );
  const [resident_opt, setResident_opt] = useState(
    options[language].resident_options
  );
  const [station_opt, setStation_opt] = useState(
    options[language].station_options
  );
  const [residents, setResidents] = useState([]);
  const [stations, setStations] = useState([]);
  const [target_opt, setTarget_opt] = useState(
    options[language].target_options
  );
  const [targets, setTargets] = useState([]);
  const [areaMin, setAreaMin] = useState("");
  const [areaMax, setAreaMax] = useState("");
  const [floorMin, setFloorMin] = useState("");
  const [floorMax, setFloorMax] = useState("");
  const countofPages = useSelector((state) => state.countOfPages);
  const page = useSelector((state) => state.page);
  const searchBool = useSelector((state) => state.search);
  const [showAditional, setShowAditional] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState(null);
  const [selectedCities, setSelectedCities] = useState(null);
  const [city, setCity] = useState([options[language].cities[8]]);
  const [sortedCities, setSortedCities] = useState(options[language].cities);
  const [sites, setSites] = useState([]);
  const [site_opt, setSiteOpt] = useState(options[language].site_options);
  const [rooms, setRooms] = useState([]);
  const [rotateIcon, setRotateIcon] = useState(false);
  const [rotateIcon2, setRotateIcon2] = useState(false);
  const [searchHistoryCheck, setSearchHistoryCheck] = useState(false);
  const showMap = useSelector((state) => state.showOrCloseMap);
  const [offers, setOffers] = useState(localStorage.getItem("MySearchs"));
  const [historySpan, setHistorySpan] = useState();
  const [count, setCount] = useState();
  const [isSearchByHistory, setIsSearchByHistory] = useState(false);
  const [isSearchByHistoryData, setIsSearchByHistoryData] = useState();
  const [queryParameters] = useSearchParams();
  const shouldRunSecondEffect = useSelector(
    (state) => state.shouldRunSecondEffect
  );
  useEffect(() => {
    if (fTypeReducer !== flatType) {
      setFlatType(fTypeReducer);
      searchButton(fTypeReducer);
    }
  }, [fTypeReducer]);
  const changeParametrs = () => {
    if (queryParameters.get("satis") !== null) {
      setOfferType("s");
    }
    if (queryParameters.get("kiraye") !== null) {
      setOfferType("k");
    }
    if (queryParameters.get("gunluk") !== null) {
      setOfferType("g");
    }
    if (queryParameters.get("emlak") !== null) {
      queryParameters.get("emlak") === "menzil"
        ? setFlatType(options[language].flat_type_options[0])
        : queryParameters.get("emlak") === "heyet-evi-bag-villa"
        ? setFlatType(options[language].flat_type_options[1])
        : queryParameters.get("emlak") === "ofis"
        ? setFlatType(options[language].flat_type_options[2])
        : queryParameters.get("emlak") === "qaraj"
        ? setFlatType(options[language].flat_type_options[3])
        : queryParameters.get("emlak") === "torpaq"
        ? setFlatType(options[language].flat_type_options[4])
        : queryParameters.get("emlak") === "obyekt"
        ? setFlatType(options[language].flat_type_options[5])
        : setFlatType(options[language].flat_type_options[6]);
    }
    if (queryParameters.get("seher") !== null) {
      const citiesArray = queryParameters.get("seher").split("-");
      if (citiesArray.length <= 3) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].cities.find(
            (label) => label.label === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setCity(validResultArray);
      }
      if (citiesArray.length > 3) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].cities.find(
            (label) => label.value === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setCity(validResultArray);
      }
    }
    if (queryParameters.get("rayon") !== null) {
      const citiesArray = queryParameters.get("rayon").split("-");
      if (citiesArray.length <= 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].region_options.find(
            (label) => label.label === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setProvince(validResultArray);
      }
      if (citiesArray.length > 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].region_options.find(
            (label) => label.value === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setProvince(validResultArray);
      }
    }
    if (queryParameters.get("qesebe") !== null) {
      const citiesArray = queryParameters.get("qesebe").split("-");
      if (citiesArray.length <= 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].resident_options.find(
            (label) => label.label === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setResidents(validResultArray);
      }
      if (citiesArray.length > 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].resident_options.find(
            (label) => label.value === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setResidents(validResultArray);
      }
    }
    if (queryParameters.get("otaqlar") !== null) {
      const roomsArray = queryParameters.get("otaqlar").split("-");
      const resultArray = roomsArray.map((city, index) => {
        const matchingLabel = options[language].room_options.find(
          (label) => label.value === city
        );
        return matchingLabel ? { ...matchingLabel, order: index } : null;
      });
      const validResultArray = resultArray.filter((result) => result !== null);
      setRooms(validResultArray);
    }
    if (queryParameters.get("min-qiymet") !== null) {
      const mprice = queryParameters.get("min-qiymet");
      setMinPrice(mprice);
    }
    if (queryParameters.get("max-qiymet") !== null) {
      const mprice = queryParameters.get("max-qiymet");
      setMaxPrice(mprice);
    }
    if (queryParameters.get("min-qiymet-m2") !== null) {
      const mprice = queryParameters.get("min-qiymet-m2");
      setMinPricePerM2(mprice);
    }
    if (queryParameters.get("max-qiymet-m2") !== null) {
      const mprice = queryParameters.get("max-qiymet-m2");
      setMaxPricePerM2(mprice);
    }
    if (queryParameters.get("min-sahe") !== null) {
      const mprice = queryParameters.get("min-sahe");
      setAreaMin(mprice);
    }
    if (queryParameters.get("max-sahe") !== null) {
      const mprice = queryParameters.get("max-sahe");
      setAreaMax(mprice);
    }
    if (queryParameters.get("min-mertebe") !== null) {
      const mprice = queryParameters.get("min-mertebe");
      setFloorMin(mprice);
    }
    if (queryParameters.get("max-mertebe") !== null) {
      const mprice = queryParameters.get("max-mertebe");
      setFloorMax(mprice);
    }
    if (queryParameters.get("metro") !== null) {
      const citiesArray = queryParameters.get("metro").split("-");
      if (citiesArray.length <= 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].station_options.find(
            (label) => label.label === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setStations(validResultArray);
      }
      if (citiesArray.length > 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].station_options.find(
            (label) => label.value === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setStations(validResultArray);
      }
    }
    if (queryParameters.get("nisangah") !== null) {
      const citiesArray = queryParameters.get("nisangah").split("-");
      if (citiesArray.length <= 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].target_options.find(
            (label) => label.label === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setTargets(validResultArray);
      }
      if (citiesArray.length > 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].target_options.find(
            (label) => label.value === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setTargets(validResultArray);
      }
    }
    if (queryParameters.get("esyali") !== null) {
      SetWithGoodsTrue(true);
    }
    if (queryParameters.get("esyasiz") !== null) {
      SetWithGoodsFalse(true);
    }
    if (queryParameters.get("temirli") !== null) {
      setRepairTrue(true);
    }
    if (queryParameters.get("temirsiz") !== null) {
      setRepairFalse(true);
    }
    if (queryParameters.get("senedli") !== null) {
      setDocumentTrue(true);
    }
    if (queryParameters.get("senedsiz") !== null) {
      setDocumentFalse(true);
    }
    if (queryParameters.get("sayt") !== null) {
      const siteArray = queryParameters.get("sayt").split("-");
      const resultArray = siteArray.map((city, index) => {
        const matchingLabel = options[language].site_options.find(
          (label) => label.value === city
        );
        return matchingLabel ? { ...matchingLabel, order: index } : null;
      });
      const validResultArray = resultArray.filter((result) => result !== null);
      setSites(validResultArray);
    }
    if (queryParameters.get("ipoteka") !== null) {
      SetWithCredit(true);
    }
    if (queryParameters.get("page") !== null) {
      dispatch(getPage(Number(queryParameters.get("page"))));
    }
    if (queryParameters.get("filter") !== null) {
      dispatch(
        changeFilter(
          queryParameters.get("filter") === "dn"
            ? options[language].filter_options[0]
            : queryParameters.get("filter") === "do"
            ? options[language].filter_options[1]
            : queryParameters.get("filter") === "pl"
            ? options[language].filter_options[2]
            : queryParameters.get("filter") === "ph"
            ? options[language].filter_options[3]
            : options[language].filter_options[0]
        )
      );
    }
  };
  useEffect(() => {
    setOffers(localStorage.getItem("MySearchs"));
    if (offers) {
      setCount(JSON.parse(offers).length);
      setHistorySpan(
        Object.values(JSON.parse(offers))
          .reverse()
          .map((el, index) => (
            <span
              className="searchHistorySpan"
              onClick={() => searchByHistory(el)}
              style={{ cursor: "pointer" }}
              key={index}
            >
            {el.offerType === "s"
              ? " • Satılır"
              : el.offerType === "g"
              ? " • Günlük"
              : " • Kirayə"}
              {el.flatType ? " • " + el.flatType.label : " • Kirayə"}
              {el.city?.length
                ? el.city.map((c) => {
                    return " • " + c.label;
                  })
                : ""}
              {el.province?.length !== 0 &&
              el.city.some((c) => c.value.includes("8"))
                ? el.province?.map((r) => {
                    return " • " + r.label;
                  })
                : ""}
              {el.residents?.length &&
              el.city.some((c) => c.value.includes("8"))
                ? el.residents.map((r) => {
                    return " • " + r.label;
                  })
                : ""}
              {el.targets?.length && el.city.some((c) => c.value.includes("8"))
                ? el.targets.map((r) => {
                    return " • " + r.label;
                  })
                : ""}
              {el.stations?.length && el.city.some((c) => c.value.includes("8"))
                ? el.stations.map((r) => {
                    return " • " + r.label;
                  })
                : ""}
              {el.rooms?.length
                ? el.rooms.map((r) => {
                    return " • " + r.label + "otaqlı";
                  })
                : ""}
              {el.sites?.length
                ? el.sites.map((r) => {
                    return " • " + r.label;
                  })
                : ""}
              {el.areaMin !== "" ? " • Minimum sahə " + el.areaMin : ""}
              {el.areaMax !== "" ? " • Maksimum sahə " + el.areaMax : ""}
              {el.floorMin !== "" ? " • Minimum mərtəbə " + el.floorMin : ""}
              {el.floorMax !== "" ? " • Maksimum mərtəbə " + el.floorMax : ""}
              {el.minPrice !== 0 && el.minPrice !== ""
                ? " • Minimum qiymət " + el.minPrice
                : ""}
              {el.maxPrice !== 99999999999 && el.maxPrice !== ""
                ? " • Maksimum qiymət " + el.maxPrice
                : ""}
              {el.withGoodsTrue === true ? " • Əşyalı" : ""}
              {el.withGoodsFalse === true ? " • Əşyasız" : ""}
              {el.repairTrue === true ? " • Təmirli" : ""}
              {el.repairFalse === true ? " • Təmirsiz" : ""}
              {el.documentTrue === true ? " • Sənədli" : ""}
              {el.documentFalse === true ? " • Sənədsiz" : ""}
              {el.withCredit === true ? " • İpotekaya yararlı" : ""}
            </span>
          ))
      );
    } else {
      setCount(0);
    }
  }, [searchHistoryCheck, filter, page, showMap]);
  useEffect(() => {
    const fetchData = async () => {
      //gulcan
      changeParametrs();
      if (
        queryParameters.get("emlak") !== null ||
        queryParameters.get("satis") !== null ||
        queryParameters.get("page") !== null ||
        queryParameters.get("filter") !== null ||
        queryParameters.get("seher") !== null ||
        queryParameters.get("otaqlar") !== null ||
        queryParameters.get("min-qiymet") !== null ||
        queryParameters.get("max-qiymet") !== null ||
        queryParameters.get("min-qiymet-m2") !== null ||
        queryParameters.get("max-qiymet-m2") !== null ||
        queryParameters.get("min-sahe") !== null ||
        queryParameters.get("max-sahe") !== null ||
        queryParameters.get("rayon") !== null ||
        queryParameters.get("qesebe") !== null ||
        queryParameters.get("metro") !== null ||
        queryParameters.get("nisangah") !== null ||
        queryParameters.get("min-mertebe") !== null ||
        queryParameters.get("max-mertebe") !== null ||
        queryParameters.get("sayt") !== null ||
        queryParameters.get("esyali") !== null ||
        queryParameters.get("esyasiz") !== null ||
        queryParameters.get("ipoteka") !== null ||
        queryParameters.get("temirli") !== null ||
        queryParameters.get("temirsiz") !== null ||
        queryParameters.get("senedli") !== null ||
        queryParameters.get("senedsiz") !== null
      ) {
        dispatch(
          searchOffer(
            queryParameters.get("emlak") === "menzil"
              ? options[language].flat_type_options[0]
              : queryParameters.get("emlak") === "heyet-evi-bag-villa"
              ? options[language].flat_type_options[1]
              : queryParameters.get("emlak") === "ofis"
              ? options[language].flat_type_options[2]
              : queryParameters.get("emlak") === "qaraj"
              ? options[language].flat_type_options[3]
              : queryParameters.get("emlak") === "torpaq"
              ? options[language].flat_type_options[4]
              : queryParameters.get("emlak") === "obyekt"
              ? options[language].flat_type_options[5]
              : options[language].flat_type_options[0],
              queryParameters.get("satis") !== null
                ? "s"
                : queryParameters.get("kiraye") !== null
                ? "k"
                : queryParameters.get("gunluk") !== null
                ? "g"
                : "s",
            queryParameters.get("page") !== null
              ? Number(queryParameters.get("page"))
              : 1,
            queryParameters.get("filter") !== null
              ? queryParameters.get("filter") === "dn"
                ? options[language].filter_options[0]
                : queryParameters.get("filter") === "do"
                ? options[language].filter_options[1]
                : queryParameters.get("filter") === "pl"
                ? options[language].filter_options[2]
                : queryParameters.get("filter") === "ph"
                ? options[language].filter_options[3]
                : options[language].filter_options[4]
              : options[language].filter_options[0],
            queryParameters.get("seher") !== null
              ? ((citiesArray) =>
                  citiesArray.length <= 3
                    ? citiesArray
                        .map((city, index) => {
                          const matchingLabel = options[language].cities.find(
                            (label) => label.label === city
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null)
                    : citiesArray
                        .map((city, index) => {
                          const matchingLabel = options[language].cities.find(
                            (label) => label.value === city
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null))(
                  queryParameters.get("seher").split("-")
                )
              : [],
            queryParameters.get("otaqlar") !== null
              ? ((roomsArray) =>
                  roomsArray
                    .map((city, index) => {
                      const matchingLabel = options[language].room_options.find(
                        (label) => label.value === city
                      );
                      return matchingLabel
                        ? { ...matchingLabel, order: index }
                        : null;
                    })
                    .filter((result) => result !== null))(
                  queryParameters.get("otaqlar").split("-")
                )
              : [],
            queryParameters.get("min-qiymet") !== null
              ? queryParameters.get("min-qiymet")
              : "",
            queryParameters.get("max-qiymet") !== null
              ? queryParameters.get("max-qiymet")
              : "",
            queryParameters.get("min-sahe") !== null
              ? queryParameters.get("min-sahe")
              : "",
            queryParameters.get("max-sahe") !== null
              ? queryParameters.get("max-sahe")
              : "",
            queryParameters.get("rayon") !== null
              ? ((regionsArray) =>
                  regionsArray.length <= 1
                    ? regionsArray
                        .map((region, index) => {
                          const matchingLabel = options[
                            language
                          ].region_options.find(
                            (label) => label.label === region
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null)
                    : regionsArray
                        .map((region, index) => {
                          const matchingLabel = options[
                            language
                          ].region_options.find(
                            (label) => label.value === region
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null))(
                  queryParameters.get("rayon").split("-")
                )
              : [],
            queryParameters.get("qesebe") !== null
              ? ((residentsArray) =>
                  residentsArray.length <= 1
                    ? residentsArray
                        .map((resident, index) => {
                          const matchingLabel = options[
                            language
                          ].resident_options.find(
                            (label) => label.label === resident
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null)
                    : residentsArray
                        .map((region, index) => {
                          const matchingLabel = options[
                            language
                          ].resident_options.find(
                            (label) => label.value === region
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null))(
                  queryParameters.get("qesebe").split("-")
                )
              : [],
            queryParameters.get("metro") !== null
              ? ((metroArray) =>
                  metroArray.length <= 1
                    ? metroArray
                        .map((metro, index) => {
                          const matchingLabel = options[
                            language
                          ].station_options.find(
                            (label) => label.label === metro
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null)
                    : metroArray
                        .map((metro, index) => {
                          const matchingLabel = options[
                            language
                          ].station_options.find(
                            (label) => label.value === metro
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null))(
                  queryParameters.get("metro").split("-")
                )
              : [],
            queryParameters.get("nisangah") !== null
              ? ((residentsArray) =>
                  residentsArray.length <= 1
                    ? residentsArray
                        .map((resident, index) => {
                          const matchingLabel = options[
                            language
                          ].resident_options.find(
                            (label) => label.label === resident
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null)
                    : residentsArray
                        .map((resident, index) => {
                          const matchingLabel = options[
                            language
                          ].resident_options.find(
                            (label) => label.value === resident
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null))(
                  queryParameters.get("nisangah").split("-")
                )
              : [],
            queryParameters.get("min-mertebe") !== null
              ? queryParameters.get("min-mertebe")
              : "",
            queryParameters.get("max-mertebe") !== null
              ? queryParameters.get("max-mertebe")
              : "",
            queryParameters.get("sayt") !== null
              ? ((siteArray) =>
                  siteArray.length <= 1
                    ? siteArray
                        .map((site, index) => {
                          const matchingLabel = options[
                            language
                          ].site_options.find((label) => label.label === site);
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null)
                    : siteArray
                        .map((site, index) => {
                          const matchingLabel = options[
                            language
                          ].site_options.find((label) => label.value === site);
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null))(
                  queryParameters.get("sayt").split("-")
                )
              : [],
            queryParameters.get("esyali") !== null ? true : false,
            queryParameters.get("esyasiz") !== null ? true : false,
            queryParameters.get("ipoteka") !== null ? true : false,
            queryParameters.get("temirli") !== null ? true : false,
            queryParameters.get("temirsiz") !== null ? true : false,
            queryParameters.get("senedli") !== null ? true : false,
            queryParameters.get("senedsiz") !== null ? true : false,
            queryParameters.get("min-qiymet-m2") !== null
              ? queryParameters.get("min-qiymet-m2")
              : "",
            queryParameters.get("max-qiymet-m2") !== null
              ? queryParameters.get("max-qiymet-m2")
              : ""
          )
        );
      } else {
        dispatch(getInitial());
      }
    };
    fetchData();
  }, []);
  const addRooms = (value) => {
    if (
      rooms.some(
        (room) => room.value === value.value && room.label === value.label
      )
    ) {
      setRooms(
        rooms.filter(
          (room) => !(room.value === value.value && room.label === value.label)
        )
      );
    } else {
      const newRooms = [...rooms, value];
      setRooms(newRooms);
    }
  };
  useEffect(() => {
    dispatch(
      changeSearchRow(
        `${
          offerType === "s"
            ? options[language].sale
            : offerType === "g"
            ? options[language].day
            : options[language].rent
        }${flatType ? " • " + (flatType.label ? flatType.label : options[language].flat_type_options[0].label) : ""}${
          city?.length
            ? " • " +
              city.map((c) => {
                return " " + c.label;
              })
            : ""
        }`
      )
    );
    if (!shouldRunSecondEffect) {
      dispatch(setShouldRunSecondEffect(true));
      if (!isSearchByHistory) {
        if (!showMap) {
          const stationValues = stations
            ?.map((station) => station.value - 1)
            .join(",");
          const roomValues = rooms?.map((room) => room.value).join(",");
          const siteValues = sites?.map((site) => site.value).join(",");
          const residentValues = residents
            ?.map((resident) => resident.value - 1)
            .join(",");
          const targetValues = targets
            ?.map((target) => target.value - 1)
            .join(",");
          const regionValues = province
            ?.map((region) => region.value)
            .join(",");
          const cityValues = city?.map((city) => city.value).join(",");
          const url = new URL(window.location.href);
          offerType
          ? url.searchParams.set(
              offerType === "s"
                ? "satis"
                : offerType === "g"
                ? "gunluk"
                : "kiraye",
              ""
            ) ||
            url.searchParams.delete(offerType === "s" ? "kiraye" : "satis") ||
            url.searchParams.delete(offerType === "s" ? "gunluk" : "satis") ||
            url.searchParams.delete(offerType === "k" ? "satis" : "kiraye") ||
            url.searchParams.delete(
              offerType === "k" ? "gunluk" : "kiraye"
            ) ||
            url.searchParams.delete(
              offerType === "g" ? "kiraye" : "gunluk"
            ) ||
            url.searchParams.delete(offerType === "g" ? "satis" : "gunluk")
          : url.searchParams.delete(offerType === "s" ? "kiraye" : "satis") ||
            url.searchParams.delete(offerType === "s" ? "gunluk" : "satis") ||
            url.searchParams.delete(offerType === "k" ? "satis" : "kiraye") ||
            url.searchParams.delete(
              offerType === "k" ? "gunluk" : "kiraye"
            ) ||
            url.searchParams.delete(
              offerType === "g" ? "kiraye" : "gunluk"
            ) ||
            url.searchParams.delete(offerType === "g" ? "satis" : "gunluk");
        flatType
            ? url.searchParams.set(
                "emlak",
                flatType === 1 || flatType.value === "1"
                  ? "menzil"
                  : flatType.value === "2"
                  ? "heyet-evi-bag-villa"
                  : flatType.value === "3"
                  ? "ofis"
                  : flatType.value === "4"
                  ? "qaraj"
                  : flatType.value === "5"
                  ? "torpaq"
                  : flatType.value === "6"
                  ? "obyekt"
                  : "menzil"
              )
            : url.searchParams.delete("emlak");
          cityValues.length !== 0
            ? url.searchParams.set(
                "seher",
                city.length > 3
                  ? city?.map((city) => city.value).join("-")
                  : city?.map((city) => city.label).join("-")
              )
            : url.searchParams.delete("seher");
          cityValues.includes("8") && regionValues.length !== 0
            ? url.searchParams.set(
                "rayon",
                province.length > 1
                  ? province?.map((city) => city.value).join("-")
                  : province?.map((city) => city.label).join("-")
              )
            : url.searchParams.delete("rayon");
          cityValues.includes("8") && residentValues.length !== 0
            ? url.searchParams.set(
                "qesebe",
                residents.length > 1
                  ? residents?.map((city) => city.value).join("-")
                  : residents?.map((city) => city.label).join("-")
              )
            : url.searchParams.delete("qesebe");
          roomValues.length !== 0
            ? url.searchParams.set("otaqlar", roomValues.replaceAll(",", "-"))
            : url.searchParams.delete("otaqlar");
          minPrice !== ""
            ? url.searchParams.set("min-qiymet", minPrice)
            : url.searchParams.delete("min-qiymet");
          maxPrice !== "" && maxPrice !== 99999999999
            ? url.searchParams.set("max-qiymet", maxPrice)
            : url.searchParams.delete("max-qiymet");
          areaMin !== ""
            ? url.searchParams.set("min-sahe", areaMin)
            : url.searchParams.delete("min-sahe");
          areaMax !== "" && areaMax !== 99999999999
            ? url.searchParams.set("max-sahe", areaMax)
            : url.searchParams.delete("max-sahe");
          floorMin !== ""
            ? url.searchParams.set("min-mertebe", floorMin)
            : url.searchParams.delete("min-mertebe");
          floorMax !== "" && floorMax !== 99999999999
            ? url.searchParams.set("max-mertebe", floorMax)
            : url.searchParams.delete("max-mertebe");
          cityValues.includes("8") && stationValues.length !== 0
            ? url.searchParams.set(
                "metro",
                stations.length > 1
                  ? stations?.map((city) => city.value).join("-")
                  : stations?.map((city) => city.label).join("-")
              )
            : url.searchParams.delete("metro");
          cityValues.includes("8") && targetValues.length !== 0
            ? url.searchParams.set(
                "nisangah",
                targets.length > 1
                  ? targets?.map((city) => city.value).join("-")
                  : targets?.map((city) => city.label).join("-")
              )
            : url.searchParams.delete("nisangah");
          withGoodsTrue
            ? url.searchParams.set("esyali", "")
            : url.searchParams.delete("esyali");
          withGoodsFalse
            ? url.searchParams.set("esyasiz", "")
            : url.searchParams.delete("esyasiz");
          repairTrue
            ? url.searchParams.set("temirli", "")
            : url.searchParams.delete("temirli");
          repairFalse
            ? url.searchParams.set("temirsiz", "")
            : url.searchParams.delete("temirsiz");
          documentTrue
            ? url.searchParams.set("senedli", "")
            : url.searchParams.delete("senedli");
          documentFalse
            ? url.searchParams.set("senedsiz", "")
            : url.searchParams.delete("senedsiz");
          siteValues.length !== 0
            ? url.searchParams.set(
                "sayt",
                sites.length > 1
                  ? sites?.map((city) => city.value).join("-")
                  : sites?.map((city) => city.label).join("-")
              )
            : url.searchParams.delete("sayt");
          withCredit
            ? url.searchParams.set("ipoteka", "")
            : url.searchParams.delete("ipoteka");
          filter && url.searchParams.set("filter", filter.value);
          page !== 0
            ? url.searchParams.set("page", page)
            : url.searchParams.delete("page");
          minPricePerM2 !== ""
            ? url.searchParams.set("min-qiymet-m2", minPricePerM2)
            : url.searchParams.delete("min-qiymet-m2");
          maxPricePerM2 !== "" && maxPricePerM2 !== 99999999999
            ? url.searchParams.set("max-qiymet-m2", maxPricePerM2)
            : url.searchParams.delete("max-qiymet-m2");
          window.location.href = url
            .toString()
            .replace("butun-premium-elanlar", "");
        }
        return dispatch(
          requestOffersWithLocations(
            flatType,
            offerType,
            page,
            filter,
            city,
            rooms,
            minPrice,
            maxPrice,
            areaMin,
            areaMax,
            province,
            residents,
            stations,
            targets,
            floorMin,
            floorMax,
            sites,
            withGoodsTrue,
            withGoodsFalse,
            withCredit,
            repairTrue,
            repairFalse,
            documentTrue,
            documentFalse,
            minPricePerM2,
            maxPricePerM2
          )
        );
      } else {
        if (!showMap)
          return dispatch(
            searchOffer(
              isSearchByHistoryData.flatType,
              isSearchByHistoryData.offerType,
              page,
              isSearchByHistoryData.filter,
              isSearchByHistoryData.city,
              isSearchByHistoryData.rooms,
              isSearchByHistoryData.minPrice,
              isSearchByHistoryData.maxPrice,
              isSearchByHistoryData.areaMin,
              isSearchByHistoryData.areaMax,
              isSearchByHistoryData.province,
              isSearchByHistoryData.residents,
              isSearchByHistoryData.stations,
              isSearchByHistoryData.targets,
              isSearchByHistoryData.floorMin,
              isSearchByHistoryData.floorMax,
              isSearchByHistoryData.sites,
              isSearchByHistoryData.withGoodsTrue,
              isSearchByHistoryData.withGoodsFalse,
              isSearchByHistoryData.withCredit,
              isSearchByHistoryData.repairTrue,
              isSearchByHistoryData.repairFalse,
              isSearchByHistoryData.documentTrue,
              isSearchByHistoryData.documentFalse,
              isSearchByHistoryData.minPricePerM2,
              isSearchByHistoryData.maxPricePerM2
            )
          );
        return dispatch(
          requestOffersWithLocations(
            isSearchByHistoryData.flatType,
            isSearchByHistoryData.offerType,
            page,
            isSearchByHistoryData.filter,
            isSearchByHistoryData.city,
            isSearchByHistoryData.rooms,
            isSearchByHistoryData.minPrice,
            isSearchByHistoryData.maxPrice,
            isSearchByHistoryData.areaMin,
            isSearchByHistoryData.areaMax,
            isSearchByHistoryData.province,
            isSearchByHistoryData.residents,
            isSearchByHistoryData.stations,
            isSearchByHistoryData.targets,
            isSearchByHistoryData.floorMin,
            isSearchByHistoryData.floorMax,
            isSearchByHistoryData.sites,
            isSearchByHistoryData.withGoodsTrue,
            isSearchByHistoryData.withGoodsFalse,
            isSearchByHistoryData.withCredit,
            isSearchByHistoryData.repairTrue,
            isSearchByHistoryData.repairFalse,
            isSearchByHistoryData.documentTrue,
            isSearchByHistoryData.documentFalse,
            isSearchByHistoryData.minPricePerM2,
            isSearchByHistoryData.maxPricePerM2
          )
        );
      }
    }
  }, [shouldRunSecondEffect, filter, page, showMap]);
  const handleComplexSearchClick = () => {
    setShowAditional(!showAditional);
    setRotateIcon(!rotateIcon);
    try {
      if (showAditional) {
        myElementRef.current
          .querySelectorAll(".css-b62m3t-container")
          ?.forEach((element) => {
            element.style.width = "calc(100% / 6 - 15px)";
          });
      } else {
        myElementRef.current
          .querySelectorAll(".css-b62m3t-container")
          ?.forEach((element) => {
            element.style.width = "calc(100% / 5 - 10px)";
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleHistoryClick = () => {
    setShowHistory(!showHistory);
    setRotateIcon2(!rotateIcon2);
  };
  const rotationStyle = {
    transform: rotateIcon ? "rotate(180deg)" : "rotate(0deg)",
  };
  const rotationStyle2 = {
    transform: rotateIcon2 ? "rotate(180deg)" : "rotate(0deg)",
  };
  function clearComplexFilter() {
    setSites([]);
    setRooms([]);
    setResidents([]);
    setProvince([]);
    setTargets([]);
    setStations([]);
    setTargets([]);
    setCity([]);
    setAreaMax("");
    setAreaMin("");
    setFloorMax("");
    setFloorMin("");
    setFlatType(options[language].flat_type_options[0]);
    setOfferType("s");
    setMinPrice("");
    setMaxPrice("");
    setMinPricePerM2("");
    setMaxPricePerM2("");
    SetWithGoodsTrue(false);
    SetWithGoodsFalse(false);
    SetWithCredit(false);
    setDocumentTrue(false);
    setDocumentFalse(false);
    setRepairTrue(false);
    setRepairFalse(false);
  }
  function setResidentsF(e) {
    if (e.length !== 0) {
      const cityExists = city.some((cityItem) => {
        return Object.keys(cityItem).every((key) => {
          return (
            cityItem.hasOwnProperty(key) &&
            cityItem[key] === options[language].cities[8][key]
          );
        });
      });

      if (!cityExists) {
        setCity([...city, options[language].cities[8]]);
      }
      // if (!province.length) {
      setProvince([
        ...province,
        options[language].region_options[
          options[language].region_options.findIndex(
            (item) => item.label === e[e.length - 1]?.region
          )
        ],
      ]);
      // const filtered_res = options[language].resident_options.filter(
      //   item => item.region === e[0]?.region
      // )
      // setResident_opt([...filtered_res])
      const filtered_resT = options[language].target_options.filter(
        (item) => item.region === e[0]?.region
      );
      setTarget_opt([...filtered_resT]);
      // }
    }
    setResidents(e);
    // else if (e.length === 0) {
    //     setResident_opt(resident_options)
    //     setResidents([])
    // }
  }
  function setTargetsF(e) {
    if (e.length !== 0) {
      const cityExists = city.some((cityItem) => {
        return Object.keys(cityItem).every((key) => {
          return (
            cityItem.hasOwnProperty(key) &&
            cityItem[key] === options[language].cities[8][key]
          );
        });
      });

      if (!cityExists) {
        setCity([...city, options[language].cities[8]]);
      }
      if (!province.length) {
        setProvince([
          options[language].region_options[
            options[language].region_options.findIndex(
              (item) => item.label === e[e.length - 1]?.region
            )
          ],
        ]);
        const filtered_res = options[language].target_options.filter(
          (item) => item.region === e[0]?.region
        );
        setTarget_opt([...filtered_res]);
        // const filtered_resR = options[language].resident_options.filter(
        //   item => item.region === e[0]?.region
        // )
        // setResident_opt([...filtered_resR])
      }
    }
    setTargets(e);
    // else if (e.length === 0) {
    //     setTarget_opt(target_options)
    //     setTargets([])
    // }
  }
  function setRegionsF(e) {
    if (e.length === 0) {
      setProvince([]);
      setResident_opt(options[language].resident_options);
      setResidents([]);
      setTarget_opt(options[language].target_options);
      setTargets([]);
    } else {
      const cityExists = city.some((cityItem) => {
        return Object.keys(cityItem).every((key) => {
          return (
            cityItem.hasOwnProperty(key) &&
            cityItem[key] === options[language].cities[8][key]
          );
        });
      });

      if (!cityExists) {
        setCity([...city, options[language].cities[8]]);
      }
      setProvince(e);
      const fR = [];
      const fT = [];
      e.forEach((region) => {
        // const filtered_res = options[language].resident_options.filter(
        //   item =>
        //     item.region === options[language].region_options[region.value].label
        // )
        // fR.push(...filtered_res)
        const filtered_resT = options[language].target_options.filter(
          (item) =>
            item.region === options[language].region_options[region.value].label
        );
        fT.push(...filtered_resT);
      });
      // setResident_opt(fR)
      // setResidents([])
      setTarget_opt(fT);
      // setTargets([])
    }
  }
  function setStationsF(e) {
    if (e.length !== 0) {
      setStations(e);
      const cityExists = city.some((cityItem) => {
        return Object.keys(cityItem).every((key) => {
          return (
            cityItem.hasOwnProperty(key) &&
            cityItem[key] === options[language].cities[8][key]
          );
        });
      });

      if (!cityExists) {
        setCity([...city, options[language].cities[8]]);
      }
    } else if (e.length === 0) {
      setStation_opt(options[language].station_options);
      setStations([]);
    }
  }
  function addOrRemoveObjectFromLocalStorage(obj) {
    const existingData = localStorage.getItem("MySearchs");
    let dataArray = [];
    if (existingData) {
      dataArray = JSON.parse(existingData);
      if (dataArray.length >= 5) {
        dataArray = dataArray.slice(dataArray.length - 4);
      }
      const existingIndex = dataArray.findIndex(
        (item) => JSON.stringify(item) === JSON.stringify(obj)
      );
      if (existingIndex === -1) {
        dataArray.push(obj);
      }
      localStorage.setItem("MySearchs", JSON.stringify(dataArray));
    } else {
      const newDataArray = [obj];
      localStorage.setItem("MySearchs", JSON.stringify(newDataArray));
    }

    setSearchHistoryCheck(!searchHistoryCheck);
  }
  const searchByHistory = (el) => {
    setIsSearchByHistoryData(el);
    setIsSearchByHistory(true);
    dispatch(
      searchOffer(
        el.flatType,
        el.offerType,
        el.page,
        el.filter,
        el.city,
        el.rooms,
        el.minPrice,
        el.maxPrice,
        el.areaMin,
        el.areaMax,
        el.province,
        el.residents,
        el.stations,
        el.targets,
        el.floorMin,
        el.floorMax,
        el.sites,
        el.withGoodsTrue,
        el.withGoodsFalse,
        el.withCredit,
        el.repairTrue,
        el.repairFalse,
        el.documentTrue,
        el.documentFalse,
        el?.minPricePerM2,
        el?.maxPricePerM2
      )
    );
  };
  const searchButton = (fltType) => {
    let ftType = fltType ? fltType : flatType;
    setIsSearchByHistory(false);
    setIsSearchByHistoryData();

    dispatch(
      changeSearchRow(
        `${
          offerType === "s"
            ? options[language].sale
            : offerType === "g"
            ? options[language].day
            : options[language].rent}${flatType ? " • " + (flatType.label ? flatType.label : options[language].flat_type_options[0].label) : ""}${
          city?.length
            ? " • " +
              city.map((c) => {
                return " " + c.label;
              })
            : ""
        }`
      )
    );
    addOrRemoveObjectFromLocalStorage({
      ftType,
      offerType,
      page,
      filter,
      city,
      rooms,
      minPrice,
      maxPrice,
      areaMin,
      areaMax,
      province,
      residents,
      stations,
      targets,
      floorMin,
      floorMax,
      sites,
      withGoodsTrue,
      withGoodsFalse,
      withCredit,
      repairTrue,
      repairFalse,
      documentTrue,
      documentFalse,
      minPricePerM2,
      maxPricePerM2,
    });

    const unselectedOptionsC = options[language].cities.filter((opt) => {
      return !city.some((ct) => opt.label === ct.label);
    });
    const sortedOptionsC = city
      .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      .concat(unselectedOptionsC);
    setSortedCities(sortedOptionsC);

    const unselectedOptionsR = options[language].region_options.filter(
      (opt) => {
        return !province.some((ct) => opt.label === ct.label);
      }
    );
    const sortedOptionsR = province
      .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      .concat(unselectedOptionsR);
    setProvince_opt(sortedOptionsR);

    const unselectedOptionsRe = resident_opt.filter((opt) => {
      return !residents.some((ct) => opt.label === ct.label);
    });
    const sortedOptionsRe = residents
      .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      .concat(unselectedOptionsRe);
    setResident_opt(sortedOptionsRe);

    const unselectedOptionsT = target_opt.filter((opt) => {
      return !targets.some((ct) => opt.label === ct.label);
    });
    const sortedOptionsT = targets
      .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      .concat(unselectedOptionsT);
    setTarget_opt(sortedOptionsT);

    const unselectedOptionsM = options[language].station_options.filter(
      (opt) => {
        return !stations.some((ct) => opt.label === ct.label);
      }
    );
    const sortedOptionsM = stations
      .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      .concat(unselectedOptionsM);
    setStation_opt(sortedOptionsM);

    const unselectedOptionsS = options[language].site_options.filter((opt) => {
      return !sites.some((ct) => opt.label === ct.label);
    });
    const sortedOptionsS = sites.concat(unselectedOptionsS);
    setSiteOpt(sortedOptionsS);

    if (!showMap) {
      if (offerTypeAction !== "get") {
        return dispatch(
          searchOffer(
            ftType,
            offerType,
            page,
            filter,
            city,
            rooms,
            minPrice,
            maxPrice,
            areaMin,
            areaMax,
            province,
            residents,
            stations,
            targets,
            floorMin,
            floorMax,
            sites,
            withGoodsTrue,
            withGoodsFalse,
            withCredit,
            repairTrue,
            repairFalse,
            documentTrue,
            documentFalse,
            minPricePerM2,
            maxPricePerM2
          )
        );
      } else {
        const stationValues = stations
          ?.map((station) => station.value - 1)
          .join(",");
        const roomValues = rooms?.map((room) => room.value).join(",");
        const siteValues = sites?.map((site) => site.value).join(",");
        const residentValues = residents
          ?.map((resident) => resident.value - 1)
          .join(",");
        const targetValues = targets
          ?.map((target) => target.value - 1)
          .join(",");
        const regionValues = province?.map((region) => region.value).join(",");
        const cityValues = city?.map((city) => city.value).join(",");
        const url = new URL(window.location.href);
        offerType
        ? url.searchParams.set(
            offerType === "s"
              ? "satis"
              : offerType === "g"
              ? "gunluk"
              : "kiraye",
            ""
          ) ||
          url.searchParams.delete(offerType === "s" ? "kiraye" : "satis") ||
          url.searchParams.delete(offerType === "s" ? "gunluk" : "satis") ||
          url.searchParams.delete(offerType === "k" ? "satis" : "kiraye") ||
          url.searchParams.delete(offerType === "k" ? "gunluk" : "kiraye") ||
          url.searchParams.delete(offerType === "g" ? "kiraye" : "gunluk") ||
          url.searchParams.delete(offerType === "g" ? "satis" : "gunluk")
        : url.searchParams.delete(offerType === "s" ? "kiraye" : "satis");
      url.searchParams.delete(offerType === "s" ? "gunluk" : "satis") ||
        url.searchParams.delete(offerType === "k" ? "satis" : "kiraye") ||
        url.searchParams.delete(offerType === "k" ? "gunluk" : "kiraye") ||
        url.searchParams.delete(offerType === "g" ? "kiraye" : "gunluk") ||
        url.searchParams.delete(offerType === "g" ? "satis" : "gunluk");
      ftType
          ? url.searchParams.set(
              "emlak",
              ftType === 1 || ftType.value === "1"
                ? "menzil"
                : ftType.value === "2"
                ? "heyet-evi-bag-villa"
                : ftType.value === "3"
                ? "ofis"
                : ftType.value === "4"
                ? "qaraj"
                : ftType.value === "5"
                ? "torpaq"
                : ftType.value === "6"
                ? "obyekt"
                : "menzil"
            )
          : url.searchParams.delete("emlak");
        cityValues.length !== 0
          ? url.searchParams.set(
              "seher",
              city.length > 3
                ? city?.map((city) => city.value).join("-")
                : city?.map((city) => city.label).join("-")
            )
          : url.searchParams.delete("seher");
        cityValues.includes("8") && regionValues.length !== 0
          ? url.searchParams.set(
              "rayon",
              province.length > 1
                ? province?.map((city) => city.value).join("-")
                : province?.map((city) => city.label).join("-")
            )
          : url.searchParams.delete("rayon");
        cityValues.includes("8") && residentValues.length !== 0
          ? url.searchParams.set(
              "qesebe",
              residents.length > 1
                ? residents?.map((city) => city.value).join("-")
                : residents?.map((city) => city.label).join("-")
            )
          : url.searchParams.delete("qesebe");
        roomValues.length !== 0
          ? url.searchParams.set("otaqlar", roomValues.replaceAll(",", "-"))
          : url.searchParams.delete("otaqlar");
        minPrice !== ""
          ? url.searchParams.set("min-qiymet", minPrice)
          : url.searchParams.delete("min-qiymet");
        maxPrice !== "" && maxPrice !== 99999999999
          ? url.searchParams.set("max-qiymet", maxPrice)
          : url.searchParams.delete("max-qiymet");
        minPricePerM2 !== ""
          ? url.searchParams.set("min-qiymet-m2", minPricePerM2)
          : url.searchParams.delete("min-qiymet-m2");
        maxPricePerM2 !== "" && maxPricePerM2 !== 99999999999
          ? url.searchParams.set("max-qiymet-m2", maxPricePerM2)
          : url.searchParams.delete("max-qiymet-m2");
        areaMin !== ""
          ? url.searchParams.set("min-sahe", areaMin)
          : url.searchParams.delete("min-sahe");
        areaMax !== "" && areaMax !== 99999999999
          ? url.searchParams.set("max-sahe", areaMax)
          : url.searchParams.delete("max-sahe");
        floorMin !== ""
          ? url.searchParams.set("min-mertebe", floorMin)
          : url.searchParams.delete("min-mertebe");
        floorMax !== "" && floorMax !== 99999999999
          ? url.searchParams.set("max-mertebe", floorMax)
          : url.searchParams.delete("max-mertebe");
        cityValues.includes("8") && stationValues.length !== 0
          ? url.searchParams.set(
              "metro",
              stations.length > 1
                ? stations?.map((city) => city.value).join("-")
                : stations?.map((city) => city.label).join("-")
            )
          : url.searchParams.delete("metro");
        cityValues.includes("8") && targetValues.length !== 0
          ? url.searchParams.set(
              "nisangah",
              targets.length > 1
                ? targets?.map((city) => city.value).join("-")
                : targets?.map((city) => city.label).join("-")
            )
          : url.searchParams.delete("nisangah");
        withGoodsTrue
          ? url.searchParams.set("esyali", "")
          : url.searchParams.delete("esyali");
        withGoodsFalse
          ? url.searchParams.set("esyasiz", "")
          : url.searchParams.delete("esyasiz");
        repairTrue
          ? url.searchParams.set("temirli", "")
          : url.searchParams.delete("temirli");
        repairFalse
          ? url.searchParams.set("temirsiz", "")
          : url.searchParams.delete("temirsiz");
        documentTrue
          ? url.searchParams.set("senedli", "")
          : url.searchParams.delete("senedli");
        documentFalse
          ? url.searchParams.set("senedsiz", "")
          : url.searchParams.delete("senedsiz");
        siteValues.length !== 0
          ? url.searchParams.set(
              "sayt",
              sites.length > 1
                ? sites?.map((city) => city.value).join("-")
                : sites?.map((city) => city.label).join("-")
            )
          : url.searchParams.delete("sayt");
        withCredit
          ? url.searchParams.set("ipoteka", "")
          : url.searchParams.delete("ipoteka");
        filter && url.searchParams.set("filter", filter.value);
        page !== 0
          ? url.searchParams.set("page", page)
          : url.searchParams.delete("page");
        return (window.location.href = url
          .toString()
          .replace("butun-premium-elanlar", ""));
      }
    }
    return dispatch(
      requestOffersWithLocations(
        ftType,
        offerType,
        page,
        filter,
        city,
        rooms,
        minPrice,
        maxPrice,
        areaMin,
        areaMax,
        province,
        residents,
        stations,
        targets,
        floorMin,
        floorMax,
        sites,
        withGoodsTrue,
        withGoodsFalse,
        withCredit,
        repairTrue,
        repairFalse,
        documentTrue,
        documentFalse,
        minPricePerM2,
        maxPricePerM2
      )
    );
  };
  useEffect(() => {
    historyRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [showHistory]);
  useMemo(() => {
    // if(!document.cookie.includes("countRequest")){
    //     let expirationDate = new Date();
    //     expirationDate.setTime(expirationDate.getTime() + (2000));
    //     let cookieString = "countRequest=1; expires=" + expirationDate.toUTCString() + "; path=/";
    //     document.cookie = cookieString;
    dispatch(
      getCountOfOffers(
        flatType,
        offerType,
        city,
        rooms,
        minPrice,
        maxPrice,
        areaMin,
        areaMax,
        province,
        residents,
        stations,
        targets,
        floorMin,
        floorMax,
        sites,
        withGoodsTrue,
        withGoodsFalse,
        withCredit,
        repairTrue,
        repairFalse,
        documentTrue,
        documentFalse,
        minPricePerM2,
        maxPricePerM2
      )
    );
    // }
  }, [
    flatType,
    minPrice,
    maxPrice,
    offerType,
    withGoodsTrue,
    withGoodsFalse,
    withCredit,
    documentTrue,
    documentFalse,
    repairTrue,
    repairFalse,
    province,
    residents,
    stations,
    targets,
    areaMin,
    areaMax,
    floorMin,
    floorMax,
    city,
    sites,
    rooms,
    minPricePerM2,
    maxPricePerM2,
  ]);
  return (
    <>
      {showMobileFilter && (
        <div
          className="filterMobile"
          onClick={(e) =>
            e.target.className === "filterMobile" &&
            dispatch(setShowMobileFilter(false))
          }
        >
          <div
            className={`filterMobile-content ${
              showMobileFilter ? "showMobileFilter" : ""
            }`}
          >
            <div className="filterMobile-content-parts">
              <div className="filterMobile-content-part-head">
                <span
                  style={{ width: "50px", cursor: "pointer" }}
                  onClick={() => dispatch(setShowMobileFilter(false))}
                >
                  <KeyboardArrowLeftRoundedIcon
                    sx={{
                      marginLeft: "-8px",
                      fontSize: "30px",
                      color: "#0089CF",
                    }}
                  />
                </span>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  {options[language].additionalsearchName}
                </span>
                <span
                  style={{
                    width: "50px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    color: "#0089CF",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                  onClick={() => clearComplexFilter()}
                >
                  {options[language].clean2}
                </span>
              </div>
              <div className="filterMobile-content-part-options">
                <div className="filterMobile-content-part-salerent">
                  <div className="filterMobile-content-part-salerent-select">
                    <div
                      className={`filterMobile-content-part-sale-select ${
                        offerType === "s" ? "active" : "inactive"
                      }`}
                      onClick={() => {
                        //   dispatch(setShouldRunSecondEffect(false))
                        setOfferType("s");
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "500",
                        }}
                      >
                        {options[language].sale}
                      </span>
                    </div>
                    {/* <hr className='filterMobile-content-part-salerent-select-hr' /> */}
                    <div
                      className={`filterMobile-content-part-rent-select ${
                        (offerType === 'g' || offerType === 'k') ? "active" : "inactive"
                      }`}
                      onClick={() => {
                        //   dispatch(setShouldRunSecondEffect(false))
                        setOfferType("k");
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "500",
                        }}
                      >
                        {options[language].rent}
                      </span>
                    </div>
                  </div>
                </div>

                {(offerType === 'g' || offerType === 'k') && <div
                  className="filterMobile-content-part-options-details"
                  style={{ marginTop: "20px" }}
                >
                  <div style={{ display: "flex", gap: "10px", height: "35px" }}>
                    <span
                      className={`${offerType === "k" && "active"}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => setOfferType("k")}
                    >
                      {options[language].rent_type_options[0].label}
                    </span>
                    <span
                      className={`${offerType === "g" && "active"}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => setOfferType("g")}
                    >
                      {options[language].rent_type_options[1].label}
                    </span>
                  </div>
                </div>}
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#444",
                    margin: "15px 0 5px 0",
                  }}
                >
                  {options[language].cityName}
                </label>
                <div
                  className="filterMobile-content-part-site-select-divs"
                  onClick={(e) =>
                    setOpenOrCloseSelectionOfCities(
                      !openOrCloseSelectionOfCities
                    )
                  }
                >
                  {city.length !== 0 ? (
                    <span
                      style={{
                        maxHeight: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {city
                        ?.map(({ order, ...rest }) => rest)
                        ?.filter((value, index, array) => {
                          const labels = array.map((el) => el.label);
                          return labels.indexOf(value.label) === index;
                        })
                        ?.map((el, index) =>
                          index === 0 ? el.label : ", " + el.label
                        )}
                    </span>
                  ) : (
                    <span style={{ color: "#888888" }}>
                      {options[language].cityName}
                    </span>
                  )}
                  <img src={showSites} alt="show sites icon" />
                </div>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#444",
                    margin: "15px 0 5px 0",
                  }}
                >
                  {options[language].regionName},{" "}
                  {options[language].stationName.toLowerCase()},{" "}
                  {options[language].targetName.toLowerCase()}
                </label>
                <div
                  className="filterMobile-content-part-site-select-divs"
                  onClick={(e) =>
                    setOpenOrCloseSelectionOfRegions(
                      !openOrCloseSelectionOfRegions
                    )
                  }
                >
                  {province.length !== 0 ? (
                    <span>
                      {province
                        ?.map(({ order, ...rest }) => rest)
                        ?.filter((value, index, array) => {
                          const labels = array.map((el) => el.label);
                          return labels.indexOf(value.label) === index;
                        })
                        ?.map((el, index) =>
                          index === 0 ? el.label : ", " + el.label
                        )}
                    </span>
                  ) : (
                    <span style={{ color: "#888888" }}>
                      {options[language].regionName},{" "}
                      {options[language].stationName.toLowerCase()},{" "}
                      {options[language].targetName.toLowerCase()}
                    </span>
                  )}
                  <img src={showSites} alt="show sites icon" />
                </div>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#444",
                    margin: "10px 0 -10px 0",
                  }}
                >
                  {options[language].flatTypeName}
                </label>
                <div className="mobile-offers-content-filter-part">
                  <div
                    className="mobile-offers-content-filter"
                    style={{
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      border:
                        flatType?.value === "1"
                          ? "1.5px solid #0089CF"
                          : "1px solid #DEDEDE",
                      outline:
                        flatType?.value === "1"
                          ? "0px solid transparent"
                          : "0.5px solid transparent",
                    }}
                    onClick={(e) =>
                      setFlatType(options[language].flat_type_options[0])
                    }
                  >
                    <FlatImage
                      color={flatType?.value === "1" ? "#0089CF" : "#666666"}
                    />
                    <span
                      style={{
                        color: flatType?.value === "1" ? "#0089CF" : "#666666",
                        fontSize: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {options[language].flat_type_options[0].label}
                    </span>
                  </div>
                  <div
                    className="mobile-offers-content-filter"
                    style={{
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      border:
                        flatType?.value === "2"
                          ? "1.5px solid #0089CF"
                          : "1px solid #DEDEDE",
                      outline:
                        flatType?.value === "2"
                          ? "0px solid transparent"
                          : "0.5px solid transparent",
                    }}
                    onClick={(e) =>
                      setFlatType(options[language].flat_type_options[1])
                    }
                  >
                    <CountryImage
                      color={flatType?.value === "2" ? "#0089CF" : "#666666"}
                    />
                    <span
                      style={{
                        color: flatType?.value === "2" ? "#0089CF" : "#666666",
                        fontSize: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {options[language].flat_type_options[1].label2}
                    </span>
                  </div>
                  <div
                    className="mobile-offers-content-filter"
                    style={{
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      border:
                        flatType?.value === "5"
                          ? "1.5px solid #0089CF"
                          : "1px solid #DEDEDE",
                      outline:
                        flatType?.value === "5"
                          ? "0px solid transparent"
                          : "0.5px solid transparent",
                    }}
                    onClick={(e) =>
                      setFlatType(options[language].flat_type_options[4])
                    }
                  >
                    <LandImage
                      color={flatType?.value === "5" ? "#0089CF" : "#666666"}
                    />
                    <span
                      style={{
                        color: flatType?.value === "5" ? "#0089CF" : "#666666",
                        fontSize: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {options[language].flat_type_options[4].label}
                    </span>
                  </div>
                  <div
                    className="mobile-offers-content-filter"
                    style={{
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      border:
                        flatType?.value === "6"
                          ? "1.5px solid #0089CF"
                          : "1px solid #DEDEDE",
                      outline:
                        flatType?.value === "6"
                          ? "0px solid transparent"
                          : "0.5px solid transparent",
                    }}
                    onClick={(e) =>
                      setFlatType(options[language].flat_type_options[5])
                    }
                  >
                    <ObyektImage
                      color={flatType?.value === "6" ? "#0089CF" : "#666666"}
                    />
                    <span
                      style={{
                        color: flatType?.value === "6" ? "#0089CF" : "#666666",
                        fontSize: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {options[language].flat_type_options[5].label}
                    </span>
                  </div>
                  <div
                    className="mobile-offers-content-filter"
                    style={{
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      border:
                        flatType?.value === "3"
                          ? "1.5px solid #0089CF"
                          : "1px solid #DEDEDE",
                      outline:
                        flatType?.value === "3"
                          ? "0px solid transparent"
                          : "0.5px solid transparent",
                    }}
                    onClick={(e) =>
                      setFlatType(options[language].flat_type_options[2])
                    }
                  >
                    <OfisImage
                      color={flatType?.value === "3" ? "#0089CF" : "#666666"}
                    />
                    <span
                      style={{
                        color: flatType?.value === "3" ? "#0089CF" : "#666666",
                        fontSize: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {options[language].flat_type_options[2].label}
                    </span>
                  </div>
                  <div
                    className="mobile-offers-content-filter"
                    style={{
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      border:
                        flatType?.value === "4"
                          ? "1.5px solid #0089CF"
                          : "1px solid #DEDEDE",
                      outline:
                        flatType?.value === "4"
                          ? "0px solid transparent"
                          : "0.5px solid transparent",
                    }}
                    onClick={(e) =>
                      setFlatType(options[language].flat_type_options[3])
                    }
                  >
                    <GarageImage
                      color={flatType?.value === "4" ? "#0089CF" : "#666666"}
                    />
                    <span
                      style={{
                        color: flatType?.value === "4" ? "#0089CF" : "#666666",
                        fontSize: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {options[language].flat_type_options[3].label}
                    </span>
                  </div>
                </div>
                {flatType.value === 1 || flatType.value === "1" ? (
                  <>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#444",
                        margin: "15px 0 5px 0",
                      }}
                    >
                      {options[language].roomsCountName}
                    </label>
                    <div className="filterMobile-content-part-options-rooms">
                      <div className="filterMobile-content-part-options-rooms-divs">
                        <div
                          className={`filterMobile-content-part-options-rooms-div ${
                            rooms?.some(
                              (room) => room.value === "1" && room.label === "1"
                            ) && "active"
                          }`}
                          onClick={() => addRooms({ value: "1", label: "1" })}
                        >
                          1
                        </div>
                        <div
                          className={`filterMobile-content-part-options-rooms-div ${
                            rooms?.some(
                              (room) => room.value === "2" && room.label === "2"
                            ) && "active"
                          }`}
                          onClick={() => addRooms({ value: "2", label: "2" })}
                        >
                          2
                        </div>
                        <div
                          className={`filterMobile-content-part-options-rooms-div ${
                            rooms?.some(
                              (room) => room.value === "3" && room.label === "3"
                            ) && "active"
                          }`}
                          onClick={() => addRooms({ value: "3", label: "3" })}
                        >
                          3
                        </div>
                        <div
                          className={`filterMobile-content-part-options-rooms-div ${
                            rooms?.some(
                              (room) => room.value === "4" && room.label === "4"
                            ) && "active"
                          }`}
                          onClick={() => addRooms({ value: "4", label: "4" })}
                        >
                          4
                        </div>
                        <div
                          className={`filterMobile-content-part-options-rooms-div ${
                            rooms?.some(
                              (room) =>
                                room.value === "50" && room.label === "50"
                            ) && "active"
                          }`}
                          onClick={() => addRooms({ value: "50", label: "50" })}
                        >
                          5+
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#444",
                    margin: "15px 0 5px 0",
                  }}
                >
                  {options[language].priceName.replace(":", "")} - AZN
                </label>
                <div className="filterMobile-content-part-options-price">
                  <div className="filterMobile-content-part-options-price-divs">
                    <input
                      value={minPrice}
                      placeholder={options[language].min}
                      min={0}
                      type="number"
                      onChange={(e) => setMinPrice(e.target.value)}
                    />
                    <input
                      value={maxPrice}
                      placeholder={options[language].max}
                      min={0}
                      type="number"
                      onChange={(e) => setMaxPrice(e.target.value)}
                    />
                  </div>
                </div>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#444",
                    margin: "15px 0 5px 0",
                  }}
                >
                  {flatType.value === "5"
                      ? options[language].pricePerSotName :
                      options[language].pricePerM2Name }
                </label>
                <div className="filterMobile-content-part-options-price">
                  <div className="filterMobile-content-part-options-price-divs">
                    <input
                      value={minPricePerM2}
                      placeholder={options[language].min}
                      min={0}
                      type="number"
                      onChange={(e) => setMinPricePerM2(e.target.value)}
                    />
                    <input
                      value={maxPricePerM2}
                      placeholder={options[language].max}
                      min={0}
                      type="number"
                      onChange={(e) => setMaxPricePerM2(e.target.value)}
                    />
                  </div>
                </div>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#444",
                    margin: "15px 0 5px 0",
                  }}
                >
                  {`${options[language].areaName}${
                    flatType.value === "5"
                      ? " - " + options[language].sot
                      : " - " + options[language].m2
                  }:`}
                </label>
                <div className="filterMobile-content-part-options-area">
                  <div className="filterMobile-content-part-options-area-divs">
                    <input
                      value={areaMin}
                      placeholder={options[language].min}
                      min={0}
                      type="number"
                      onChange={(e) => setAreaMin(e.target.value)}
                    />
                    <input
                      value={areaMax}
                      placeholder={options[language].max}
                      min={0}
                      type="number"
                      onChange={(e) => setAreaMax(e.target.value)}
                    />
                  </div>
                </div>
                {flatType.value !== 5 && flatType.value !== "5" ? (
                  <>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#444",
                        margin: "15px 0 5px 0",
                      }}
                    >
                      {options[language].floorName.replace(":", "")}
                    </label>
                    <div className="filterMobile-content-part-options-floor">
                      <div className="filterMobile-content-part-options-floor-divs">
                        <input
                          value={floorMin}
                          placeholder={options[language].min}
                          min={0}
                          type="number"
                          onChange={(e) => setFloorMin(e.target.value)}
                        />
                        <input
                          value={floorMax}
                          placeholder={options[language].max}
                          min={0}
                          type="number"
                          onChange={(e) => setFloorMax(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#444",
                    margin: "15px 0 5px 0",
                  }}
                >
                  {options[language].siteName}
                </label>
                <div
                  className="filterMobile-content-part-site-select-divs"
                  onClick={(e) =>
                    setOpenOrCloseSelectionOfSites(!openOrCloseSelectionOfSites)
                  }
                >
                  {sites.length !== 0 ? (
                    <span>
                      {sites?.map((el, index) =>
                        index === 0 ? el?.value : ", " + el?.value
                      )}
                    </span>
                  ) : (
                    <span style={{ color: "#888888" }}>
                      {options[language].siteNameEnter}
                    </span>
                  )}
                  <img src={showSites} alt="show sites icon" />
                </div>

                <div
                  className="filterMobile-content-part-options-details"
                  style={{ marginTop: "20px" }}
                >
                  {flatType.value !== 5 && flatType.value !== "5" ? (
                    <>
                      <div
                        style={{ display: "flex", gap: "3px", height: "30px" }}
                      >
                        <span
                          className={`${repairTrue && "active"}`}
                          onClick={() => setRepairTrue(!repairTrue)}
                        >
                          {options[language].repairName}
                        </span>
                        <span
                          className={`${repairFalse && "active"}`}
                          onClick={() => setRepairFalse(!repairFalse)}
                        >
                          {options[language].withoutRepairName}
                        </span>
                      </div>
                      <div
                        style={{ display: "flex", gap: "3px", height: "30px" }}
                      >
                        <span
                          className={`${withGoodsTrue && "active"}`}
                          onClick={() => SetWithGoodsTrue(!withGoodsTrue)}
                        >
                          {options[language].withGoodsName}
                        </span>
                        <span
                          className={`${withGoodsFalse && "active"}`}
                          onClick={() => SetWithGoodsFalse(!withGoodsFalse)}
                        >
                          {options[language].withoutGoodsName}
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div style={{ display: "flex", gap: "3px", height: "30px" }}>
                    <span
                      className={`${documentTrue && "active"}`}
                      onClick={() => setDocumentTrue(!documentTrue)}
                    >
                      {options[language].withDocumentsName}
                    </span>
                    <span
                      className={`${documentFalse && "active"}`}
                      onClick={() => setDocumentFalse(!documentFalse)}
                    >
                      {options[language].withoutDocumentsName}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      height: "30px",
                      width: "163px",
                    }}
                  >
                    <span
                      className={`${withCredit && "active"}`}
                      onClick={() => SetWithCredit(!withCredit)}
                    >
                      {options[language].mortgageName}
                    </span>
                  </div>
                </div>
                <hr
                  className="filterMobile-content-part-options-hr"
                  style={{ marginTop: "10px" }}
                />
                <div
                  className="mobile-history"
                  onClick={(e) => handleHistoryClick()}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <HistoryRoundedIcon />
                    <span style={{ fontSize: "16px" }}>
                      {options[language].historyName}
                    </span>
                  </div>
                  <KeyboardArrowDownRoundedIcon
                    sx={{ fill: "#0089CF" }}
                    style={rotationStyle2}
                  />
                </div>
                {showHistory && (
                  <>
                    <div className="filterMobile-history-row-content">
                      <span className="Mobilehistory-span-title">
                        {options[language].lastFiveSearchName}
                      </span>
                      {historySpan}
                    </div>
                    <hr
                      ref={historyRef}
                      className="filterMobile-content-part-options-hr"
                      style={{ marginTop: "10px" }}
                    />
                  </>
                )}
              </div>
              <div className="filterMobile-content-part-button">
                <button
                  onClick={() => {
                    searchButton();
                    dispatch(setShowMobileFilter(false));
                  }}
                >
                  {loadingOfCount ? (
                    <CircularProgress
                      size={20}
                      fontSize="small"
                      color="inherit"
                    />
                  ) : (
                    countOfOffers + " "
                  )}
                  {options[language].showOffersName}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {openOrCloseSelectionOfSites && (
        <div
          className="site-selection"
          onClick={(e) => {
            e.target.className === "site-selection" &&
              setOpenOrCloseSelectionOfSites(false);
          }}
        >
          <div className="site-selection-content">
            <div className="site-selection-content-header">
              <span>{options[language].siteNameEnter}</span>
              <img
                onClick={(e) => setOpenOrCloseSelectionOfSites(false)}
                src={closeSiteSelection}
                alt="close sites selection"
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "#ECECEC",
              }}
            ></div>
            <div
              className="site-selection-content-body"
              style={{ marginTop: "10px" }}
            >
              {site_opt
                ?.map(({ order, ...rest }) => rest)
                .map((el, index) => {
                  return (
                    <div
                      key={index}
                      className="site-selection-content-body-sites"
                      onClick={(e) => {
                        const isElInSites = sites?.some((site) => {
                          return Object.keys(el).every((key) => {
                            return (
                              site.hasOwnProperty(key) && site[key] === el[key]
                            );
                          });
                        });

                        if (isElInSites) {
                          setSites(sites.filter((site) => site !== el));
                        } else {
                          setSites([...(sites || []), el]);
                        }
                      }}
                    >
                      <div
                        className={
                          sites?.some((site) => {
                            return Object.keys(el).every((key) => {
                              return (
                                site.hasOwnProperty(key) &&
                                site[key] === el[key]
                              );
                            });
                          })
                            ? "site-checked"
                            : "site-not-checked"
                        }
                      >
                        <img src={checkedIcon} alt="checked" />
                      </div>
                      <span style={{ fontSize: "16px", color: "#444" }}>
                        {el.value}
                      </span>
                    </div>
                  );
                })}
            </div>
            <div className="site-selection-content-footer">
              <div
                className="site-selection-content-footer-button clean"
                onClick={(e) => setSites([])}
              >
                {options[language].clean}
              </div>
              <div
                className="site-selection-content-footer-button add"
                onClick={(e) => setOpenOrCloseSelectionOfSites(false)}
              >
                {options[language].add}
              </div>
            </div>
          </div>
        </div>
      )}
      {openOrCloseSelectionOfCities && (
        <div
          className="cities-selection"
          onClick={(e) => {
            e.target.className === "cities-selection" &&
              setOpenOrCloseSelectionOfCities(false) &&
              setCitySearch("");
          }}
        >
          <div className="cities-selection-content">
            <div className="cities-selection-content-header">
              <div
                onClick={(e) => {
                  setOpenOrCloseSelectionOfCities(false);
                  setCitySearch("");
                }}
                style={{ width: "50px" }}
              >
                <KeyboardArrowLeftRoundedIcon
                  sx={{
                    marginLeft: "-8px",
                    fontSize: "30px",
                    color: "#0089CF",
                  }}
                />
              </div>
              <span>{options[language].cityName}</span>
              <div style={{ width: "50px" }}></div>
            </div>
            <div
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "#ECECEC",
              }}
            ></div>
            <div
              className="search-city"
              style={{
                width: "95%",
                padding: "10px 2.5%",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "2.5%",
                marginTop: "2.5%",
              }}
            >
              <input
                style={{ width: "calc(100% - 28px)" }}
                placeholder={options[language].cityName}
                value={citySearch}
                onChange={(e) => setCitySearch(e.target.value)}
              />
              <img src={searchIcon} alt="search button" />
            </div>
            <div
              className="cities-selection-content-body"
              style={{ marginTop: "10px" }}
            >
              {(citySearch.replaceAll(" ", "") === ""
                ? sortedCities?.map(({ order, ...rest }) => rest)
                : sortedCities
                    ?.map(({ order, ...rest }) => rest)
                    ?.filter((city) =>
                      city?.label
                        ?.toLowerCase()
                        ?.includes(citySearch?.toLowerCase())
                    )
              ).map((el, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="cities-selection-content-body-sites"
                      onClick={(e) => {
                        setCitySearch("");
                        const elementExists = city?.some((cityItem) => {
                          return Object.keys(el).every((key) => {
                            return (
                              cityItem.hasOwnProperty(key) &&
                              cityItem[key] === el[key]
                            );
                          });
                        });

                        if (elementExists) {
                          setCity(
                            city?.filter((cityItem) => {
                              return !Object.keys(el).every((key) => {
                                return (
                                  cityItem.hasOwnProperty(key) &&
                                  cityItem[key] === el[key]
                                );
                              });
                            })
                          );
                        } else {
                          setCity([...(city || []), el]);
                        }
                      }}
                    >
                      <span style={{ fontSize: "16px", color: "#444" }}>
                        {el.label}
                      </span>
                      <div
                        className={
                          city?.some((cityItem) => {
                            return Object.keys(el).every((key) => {
                              return (
                                cityItem.hasOwnProperty(key) &&
                                cityItem[key] === el[key]
                              );
                            });
                          })
                            ? "cities-checked"
                            : "cities-not-checked"
                        }
                      >
                        <img src={checkedIcon} alt="checked" />
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#ECECEC",
                        marginTop: "8px",
                      }}
                    ></div>
                  </>
                );
              })}
            </div>
            <div className="cities-selection-content-footer">
              <div
                className="cities-selection-content-footer-button clean"
                onClick={(e) => {
                  setCity([]);
                  setCitySearch("");
                }}
              >
                {options[language].clean}
              </div>
              <div
                className="cities-selection-content-footer-button add"
                onClick={(e) => {
                  setOpenOrCloseSelectionOfCities(false);
                  setCitySearch("");
                }}
              >
                {options[language].add}
              </div>
            </div>
          </div>
        </div>
      )}
      {openOrCloseSelectionOfRegions && (
        <div
          className="cities-selection"
          onClick={(e) => {
            e.target.className === "cities-selection" &&
              setOpenOrCloseSelectionOfRegions(false);
          }}
        >
          <div className="cities-selection-content">
            <div className="cities-selection-content-header">
              <div
                onClick={(e) => setOpenOrCloseSelectionOfRegions(false)}
                style={{ width: "50px" }}
              >
                <KeyboardArrowLeftRoundedIcon
                  sx={{
                    marginLeft: "-8px",
                    fontSize: "30px",
                    color: "#0089CF",
                  }}
                />
              </div>
              <span>{options[language].regionName}</span>
              <div style={{ width: "50px" }}></div>
            </div>
            <div
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "#ECECEC",
              }}
            ></div>
            <div
              className="search-city"
              style={{
                width: "95%",
                padding: "10px 2.5%",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "2.5%",
                marginTop: "2.5%",
              }}
            >
              <input
                style={{ width: "calc(100% - 28px)" }}
                placeholder={
                  options[language].regionName +
                  ", " +
                  options[language].stationName.toLowerCase() +
                  ", " +
                  options[language].targetName.toLowerCase()
                }
                value={citySearch}
                onChange={(e) => setCitySearch(e.target.value)}
              />
              <img src={searchIcon} alt="search button" />
            </div>
            <div className="select-reg-station-target">
              <div
                onClick={(e) => setSelectedSearchInRegion("region")}
                style={{
                  borderRadius: "7px 0px 0px 7px",
                  borderRightWidth:
                    selectedSearchInRegion === "station" ? "1px" : "0px",
                }}
                className={`select-reg-station-target-button ${
                  selectedSearchInRegion === "region" ? "active" : ""
                }`}
              >
                {options[language].regionName}
              </div>
              <div
                onClick={(e) => setSelectedSearchInRegion("station")}
                style={{ borderRightWidth: "1px", borderLeftWidth: "1px" }}
                className={`select-reg-station-target-button ${
                  selectedSearchInRegion === "station" ? "active" : ""
                }`}
              >
                {options[language].stationName}
              </div>
              <div
                onClick={(e) => setSelectedSearchInRegion("target")}
                style={{
                  borderRadius: "0px 7px 7px 0px",
                  borderLeftWidth:
                    selectedSearchInRegion === "station" ? "1px" : "0px",
                }}
                className={`select-reg-station-target-button ${
                  selectedSearchInRegion === "target" ? "active" : ""
                }`}
              >
                {options[language].targetName}
              </div>
            </div>
            <div
              className="cities-selection-content-body"
              style={{ marginTop: "10px", maxHeight: "calc(100vh - 310px)" }}
            >
              {(selectedSearchInRegion === "region"
                ? citySearch.replaceAll(" ", "") === ""
                  ? province_opt?.map(({ order, ...rest }) => rest)
                  : province_opt
                      ?.map(({ order, ...rest }) => rest)
                      .filter((province) =>
                        province?.label
                          ?.toLowerCase()
                          ?.includes(citySearch?.toLowerCase())
                      )
                : selectedSearchInRegion === "station"
                ? citySearch.replaceAll(" ", "") === ""
                  ? station_opt?.map(({ order, ...rest }) => rest)
                  : station_opt
                      ?.map(({ order, ...rest }) => rest)
                      .filter((station) =>
                        station?.label
                          ?.toLowerCase()
                          ?.includes(citySearch?.toLowerCase())
                      )
                : selectedSearchInRegion === "target"
                ? citySearch.replaceAll(" ", "") === ""
                  ? target_opt?.map(({ order, ...rest }) => rest)
                  : target_opt
                      ?.map(({ order, ...rest }) => rest)
                      .filter((target) =>
                        target?.label
                          ?.toLowerCase()
                          ?.includes(citySearch?.toLowerCase())
                      )
                : province_opt?.map(({ order, ...rest }) => rest)
              ).map((el, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="cities-selection-content-body-sites"
                      style={{
                        flexDirection:
                          selectedSearchInRegion === "region"
                            ? "column"
                            : "row",
                      }}
                    >
                      {selectedSearchInRegion === "region" ? (
                        <>
                          <div
                            onClick={(e) => {
                              const isElInProvince = province?.some((prov) => {
                                return Object.keys(el).every((key) => {
                                  return (
                                    prov.hasOwnProperty(key) &&
                                    prov[key] === el[key]
                                  );
                                });
                              });

                              if (isElInProvince) {
                                setRegionsF(
                                  province.filter((prov) => {
                                    return !Object.keys(el).every((key) => {
                                      return (
                                        prov.hasOwnProperty(key) &&
                                        prov[key] === el[key]
                                      );
                                    });
                                  })
                                );
                              } else {
                                setRegionsF([...(province || []), el]);
                              }
                            }}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "16px",
                                color: "#444",
                                fontWeight: "500",
                              }}
                            >
                              {el.label}
                            </span>
                            <div
                              className={
                                province?.some((prov) => {
                                  return Object.keys(el).every((key) => {
                                    return (
                                      prov.hasOwnProperty(key) &&
                                      prov[key] === el[key]
                                    );
                                  });
                                })
                                  ? "cities-checked"
                                  : "cities-not-checked"
                              }
                            >
                              <img src={checkedIcon} alt="checked" />
                            </div>
                          </div>
                          {resident_opt
                            ?.map(({ order, ...rest }) => rest)
                            .map((res, index) => {
                              if (res.region === el.label)
                                return (
                                  <>
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "1px",
                                        backgroundColor: "#ECECEC",
                                        marginTop: "8px",
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingLeft: "30px",
                                        marginTop: "8px",
                                      }}
                                      onClick={(e) => {
                                        const isResInResidents =
                                          residents?.some((resident) => {
                                            return Object.keys(res).every(
                                              (key) => {
                                                return (
                                                  resident.hasOwnProperty(
                                                    key
                                                  ) &&
                                                  resident[key] === res[key]
                                                );
                                              }
                                            );
                                          });

                                        if (isResInResidents) {
                                          setResidentsF(
                                            residents.filter((resident) => {
                                              return !Object.keys(res).every(
                                                (key) => {
                                                  return (
                                                    resident.hasOwnProperty(
                                                      key
                                                    ) &&
                                                    resident[key] === res[key]
                                                  );
                                                }
                                              );
                                            })
                                          );
                                        } else {
                                          setResidentsF([
                                            ...(residents || []),
                                            res,
                                          ]);
                                        }
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          color: "#444",
                                        }}
                                      >
                                        {res.label}
                                      </span>
                                      <div
                                        className={
                                          residents?.some((resident) => {
                                            return Object.keys(res).every(
                                              (key) => {
                                                return (
                                                  resident.hasOwnProperty(
                                                    key
                                                  ) &&
                                                  resident[key] === res[key]
                                                );
                                              }
                                            );
                                          })
                                            ? "cities-checked"
                                            : "cities-not-checked"
                                        }
                                      >
                                        <img src={checkedIcon} alt="checked" />
                                      </div>
                                    </div>
                                  </>
                                );
                            })}
                        </>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          onClick={(e) => {
                            if (selectedSearchInRegion === "station") {
                              const isElInStations = stations?.some(
                                (station) => {
                                  return Object.keys(el).every((key) => {
                                    return (
                                      station.hasOwnProperty(key) &&
                                      station[key] === el[key]
                                    );
                                  });
                                }
                              );

                              if (isElInStations) {
                                setStationsF(
                                  stations.filter((station) => {
                                    return !Object.keys(el).every((key) => {
                                      return (
                                        station.hasOwnProperty(key) &&
                                        station[key] === el[key]
                                      );
                                    });
                                  })
                                );
                              } else {
                                setStationsF([...(stations || []), el]);
                              }
                            }

                            if (selectedSearchInRegion === "target") {
                              const isElInTargets = targets?.some((target) => {
                                return Object.keys(el).every((key) => {
                                  return (
                                    target.hasOwnProperty(key) &&
                                    target[key] === el[key]
                                  );
                                });
                              });

                              if (isElInTargets) {
                                setTargetsF(
                                  targets.filter((target) => {
                                    return !Object.keys(el).every((key) => {
                                      return (
                                        target.hasOwnProperty(key) &&
                                        target[key] === el[key]
                                      );
                                    });
                                  })
                                );
                              } else {
                                setTargetsF([...(targets || []), el]);
                              }
                            }
                          }}
                        >
                          <span style={{ fontSize: "16px", color: "#444" }}>
                            {el.label}
                          </span>
                          <div
                            className={
                              selectedSearchInRegion === "station"
                                ? stations?.some((station) => {
                                    return Object.keys(el).every((key) => {
                                      return (
                                        station.hasOwnProperty(key) &&
                                        station[key] === el[key]
                                      );
                                    });
                                  })
                                  ? "cities-checked"
                                  : "cities-not-checked"
                                : selectedSearchInRegion === "target"
                                ? targets?.some((target) => {
                                    return Object.keys(el).every((key) => {
                                      return (
                                        target.hasOwnProperty(key) &&
                                        target[key] === el[key]
                                      );
                                    });
                                  })
                                  ? "cities-checked"
                                  : "cities-not-checked"
                                : "cities-not-checked"
                            }
                          >
                            <img src={checkedIcon} alt="checked" />
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#ECECEC",
                        marginTop: "8px",
                      }}
                    ></div>
                  </>
                );
              })}
            </div>
            <div className="cities-selection-content-footer">
              <div
                className="cities-selection-content-footer-button clean"
                onClick={(e) => {
                  setCitySearch("");
                  if (selectedSearchInRegion === "region") {
                    return setRegionsF([]);
                  }
                  if (selectedSearchInRegion === "station") {
                    return setStationsF([]);
                  }
                  if (selectedSearchInRegion === "target") {
                    return setTargetsF([]);
                  }
                }}
              >
                {options[language].clean}
              </div>
              <div
                className="cities-selection-content-footer-button add"
                onClick={(e) => setOpenOrCloseSelectionOfRegions(false)}
              >
                {options[language].add}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileFilter;
