import React from 'react';
import "./Header.css";
import DesktopHeader from '../DesktopHeader/DesktopHeader';
import { useSelector } from 'react-redux';
import MobileHeader from '../MobileHeader/MobileHeader';

const Header = () => {
    const isMobile = useSelector(state => state.isMobile);
    return (
        <>
            {isMobile ? <MobileHeader /> : <DesktopHeader />}
        </>
    );
};

export default Header;
