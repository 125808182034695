import React from "react";
import "@reach/combobox/styles.css";
import { useState } from "react";
import mark2 from "../../images/mark2.svg";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default function GoogleMapsOnePoint(latLng) {
  const [center, setCenter] = useState({
    lat: parseFloat(latLng.latLng[0]),
    lng: parseFloat(latLng.latLng[1]),
  });

  const customIcon = L.icon({
    iconUrl: mark2,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });
  return (
    <MapContainer
      center={[center.lat, center.lng]}
      zoom={15}
      scrollWheelZoom={true}
      className="map-container2"
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[center?.lat, center?.lng]} icon={customIcon}></Marker>
    </MapContainer>
  );
}
