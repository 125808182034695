import React from 'react';
import './Loading2.css'; 
import { options } from "../../constants"
import { useSelector } from 'react-redux';

const Loading2 = () => {
  const language = useSelector(state => state.language)
  return (
    <div className="loading-container2">
      {/* <div className="loading-spinner" style={{ background: '#0089CF' }}></div>
      <div className="loading-spinner" style={{ background: '#DCDCDC' }}></div> */}
      <span>{options[language].searchOffersLoading}</span>
      <div className="progress"></div>
    </div>
  );
};

export default Loading2;
