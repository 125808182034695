import React, { useState } from 'react'
import './SearchPopUp.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  openSearchByUrlPopUp,
  searchByUrl,
  setSearchByUrlData
} from '../../redux/action'
import { options } from '../../constants'
import X from '../images/x.svg'
import OfferCard from './OfferCardForSearchPopUp'
import CircularProgress from '@mui/material/CircularProgress'

const SearchPopUp = () => {
  const dispatch = useDispatch()
  const searchByUrlPopUp = useSelector(state => state.searchByUrlPopUp)
  const searchByUrlData = useSelector(state => state.searchByUrlData)
  const loadingSearchByUrlData = useSelector(
    state => state.loadingSearchByUrlData
  )
  const language = useSelector(state => state.language)
  const [linkW, setLinkW] = useState(true)
  const [linkUsedSites, setLinkUsedSites] = useState(true)
  const [url, setUrl] = useState('')
  const searchLink = () => {
    if (url !== '') {
      setLinkW(true)
    } else {
      dispatch(setSearchByUrlData([]))
      return setLinkW(false)
    }
    if (
      url.includes('tap.az') ||
      url.includes('bina.az') ||
      url.includes('arenda.az') ||
      url.includes('emlak.az') ||
      url.includes('lalafo.az') ||
      url.includes('yeniemlak.az') ||
      url.includes('rahatemlak.az') ||
      url.includes('ucuztap.az')
    ) {
      setLinkUsedSites(true)
    } else {
      dispatch(setSearchByUrlData([]))
      return setLinkUsedSites(false)
    }
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return dispatch(searchByUrl('https://' + url.replace('www.', '')))
    }
    dispatch(
      searchByUrl(url.replace('www.', '').replace('http://', 'https://'))
    )
  }
  return (
    <div
      className='searchByUrlPopUp'
      onClick={e =>
        e.target.className === 'searchByUrlPopUp' &&
        dispatch(openSearchByUrlPopUp(!searchByUrlPopUp)) &&
        dispatch(setSearchByUrlData([]))
      }
    >
      <div className='searchByUrlPopUp-content'>
        <div className='searchByUrlPopUp-content-title'>
          <span>{options[language].findOffer}</span>
          <img
            src={X}
            alt='x'
            onClick={() => {
              dispatch(openSearchByUrlPopUp(!searchByUrlPopUp))
              dispatch(setSearchByUrlData([]))
            }}
          />
        </div>
        <div className='searchByUrlPopUp-content-description'>
          <span className='searchByUrlPopUp-content-description-txt'></span>
          <span
            className='searchByUrlPopUp-content-description-clean'
            onClick={() => {
              setUrl('')
              dispatch(setSearchByUrlData([]))
            }}
          >
            {options[language].clean}
          </span>
        </div>
        <div className='searchByUrlPopUp-content-input-part'>
          <input
            style={{ border: linkW ? '1px solid grey' : '1px solid red' }}
            value={url}
            onChange={e => setUrl(e.target.value)}
            placeholder={options[language].writeLinkForSearch}
          />
          <span
            style={{ color: linkUsedSites ? 'black' : 'red' }}
            className='searchByUrlPopUp-content-input-span'
          >
            {options[language].supportedSitesSearch}
          </span>
        </div>
        <div className='searchByUrlPopUp-content-button'>
          <div className='popup-button' onClick={() => searchLink()}>
            {loadingSearchByUrlData ? (
              <CircularProgress size={20} fontSize='small' color='inherit' />
            ) : (
              options[language].showOfferSearch
            )}
          </div>
        </div>
        <div className='searchByUrlPopUp-content-offer'>
          {searchByUrlData.length ? (
            searchByUrlData[0].flat_type !== 'null' ? (
              <OfferCard offer={searchByUrlData[0]} />
            ) : (
              <span style={{ color: '#ff2b00' }}>
                {options[language].notFoundOffers}
              </span>
            )
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default SearchPopUp
