import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import "./NewOffer.css";
import { blue500 } from "material-ui/styles/colors";
import OfferCard from "./OfferCard";
import { options } from "../../constants";
import GoogleMaps from "../../Components/GoogleMaps/GoogleMaps";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { getByIdAction, updateOfferAction } from "../../redux/action";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import MiniBanner from "../../Components/MiniBanner/MiniBanner";
import InputMask from "react-input-mask";

const UpdateOffer = () => {
  const navigate = useNavigate();
  useEffect(() => {
    !localStorage.getItem("token") && navigate("/");
  }, []);
  const uploadImage = useRef(null);
  const uploadVideo = useRef(null);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const offerData = useSelector((state) => state.offerDetail);
  const offerTypeReduce = useSelector((state) => state.offerTypeForUpdate);
  const [flatType, setFlatType] = useState([]);
  const [offerType, setOfferType] = useState([]);
  const [rentPeriod, setRentPeriod] = useState([]);
  const [mortgage, setMortgage] = useState([]);
  const [typeOfFlat, setTypeOfFlat] = useState([]);
  const [area, setArea] = useState("");
  const [landArea, setLandArea] = useState("");
  // const [location, setLocation] = useState([])
  const [rooms, setRooms] = useState("");
  const loadingUploadOffer = useSelector((state) => state.loadingUploadOffer);
  const [city, setCity] = useState();
  const [region, setRegion] = useState();
  const [resident, setResident] = useState();
  const [target, setTarget] = useState();
  const [station, setStation] = useState();
  const [floor, setFloor] = useState("");
  const [max_floor, setMaxFloor] = useState("");
  const [documents, setDocuments] = useState(null);
  const [withGoods, setWithGoods] = useState(null);
  const [repair, setRepair] = useState(null);
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const latLng = useSelector((state) => state.latLng);
  const [notification, setNotification] = useState("");
  const [showNot, setShowNot] = useState(false);
  const cardData = {
    flatType,
    offerType,
    rentPeriod,
    typeOfFlat,
    area,
    landArea,
    latLng,
    rooms,
    city,
    region,
    resident,
    target,
    station,
    floor,
    max_floor,
    mortgage,
    documents,
    withGoods,
    repair,
    price,
    description,
    name,
    email,
    mobileNum,
    selectedImage,
    selectedVideo,
  };
  const handleDeleteImage = (indexToDelete) => {
    setSelectedImage((prevImages) =>
      prevImages.filter((image, index) => index !== indexToDelete)
    );
  };
  useEffect(() => {
    document.title = options[language].newOfferTitle;
    dispatch(
      getByIdAction(
        window.location.pathname.split("/")[2],
        window.location.pathname.split("/")[3]
      )
    );
  }, []);
  async function fetchFilesFromLinks(links) {
    const filePromises = links.map(async (link) => {
      const response = await fetch(link);
      const blob = await response.blob();
      const type = link.includes(".mp4")
        ? "video/mp4"
        : response.headers.get("content-type");
      const filename = link.split("/").pop();
      return new File([blob], filename, { type });
    });
    return Promise.all(filePromises);
  }

  async function loadFiles() {
    const files = await fetchFilesFromLinks(
      offerData?.images
        ? offerData.images?.map((img) => img.path.toString())
        : []
    );
    setSelectedImage(files);
    const videos = await fetchFilesFromLinks(
      offerData?.video ? [offerData.video?.toString()] : []
    );
    handleFileDownload(videos);
  }
  useEffect(() => {
    setFlatType(
      offerTypeReduce.replace("sale", "").replace("rent", "") === "flat"
        ? options[language].flat_type_options[0]
        : offerTypeReduce.replace("sale", "").replace("rent", "") === "country"
        ? options[language].flat_type_options[1]
        : offerTypeReduce.replace("sale", "").replace("rent", "") === "office"
        ? options[language].flat_type_options[2]
        : offerTypeReduce.replace("sale", "").replace("rent", "") === "garage"
        ? options[language].flat_type_options[3]
        : offerTypeReduce.replace("sale", "").replace("rent", "") === "land"
        ? options[language].flat_type_options[4]
        : offerTypeReduce.replace("sale", "").replace("rent", "") ==
          "commercial"
        ? options[language].flat_type_options[5]
        : "flat"
    );
    setOfferType(
      offerTypeReduce?.includes("sale")
        ? options[language].offer_type_options[0]
        : options[language].offer_type_options[1]
    );
    setRentPeriod(
      offerData.rentPeriod === true
        ? options[language].rent_type_options[0]
        : options[language].rent_type_options[1]
    );
    setMortgage(
      offerData.mortgage === true
        ? options[language].mortgage_options[0]
        : options[language].mortgage_options[1]
    );
    setTypeOfFlat(
      offerData.typeOfFlat === true
        ? options[language].type_of_flat[0]
        : options[language].type_of_flat[1]
    );
    setArea(offerData.area?.toString());
    setLandArea(offerData.area?.toString());
    setRooms(offerData.room?.toString());
    setCity(
      options[language].cities[
        offerData?.city !== null ? parseInt(offerData.city) : 0
      ]
    );
    setRegion(
      options[language].region_options[
        offerData?.region !== null ? parseInt(offerData.region) : []
      ]
    );
    setResident(
      options[language].resident_options[
        offerData?.residential_area !== null
          ? parseInt(offerData.residential_area)
          : []
      ]
    );
    offerData?.target !== null
      ? setTarget(
          offerData.target?.map((mtr) => options[language].target_options[mtr])
        )
      : setTarget([]);
    offerData?.metro !== null
      ? setStation(
          offerData.metro?.map((mtr) => options[language].station_options[mtr])
        )
      : setStation([]);
    setFloor(offerData?.floor !== null ? offerData.floor?.toString() : "");
    setMaxFloor(
      offerData?.max_floor !== null ? offerData.max_floor?.toString() : ""
    );
    setDocuments(offerData?.document !== null ? offerData?.document : null);
    setWithGoods(offerData?.with_goods !== null ? offerData?.with_goods : null);
    setRepair(offerData?.repair !== null ? offerData?.repair : null);
    setPrice(offerData?.price !== null ? offerData.price : "");
    setDescription(offerData?.details !== null ? offerData.details : "");
    setName(offerData?.user !== null ? offerData.user : "");
    setEmail(offerData?.email !== null ? offerData.email : "");
    setMobileNum(offerData?.phone !== null ? offerData.phone : "");
    // setSelectedImage(offerData?.images ? [...offerData.images.map((img) => img.path.toString())] : []);
    loadFiles();
    setShowMap(offerData?.location !== null ? true : false);
  }, [offerData]);
  const updateOffer = () => {
    let checker = 0;
    let str = "";
    setNotification("");
    setShowNot(false);
    if (!flatType?.value) {
      checker++;
      str += options[language].newOffer_flatType;
    }
    if (!offerType?.value) {
      checker++;
      str += options[language].newOffer_offerType;
    }
    if (offerType?.value === "false" && !rentPeriod?.value) {
      checker++;
      str += options[language].newOffer_rentPeriod;
    }
    if (flatType?.value !== "5" && area === "") {
      checker++;
      str += options[language].newOffer_AreaM2;
    }
    if (flatType?.value === "5" && landArea === "") {
      checker++;
      str += options[language].newOffer_AreaSot;
    }
    if (!city?.value) {
      checker++;
      str += options[language].newOffer_City;
    }
    if (price === "") {
      checker++;
      str += options[language].newOffer_Price;
    }
    if (description === "") {
      checker++;
      str += options[language].newOffer_Desc;
    }
    if (!selectedImage.length) {
      checker++;
      str += options[language].newOffer_Images;
    }
    if (name === "") {
      checker++;
      str += options[language].newOffer_Name;
    }
    if (email === "") {
      checker++;
      str += options[language].newOffer_Email;
    }
    if (mobileNum === "" || mobileNum.replaceAll("_", "").length !== 17) {
      checker++;
      str += options[language].newOffer_PhoneNum;
    }
    let title =
      (rooms.length ? rooms + " otaqlı " : "") +
      (flatType ? (flatType.label ? flatType.label.toLowerCase() : "") : "") +
      (offerType
        ? offerType.value
          ? offerType.value === "true"
            ? ", satılır, "
            : ", kirayə verilir, "
          : ""
        : "") +
      (area.length ? area + "m2 " : "") +
      (landArea.length ? landArea + "sot " : "") +
      (!landArea.length &&
      !area.length &&
      !offerType.value &&
      !flatType.label &&
      !rooms.length
        ? "Elanın başlığı "
        : "");
    if (checker > 0) {
      setShowNot(true);
      setNotification(str);
    } else {
      dispatch(
        updateOfferAction(
          cardData,
          title,
          window.location.pathname.split("/")[3]
        )
      );
    }
  };
  const handleFileChange = async (event) => {
    await handleDeleteVideo();
    const files = Array.from(event.target.files);
    const videoFiles = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setSelectedVideo(videoFiles);
  };
  const handleFileDownload = (event) => {
    const files = Array.from(event);
    const videoFiles = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setSelectedVideo(videoFiles);
  };
  const handleDeleteVideo = async (index) => {
    selectedVideo.forEach((video) => {
      URL.revokeObjectURL(video.preview);
    });
    setSelectedVideo([]);
  };
  return (
    <div className="new-offer">
      <MiniBanner />
      <Header />
      <div className="new-offer-content-title">
        {options[language].updateOfferName}
      </div>
      <div className="new-offer-content">
        <div className="new-offer-content-left">
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
              {options[language].newOffer_flatType.replace(", ", "")}{" "}
              <span className="red-point">*</span>
            </span>
            <div className="new-offer-content-left-row-input">
              <Select
                closeMenuOnSelect={true}
                isMulti={false}
                options={options[language].flat_type_options}
                onChange={(e) => setFlatType(e)}
                value={flatType}
                placeholder={options[language].newOffer_flatType.replace(
                  ", ",
                  ""
                )}
              />
            </div>
          </div>
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
              {options[language].newOffer_offerType.replace(", ", "")}{" "}
              <span className="red-point">*</span>
            </span>
            <div className="new-offer-content-left-row-input">
              <Select
                closeMenuOnSelect={true}
                isMulti={false}
                options={options[language].offer_type_options}
                onChange={(e) => setOfferType(e)}
                value={offerType}
                placeholder={options[language].newOffer_offerType.replace(
                  ", ",
                  ""
                )}
              />
            </div>
          </div>
          {offerType?.value === "false" ? (
            <div className="new-offer-content-left-row">
              <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
                {options[language].newOffer_rentPeriod.replace(", ", "")}{" "}
                <span className="red-point">*</span>
              </span>
              <div className="new-offer-content-left-row-input">
                <Select
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={options[language].rent_type_options}
                  onChange={(e) => setRentPeriod(e)}
                  value={rentPeriod}
                  placeholder={options[language].newOffer_rentPeriod.replace(
                    ", ",
                    ""
                  )}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {offerType?.value === "true" ? (
            <div className="new-offer-content-left-row">
              <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
                {options[language].mortgageName}{" "}
                <span className="red-point">*</span>
              </span>
              <div className="new-offer-content-left-row-input">
                <Select
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={options[language].mortgage_options}
                  onChange={(e) => setMortgage(e)}
                  value={mortgage}
                  placeholder={options[language].mortgageName}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {flatType?.value !== "5" ? (
            <div className="new-offer-content-left-row">
              <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
                {options[language].newOffer_AreaM2.replace(", ", "")}{" "}
                <span className="red-point">*</span>
              </span>
              <div className="new-offer-content-left-row-input">
                <input
                  value={area}
                  className="inputTag"
                  placeholder={options[language].newOffer_AreaM2.replace(
                    ", ",
                    ""
                  )}
                  min={0}
                  type="number"
                  onChange={(e) => setArea(e.target.value)}
                />
              </div>
            </div>
          ) : (
            <div className="new-offer-content-left-row">
              <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
                {options[language].newOffer_AreaSot.replace(", ", "")}{" "}
                <span className="red-point">*</span>
              </span>
              <div className="new-offer-content-left-row-input">
                <input
                  value={landArea}
                  className="inputTag"
                  placeholder={options[language].newOffer_AreaSot.replace(
                    ", ",
                    ""
                  )}
                  min={0}
                  type="number"
                  onChange={(e) => setLandArea(e.target.value)}
                />
              </div>
            </div>
          )}
          {flatType?.value !== "5" && (
            <div className="new-offer-content-left-row">
              <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
                {options[language].roomsCountName}{" "}
              </span>
              <div className="new-offer-content-left-row-input">
                <input
                  value={rooms}
                  className="inputTag"
                  placeholder={options[language].roomsCountName}
                  min={0}
                  type="number"
                  onChange={(e) => setRooms(e.target.value)}
                />
              </div>
            </div>
          )}
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
              {options[language].mapSmall}{" "}
            </span>
            <div className="new-offer-content-left-row-input">
              <button
                className="new-offer-content-left-row-button"
                onClick={() => setShowMap(!showMap)}
              >
                {!showMap
                  ? options[language].mapSmallShow
                  : options[language].mapSmallDontShow}
              </button>
            </div>
          </div>
          {showMap && <GoogleMaps latLng={offerData?.location} />}
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
              {options[language].cityName} <span className="red-point">*</span>
            </span>
            <div className="new-offer-content-left-row-input">
              <Select
                closeMenuOnSelect={true}
                isMulti={false}
                options={options[language].cities}
                onChange={(e) => setCity(e)}
                value={city}
                placeholder={options[language].cityName}
              />
            </div>
          </div>
          {city?.value === "8" ? (
            <>
              <div className="new-offer-content-left-row">
                <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
                  {options[language].regionName}{" "}
                </span>
                <div className="new-offer-content-left-row-input">
                  <Select
                    closeMenuOnSelect={true}
                    isMulti={false}
                    options={options[language].region_options}
                    onChange={(e) => setRegion(e)}
                    value={region}
                    placeholder={options[language].regionName}
                  />
                </div>
              </div>
              <div className="new-offer-content-left-row">
                <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
                  {options[language].residentName}{" "}
                </span>
                <div className="new-offer-content-left-row-input">
                  <Select
                    closeMenuOnSelect={true}
                    isMulti={false}
                    options={
                      region
                        ? options[language].resident_options.filter(
                            (option) => option.region === region.label
                          )
                        : options[language].resident_options
                    }
                    onChange={(e) => setResident(e)}
                    value={resident}
                    placeholder={options[language].residentName}
                  />
                </div>
              </div>
              <div className="new-offer-content-left-row">
                <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
                  {options[language].targetName}{" "}
                </span>
                <div className="new-offer-content-left-row-input">
                  <Select
                    closeMenuOnSelect={false}
                    isMulti={true}
                    options={
                      region
                        ? options[language].target_options.filter(
                            (option) => option.region === region.label
                          )
                        : options[language].target_options
                    }
                    onChange={(e) => setTarget(e)}
                    value={target}
                    placeholder={options[language].targetName}
                  />
                </div>
              </div>
              <div className="new-offer-content-left-row">
                <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
                  {options[language].stationName}{" "}
                </span>
                <div className="new-offer-content-left-row-input">
                  <Select
                    closeMenuOnSelect={false}
                    isMulti={true}
                    options={options[language].station_options}
                    onChange={(e) => setStation(e)}
                    value={station}
                    placeholder={options[language].stationName}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {flatType?.value !== "5" && flatType?.value !== "4" ? (
            <>
              <div className="new-offer-content-left-row">
                <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
                  {options[language].floorName.replace(":", "")}{" "}
                </span>
                <div className="new-offer-content-left-row-input">
                  <input
                    value={floor}
                    className="inputTag"
                    placeholder={options[language].floorName.replace(":", "")}
                    min={0}
                    type="number"
                    onChange={(e) => setFloor(e.target.value)}
                  />
                </div>
              </div>
              <div className="new-offer-content-left-row">
                <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
                  {options[language].allFloorNumberName}{" "}
                </span>
                <div className="new-offer-content-left-row-input">
                  <input
                    value={max_floor}
                    className="inputTag"
                    placeholder={options[language].allFloorNumberName}
                    min={0}
                    type="number"
                    onChange={(e) => setMaxFloor(e.target.value)}
                  />
                </div>
              </div>{" "}
            </>
          ) : (
            <></>
          )}
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span">
              {options[language].withDocumentsName}{" "}
            </span>
            <div className="new-offer-content-left-row-input new-offer-content-left-row-check">
              <Checkbox
                checked={documents}
                onChange={(e) => setDocuments(true)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 28 },
                  color: blue500,
                  "&.Mui-checked": {
                    color: blue500,
                  },
                }}
              />
            </div>
          </div>
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span">
              {options[language].withoutDocumentsName}{" "}
            </span>
            <div className="new-offer-content-left-row-input new-offer-content-left-row-check">
              <Checkbox
                checked={documents !== null ? !documents : false}
                onChange={(e) => setDocuments(false)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 28 },
                  color: blue500,
                  "&.Mui-checked": {
                    color: blue500,
                  },
                }}
              />
            </div>
          </div>
          {flatType?.value !== "5" && (
            <>
              <div className="new-offer-content-left-row">
                <span className="new-offer-content-left-row-span">
                  {options[language].withGoodsName}{" "}
                </span>
                <div className="new-offer-content-left-row-input new-offer-content-left-row-check">
                  <Checkbox
                    checked={withGoods}
                    onChange={(e) => setWithGoods(true)}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                      color: blue500,
                      "&.Mui-checked": {
                        color: blue500,
                      },
                    }}
                  />
                </div>
              </div>
              <div className="new-offer-content-left-row">
                <span className="new-offer-content-left-row-span">
                  {options[language].withoutGoodsName}{" "}
                </span>
                <div className="new-offer-content-left-row-input new-offer-content-left-row-check">
                  <Checkbox
                    checked={withGoods !== null ? !withGoods : false}
                    onChange={(e) => setWithGoods(false)}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                      color: blue500,
                      "&.Mui-checked": {
                        color: blue500,
                      },
                    }}
                  />
                </div>
              </div>
              <div className="new-offer-content-left-row">
                <span className="new-offer-content-left-row-span">
                  {options[language].repairName}{" "}
                </span>
                <div className="new-offer-content-left-row-input new-offer-content-left-row-check">
                  <Checkbox
                    checked={repair}
                    onChange={(e) => setRepair(true)}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                      color: blue500,
                      "&.Mui-checked": {
                        color: blue500,
                      },
                    }}
                  />
                </div>
              </div>
              <div className="new-offer-content-left-row">
                <span className="new-offer-content-left-row-span">
                  {options[language].withoutRepairName}{" "}
                </span>
                <div className="new-offer-content-left-row-input new-offer-content-left-row-check">
                  <Checkbox
                    checked={repair !== null ? !repair : false}
                    onChange={(e) => setRepair(false)}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                      color: blue500,
                      "&.Mui-checked": {
                        color: blue500,
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
              {options[language].priceName.replace(":", "")}{" "}
              <span className="red-point">*</span>
            </span>
            <div className="new-offer-content-left-row-input">
              <input
                value={price}
                className="inputTag"
                placeholder={options[language].priceName.replace(":", "")}
                min={0}
                type="number"
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
          </div>
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
              {options[language].newOffer_Desc.replace(", ", "")}{" "}
              <span className="red-point">*</span>
            </span>
            <div className="new-offer-content-left-row-input">
              <textarea
                value={description}
                placeholder={options[language].newOffer_Desc.replace(", ", "")}
                className="inputTag"
                name="postContent"
                rows={4}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
              {options[language].imagesSmall}{" "}
              <span className="red-point">*</span>
            </span>
            <div className="new-offer-content-left-row-input">
              <button
                className="new-offer-content-left-row-button"
                onClick={(e) => uploadImage.current.click()}
              >
                {options[language].addImagesSmall}
              </button>
              <div className="prevImageDiv">
                {selectedImage &&
                  selectedImage.map((image, index) => (
                    <div className="prevImage" key={index}>
                      <img
                        alt="not found"
                        width={"70px"}
                        height={"70px"}
                        src={
                          typeof image === "string"
                            ? image
                            : URL.createObjectURL(image)
                        }
                      />
                      <div
                        className="deleteBtn"
                        onClick={() => handleDeleteImage(index)}
                      >
                        &#10005;
                      </div>
                    </div>
                  ))}
              </div>
              <input
                style={{ display: "none" }}
                ref={uploadImage}
                className="inputGroupFile"
                placeholder={options[language].addImagesSmall}
                type="file"
                id="resume"
                name="myImage"
                accept="image/*"
                multiple
                onChange={(event) => {
                  setSelectedImage((prevImages) => [
                    ...prevImages,
                    ...event.target.files,
                  ]);
                }}
              />
            </div>
          </div>
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
              {options[language].videoSmall}
            </span>
            <div className="new-offer-content-left-row-input">
              <button
                className="new-offer-content-left-row-button"
                onClick={() => uploadVideo?.current?.click()}
              >
                {options[language].addVideoSmall}
              </button>
              <div className="prevImageDiv">
                {selectedVideo.length > 0 &&
                  selectedVideo.slice(0, 1).map((video, index) => (
                    <div className="prevImage" key={index}>
                      <video width="250" height="250" controls>
                        <source src={video.preview} type={video.file.type} />
                        Your browser does not support the video tag.
                      </video>
                      <div
                        className="deleteBtn"
                        onClick={() => handleDeleteVideo(index)}
                      >
                        &#10005;
                      </div>
                    </div>
                  ))}
              </div>
              <input
                style={{ display: "none" }}
                ref={uploadVideo}
                className="inputGroupFile"
                placeholder={options[language].addVideoSmall}
                type="file"
                id="video"
                name="myImage"
                accept="video/mp4"
                multiple
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
              {options[language].myOffersNameChangeName}{" "}
              <span className="red-point">*</span>
            </span>
            <div className="new-offer-content-left-row-input">
              <input
                value={name}
                name="firstName"
                autoComplete="given-name"
                className="inputTag"
                placeholder={options[language].myOffersNameChangeName}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
              {options[language].newOffer_Email?.replace(",", "")}{" "}
              <span className="red-point">*</span>
            </span>
            <div className="new-offer-content-left-row-input">
              <input
                value={email}
                autoComplete="email"
                className="inputTag"
                placeholder={options[language].newOffer_Email?.replace(",", "")}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="new-offer-content-left-row">
            <span className="new-offer-content-left-row-span new-offer-content-left-row-span-hide">
              {options[language].phoneNumberName}{" "}
              <span className="red-point">*</span>
            </span>
            <div className="new-offer-content-left-row-input">
              {/* <input
                value={mobileNum}
                name="tel"
                autoComplete="tel"
                className="inputTag"
                placeholder={options[language].phoneNumberName}
                onChange={(e) => handlePhoneChange(e)}
              /> */}
              <InputMask
                mask="+\9\94(99)999-99-99"
                placeholder={options[language].phoneNumberName}
                name="tel"
                className="inputTag"
                value={mobileNum}
                onChange={(e) => setMobileNum(e.target.value)}
              />
            </div>
          </div>
          {showNot !== 0 && (
            <Alert severity="error">
              {notification} {options[language].writeInfoInThisFields}
            </Alert>
          )}
          <button
            className="new-offer-content-left-row-button"
            onClick={() => updateOffer()}
          >
            {loadingUploadOffer ? (
              <CircularProgress size={20} fontSize="small" color="inherit" />
            ) : (
              "Elanı yenilə"
            )}
          </button>
        </div>
        <hr />
        <div className="new-offer-content-right">
          <div className="new-offer-content-right-conditions">
            <span className="new-offer-content-right-span new-offer-content-right-span-title ">
              {options[language].simpleRules}
            </span>
            <span className="new-offer-content-right-span-conditions">
              &#x2022; {options[language].simpleRules1}
            </span>
            <span className="new-offer-content-right-span-conditions">
              &#x2022; {options[language].simpleRules2}
            </span>
            <span className="new-offer-content-right-span-conditions">
              &#x2022; {options[language].simpleRules3}
            </span>
          </div>
          <div className="new-offer-content-right-content">
            <span className="new-offer-content-right-span">
              {options[language].offerView}
            </span>
            <OfferCard offer={cardData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateOffer;
