import React, { useEffect } from "react";
import MiniBanner from "../../Components/MiniBanner/MiniBanner";
import Header from "../../Components/Header/Header";
import PaymentOfUploadedOffer from "../../Components/PaymentOfUploadedOffer/PaymentOfUploadedOffer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { options } from "../../constants";
import { Empty } from "antd";
import Button from "@mui/material/Button";

const OfferUploaded = () => {
  const navigate = useNavigate();
  const language = useSelector((state) => state.language);
  return (
    <>
      <MiniBanner />
      <Header />
      {JSON.parse(sessionStorage.getItem("showSuccessUploadOfferPopUp")) ? (
        <PaymentOfUploadedOffer />
      ) : (
        <div
          style={{ width: "100%", textAlign: "center", padding: " 20px 0 0 0" }}
        >
          <Empty description={options[language].notUploadedOffer} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, backgroundColor: "#0089CF", width: "280px" }}
            onClick={() => navigate("/elan-yerlesdir")}
          >
            {options[language].uploadOffer}
          </Button>
        </div>
      )}
    </>
  );
};

export default OfferUploaded;
