import React from 'react'

const Frame = ({ color }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="20" height="20" rx="3" stroke={color ? color : "#888888"} stroke-width="2" />
        </svg>


    )
}

export default Frame