import React, { useState } from "react";
import "./Offers.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import whiteFon from "../../images/whitefon.jpg";
import { options } from "../../constants";
import HeartWhite from "../../images/blurHeart.svg";
import HeartRed from "../../images/FilledHeart.svg";
import { useEffect } from "react";
import locationIcon from "../../images/locationIcon.svg";
import { white } from "material-ui/styles/colors";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { saveLastSearchUrl } from "../../redux/action";

const OfferCard = ({ offer }) => {
  const language = useSelector((state) => state.language);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [url, setUrl] = useState(offer?.url?.split("/")[2]);
  const [changed, setChanged] = useState(false);
  const offerType = useSelector((state) => state.offerType);
  const [isFavorite, setIsFavorite] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  if (url === "dasinmaz-emlak") {
    setUrl("tap.az");
    setChanged(true);
  }
  useEffect(() => {
    const favoritesData = localStorage.getItem("MyFavourites");
    if (favoritesData) {
      const isObjectInFavorites =
        JSON.parse(favoritesData)?.findIndex((item) => item.id === offer.id) !==
        -1;

      setIsFavorite(isObjectInFavorites);
    }
  }, [offer.id]);
  function addOrRemoveObjectFromLocalStorage(obj) {
    const existingData = localStorage.getItem("MyFavourites");
    if (existingData) {
      const dataArray = JSON.parse(existingData);
      const existingIndex = dataArray.findIndex((item) => item.id === obj.id);
      if (existingIndex !== -1) {
        dataArray.splice(existingIndex, 1);
      } else {
        dataArray.push(obj);
      }
      localStorage.setItem("MyFavourites", JSON.stringify(dataArray));
    } else {
      const newDataArray = [obj];
      localStorage.setItem("MyFavourites", JSON.stringify(newDataArray));
    }
    setIsFavorite(!isFavorite);
  }
  const addInFavorite = () => {
    addOrRemoveObjectFromLocalStorage(offer);
  };
  // const navigateToDetails = (e) => {
  //   if (e.target.parentElement.className === "image-gallery-icon image-gallery-left-nav" ||
  //     e.target.parentElement.className === "image-gallery-icon image-gallery-right-nav" ||
  //     e.target.className === "middleRow-right" ||
  //     e.target.className === "middleRow-left" ||
  //     e.target.className.animVal.includes("middleRow-right-icon") ||
  //     e.target.className.animVal.includes("middleRow-left-icon")
  //   ) {
  //     e.preventDefault()
  //   }
  // };
  const getLeftImg = () => {
    if (imgIndex !== 0) {
      let index = imgIndex - 1;
      setImgIndex(index);
    } else {
      setImgIndex(offer.images.length - 1);
    }
  };
  const getRightImg = () => {
    if (imgIndex < offer.images.length - 1) {
      let index = imgIndex + 1;
      setImgIndex(index);
    } else {
      setImgIndex(0);
    }
  };
  const clickOnLink = () => {
    if (
      !(
        window.location.href.includes("/elan/") ||
        window.location.href.includes("/secilmis-elanlar/") ||
        window.location.href.includes("secilmis-elanlar")
      )
    ) {
      dispatch(
        saveLastSearchUrl(window.location.href.replace("https://ebaz.az/", ""))
      );
    }
  };
  return (
    <Card
      sx={{
        width: "42vw",
        minWidth: "240px",
        height: "300px",
        maxWidth: 300,
        marginBottom: "26px",
        borderRadius: "15px",
        boxShadow: " 0 4px 4px rgba(0,0,0,.25)",
      }}
    >
      <CardActionArea className="card-action-area" disableRipple>
        <div className="additionalRow">
          <div className="additionalRowLeft">
            {/* <FaRegHeart color='white' size={25} /> */}
            <img
              src={
                localStorage.getItem("MyFavourites")
                  ? JSON.parse(localStorage.getItem("MyFavourites"))?.findIndex(
                      (item) => item.id === offer.id
                    ) !== -1
                    ? HeartRed
                    : HeartWhite
                  : HeartWhite
              }
              alt="heart red"
              onClick={() => addInFavorite()}
            />
          </div>
          <div className="additionalRowRight">
            {/* <FaRegHeart color='white' size={25} fill='white' /> */}
          </div>
        </div>

        <Link
          onClick={() => clickOnLink()}
          to={{
            pathname:
              window.location.pathname === "/" ||
              window.location.pathname.includes("/elan/")
                ? `/elan/${offer.type ? offer.type : offerType}/${offer.id}`
                : `/secilmis-elanlar/${offer.type ? offer.type : offerType}/${
                    offer.id
                  }`,
          }}
          state={{ offer }}
        >
          {offer.images?.length > 1 && (
            <div className="middleRow">
              <div
                className="middleRow-left"
                onClick={(e) => {
                  e.preventDefault();
                  getLeftImg();
                }}
              >
                <ArrowBackIosRounded
                  className="middleRow-left-icon"
                  fontSize="large"
                  sx={{ color: white }}
                />
              </div>
              <div
                className="middleRow-right"
                onClick={(e) => {
                  e.preventDefault();
                  getRightImg();
                }}
              >
                <ArrowForwardIosRounded
                  className="middleRow-right-icon"
                  fontSize="large"
                  sx={{ color: white }}
                />
              </div>
            </div>
          )}
          <CardMedia
            component="img"
            height="200"
            image={
              (offer.images !== null && offer.images?.length !== 0) ? offer.images[imgIndex]?.path : whiteFon
            }
            alt={offer.title ? offer.title : "elan"}
            loading="lazy"
                onError={e => {
                  e.target.onerror = null
                  e.target.src = whiteFon
                }}
          />
        </Link>
        <Link
          onClick={() => clickOnLink()}
          to={{
            pathname:
              window.location.pathname === "/" ||
              window.location.pathname.includes("/elan/")
                ? `/elan/${offer.type ? offer.type : offerType}/${offer.id}`
                : `/secilmis-elanlar/${offer.type ? offer.type : offerType}/${
                    offer.id
                  }`,
          }}
          state={{ offer }}
        >
          <CardContent sx={{ padding: "10px", height: "110px", width: "100%" }}>
            <hr
              className="horizontal"
              style={{ position: "absolute", top: "193px", color: "black" }}
            />
            <Typography
              variant="body3"
              color="text.secondary"
              className="typography-price"
              sx={{ fontSize: "18px", position: "absolute", top: "195px" }}
            >
              {offer.price.toLocaleString("ru")} AZN
            </Typography>
            <hr
              className="horizontal"
              style={{ position: "absolute", top: "227px", color: "black" }}
            />
            <Typography
              className="city-and-link-data"
              variant="body2"
              color="text.secondary"
              sx={{
                fontWeight: 500,
                width: "calc(100% + 0px)",
                fontSize: "16px",
                position: "absolute",
                top: "220px",
                color: "#222222",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "5px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "calc(100% + 0px)",
                }}
              >
                {/* {offer.city !== null || offer.region !== null || offer.residential_area !== null ? <img style={{ width: "16px", height: "16px" }} src={locationIcon} alt='location' /> : <></>} */}
                {offer.city !== null
                  ? options[language].cities[offer.city].label
                  : ""}
                {offer.region !== null && offer.city === null
                  ? options[language].cities[8].label
                  : ""}
                {offer.region !== null
                  ? ", " + options[language].region_options[offer.region].label
                  : ""}
                {offer.residential_area !== null && offer.region === null
                  ? ", " +
                    options[language].resident_options[offer.residential_area]
                      .region
                  : ""}
                {offer.residential_area !== null
                  ? ", " +
                    options[language].resident_options[offer.residential_area]
                      .label +
                    options[language].residentsSmall
                  : ""}
              </div>
            </Typography>
            <Typography
              className="room-area-and-floor-data"
              variant="body2"
              color="text.secondary"
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                position: "absolute",
                top: "253px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "100%",
                maxWidth: "calc(100% - 20px)",
              }}
            >
              {offer.room && offer.room + options[language].WithRoomsSmall}
              {offer.room && offer.area && (
                <hr className="vertical dot-vertical" />
              )}
              {offer.area && (
                <>
                  {Number(offer.area).toFixed(2).replace(".00", "")}
                  {offer.type === "landsales" || offer.type === "landrents"
                    ? +" " + options[language].sot
                    : " " + options[language].m2}
                </>
              )}
              {offer.floor && offer.area && (
                <hr className="vertical dot-vertical" />
              )}
              {offer.floor && (
                <>
                  {offer.floor}/{offer.max_floor} {options[language].floorSmall}
                </>
              )}
            </Typography>

            <hr
              className="horizontal"
              style={{ position: "absolute", top: "265px", color: "black" }}
            />

            <Typography
              className="city-and-link-data"
              variant="body2"
              color="text.secondary"
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                position: "absolute",
                top: "0px",
                color: "black",
                marginLeft: "10px",
              }}
            >
              <Link
                onClick={() => clickOnLink()}
                to={{
                  pathname:
                    window.location.pathname === "/" ||
                    window.location.pathname.includes("/elan/")
                      ? `/elan/${offer.type ? offer.type : offerType}/${
                          offer.id
                        }`
                      : `/secilmis-elanlar/${
                          offer.type ? offer.type : offerType
                        }/${offer.id}`,
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "188px",
                    right: "0px",
                    color: "#888888",
                  }}
                >
                  {offer.updated_at
                    ? `${
                        new Date(offer?.updated_at).getDate() ===
                          new Date().getDate() &&
                        new Date(offer?.updated_at).getMonth() ===
                          new Date().getMonth() &&
                        new Date(offer?.updated_at).getFullYear() ===
                          new Date().getFullYear()
                          ? `${new Date(offer?.updated_at)
                              .toLocaleString("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              })
                              .replace("24:", "00:")}`
                          : new Date(offer?.updated_at).getDate() ===
                              new Date().getDate() - 1 &&
                            new Date(offer?.updated_at).getMonth() ===
                              new Date().getMonth() &&
                            new Date(offer?.updated_at).getFullYear() ===
                              new Date().getFullYear()
                          ? `${options[language].yesterdaySmall}`
                          : new Date(offer?.updated_at)
                              .toLocaleString("ru-Ru", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                              })
                              .replace(/20\d{2}/, /\d{2}/)
                      }`
                    : ""}
                </span>
              </Link>
              <Typography
                className="city-and-link-data-url-part"
                variant="body2"
                color="text.secondary"
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  position: "absolute",
                  top: `140px`,
                  color: "white",
                  justifyContent: "flex-start",
                  right: "-10px",
                }}
              >
                {changed ? (
                  <a
                    style={{ zIndex: "5" }}
                    href={"https://tap.az" + offer.url}
                    target="_blank"
                    rel="nofollow"
                    className="offer-url"
                  >
                    {offer.url?.split("/")[2]}
                  </a>
                ) : (
                  <a
                    style={{ zIndex: "5" }}
                    rel="nofollow"
                    href={offer.url}
                    className="offer-url"
                    target="_blank"
                  >
                    {offer.url?.split("/")[2]}
                  </a>
                )}{" "}
                {offer?.url === null && (
                  <a
                    style={{ zIndex: "5", textDecoration: "underline" }}
                    href={`https://ebaz.az/elan/${
                      offer.type ? offer.type : offerType
                    }/${offer.id}`}
                    target="_blank"
                    className="offer-url"
                  >
                    ebaz.az
                  </a>
                )}
              </Typography>
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
};

export default OfferCard;
