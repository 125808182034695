import React, { useEffect, useState } from 'react'
import './Predict.css'
import { LineChart } from '@mui/x-charts/LineChart'
import { useSelector } from 'react-redux'

const Predict = ({ data }) => {
  const [graphData, setGrapData] = useState([])
  const [graphDataPredictArray, setGrapDataPredictArray] = useState([])
  const [graphDataStatisticArray, setGrapDataStatisticArray] = useState([])
  const [monthsArray, setMonthsArray] = useState([])
  const isMobile = useSelector(state => state.isMobile)
  useEffect(() => {
    setGrapData(data)
    const allMonths = []
    let currentDate = new Date(data[0]?.month)
    const lastDate = new Date(data[data.length - 1]?.month)

    while (currentDate <= lastDate) {
      allMonths.push(new Date(currentDate))
      currentDate.setMonth(currentDate.getMonth() + 1)
    }

    const uniqueMonths = Array.from(
      new Set(allMonths.map(month => month.toISOString()))
    ).map(isoString => new Date(isoString))

    const avgPriceArray = []
    let currentIndex = 0
    for (const month of allMonths) {
      const entry = data[currentIndex]
      if (
        entry &&
        month.toISOString() === new Date(entry?.month).toISOString()
      ) {
        avgPriceArray.push(entry?.avg_price_per_m2?.toFixed(2))
        currentIndex++
      } else {
        avgPriceArray.push(null)
      }
    }
    const lastAvgPriceArray = Array(allMonths.length).fill(null)
    lastAvgPriceArray[allMonths.length - 1] =
      data[data.length - 1]?.avg_price_per_m2?.toFixed(2)
    if (data.length > 1) {
      lastAvgPriceArray[allMonths.length - 2] =
        data[data.length - 2]?.avg_price_per_m2?.toFixed(2)
    }
    setMonthsArray(uniqueMonths)
    setGrapDataStatisticArray(avgPriceArray)
    setGrapDataPredictArray(lastAvgPriceArray)
  }, [data])
  return (
    <div className='predict'>
      <div className='predict-content'>
        <div className='predict-content-graph-part' style={{position:'relative'}}>
          <span style={{position:'absolute', top:'15px', zIndex:'2', left:'25px' }}>AZN/m2</span>
          {monthsArray.length !== 0 && (
            <LineChart
              sx={{
                width: '100%',
                backgroundColor: 'white',
                '& .MuiLineElement-root': {
                  strokeDasharray: '10 2',
                  strokeWidth: 4,
                  color: 'black'
                },
                '& .MuiAreaElement-series-Predict': {
                  fill: '#b2e7fa90' //#7fd6f6
                },
                '& .MuiAreaElement-series-Statistics': {
                  fill: '#7fd6f6' //#7fd6f6
                }
              }}
              xAxis={[
                {
                  id: 'Years',
                  data: monthsArray,
                  scaleType: 'time',
                  tickInterval: monthsArray,
                  valueFormatter: date => {
                    const monthNames = [
                      'Yanvar',
                      'Fevral',
                      'Mart',
                      'Aprel',
                      'May',
                      'İyun',
                      'İyul',
                      'Avqust',
                      'Sentyabr',
                      'Oktyabr',
                      'Noyabr',
                      'Dekabr'
                    ]
                    return `${
                      monthNames[date.getMonth()]
                    } ${date.getFullYear()}`
                  }
                }
              ]}
              series={[
                {
                  id: 'Statistics',
                  data: graphDataStatisticArray,
                  area: true,
                  showMark: true,
                  connectNulls: true
                },
                {
                  id: 'Predict',
                  data: graphDataPredictArray,
                  area: true,
                  showMark: true,
                  connectNulls: true
                }
              ]}
              height={isMobile ? 300 : 500}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Predict
