import React from 'react'

const GarageImage = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.8 7.0505L0.33501 6.39951C0.124772 6.54969 0 6.79214 0 7.0505H0.8ZM12 7.04806L12.465 6.39708C12 6.39685 12 6.39706 11.535 6.39708L12 7.04806ZM23.2 7.0505H24C24 6.79214 23.8752 6.54969 23.665 6.39951L23.2 7.0505ZM4 12V11.2H3.2V12H4ZM20 12H20.8V11.2H20V12ZM1.6 24V7.0505H0L0 24H1.6ZM1.26499 7.7015L12.465 7.69905L11.535 6.39708L0.33501 6.39951L1.26499 7.7015ZM11.535 7.69905L22.735 7.7015L23.665 6.39951L12.465 6.39708L11.535 7.69905ZM22.4 7.0505V24H24V7.0505H22.4ZM4.8 24V12H3.2V24H4.8ZM4 12.8H20V11.2H4V12.8ZM19.2 12V24H20.8V12H19.2ZM4.8 20.4977H19.2V18.8977H4.8V20.4977Z" fill={color}/>
<path d="M4.8 24H0V22.4H24V24H4.8Z" fill={color}/>
<path d="M19.2 16.6988H4.8V15.0988H19.2V16.6988Z" fill={color}/>
</svg>

  )
}

export default GarageImage