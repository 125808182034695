import React from 'react';
import './Loading.css'; 

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-spinner" style={{ background: '#0089CF' }}></div>
      <div className="loading-spinner" style={{ background: '#DCDCDC' }}></div>
    </div>
  );
};

export default Loading;
