import React, { useState } from 'react'
import Heart from './heart.svg'
import Login from '../../images/login.svg'
import Profile from './profile.svg'
import LinkIcon from './link.svg'
import { options } from '../../constants'
import Globus from './globus.svg'
import Blog from './blog.png'
import './Header.css'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { changeSiteLanguage, openSearchByUrlPopUp } from '../../redux/action'
import Plus from './plus.svg'
import DownV from './downV.svg'
import EbazLogo from '../images/ebaz_logo.png'

const DesktopHeader = () => {
  const [showMobIcons, setShowMobIcons] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  const language = useSelector(state => state.language)
  const dispatch = useDispatch()
  const [languageBar, openLanguageBar] = useState(false)
  const searchByUrlPopUp = useSelector(state => state.searchByUrlPopUp)
  const changeLanguage = language => {
    openLanguageBar(!languageBar)
    if (localStorage.getItem('language') !== language) {
      const url = new URL(window.location.href)
      url.searchParams.delete("ru");
      url.searchParams.delete("az");
      url.searchParams.delete("en");
      url.searchParams.set(language, "")
      window.localStorage.setItem('language', language)
      window.location.href = url.toString().replace(/=$|=(?=&)/g, '')
      // dispatch(changeSiteLanguage(language))
    //   window.location.reload()
    }
  }
  useEffect(() => {
    localStorage.getItem('token') ? setIsLogged(true) : setIsLogged(false)
  }, [])
  useEffect(() => {
    localStorage.getItem('token') ? setIsLogged(true) : setIsLogged(false)
  }, [localStorage.getItem('token')])
  return (
    <>
      <header className='py-3'>
        <div className='container'>
          <nav className='navbar navbar-expand main-nav'>
            <a className='navbar-brand' href='/'>
              {/* <span className='EvbuLogo'>Ebaz </span> */}
              <img src={EbazLogo} alt='Ebaz Logo' style={{ width: '120px' }} />
            </a>
            <ul id='navbar_main' className='navbar-nav ms-auto desktop_menu'>
              <li
                className='nav-item d-none d-md-block'
                onClick={() => openLanguageBar(!languageBar)}
                style={{ cursor: 'pointer', position: 'relative' }}
              >
                <img className='imagesizest' alt='Green Heart' src={Globus} />
                {language.toUpperCase()}
                <img
                  className={`dropdown  ${
                    languageBar ? 'dropOpen' : 'dropClose'
                  }`}
                  alt=' Green Heart'
                  src={DownV}
                  style={{
                    width: '10px',
                    transform: languageBar ? 'rotate(180deg)' : 'none',
                    transition: 'transform 0.5s ease'
                  }}
                />
                <div
                  className={`languageDropDown  ${
                    languageBar ? 'languageOpen' : 'languageClose'
                  }`}
                  style={{ position: 'absolute' }}
                >
                  <span onClick={() => changeLanguage('az')}>AZ</span>
                  <span onClick={() => changeLanguage('ru')}>RU</span>
                  {/* <span onClick={() => changeLanguage('en')}>EN</span> */}
                </div>
              </li>
              <li
                className='nav-item d-none d-md-block'
                onClick={() =>
                  dispatch(openSearchByUrlPopUp(!searchByUrlPopUp))
                }
                style={{ cursor: 'pointer' }}
              >
                <img
                  className='imagesizest'
                  alt=' Green Heart'
                  src={LinkIcon}
                />
                {options[language].findOffer}
              </li>
              <li className='nav-item d-none d-md-block'>
                <a
                  className='nav-link position-relative fav_link'
                  href='/secilmis-elanlar'
                >
                  <img className='imagesizest' alt=' Green Heart' src={Heart} />
                  {options[language].favourites}
                </a>
              </li>
              {/* <li className='nav-item d-none d-md-block'>
                <a
                  className='nav-link position-relative fav_link'
                  href='/bloglar'
                >
                  <img className='imagesizest blog' alt='Blog' src={Blog} />
                  {options[language].blog}
                </a>
              </li> */}
              {localStorage.getItem('token') ? (
                <li className='nav-item d-none d-md-block'>
                  <a className='nav-link login_btn' href='/profil'>
                    <img className='imagesizest' src={Profile} alt='profile' />
                    {options[language].profile}
                  </a>
                </li>
              ) : (
                <li className='nav-item d-none d-md-block'>
                  <img className='imagesizest' src={Profile} alt='profile' />
                  <a className='nav-link login_btn' href='/daxil-ol'>
                    {options[language].signIn}{' '}
                  </a>
                </li>
              )}
              <li className='nav-item newOffer'>
                <a className='nav-link add_btn btn ' href='/elan-yerlesdir'>
                  <img src={Plus} alt='plus' />
                  {options[language].uploadOffer}
                </a>
              </li>
            </ul>
          </nav>
          <nav
            id='navbar_mobile'
            className='mobile-offcanvas navbar navbar-expand-lg d-lg-none'
          >
            <div className='container-fluid'>
              <ul className='navbar-nav ms-auto desktop_menu'>
                <li className='nav-item newOffer'>
                  <a
                    className='nav-link add_btn btn btn-outline-success'
                    href='/elan-yerlesdir'
                  >
                    {options[language].uploadOffer}
                  </a>
                </li>
              </ul>
              <div className='offcanvas-header'>
                <button
                  data-trigger='navbar_main'
                  className='btn bar_btn'
                  type='button'
                  aria-label='Menyu'
                  onClick={e => setShowMobIcons(!showMobIcons)}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
            {showMobIcons && (
              <div className='popupMobile'>
                <ul className='navbar-nav ms-auto navbar-column'>
                  <li className='nav-item'>
                    <a
                      className='nav-link position-relative fav_link'
                      href='/secilmis-elanlar'
                    >
                      <img alt=' Green Heart' src={Heart} />{' '}
                      {options[language].favourites}
                    </a>
                  </li>
                  {localStorage.getItem('token') ? (
                    <li className='nav-item'>
                      <a className='nav-link' href='/profil'>
                        <img
                          alt=' user profile'
                          className='icon mr-1'
                          src={Profile}
                          width='24'
                        />
                        {options[language].profile}
                      </a>
                    </li>
                  ) : (
                    <li className='nav-item'>
                      <a className='nav-link login_btn_mobile' href='/daxil-ol'>
                        <img
                          alt=' Login'
                          className='icon mr-1'
                          src={Login}
                          width='24'
                        />{' '}
                        {options[language].signIn}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </nav>
        </div>
      </header>
    </>
  )
}

export default DesktopHeader
