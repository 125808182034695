import React from 'react'

const Top = ({color}) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2727 18V10.6364H18L9 0L0 10.6364H5.72727V18H12.2727Z" fill={color ? color : "white"} />
        </svg>

    )
}

export default Top