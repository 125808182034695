import React, { useEffect, useState } from "react";
import "./MyOffers.css";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
// import Button from '@mui/material/Button'
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Modal, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { options } from "../../constants";
import {
  boostOfferAction,
  changeProfilInfoAction,
  deleteOfferAction,
  getAllMyOffers,
  updateProfilInfoSuccessAction,
} from "../../redux/action";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Loading from "../../Components/Loading/Loading";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Empty } from "antd";
import MiniBanner from "../../Components/MiniBanner/MiniBanner";
const MyOffers = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllMyOffers());
  }, []);
  const navigate = useNavigate();
  const myAllOffers = useSelector((state) => state.myAllOffers);
  const loadingUpdateProfilInfo = useSelector(
    (state) => state.loadingUpdateProfilInfo
  );
  const updateProfilInfoSuccess = useSelector(
    (state) => state.updateProfilInfoSuccess
  );
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState(1);
  const language = useSelector((state) => state.language);
  const search = useSelector((state) => state.search);
  const [open, setOpen] = useState(false);
  const [input1Value, setInput1Value] = useState(localStorage.getItem("name"));
  const [input2Value, setInput2Value] = useState(
    localStorage.getItem("lastname")
  );
  const [input1Error, setInput1Error] = useState(false);
  const [input2Error, setInput2Error] = useState(false);
  React.useLayoutEffect(() => {
    document.title = options[language].newOffersTitle;
  }, []);
  useEffect(() => {
    !localStorage.getItem("token") && navigate("/");
  }, []);
  const updateOffer = (flatType, id) => {
    navigate(`/elani-yenile/${flatType.replace(" ", "")}/${id}`);
  };
  const deleteOffer = (flatType, id) => {
    dispatch(deleteOfferAction(flatType, id));
  };
  const SigOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("lastname");
    localStorage.removeItem("mobileNum");
    localStorage.removeItem("email");
    localStorage.removeItem("id");
    navigate("/");
    window.location.reload();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setInput1Error(false);
    setInput2Error(false);
    setInput1Value(localStorage.getItem("name"));
    setInput2Value(localStorage.getItem("lastname"));
    dispatch(updateProfilInfoSuccessAction(""));
  };
  const handleInputChange1 = (event) => {
    setInput1Value(event.target.value);
    dispatch(updateProfilInfoSuccessAction(""));
  };
  const handleInputChange2 = (event) => {
    setInput2Value(event.target.value);
    dispatch(updateProfilInfoSuccessAction(""));
  };
  const handleButtonClick = () => {
    if (input1Value.trim() === "") {
      setInput1Error(true);
    }
    if (input2Value.trim() === "") {
      setInput2Error(true);
    }
    if (input2Value.trim() !== "" && input1Value.trim() !== "") {
      dispatch(changeProfilInfoAction(input1Value, input2Value));
      // handleClose();
    }
  };
  return (
    <>
      <MiniBanner />
      <Header />
      <div className="myoffers">
        <div className="myoffers-content">
          <div className="myoffers-content-profil-info">
            <div className="myoffers-content-profil-info-content">
              <div className="myoffers-content-profil-info-content-img">
                <AccountCircleIcon
                  className="accountCircleIcon"
                  style={{ fontSize: 60 }}
                />
              </div>
              <div className="myoffers-content-profil-info-content-text">
                <span>
                  {localStorage.getItem("lastname")}{" "}
                  {localStorage.getItem("name")}{" "}
                  <EditRoundedIcon
                    onClick={handleOpen}
                    style={{
                      fontSize: 20,
                      marginBottom: "8px",
                      color: "#ff2b00",
                      cursor: "pointer",
                    }}
                  />
                </span>
                <span>{localStorage.getItem("mobileNum")}</span>
              </div>
            </div>
            <div className="logOut" onClick={() => SigOut()}>
              <svg
                width="25"
                height="19"
                viewBox="0 0 25 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.7427 2.71906H14.5141V0.719055H22.7427C23.8741 0.719055 24.7998 1.61906 24.7998 2.71906V16.7191C24.7998 17.8191 23.8741 18.7191 22.7427 18.7191H14.5141V16.7191H22.7427V2.71906Z"
                  fill="#0089CF"
                />
                <path
                  d="M10.0548 2.04252C10.0548 1.58173 10.6252 1.36623 10.9299 1.71191L17.4045 9.05784C17.7376 9.43573 17.7376 10.0024 17.4045 10.3803L10.9299 17.7262C10.6252 18.0719 10.0548 17.8564 10.0548 17.3956V13.6191C10.0548 13.4534 9.92045 13.3191 9.75477 13.3191H1.80013C1.24785 13.3191 0.800135 12.8713 0.800135 12.3191V7.11906C0.800135 6.56677 1.24785 6.11906 1.80013 6.11906H9.75477C9.92045 6.11906 10.0548 5.98474 10.0548 5.81905V2.04252Z"
                  fill="#0089CF"
                />
              </svg>
              {options[language].logout}
            </div>
          </div>
          <hr style={{ width: "100%", border: "1px solid grey" }} />
          <div className="myoffers-content-title">
            <span>{options[language].myOffersName}</span>
          </div>
          <div className="myoffers-content-navbar">
            <Box sx={{ width: "100%" }}>
              <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              >
                <BottomNavigationAction
                  label={options[language].myOffersActiveName}
                  icon={<CheckCircleIcon />}
                  onClick={(e) => {
                    setValue(0);
                    setStatus(1);
                  }}
                />
                {/* <BottomNavigationAction label="Moderasiyada" icon={<PublishedWithChangesIcon />} /> */}
                <BottomNavigationAction
                  label={options[language].myOffersDeactiveName}
                  icon={<RunningWithErrorsIcon />}
                  onClick={(e) => {
                    setValue(1);
                    setStatus(3);
                  }}
                />
                {/* <BottomNavigationAction label="Qəbul edilmədi" icon={<CancelIcon />} /> */}
              </BottomNavigation>
            </Box>
          </div>
          <div className="myoffers-content-offers">
            {search ? (
              <div className="my-offers-loading">
                <Loading />
              </div>
            ) : myAllOffers.length !== 0 ? (
              myAllOffers
                .filter((offer) => offer.property_status === status)
                .map((offer) => (
                  <Card
                    sx={{
                      display: "flex",
                      maxWidth: "550px",
                      margin: "10px",
                      borderRadius: "10px",
                      boxShadow: " 0 3px 8px rgba(0,0,0,.24)",
                    }}
                    key={offer.property_id2}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: 151 }}
                      image={offer.property_images?.[0]?.replace(
                        "https:/api",
                        "https://api"
                      )}
                      alt={offer.title ? offer.title : "elan"}
                      // loading='lazy'/\
                    />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography component="div" variant="h5">
                          {offer.property_title}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                        >
                          {offer.property_price.toLocaleString("ru")} AZN
                        </Typography>
                      </CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          pl: 1,
                          pb: 1,
                        }}
                      >
                        <Stack
                          direction="row"
                          gap={1}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {offer.property_status === 1 ? (
                            <>
                              <Button
                                variant="contained"
                                style={{ background: "#044aba" }}
                                startIcon={<EditIcon />}
                                onClick={() =>
                                  updateOffer(
                                    offer.property_type,
                                    offer.property_id2
                                  )
                                }
                              >
                                {options[language].myOffersReductName}
                              </Button>
                              <Button
                                variant="contained"
                                style={{ background: "red" }}
                                startIcon={<DeleteIcon />}
                                onClick={() =>
                                  deleteOffer(
                                    offer.property_type,
                                    offer.property_id2
                                  )
                                }
                              >
                                {options[language].myOffersDeleteFromOffersName}
                              </Button>{" "}
                            </>
                          ) : (
                            <Button
                              variant="contained"
                              style={{ background: "#76c81c" }}
                              onClick={() =>
                                dispatch(
                                  boostOfferAction(
                                    offer.property_type,
                                    offer.property_id2
                                  )
                                )
                              }
                            >
                              {options[language].myOffersRefreshOfferName}
                            </Button>
                          )}
                        </Stack>
                      </Box>
                    </Box>
                  </Card>
                ))
            ) : (
              <Empty
                description={options[language].myOffersDontHaveOffersName}
              />
            )}
            {search ? (
              <></>
            ) : myAllOffers.length !== 0 ? (
              myAllOffers.filter((o) => o.property_status === status).length ===
              0 ? (
                status === 1 ? (
                  <Empty
                    style={{ marginTop: "15px" }}
                    key="empty-offer"
                    description={
                      options[language].myOffersDontHaveActiveOffersName
                    }
                  />
                ) : (
                  <Empty
                    style={{ marginTop: "15px" }}
                    key="empty-offer"
                    description={
                      options[language].myOffersDontHaveDeactiveOffersName
                    }
                  />
                )
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 400,
            width: "95%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            outline: "none",
          }}
        >
          <IconButton
            style={{ position: "absolute", top: 5, right: 5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <h2 id="modal-title">
            {options[language].myOffersNameSurnameChangeName}
          </h2>
          <TextField
            label={options[language].myOffersNameChangeName}
            value={input1Value}
            onChange={handleInputChange1}
            fullWidth
            margin="normal"
            error={input1Error}
            helperText={
              input1Error ? options[language].myOffersSetNameChangeName : ""
            }
          />
          <TextField
            label={options[language].myOffersLastnameChangeName}
            value={input2Value}
            onChange={handleInputChange2}
            fullWidth
            margin="normal"
            error={input2Error}
            helperText={
              input2Error ? options[language].myOffersSetLastnameChangeName : ""
            }
          />
          <Button
            onClick={handleButtonClick}
            variant="contained"
            color="primary"
            sx={{
              "&.Mui-disabled": {
                background: "#1976f0",
                color: "#fff",
              },
            }}
            disabled={
              updateProfilInfoSuccess ===
              options[language].myOffersNameLastnameChangedSuccessfullyName
            }
          >
            {loadingUpdateProfilInfo ? (
              <CircularProgress size={20} fontSize="small" color="inherit" />
            ) : updateProfilInfoSuccess !== "" ? (
              updateProfilInfoSuccess
            ) : (
              options[language].myOffersNameLastnameChangeButtonName
            )}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MyOffers;
