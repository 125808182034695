import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { useState } from "react";
import Loading from "../Loading/Loading"
import "./GoogleMaps.css";
import { GoogleMap, useLoadScript, Marker, MarkerF } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { options } from "../../constants"
import "@reach/combobox/styles.css";
import { getStationsNames, setLatLng } from '../../redux/action';


var latV = 40.40;
var lngV = 49.87;
export default function GoogleMaps(latLng) {
  latV = latLng.latLng ? latLng.latLng[0] : 40.40;
  lngV = latLng.latLng ? latLng.latLng[1] : 49.87;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBx3uoJ51lGTzV-pm1GBKDfZUIwoZw2cqI",
    libraries: ["places"],
  });

  if (!isLoaded) return <Loading/>;
  return <Map />;
}

function Map() {
  const dispatch = useDispatch();
  var center =  ({ lat: latV, lng: lngV });
  const [selected, setSelected] = useState(null);
  const google = window.google;
  const geocoder = new google.maps.Geocoder();
  var address22 = "";
  // var stationsNames = [];
  const stations = useSelector((state) => state.stationNames);
  // const fetchData = async (latV, lngV) => {
  //   stationsNames = [];
  //   try {
  //     const response = await fetch(`/api/place/nearbysearch/json?location=${latV},${lngV}&radius=1000&type=subway_station&key=AIzaSyBx3uoJ51lGTzV-pm1GBKDfZUIwoZw2cqI`);
  //     const jsonData = await response.json();
  //     jsonData?.results.forEach(station => {
  //       stationsNames.push(station.name);
  //     })
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  //   dispatch(getStationsNames(stationsNames));
  // };
  const handleMapClick = (e) => {
    try{
      setSelected({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      latV = e.latLng.lat();
      lngV = e.latLng.lng();
      geocoder.geocode({location: { lat: selected.lat, lng: selected.lng }})
      .then((response) => {
        if (response.results[0]) {
          address22 = response.results[0].formatted_address;
          document.querySelector(".combobox-input").value = address22;
        } else {
          window.alert("No results found");
        }})
      .catch((e) => window.alert("Geocoder failed due to: " + e));
      // fetchData(latV, lngV);
      dispatch(setLatLng(`${center.lat}, ${center.lng}`))
    }
    catch(e){
    }
    // const offers = useSelector((state) => state.defaultOffers);
  }

  return (
    <>
      <div className="places-container">
        <PlacesAutocomplete setSelected={setSelected} />
        {/* <input className='latLng transparent item_price select string required' readOnly value={`${center.lat}, ${center.lng}`} style={{display:""}}/> */}
      </div>
      

      <GoogleMap
        zoom={15}
        center={center}
        mapContainerClassName="map-container"
        onClick={(e) => handleMapClick(e)}
      >
        {selected && <MarkerF position={selected} />}
      </GoogleMap>
    </>
  );
}

const PlacesAutocomplete = ({ setSelected }) => {
  const language = useSelector(state => state.language)
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "az" }, // Set the country to Azerbaijan (az)
    },
  });
  const dispatch = useDispatch()
  var stationsNames = [];
  const fetchData = async (latV, lngV) => {
    stationsNames = [];
    try {
      const response = await fetch(`/api/place/nearbysearch/json?location=${latV},${lngV}&radius=1000&type=subway_station&key=AIzaSyBx3uoJ51lGTzV-pm1GBKDfZUIwoZw2cqI`);
      const jsonData = await response.json();
      jsonData?.results.forEach(station => {
        stationsNames.push(station.name);
      })
    } catch (error) {
      console.error('Error:', error);
    }
    dispatch(getStationsNames(stationsNames));
  };
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
    latV = lat;
    lngV = lng;
    fetchData(latV, lngV);
  };
  

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className="combobox-input input string required "
        placeholder={options[language].enterAddress}
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))
          }
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};
