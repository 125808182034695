import React, { useState } from "react";
import "./PaymentOfUploadedOffer.css";
import successImage from "./Frame 168.svg";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { options } from "../../constants";
import whiteFon from "./Rectangle 22.png";
import HeartWhite from "../../images/blurHeart.svg";
import BoostIcon from "../../img/boostSVG.svg";
import BoostIconWhite from "../../img/boostSVGWhite.svg";
import PremiumIcon from "../../img/premiumSVG.svg";
import Frame from "./Frame";
import Urgent from "./Urgent";
import Top from "../../Pages/Offer/Top";
import CircularProgress from "@mui/material/CircularProgress";
import XIconBlack from "../../images/xBlack.svg";
import CrownIcon from "../../Components/images/CrownCard.svg";
import {
  isFirstOfferOfUserAction,
  loadingBoostButtonAction,
  loadingPremiumButtonAction,
  makeBoostOfferApi,
  makePremiumOfferApi,
  showSuccessUploadOfferPopUp,
  uploadedOfferAction,
} from "../../redux/action";

const PaymentOfUploadedOffer = () => {
  const dispatch = useDispatch();
  const uploadedOffer = sessionStorage.getItem("uploadedOffer")
    ? JSON.parse(sessionStorage.getItem("uploadedOffer"))
    : [];
  const isFirstOfferOfUser = sessionStorage.getItem("isFirst")
    ? JSON.parse(sessionStorage.getItem("isFirst"))
    : false;
  const [typeOfReklam, setTypeOfReklam] = useState("frame");
  const [selectedTarif, setSelectedTarif] = useState("1");
  const [selectedPeriod, setSelectedPeriod] = useState("1");
  const language = useSelector((state) => state.language);
  const isMobile = useSelector((state) => state.isMobile);
  const [url, setUrl] = useState();
  const [changed, setChanged] = useState(false);
  const [filteredArray, setFilteredArray] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const loadingPremiumButton = useSelector(
    (state) => state.loadingClickOnMakePremiumButton
  );
  const loadingBoostButton = useSelector(
    (state) => state.loadingClickOnMakeBoostButton
  );
  const premiumOffer = (offerId) => {
    dispatch(loadingPremiumButtonAction(true));
    let premiumPrice = options[language].packets["premium"].find(
      (option) => option.dayOrTime === selectedPeriod
    )?.price;
    dispatch(
      makePremiumOfferApi(
        typeOfReklam === "frame"
          ? (Number(premiumPrice) + 0.99).toFixed(2)
          : typeOfReklam === "urgent"
          ? (Number(premiumPrice) + 1.99).toFixed(2)
          : Number(premiumPrice),
        offerId,
        localStorage.getItem("id"),
        selectedPeriod,
        typeOfReklam === "frame" ? true : false,
        typeOfReklam === "urgent" ? true : false,
        uploadedOffer?.type
      )
    );
  };
  const boostOffer = (offerId) => {
    dispatch(loadingBoostButtonAction(true));
    dispatch(loadingPremiumButtonAction(true));
    let premiumPrice = options[language].packets[typeOfReklam].find(
      (option) => option.dayOrTime === selectedPeriod
    )?.price;
    dispatch(
      makeBoostOfferApi(
        premiumPrice,
        offerId,
        localStorage.getItem("id"),
        selectedPeriod,
        uploadedOffer?.type,
        typeOfReklam
      )
    );
  };
  const closePayment = () => {
    window.location.href = "https://ebaz.az/profil";
    dispatch(showSuccessUploadOfferPopUp(false));
    dispatch(uploadedOfferAction([]));
    dispatch(isFirstOfferOfUserAction(false));
  };
  return (
    <div className="payment-of-uploaded-offer">
      <div className="payment-of-uploaded-offer-popup">
        {/* <img
          onClick={() => closePayment()}
          src={XIconBlack}
          alt="close icon"
          style={{
            height: "40px",
            position: "absolute",
            right: "0px",
            top: "3px",
            cursor: "pointer",
            zIndex: "1",
          }}
        /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="payment-of-uploaded-offer-popup-div">
            <span className="payment-of-uploaded-offer-popup-title">
              {options[language].uploadedOffer}
            </span>
            <img src={successImage} alt="success" />
          </div>
          {isFirstOfferOfUser && (
            <div className="payment-of-uploaded-offer-popup-div">
              <span className="payment-of-uploaded-offer-popup-firstOffer">
                {options[language].firstOfferWord}
              </span>
            </div>
          )}

          {uploadedOffer?.length !== 0 && (
            <Card
              className="cardB"
              sx={{
                width: "45vw",
                position: "relative",
                height: isMobile ? "200px" : "270px",
                maxWidth: 270,
                minWidth: 210,
                borderRadius: "10px",
                marginTop: "25px",
                boxShadow:
                  typeOfReklam === "frame"
                    ? "0 0 0 2px #0D7CBA"
                    : typeOfReklam === "urgent"
                    ? "0 0 0 2px #FF2B00"
                    : "0 4px 4px rgba(0,0,0,.25)",
              }}
            >
              <CardActionArea className="card-action-area">
                <div
                  className="additionalRow"
                  style={{
                    marginTop: isMobile ? "-8px" : "none",
                    marginLeft: isMobile ? "5px" : "none",
                  }}
                >
                  <div className="additionalRowLeft"></div>
                  <div className="additionalRowRight">
                    {selectedTarif === "2" && (
                      <img
                        style={{ width: isMobile ? "20px" : "30px" }}
                        className="crown-on-image"
                        src={CrownIcon}
                        alt="heart red"
                      />
                    )}
                    <img
                      style={{ width: isMobile ? "20px" : "30px" }}
                      src={HeartWhite}
                      alt="heart red"
                    />
                  </div>
                </div>
                {typeOfReklam === "urgent" && (
                  <div className="urgent-offer">
                    {options[language].urgentSmall}
                  </div>
                )}
                <CardMedia
                  component="img"
                  height={isMobile ? 110 : 170}
                  image={
                    uploadedOffer?.image?.path
                      ? uploadedOffer?.image?.path
                      : whiteFon
                  }
                  alt={uploadedOffer.title ? uploadedOffer.title : "elan"}
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = whiteFon;
                  }}
                />
                <CardContent
                  sx={{
                    padding: "10px",
                    height: isMobile ? "90px" : "100px",
                    width: "100%",
                  }}
                >
                  <hr
                    className="horizontal"
                    style={{
                      position: "absolute",
                      top: isMobile ? "103px" : "163px",
                      color: "black",
                    }}
                  />
                  <Typography
                    variant="body3"
                    color="text.secondary"
                    className="typography-price"
                    sx={{
                      fontSize: "18px",
                      position: "absolute",
                      top: isMobile ? "100px" : "160px",
                      color: "white",
                    }}
                  >
                    {uploadedOffer?.price !== null
                      ? uploadedOffer.price.toLocaleString("ru") + " AZN"
                      : options[language].priceName.replace(":", "")}
                  </Typography>
                  <hr
                    className="horizontal"
                    style={{
                      position: "absolute",
                      top: isMobile ? "130px" : "195px",
                      color: "black",
                    }}
                  />

                  <Typography
                    className="city-and-link-data"
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontWeight: 500,
                      width: "calc(100% + 0px)",
                      fontSize: "16px",
                      position: "absolute",
                      bottom: isMobile ? "36px" : "38px",
                      color: "#212529",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "5px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "calc(100% + 0px)",
                      }}
                    >
                      {/* {uploadedOffer?.city !== null || uploadedOffer?.region !== null || uploadedOffer?.residential_area !== null ? <img style={{ width: "16px", height: "16px", marginTop: "-2px" }} src={locationIcon} alt='location' /> : <><img style={{ width: "16px", height: "16px", marginTop: "-2px" }} src={locationIcon} alt='location' /> {options[language].addressSmall}</>} */}
                      {uploadedOffer?.city !== null
                        ? options[language].cities[uploadedOffer?.city].label
                        : ""}
                      {uploadedOffer?.region !== null &&
                      uploadedOffer?.city === null
                        ? options[language].cities[8].label
                        : ""}
                      {uploadedOffer?.region !== null
                        ? ", " +
                          options[language].region_options[
                            uploadedOffer?.region
                          ].label
                        : ""}
                      {uploadedOffer?.residential_area !== null &&
                      uploadedOffer?.region === null
                        ? ", " +
                          options[language].resident_options[
                            uploadedOffer?.residential_area
                          ].region
                        : ""}
                      {uploadedOffer?.residential_area !== null
                        ? ", " +
                          options[language].resident_options[
                            uploadedOffer?.residential_area
                          ].label +
                          options[language].residentsSmall
                        : ""}
                    </div>
                  </Typography>
                  <Typography
                    className="room-area-and-floor-data"
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontWeight: 500,
                      fontSize: "16px",
                      position: "absolute",
                      bottom: isMobile ? "5px" : "1px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "100%",
                      maxWidth: "calc(100% - 20px)",
                      color: "#000",
                    }}
                  >
                    {uploadedOffer?.room &&
                      uploadedOffer?.room + options[language].WithRoomsSmall}
                    {uploadedOffer?.room && uploadedOffer?.area && (
                      <hr className="vertical dot-vertical" />
                    )}
                    {uploadedOffer?.area && (
                      <>
                        {Number(uploadedOffer?.area)
                          .toFixed(2)
                          .replace(".00", "")}
                        {uploadedOffer?.type === "landsales" ||
                        uploadedOffer?.type === "landrents"
                          ? " " + options[language].sot
                          : " " + options[language].m2}
                      </>
                    )}
                    {uploadedOffer?.floor && uploadedOffer?.area && (
                      <hr className="vertical dot-vertical" />
                    )}
                    {uploadedOffer?.floor && (
                      <>
                        {uploadedOffer?.floor}/{uploadedOffer?.max_floor}{" "}
                        {options[language].floorSmall}
                      </>
                    )}
                  </Typography>
                  <hr
                    className="horizontal"
                    style={{
                      position: "absolute",
                      bottom: "42px",
                      color: "black",
                    }}
                  />
                </CardContent>
                <Typography
                  className="city-and-link-data"
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    position: "absolute",
                    top: isMobile ? "0px" : "0px",
                    color: "black",
                    marginLeft: "10px",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: isMobile ? "113px" : "176px",
                      right: "0",
                      color: "#888888",
                    }}
                  >
                    {uploadedOffer?.post_date
                      ? `${
                          new Date(uploadedOffer?.post_date).getDate() ===
                            new Date().getDate() &&
                          new Date(uploadedOffer?.post_date).getMonth() ===
                            new Date().getMonth() &&
                          new Date(uploadedOffer?.post_date).getFullYear() ===
                            new Date().getFullYear()
                            ? `${new Date(uploadedOffer?.post_date)
                                .toLocaleString("en-US", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })
                                .replace("24:", "00:")}`
                            : new Date(uploadedOffer?.post_date).getDate() ===
                                new Date().getDate() - 1 &&
                              new Date(uploadedOffer?.post_date).getMonth() ===
                                new Date().getMonth() &&
                              new Date(
                                uploadedOffer?.post_date
                              ).getFullYear() === new Date().getFullYear()
                            ? `${options[language].yesterdaySmall}`
                            : new Date(uploadedOffer?.post_date)
                                .toLocaleString("ru-Ru", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "2-digit",
                                })
                                .replace(/20\d{2}/, /\d{2}/)
                        }`
                      : "Tarix"}
                  </span>
                  {/* <a style={{ textDecoration: "underline" }} className='evbu ataglength tap'>ebaz.az</a> */}
                  <Typography
                    className="city-and-link-data-url-part"
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      position: "absolute",
                      top: isMobile ? "86px" : `146px`,
                      color: "white",
                      justifyContent: "flex-start",
                      right: "-10px",
                    }}
                  >
                    {changed ? (
                      <a
                        rel="nofollow"
                        href={"https://tap.az" + uploadedOffer?.url}
                        target="_blank"
                        className="offer-url"
                      >
                        {url}
                      </a>
                    ) : (
                      <a
                        rel="nofollow"
                        href={uploadedOffer?.url}
                        className="offer-url"
                        target="_blank"
                      >
                        {url}
                      </a>
                    )}
                    {uploadedOffer?.url === null && (
                      <a
                        rel="nofollow"
                        href={window.location.href}
                        target="_blank"
                        className="offer-url"
                      >
                        ebaz.az
                      </a>
                    )}
                  </Typography>
                </Typography>
              </CardActionArea>
            </Card>
          )}
        </div>
        <div>
          <div className="payment-of-uploaded-offer-popup-card">
            <div className="payment-of-uploaded-offer-popup-payment-part">
              <div className="payment-of-uploaded-offer-popup-div">
                <span className="payment-of-uploaded-offer-popup-title">
                  {options[language].showToMorePeople}
                </span>
              </div>
              <div
                className="payment-of-uploaded-offer-popup-div"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="payment-of-uploaded-offer-popup-select-types">
                  <div
                    onClick={() => setSelectedTarif("1")}
                    className={`payment-of-uploaded-offer-popup-select-type ${
                      selectedTarif === "1" ? "active left" : ""
                    }`}
                  >
                    <img
                      src={selectedTarif === "1" ? BoostIconWhite : BoostIcon}
                      alt="boost"
                    />
                    {options[language].boostNameSmall}
                  </div>
                  <div
                    onClick={() => setSelectedTarif("2")}
                    className={`payment-of-uploaded-offer-popup-select-type ${
                      selectedTarif === "2" ? "active right" : ""
                    }`}
                  >
                    <img src={PremiumIcon} alt="boost" />
                    {options[language].makePremiumNameSmall}
                  </div>
                </div>
                <div className="payment-of-uploaded-offer-popup-select-types2">
                  <div
                    onClick={() => setTypeOfReklam("boost")}
                    className={`payment-of-uploaded-offer-popup-select-type2 ${
                      typeOfReklam === "boost" ? "active boost" : ""
                    }`}
                  >
                    <Top
                      color={typeOfReklam === "boost" ? "#fff" : "#0EB24B"}
                    />
                    {options[language].boostOffer}
                    <div className="frame-additional">
                      <span style={{ color: "#0D7CBA" }}>
                        {selectedTarif === "1"
                          ? options[language].fromPrice1
                          : " + 0.00 AZN"}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => setTypeOfReklam("frame")}
                    className={`payment-of-uploaded-offer-popup-select-type2 ${
                      typeOfReklam === "frame" ? "active frame" : ""
                    }`}
                  >
                    <Frame
                      color={typeOfReklam === "frame" ? "#fff" : "#0D7CBA"}
                    />
                    {options[language].frameOffer}
                    <div className="frame-additional">
                      <span style={{ color: "#0D7CBA" }}>
                        {selectedTarif === "1"
                          ? options[language].fromPrice2
                          : " + 0.99 AZN"}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => setTypeOfReklam("urgent")}
                    className={`payment-of-uploaded-offer-popup-select-type2 ${
                      typeOfReklam === "urgent" ? "active urgent" : ""
                    }`}
                  >
                    <Urgent
                      color={typeOfReklam === "urgent" ? "#fff" : "#FF2B00"}
                    />
                    {options[language].urgentSmall}
                    <div className="frame-additional">
                      <span style={{ color: "#0D7CBA" }}>
                        {selectedTarif === "1"
                          ? options[language].fromPrice3
                          : " + 1.99 AZN"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="payment-types-price">
                  <span
                    style={{
                      fontSize: "20px",
                      color: "#000",
                      fontWeight: "500",
                      marginTop: "15px",
                    }}
                  >
                    {options[language].serviceTime}
                  </span>
                  <div className="horizontal-payment-types"></div>
                  {options[language].packets[
                    selectedTarif === "1" ? typeOfReklam : "premium"
                  ].map((reklamPr, index) => (
                    <>
                      <div
                        className="payment-types-price-day"
                        onClick={() => setSelectedPeriod(reklamPr.dayOrTime)}
                      >
                        <div
                          className="cycle"
                          style={{
                            borderColor:
                              selectedPeriod === reklamPr.dayOrTime
                                ? "#0D7CBA"
                                : "#888888",
                          }}
                        >
                          {selectedPeriod === reklamPr.dayOrTime && (
                            <div className="cycle-selected"></div>
                          )}
                        </div>
                        {selectedTarif === "1" ? (
                          <span>
                            {reklamPr.dayOrTime + options[language].times} /{" "}
                            {reklamPr.price + " AZN"}{" "}
                            {index !== 0 &&
                              typeOfReklam === "boost" &&
                              options[language].boostFor24Hours}
                            {typeOfReklam === "frame" ||
                            typeOfReklam === "urgent"
                              ? index !== 0
                                ? options[language].boostFor24Hours
                                : options[language].boostName
                              : ""}
                          </span>
                        ) : (
                          <span>
                            {reklamPr.dayOrTime + options[language].days} /{" "}
                            {typeOfReklam === "frame"
                              ? (Number(reklamPr.price) + 0.99).toFixed(2) +
                                " AZN"
                              : typeOfReklam === "urgent"
                              ? (Number(reklamPr.price) + 1.99).toFixed(2) +
                                " AZN"
                              : reklamPr.price + " AZN"}
                            {typeOfReklam === "frame" ||
                            typeOfReklam === "urgent" ||
                            typeOfReklam === "boost" ? (
                              <span style={{ color: "#000" }}>
                                {options[language].boostName}
                              </span>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                      </div>
                      <div className="horizontal-payment-types"></div>
                    </>
                  ))}
                  <span
                    style={{
                      fontSize: "20px",
                      color: "#000",
                      fontWeight: "500",
                      marginTop: "15px",
                    }}
                  >
                    {options[language].paymentMethod}
                  </span>
                  <div className="horizontal-payment-types"></div>
                  <div className="payment-types-price-day">
                    <div className="cycle" style={{ borderColor: "#0D7CBA" }}>
                      <div className="cycle-selected"></div>
                    </div>
                    <span>{options[language].bankCard}</span>
                  </div>
                  <div className="horizontal-payment-types"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-buttons" style={{ marginTop: "20px" }}>
            <div
              className="payment-button close"
              onClick={() => closePayment()}
            >
              {options[language].cancelPayment}
            </div>
            <div
              className="payment-button pay"
              onClick={() =>
                selectedTarif === "1"
                  ? boostOffer(uploadedOffer?.id)
                  : premiumOffer(uploadedOffer?.id)
              }
            >
              {loadingPremiumButton ? (
                <CircularProgress size={20} fontSize="small" color="inherit" />
              ) : (
                options[language].makePayment
              )}
            </div>
          </div>
          <span className="payment-last-words">
            {options[language].paymentAgreement}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaymentOfUploadedOffer;
