import React from 'react'
import './Success.css'
import EbazLogo from '../../Components/images/ebazlogo1.png'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { options } from "../../constants"
import { blue } from '@mui/material/colors';
import { useSelector } from 'react-redux';
const Success = () => {
  const language = useSelector(state => state.language)
  React.useLayoutEffect(() => {
    document.title = options[language].paymentSuccessTitle
  }, []);
  
  return (
    <div className='success-page'>
      <div className='success-page-content'>
        <div className='success-page-content-title'>
          <img src={EbazLogo} alt='Ebaz Logo' style={{ width: '80px' }} />
        </div>
        <div className='success-page-content-body'>
          <div className='success-page-content-body-icon'>
            <CheckRoundedIcon sx={{ color: blue[500], fontSize: "120px" }} />
          </div>
        </div>
        <div className='success-page-content-desc'>
          <div className='success-page-content-desc-t'>
            <span>{options[language].successName}</span>
          </div>
          <div className='success-page-content-desc-d'>
            <span>{options[language].successDescription}</span>
            <a href='/profil'>{options[language].successGoToOffers}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Success