import React, { useState } from "react";
import "./Offers.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import whiteFon from "../../images/whitefon.jpg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { options } from "../../constants";
import HeartWhite from "../../images/blurHeart.svg";
import { useEffect } from "react";
import locationIcon from "../../images/locationIcon.svg";
import { white } from "material-ui/styles/colors";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";

const OfferCard = ({ offer }) => {
  const navigate = useNavigate();
  const isMobile = useSelector((state) => state.isMobile);
  const language = useSelector((state) => state.language);
  const [url, setUrl] = useState(offer?.url?.split("/")[2]);
  const [changed, setChanged] = useState(false);
  const offerType = useSelector((state) => state.offerType);
  const [isFavorite, setIsFavorite] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  if (url === "dasinmaz-emlak") {
    setUrl("tap.az");
    setChanged(true);
  }
  useEffect(() => {
    const favoritesData = localStorage.getItem("MyFavourites");
    if (favoritesData) {
      const isObjectInFavorites =
        JSON.parse(favoritesData)?.findIndex(
          (item) => item.id === offer?.id
        ) !== -1;

      setIsFavorite(isObjectInFavorites);
    }
  }, [offer?.id]);
  function addOrRemoveObjectFromLocalStorage(obj) {
    const existingData = localStorage.getItem("MyFavourites");
    if (existingData) {
      const dataArray = JSON.parse(existingData);
      const existingIndex = dataArray.findIndex((item) => item.id === obj.id);
      if (existingIndex !== -1) {
        dataArray.splice(existingIndex, 1);
      } else {
        dataArray.push(obj);
      }
      localStorage.setItem("MyFavourites", JSON.stringify(dataArray));
    } else {
      const newDataArray = [obj];
      localStorage.setItem("MyFavourites", JSON.stringify(newDataArray));
    }
    setIsFavorite(!isFavorite);
  }
  const addInFavorite = () => {
    addOrRemoveObjectFromLocalStorage(offer);
  };
  const getLeftImg = () => {
    if (imgIndex !== 0) {
      let index = imgIndex - 1;
      setImgIndex(index);
    } else {
      setImgIndex(offer?.selectedImage?.length - 1);
    }
  };
  const getRightImg = () => {
    if (imgIndex < offer?.selectedImage?.length - 1) {
      let index = imgIndex + 1;
      setImgIndex(index);
    } else {
      setImgIndex(0);
    }
  };
  return (
    <Card
      sx={{
        width: isMobile ? "100%" : "42vw",
        height: "310px",
        maxWidth: 305,
        marginBottom: "26px",
        marginTop: "20px",
        borderRadius: "15px",
        boxShadow:
          // offer?.boostAds?.length !== 0 || offer?.ads?.length !== 0
          //   ? offer?.boostAds?.[0]?.color === 2 ||
          //     offer?.ads?.[0]?.color === 2 ?
              //  '0 0 0 1.5px #FF2B00'
            //   : offer?.boostAds?.[0]?.color === 1 ||
            //     offer?.ads?.[0]?.color === 1 ?
               '0 0 0 2px #0089CF'
            //   : 'none'
            // : 'none'
      }}
    >
      <CardActionArea className="card-action-area" disableRipple>
        {offer?.selectedImage.length > 1 && (
          <div className="middleRow" style={{ top: "75px" }}>
            <div
              className="middleRow-left"
              onClick={(e) => {
                e.preventDefault();
                getLeftImg();
              }}
            >
              <ArrowBackIosRounded
                className="middleRow-left-icon"
                fontSize="large"
                sx={{ color: white }}
              />
            </div>
            <div
              className="middleRow-right"
              onClick={(e) => {
                e.preventDefault();
                getRightImg();
              }}
            >
              <ArrowForwardIosRounded
                className="middleRow-right-icon"
                fontSize="large"
                sx={{ color: white }}
              />
            </div>
          </div>
        )}
        <CardMedia
          component="img"
          height="200"
          image={
            offer?.selectedImage.length
              ? URL.createObjectURL(offer?.selectedImage[imgIndex])
              : whiteFon
          }
          alt={offer.title ? offer.title : "elan"}
          loading="lazy"
          onLoadedData={whiteFon}
          onError={(e) => {
            e.target.src = whiteFon;
          }}
        />
        <CardContent sx={{ padding: "10px", height: "110px", width: "100%" }}>
          <hr
            className="horizontal"
            style={{ position: "absolute", top: "193px", color: "black" }}
          />
          <Typography
            variant="body3"
            color="text.secondary"
            className="typography-price"
            sx={{
              fontSize: "18px",
              position: "absolute",
              top: `${window.innerWidth >= 550 ? "190px" : "186px"}`,
              marginLeft: "3px",
            }}
          >
            {offer?.price?.toLocaleString("ru")} AZN
          </Typography>
          <hr
            className="horizontal"
            style={{ position: "absolute", top: "227px", color: "black" }}
          />

          <Typography
            className="city-and-link-data"
            variant="body2"
            color="text.secondary"
            sx={{
              fontWeight: 500,
              width: "calc(100% + 0px)",
              fontSize: "16px",
              position: "absolute",
              top: "241px",
              color: "#222222",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "5px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "calc(100% + 0px)",
              }}
            >
              {offer?.city
                ? offer?.city?.label
                  ? offer?.city?.label
                  : options[language].cityName
                : options[language].cityName}
              {offer?.region !== null && offer?.city === null
                ? options[language].cities[8]?.label
                : ""}
              {offer?.region ? `,  ${offer?.region?.label}` : ""}
              {offer?.resident && offer?.region ? ", " + offer?.resident?.label +
                  options[language].residentsSmall : ""}
              {/* {offer?.resident
                ? ", " +
                  offer?.resident?.label +
                  options[language].residentsSmall
                : ""} */}
            </div>
          </Typography>
          <Typography
            className="room-area-and-floor-data"
            variant="body2"
            color="text.secondary"
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              position: "absolute",
              top: "280px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "100%",
              maxWidth: "calc(100% - 20px)",
              color: "#000",
            }}
          >
            {offer?.rooms && offer?.rooms + options[language].WithRoomsSmall}
            {offer?.rooms && offer?.area && (
              <hr className="vertical dot-vertical" />
            )}
            {offer?.area && (
              <>
                {Number(offer?.area).toFixed(2).replace(".00", "")}
                {offer?.type === "landsales" || offer?.type === "landrents"
                  ? " " + options[language].sot
                  : " " + options[language].m2}
              </>
            )}
            {offer?.floor && offer?.area && (
              <hr className="vertical dot-vertical" />
            )}
            {offer?.floor && (
              <>
                {offer?.floor}/{offer?.max_floor} {options[language].floorSmall}
              </>
            )}
          </Typography>

          <hr
            className="horizontal"
            style={{ position: "absolute", top: "265px", color: "black" }}
          />
          <Typography
            className="city-and-link-data"
            variant="body2"
            color="text.secondary"
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              position: "absolute",
              top: "0px",
              color: "black",
              marginLeft: "10px",
            }}
          >
            <span
              style={{
                position: "absolute",
                top: "207px",
                right: "10px",
                color: "#888888",
              }}
            >
              {`${options[language].todaySmall}`}
            </span>
            <Typography
              className="city-and-link-data-url-part"
              variant="body2"
              color="text.secondary"
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                position: "absolute",
                top: `176px`,
                color: "white",
                justifyContent: "flex-start",
                right: "0px",
              }}
            >
              {
                <a
                  href={`https://ebaz.az/elan/${
                    offer?.type ? offer?.type : offerType
                  }/${offer?.id}`}
                  target="_blank"
                  className="offer-url"
                  style={{ textDecoration: "underline" }}
                >
                  ebaz.az
                </a>
              }
            </Typography>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default OfferCard;
