import React from 'react'

const FlatImage = ({ color }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.0867 22.1538H21.5482V2.15385C21.5482 0.972308 20.5759 0 19.3944 0H4.62515C3.44361 0 2.4713 0.972308 2.4713 2.15385V22.1538H0.932843C0.428227 22.1538 0.00976562 22.5723 0.00976562 23.0769C0.00976562 23.5815 0.428227 24 0.932843 24H23.0867C23.5913 24 24.0098 23.5815 24.0098 23.0769C24.0098 22.5723 23.5913 22.1538 23.0867 22.1538ZM10.7802 22.1538V18.1538C10.7802 17.9815 10.9156 17.8462 11.0879 17.8462H12.9505C13.1229 17.8462 13.2582 17.9815 13.2582 18.1538V22.1538H10.7802ZM15.1044 22.1538V18.1538C15.1044 16.9723 14.1321 16 12.9505 16H11.0879C9.90637 16 8.93406 16.9723 8.93406 18.1538V22.1538H4.31746V2.15385C4.31746 1.98154 4.45284 1.84615 4.62515 1.84615H19.3944C19.5667 1.84615 19.7021 1.98154 19.7021 2.15385V22.1538H15.1044Z'
        fill={color}
      />
      <path
        d='M7.19531 5.25342H9.59531'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
      />
      <path
        d='M14.3906 5.25342H16.7906'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
      />
      <path
        d='M14.3906 9.25439H16.7906'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
      />
      <path
        d='M14.3906 13.2524H16.7906'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
      />
      <path
        d='M7.19531 9.25342H9.59531'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
      />
      <path
        d='M7.19531 13.2534H9.59531'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default FlatImage
