import React, { useState } from 'react'
import './Offers.css'
import Select from 'react-select'
import OfferCard from './OfferCard'
import Box from '@mui/material/Box'
import { Empty } from 'antd'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { GridView, Map, Search } from '@mui/icons-material'
import MapComponent from '../Map/Map'
import Offer from '../../Pages/Offer/Offer'
import { options } from "../../constants"
import { useDispatch, useSelector } from 'react-redux'
import {
  changeFilter,
  getPage,
  setShouldRunSecondEffect,
  setShowMobileFilter,
  setShowModal,
  showOrCloseMapF
} from '../../redux/action'
import Loading from '../Loading/Loading'
import LoadingMap from '../Loading/LoadingMap'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { createBrowserHistory } from 'history'
import InitialPageOffers from '../InitialPageOffers/InitialPageOffers'
import SearchRoundedIcon from '../images/searchSvg.svg'
import SettingsIcon from '../images/settingsIcon.svg'
import { getAllVipsAction } from '../../redux/action'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/autoplay'
import { Autoplay } from 'swiper/modules'
import Crown from '../images/Crown.svg'
import Loading2 from '../Loading/Loading2'


const OffersWithPremiumOffers = () => {
  const initialOffersVips = useSelector(state => state.initialOffersVips)
  const history = createBrowserHistory()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const filter = useSelector(state => state.filter)
  const defaultOffersCount = useSelector(state => state.defaultOffersCount)
  const countOfPages = useSelector(state => state.countOfPages)
  const page = useSelector(state => state.page)
  const offersFoundOrNot = useSelector(state => state.offersFoundOrNot)
  const searchTrueOrFalse = useSelector(state => state.search)
  const lastSearchUrl = useSelector(state => state.lastSearchUrl)
  const isMobile = useSelector(state => state.isMobile)
  const handleChange = (event, value) => {
    dispatch(setShouldRunSecondEffect(false))
    dispatch(getPage(value))
  }
  const language = useSelector(state => state.language)
  const showModal = useSelector(state => state.showOrCloseModal)
  const [value, setValue] = useState(0)
  const showMap = useSelector(state => state.showOrCloseMap)
  const offers = useSelector(state => state.defaultOffers)
  const offerType = useSelector(state => state.offerType)
  const defaultOffers = Object.values(offers).map((offer, index) => (
    <OfferCard key={index} offer={offer} />
  ))
  const searchRow = useSelector(state => state.searchRow)
  const closeModal = async e => {
    if (e.target.classList[0] === 'offerdetailsModal') {
      dispatch(setShowModal(false))
      // window.scrollTo({ top: 0, behavior: 'smooth' });
      const path = window.location.pathname.split('/')
      try {
        const element = document.getElementById(path[path.length - 1])
        element.scrollIntoView({ block: 'center', inline: 'nearest' })
      } catch {
        console.log('')
      }
      if (lastSearchUrl !== '/' && lastSearchUrl !== undefined) {
        await navigate('/')
        navigate(`/${lastSearchUrl}`)
      } else {
        navigate('/')
        window.location.reload()
      }
    }
  }
  useEffect(() => {
    if (
      window.location.pathname !== '/' &&
      window.location.pathname !== '/butun-premium-elanlar'
    ) {
      dispatch(setShowModal(true))
    } else {
      dispatch(setShowModal(false))
    }
  }, [window.location.pathname])
  useEffect(() => {
    dispatch(getAllVipsAction())
  }, [])
  const styles = {
    // #0D7CBA !important
    valueContainer: base => ({
      ...base,
      maxHeight: 36,
      overflowY: 'auto',
      borderRadius: '10px'
    }),
    control: (styles, state) => ({
      ...styles,
      maxHeight: 36,
      overflowY: 'auto',
      borderRadius: state.isFocused ? '10px' : '10px',
      cursor: 'pointer',
      boxShadow: 'none',
      border: state.isFocused ? '1px solid #0D7CBA' : '1px solid #CED4DA',
      '&:hover': {
        borderColor: state.isFocused ? '#0D7CBA' : '#CED4DA'
      }
    })
  }
  const showAllVips = () => {
    dispatch(getAllVipsAction())
    navigate('/butun-premium-elanlar')
  }
  return (
    <div
      className='offers'
      style={{
        position: 'relative',
        marginTop: offerType === 'vips' ? '50px' : '0px'
      }}
    >
      {isMobile ? (
        <>
          <div
            className='mobile-offers-content'
            style={{ marginBottom: offerType === 'vips' ? '20px' : '0px' }}
          >
            <div className='mobile-offers-content-top-part'>
              <div
                className='mobile-offers-content-top-left'
                onClick={() => dispatch(setShowMobileFilter(true))}
              >
                <img src={SearchRoundedIcon} alt='search'
                  style={{ height: '20px', width:'20px' }} />
                <span style={{fontSize:'16px', fontFamily:'oxygen'}}>{options[language].searchName} : </span>
                <span style={{fontSize:'14px', fontFamily:'oxygen', color:'#aaa'}}>
                  {offerType === 'get'
                    ? options[language].offersFilterSloqan
                    : searchRow}
                </span>
              </div>
              <div
                className='mobile-offers-content-top-right'
                onClick={() => dispatch(setShowMobileFilter(true))}
              >
                <img
                  src={SettingsIcon}
                  alt='search'
                  style={{ height: '100%', width:'100%' }}
                />
              </div>
            </div>
            {offerType !== 'get' && offerType !== 'vips' && (
              <div className='mobile-offers-content-bottom-part'>
                <span
                  style={{
                    minWidth: '97px',
                    fontSize: '16px',
                    color: '#212529'
                  }}
                >
                  {options[language].offersFound}: {defaultOffersCount}{' '}
                </span>
                <Select
                  styles={styles}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={options[language].filter_options}
                  onChange={e => {
                    dispatch(setShouldRunSecondEffect(false))
                    dispatch(changeFilter(e))
                  }}
                  value={filter}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {offerType !== 'get' && offerType !== 'vips' && (
            <>
              <div className='offers-content'>
                <div className='offers-content-first-row'>
                  <span>
                    {searchRow}
                    <span style={{ marginLeft: '25px' }}>
                      {defaultOffersCount} {defaultOffersCount !== '' && options[language].offersFound}
                    </span>
                  </span>
                  <div className='offers-content-first-row-right-side'>
                    <Box sx={{ width: 140 }}>
                      <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue)
                        }}
                      >
                        <BottomNavigationAction
                          label={options[language].listSmall}
                          icon={<GridView />}
                          onClick={e => {
                            dispatch(setShouldRunSecondEffect(false))
                            dispatch(showOrCloseMapF(false))
                          }}
                        />
                        <BottomNavigationAction
                          label={options[language].mapSmall}
                          icon={<Map />}
                          onClick={e => {
                            dispatch(setShouldRunSecondEffect(false))
                            dispatch(showOrCloseMapF(true))
                          }}
                        />
                      </BottomNavigation>
                    </Box>
                    <Select
                      styles={styles}
                      closeMenuOnSelect={true}
                      isMulti={false}
                      options={options[language].filter_options}
                      onChange={e => {
                        dispatch(setShouldRunSecondEffect(false))
                        dispatch(changeFilter(e))
                      }}
                      value={filter}
                    />
                  </div>
                </div>
              </div>
              {showMap && (
                <div className='offers-content-maps'>
                  <MapComponent />
                </div>
              )}
            </>
          )}
        </>
      )}
      {initialOffersVips?.length !== 0 &&
        offerType !== 'get' &&
        offerType !== 'vips' &&
        !showMap &&
        !searchTrueOrFalse &&
        offersFoundOrNot && (
          <div
            style={{
              width: '100vw',
              maxWidth: '1330px',
              borderRadius: isMobile ? '0px' : '20px',
              height: isMobile ? '265px' : '350px',
              backgroundColor: '#F1F1F1',
              position: 'absolute',
              top: isMobile ? '130px' : '103px',
              zIndex: '-1',
              left: isMobile ? '50vw' : 'calc(50vw - 5px)',
              transform: 'translate(-50%, 0%)'
            }}
          ></div>
        )}
      {initialOffersVips?.length !== 0 &&
        offerType !== 'get' &&
        offerType !== 'vips' &&
        !showMap &&
        !searchTrueOrFalse &&
        offersFoundOrNot && (
          <div style={{ width: '95%', maxWidth: '1300px' }}>
            <div style={{ width: '100%' }}>
              <div className='InitialPageOffers-content-row InitialPageOffers-content-vip'>
                <div
                  className='InitialPageOffers-content-row-title'
                  style={{
                    marginBottom: isMobile ? '0px' : '10px',
                    marginTop: isMobile ? '0px' : '-20px'
                  }}
                >
                  <span
                    style={{
                      display: 'flex',
                      gap: '7px'
                    }}
                  >
                    <img style={{width: isMobile ? "25px" : "30px"}} src={Crown} alt='crown' />
                    {options[language].premiumOffers}
                  </span>
                  <span onClick={() => showAllVips()}>{options[language].showAll}</span>
                </div>
                <Swiper
                  style={{
                    zIndex: '0',
                    width: '100vw',
                    marginLeft: isMobile
                      ? '0'
                      : window.innerWidth >= 1400
                      ? '7px'
                      : '0'
                  }}
                  modules={[Autoplay]}
                  speed={2000}
                  autoplay={{
                    delay: 1500,
                    pauseOnMouseEnter: true
                  }}
                  loop={true}
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 10
                    },
                    480: {
                      slidesPerView: 2,
                      spaceBetween: 10
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20
                    },
                    840: {
                      slidesPerView: 3,
                      spaceBetween: 20
                    },
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 20
                    },
                    1220: {
                      slidesPerView: 4,
                      spaceBetween: 31
                    }
                  }}
                  scrollbar={{ draggable: true }}
                >
                  {initialOffersVips?.map((offer, index) => (
                    <SwiperSlide
                    style={{
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      paddingLeft: isMobile ? '5px' : '9px',
                      paddingRight: isMobile ? '5px' : '11px',
                      width: '42vw',
                      maxWidth: '305px'
                    }}
                      key={index}
                    >
                      <OfferCard key={index} offer={offer} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        )}
      <div
        className='offers-content-cards'
        style={{ paddingTop: offerType === 'get' ? '50px' : ' 0px' }}
      >
        {searchTrueOrFalse ? (
          showMap ? (
            <LoadingMap />
          ) : (
            <span
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                height: 'calc(100vh - 100px)'
              }}
            >
              <Loading2 />
            </span>
          )
        ) : offersFoundOrNot ? (
          offerType === 'get' ? (
            <div className='offers-content-cardsNew'>{defaultOffers}</div>
          ) : (
            <div className='offers-content-cardsNew'>{defaultOffers}</div>
          )
        ) : (
          <span
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              margin: '10px 0'
            }}
          >
            <Empty
              description={options[language].notFoundOffers}
              fontSize='40px'
            />
          </span>
        )}
      </div>
      {offerType !== 'get' && offerType !== 'vips' ? (
        offersFoundOrNot ? (
          !showMap ? (
            <Stack spacing={2} style={{ marginTop: '20px' }}>
              <Pagination
                page={page}
                count={countOfPages}
                variant='outlined'
                shape='rounded'
                color='primary'
                onChange={handleChange}
              />
            </Stack>
          ) : (
            <></>
          )
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {showModal && (
        <div className='offerdetailsModal' onClick={e => closeModal(e)}>
          <Offer />
        </div>
      )}
    </div>
  )
}

export default OffersWithPremiumOffers
