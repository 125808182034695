export const options = {
  az: {
    allFavouriteOffersTitle: "Ebaz.az - Bütün seçilmiş elanlar",
    allPremiumOffersTitle: "Ebaz.az - Bütün premium elanlar",
    loginTitle: "Ebaz.az - Daxil ol",
    registrationTitle: "Ebaz.az - Qeydiyyat",
    myOffersTitle: "Ebaz.az - Profil",
    newOffersTitle: "Ebaz.az - Yeni elan",
    notFoundTitle: "Ebaz.az - Xəta 404",
    paymentErrorTitle: "Ebaz.az - Uğursuz əməliyyat!",
    paymentSuccessTitle: "Ebaz.az - Uğurlu əməliyyat!",
    newOfferTitle: "Ebaz.az - Elanı yenilə",
    findOffer: "Elanı tap",
    favourites: "Seçilmişlər",
    profile: "Profil",
    signIn: "Giriş",
    uploadOffer: "Elan yerləşdir",
    sale: "Alış",
    rent: "Kirayə",
    day: "Günlük",
    clean: "Təmizlə",
    add: "Əlavə et",
    cityName: "Şəhər",
    flatTypeName: "Əmlakın növü",
    roomsCountName: "Otaq sayı",
    priceName: "Qiymət:",
    pricePerM2Name: "Kvadratmetr qiyməti:  AZN/m2",
    pricePerSotName: "Sot qiyməti:  AZN/sot",
    min: "min.",
    max: "maks.",
    areaName: "Sahə",
    sot: "sot",
    m2: "m²",
    regionName: "Rayon",
    residentName: "Qəsəbə",
    stationName: "Metro",
    targetName: "Nişangah",
    floorName: "Mərtəbə:",
    siteName: "Saytlar",
    siteNameEnter: "Elan saytlarını seçin",
    withGoodsName: "Əşyalı",
    withoutGoodsName: "Əşyasız",
    repairName: "Təmirli",
    withoutRepairName: "Təmirsiz",
    withDocumentsName: "Sənədli",
    withoutDocumentsName: "Sənədsiz",
    mortgageName: "İpoteka",
    showOffersName: "Elan göstər",
    searchName: "Axtar",
    historyName: "Əvvəlki axtarışlar",
    additionalsearchName: "Ətraflı axtarış",
    lastFiveSearchName: "Son 5 axtarış",
    allFloorNumberName: "Ümumi mərtəbə sayı",
    sale2: "Satış",
    clean2: "Sıfırla",
    sloqan: "Bütün əmlaklar bir ünvanda!",
    usersAgree: "İstifadəçi razılaşması",
    privicy: "Məxfilik siyasəti",
    services: "Xidmətlər",
    connect: "Əlaqə",
    analytics: "Analitika",
    predicted: "Gözlənilən",
    predictedHigh: "artım",
    predictedLow: "azalma",
    disclaimer:
      "*Saytın rəhbərliyi reklam bannerlərinin və yerləşdirilmiş elanların məzmununa görə məsuliyyət daşımır.",
    enterAddress: "Ünvanı qeyd edin",
    showAll: "Hamısını göstər",
    premiumOffers: "Premium Elanlar",
    saleFlats: "Alqı-satqı mənzillər",
    rentFlats: "Kirayə mənzillər",
    saleCountry: "Alqı-satqı həyat evi/bağ/villa",
    rentCountry: "Kirayə həyat evi/bağ/villa",
    saleOffice: "Alqı-satqı ofis",
    rentOffice: "Kirayə ofis",
    saleGarage: "Alqı-satqı qaraj",
    rentGarage: "Kirayə qaraj",
    login: "Daxil ol",
    saleLand: "Alqı-satqı torpaq",
    rentLand: "Kirayə torpaq",
    saleCommercial: "Alqı-satqı obyekt",
    rentCommercial: "Kirayə obyekt",
    searchOffersLoading: "Elan Axtarılır",
    logout: "Çıxış",
    offersCommon: "Elanlar",
    myOffersName: "Elanlarim",
    myOffersActiveName: "Aktiv",
    myOffersDeactiveName: "Deaktiv olundu",
    myOffersReductName: "Redaktə et",
    myOffersDeleteFromOffersName: "Elanlardan çıxart",
    myOffersRefreshOfferName: "Yenidən paylaş",
    myOffersDontHaveOffersName: "Sizin elanınız yoxdur.",
    myOffersDontHaveActiveOffersName: "Sizin aktiv elanınız yoxdur.",
    myOffersDontHaveDeactiveOffersName: "Sizin deaktiv elanınız yoxdur.",
    myOffersNameSurnameChangeName: "Ad Soyad Dəyişmək",
    myOffersNameChangeName: "Ad",
    myOffersLastnameChangeName: "Soyad",
    myOffersNameChangeRequareName: "*Zəhmət olmasa adınızı qeyd edin",
    myOffersLastnameChangeRequareName: "*Zəhmət olmasa soyadınızı qeyd edin",
    myOffersSetNameChangeName: "Adınızı qeyd edin",
    myOffersSetLastnameChangeName: "Soyadınızı qeyd edin",
    myOffersNameLastnameChangedSuccessfullyName: "Uğurla yeniləndi",
    myOffersNameLastnameChangeButtonName: "Dəyiş",
    residentsSmall: " qəs.",
    WithRoomsSmall: " otaqlı ",
    floorSmall: "mərtəbə",
    todaySmall: "Bugün",
    yesterdaySmall: "Dünən",
    searchSmall: "Axtarış",
    newOfferSmall: "Yeni elan",
    cabinetSmall: "Kabinet",
    moreSmall: "Daha çox",
    menuSmall: "Menyu",
    languageSmall: "Dil",
    languageBig: "Azərbaycan",
    languageAZ: "Azərbaycan",
    languageRU: "Русский",
    languageEN: "English",
    yesSmall: "Bəli",
    noSmall: "Xeyir",
    aboutOffer: "Əmlak haqqında",
    urgentSmall: "Təcili",
    offersFilterSloqan: "Şəhər, Əmlak, Qiymət, Sahə, Otaq...",
    offersFound: "Elan",
    listSmall: "Siyahı",
    mapSmall: "Xəritə",
    mapSmallShow: "Xəritəni göstər",
    mapSmallDontShow: "Xəritəni gizlət",
    notFoundOffers: "Təəssüf ki, axtarışa uyğun elan tapılmadı.",
    writeLinkForSearch: "Axtarmaq istdiyiniz elanın linkini yazın",
    supportedSitesSearch:
      "Dəstəklənən saytlar: tap.az, bina.az, lalafo.az, arenda.az, emlak.az, yeniemlak.az, rahatemlak.az, ucuztap.az",
    showOfferSearch: "Elanı göstər",
    favouriteOffersCount: "Seçilmiş elanlar",
    notFavouritesOffers: "Seçilmiş elan yoxdur.",
    seeAllOffers: "Bütün elanlara bax",
    phoneNumberName: "Mobil nömrə",
    writePhoneNumber: "*Zəhmət olmasa nömrənizi qeyd edin",
    sendSMStoPhone: "Sizin nömrənizə SMS-kod göndərildi",
    sendRepeatSMStoPhone: "SMS-kod yenidən göndərilsin",
    pleaseWriteSMSCode: "*Zəhmət olmasa SMS-kodu qeyd edin",
    pleaseWriteSMSCodeCorrect: "*Zəhmət olmasa SMS-kodu düzgün qeyd edin",
    saveUser: "Yadda saxla",
    userNotFound: "*İstifadəçi tapılmadı",
    loginWithBigI: "DAXİL OL",
    notProfileRegistration: "Hesabınız yoxdur? Qeydiyyatdan keç",
    profileExistLogin: "Hesabınız var? Daxil ol",
    registration: "Qeydiyyatdan",
    setEmail: "Email Adres",
    userAlreadyExist: "*Bu istifadəçi artıq qeydiyyatdan kecib",
    doRegistration: "Qeydİyyatdan keç",
    newOffer_flatType: "Əmlakın növü, ",
    newOffer_offerType: "Elanın tipi, ",
    newOffer_rentPeriod: "Kirayə müddəti, ",
    newOffer_AreaM2: "Sahə m², ",
    newOffer_AreaSot: "Sahə sot, ",
    newOffer_City: "Şəhər, ",
    newOffer_Price: "Qiymət, ",
    newOffer_Desc: "Məzmun, ",
    newOffer_Images: "Şəkillər (minimum 1 şəkil, maksimum 10 şəkil), ",
    newOffer_Name: "Ad, ",
    newOffer_Email: "E-mail, ",
    newOffer_PhoneNum: "Mobil nömrəni düzgün qeyd edin, ",
    videoSmall: "Video",
    addVideoSmall: "+Video əlavə et",
    watchVideo:"Video izlə",
    imagesSmall: "Şəkillər",
    addImagesSmall: "+Şəkil əlavə et",
    addImagesMaxCountSmall: "*Maksimum 10 şəkil",
    writeInfoInThisFields: "bu xanalarda məlumat yazın.",
    sendOffer: "Elanı göndər",
    simpleRules: "Ebaz.az-ın sadə qaydaları",
    simpleRules1: "Eyni elanı bir neçə dəfə təqdim etməyin.",
    simpleRules2:
      "Təsvir və ya şəkillərdə telefon, email və ya sayt ünvanı göstərməyin.",
    simpleRules3: "Ad yerində qiymət yazmayın - bunun üçün ayrıca yer var.",
    simpleRules4: "İlk elanınızı yerləşdirin və 2 gün ərzində bizdən \"Çərçivələmə\" və \"İrəli çəkmə\" qazanın.",
    offerView: "Elanın görünüşü",
    pageNotFoundSmall: "Səhifə tapılmadı",
    mainPageSmall: "Ana səhifə.",
    boostNameSmall: "Fərqləndir",
    makePremiumNameSmall: "Premium Et",
    deleteFromFavouritesNameSmall: "Seçilmişlərdən çıxart",
    positionOfOffer: "Yerləşmə yeri",
    addToFavouritesNameSmall: "Seçilmişlərdə saxla",
    priceByAgreement: "Razılaşma yolu ilə",
    linkCopied: "Link kopyalandı",
    similarOffers: "Bənzər elanlar",
    boostOffer: "İrəli çək",
    frameOffer: "Çərçivələ",
    fromPrice1: "0.49 AZN-dən",
    fromPrice2: "0.99 AZN-dən",
    fromPrice3: "1.99 AZN-dən",
    boostOfferDescription:
      "Elan, bütün elanların içində birinci yerə qalxacaq.",
    frameOfferDescription:
      "Elan göy çərçəvə ilə fərqlənəcək və bütün elanların içində birinci yerə qalxacaq.",
    urgentOfferDescription:
      "Elan qırmızı çərçəvə və “Təcili” etiketi ilə birbaşa fərqlənəcək və bütün elanların içində birinci yerə qalxacaq.",
    paidUntill: "Tarixinə qədər ödənilib.",
    serviceTime: "Xidmət müddəti",
    times: " dəfə",
    boostFor24Hours: "(Hər 24 saatda irəli çək)",
    boostName: "(+ İrəli çək)",
    paymentMethod: "Ödəniş üsulu",
    bankCard: "Bank kartı",
    addressSmall: "Ünvan",
    cancelPayment: "İmtina et",
    makePayment: "Ödəniş et",
    paymentAgreement:
      '"Ödəniş et" düyməsini sıxmaqla siz ebaz.az-ın İstifadəçi razılaşmasını və Ofertanı qəbul etmiş olursunuz.',
    makeOfferPremiumName: "Elanı Premium et",
    makePremiumDescription:
      "Elan, xidmət müddəti boyunca əsas səhifədə premium elanlar bölməsində göstəriləcək və pulsuz irəli çəkiləcək.",
    days: " gün",
    perDays: "/gün",
    errorName: "Xəta",
    errorDescription:
      "Ödənişinizdə xəta yarandı, zəhmət olmasa yenidən cəhd edin.",
    errorDescription2: `Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.`,
    successName: "Uğurlu Ödəniş",
    successDescription:
      "Ödənişiniz uğurla qeydə alındı, tez bir zamanda elanınız yenilənəcək.",
    successName2: "Uğurlu",
    successDescription2UpdateOffer: "Elan uğurla yeniləndi.",
    successDescription2DeleteOffer: "Elan silindi.",
    successDescription2RefreshOffer: "Elan uğurla yenidən paylaşıldı.",
    successDescription2RegistrationOffer: "Siz uğurla qeydiyyatdan keçdiniz.",
    successGoToOffers: "Elanlarınıza keçid edin.",
    updateOfferName: "Elanı yeniləmək",
    allRightsReserved: "Bütün hüquqlar qorunur.",
    siteMap: "Saytın xəritəsi",
    notUploadedOffer:"Elan yuklənməmişdir.",
    uploadedOffer:"Elanınız paylaşıldı!",
    showToMorePeople:"Elanınızı daha çox insan görsün!",
    miniBannerWords:["İlk elanınızı yerləşdirin və 2 gün ərzində bizdən \"Çərçivələmə\" və \"İrəli çəkmə\" qazanın."],
    firstOfferWord:"İlk elanınız olduğu üçün bizdən sizə 2 gün ərzində \"Çərçivələmə\" və \"İrəli çəkmə\" hədiyyə olundu!",
    saleDeleted:"Satışdan çıxarılıb",
    rentDeleted:"Kirayədən çıxarılıb",
    cities: [
      {
        value: "0",
        label: "Ağcabədi",
        center: { lat: 40.053, lng: 47.4554 },
        zoom: "10",
      },
      {
        value: "1",
        label: "Ağdam",
        center: { lat: 39.9911, lng: 46.9297 },
        zoom: "10",
      },
      {
        value: "2",
        label: "Ağdaş",
        center: { lat: 40.6335, lng: 47.4674 },
        zoom: "10",
      },
      {
        value: "3",
        label: "Ağdərə",
        center: { lat: 40.2116, lng: 46.8237 },
        zoom: "10",
      },
      {
        value: "4",
        label: "Ağstafa",
        center: { lat: 41.1125, lng: 45.4477 },
        zoom: "10",
      },
      {
        value: "5",
        label: "Ağsu",
        center: { lat: 40.5283, lng: 48.3651 },
        zoom: "10",
      },
      {
        value: "6",
        label: "Astara",
        center: { lat: 38.4688, lng: 48.8728 },
        zoom: "10",
      },
      {
        value: "7",
        label: "Babək",
        center: { lat: 39.1508, lng: 45.4485 },
        zoom: "10",
      },
      {
        value: "8",
        label: "Bakı",
        center: { lat: 40.3953, lng: 49.8822 },
        zoom: "10",
      },
      {
        value: "9",
        label: "Balakən",
        center: { lat: 41.7038, lng: 46.4044 },
        zoom: "10",
      },
      {
        value: "10",
        label: "Beyləqan",
        center: { lat: 39.7723, lng: 47.6154 },
        zoom: "10",
      },
      {
        value: "11",
        label: "Bərdə",
        center: { lat: 40.3707, lng: 47.1379 },
        zoom: "10",
      },
      {
        value: "12",
        label: "Biləsuvar",
        center: { lat: 39.4599, lng: 48.551 },
        zoom: "10",
      },
      {
        value: "13",
        label: "Cəbrayıl",
        center: { lat: 39.3987, lng: 47.0245 },
        zoom: "10",
      },
      {
        value: "14",
        label: "Cəlilabad",
        center: { lat: 39.2052, lng: 48.5101 },
        zoom: "10",
      },
      {
        value: "15",
        label: "Culfa",
        center: { lat: 38.9605, lng: 45.6293 },
        zoom: "10",
      },
      {
        value: "16",
        label: "Daşkəsən",
        center: { lat: 40.5202, lng: 46.0779 },
        zoom: "10",
      },
      {
        value: "17",
        label: "Dəliməmmədli",
        center: { lat: 40.6844, lng: 46.5728 },
        zoom: "10",
      },
      {
        value: "18",
        label: "Füzuli",
        center: { lat: 39.6025, lng: 47.1392 },
        zoom: "10",
      },
      {
        value: "19",
        label: "Gədəbəy",
        center: { lat: 40.57, lng: 45.8107 },
        zoom: "10",
      },
      {
        value: "20",
        label: "Gəncə",
        center: { lat: 40.6579, lng: 46.3523 },
        zoom: "10",
      },
      {
        value: "21",
        label: "Goranboy",
        center: { lat: 40.6073, lng: 46.7807 },
        zoom: "10",
      },
      {
        value: "22",
        label: "Göyçay",
        center: { lat: 40.6236, lng: 47.7403 },
        zoom: "10",
      },
      {
        value: "23",
        label: "Göygöl",
        center: { lat: 40.5895, lng: 46.3271 },
        zoom: "10",
      },
      {
        value: "24",
        label: "Göytəpə",
        center: { lat: 39.1171, lng: 48.5844 },
        zoom: "10",
      },
      {
        value: "25",
        label: "Hacıqabul",
        center: { lat: 40.0394, lng: 48.9203 },
        zoom: "10",
      },
      {
        value: "26",
        label: "Horadiz",
        center: { lat: 39.4511, lng: 47.3399 },
        zoom: "10",
      },
      {
        value: "27",
        label: "Xaçmaz",
        center: { lat: 41.4591, lng: 48.8021 },
        zoom: "10",
      },
      {
        value: "28",
        label: "Xankəndi",
        center: { lat: 39.8265, lng: 46.7656 },
        zoom: "10",
      },
      {
        value: "29",
        label: "Xocalı",
        center: { lat: 39.9133, lng: 46.7943 },
        zoom: "10",
      },
      {
        value: "30",
        label: "Xocavənd",
        center: { lat: 39.7915, lng: 47.1101 },
        zoom: "10",
      },
      {
        value: "31",
        label: "Xızı",
        center: { lat: 40.9109, lng: 49.0729 },
        zoom: "10",
      },
      {
        value: "32",
        label: "Xırdalan",
        center: { lat: 40.4529, lng: 49.7333 },
        zoom: "10",
      },
      {
        value: "33",
        label: "Xudat",
        center: { lat: 41.6253, lng: 48.6764 },
        zoom: "10",
      },
      {
        value: "34",
        label: "İmişli",
        center: { lat: 39.8695, lng: 48.0665 },
        zoom: "10",
      },
      {
        value: "35",
        label: "İsmayıllı",
        center: { lat: 40.7871, lng: 48.1523 },
        zoom: "10",
      },
      {
        value: "36",
        label: "Kəlbəcər",
        center: { lat: 40.1024, lng: 46.0365 },
        zoom: "10",
      },
      {
        value: "37",
        label: "Kürdəmir",
        center: { lat: 40.3699, lng: 48.1645 },
        zoom: "10",
      },
      {
        value: "38",
        label: "Kəngərli",
        center: { lat: 39.3872, lng: 45.164 },
        zoom: "10",
      },
      {
        value: "39",
        label: "Qax",
        center: { lat: 41.4207, lng: 46.932 },
        zoom: "10",
      },
      {
        value: "40",
        label: "Qazax",
        center: { lat: 41.0971, lng: 45.3516 },
        zoom: "10",
      },
      {
        value: "41",
        label: "Qəbələ",
        center: { lat: 40.9982, lng: 47.87 },
        zoom: "10",
      },
      {
        value: "42",
        label: "Qobustan",
        center: { lat: 40.5326, lng: 48.9274 },
        zoom: "10",
      },
      {
        value: "43",
        label: "Qovlar",
        center: { lat: 40.9364, lng: 45.7475 },
        zoom: "10",
      },
      {
        value: "44",
        label: "Quba",
        center: { lat: 41.3643, lng: 48.5261 },
        zoom: "10",
      },
      {
        value: "45",
        label: "Qubadlı",
        center: { lat: 39.3453, lng: 46.5789 },
        zoom: "10",
      },
      {
        value: "46",
        label: "Qusar",
        center: { lat: 41.4275, lng: 48.4302 },
        zoom: "10",
      },
      {
        value: "47",
        label: "Laçın",
        center: { lat: 39.6383, lng: 46.5461 },
        zoom: "10",
      },
      {
        value: "48",
        label: "Lerik",
        center: { lat: 38.7736, lng: 48.4151 },
        zoom: "10",
      },
      {
        value: "49",
        label: "Lənkəran",
        center: { lat: 38.7529, lng: 48.8475 },
        zoom: "10",
      },
      {
        value: "50",
        label: "Liman",
        center: { lat: 41.312, lng: 72.9278 },
        zoom: "10",
      },
      {
        value: "51",
        label: "Masallı",
        center: { lat: 39.0341, lng: 48.6589 },
        zoom: "10",
      },
      {
        value: "52",
        label: "Mingəçevir",
        center: { lat: 40.7703, lng: 47.0496 },
        zoom: "10",
      },
      {
        value: "53",
        label: "Naftalan",
        center: { lat: 40.5076, lng: 46.824 },
        zoom: "10",
      },
      {
        value: "54",
        label: "Naxçıvan",
        center: { lat: 39.2009, lng: 45.4082 },
        zoom: "10",
      },
      {
        value: "55",
        label: "Neftçala",
        center: { lat: 39.3881, lng: 49.2414 },
        zoom: "10",
      },
      {
        value: "56",
        label: "Oğuz",
        center: { lat: 41.0728, lng: 47.4651 },
        zoom: "10",
      },
      {
        value: "57",
        label: "Ordubad",
        center: { lat: 38.9022, lng: 46.0238 },
        zoom: "10",
      },
      {
        value: "58",
        label: "Saatlı",
        center: { lat: 39.9096, lng: 48.3595 },
        zoom: "10",
      },
      {
        value: "59",
        label: "Sabirabad",
        center: { lat: 39.9871, lng: 48.4693 },
        zoom: "10",
      },
      {
        value: "60",
        label: "Salyan",
        center: { lat: 39.5779, lng: 48.9719 },
        zoom: "10",
      },
      {
        value: "61",
        label: "Samux",
        center: { lat: 40.7605, lng: 46.4063 },
        zoom: "10",
      },
      {
        value: "62",
        label: "Sədərək",
        center: { lat: 39.7105, lng: 44.8864 },
        zoom: "10",
      },
      {
        value: "63",
        label: "Siyəzən",
        center: { lat: 41.0784, lng: 49.1118 },
        zoom: "10",
      },
      {
        value: "64",
        label: "Sumqayıt",
        center: { lat: 40.5855, lng: 49.6317 },
        zoom: "10",
      },
      {
        value: "65",
        label: "Şabran",
        center: { lat: 41.216, lng: 48.9946 },
        zoom: "10",
      },
      {
        value: "66",
        label: "Şahbuz",
        center: { lat: 39.4056, lng: 45.5691 },
        zoom: "10",
      },
      {
        value: "67",
        label: "Şamaxı",
        center: { lat: 40.6319, lng: 48.6364 },
        zoom: "10",
      },
      {
        value: "68",
        label: "Şəki",
        center: { lat: 41.1975, lng: 47.1571 },
        zoom: "10",
      },
      {
        value: "69",
        label: "Şəmkir",
        center: { lat: 40.8288, lng: 46.0167 },
        zoom: "10",
      },
      {
        value: "70",
        label: "Şərur",
        center: { lat: 39.5536, lng: 44.9846 },
        zoom: "10",
      },
      {
        value: "71",
        label: "Şirvan",
        center: { lat: 39.9266, lng: 48.9206 },
        zoom: "10",
      },
      {
        value: "72",
        label: "Şuşa",
        center: { lat: 39.7537, lng: 46.7465 },
        zoom: "10",
      },
      {
        value: "73",
        label: "Tərtər",
        center: { lat: 40.3444, lng: 46.9377 },
        zoom: "10",
      },
      {
        value: "74",
        label: "Tovuz",
        center: { lat: 40.9955, lng: 45.6166 },
        zoom: "10",
      },
      {
        value: "75",
        label: "Ucar",
        center: { lat: 40.5068, lng: 47.649 },
        zoom: "10",
      },
      {
        value: "76",
        label: "Yardımlı",
        center: { lat: 38.9059, lng: 48.2496 },
        zoom: "10",
      },
      {
        value: "77",
        label: "Yevlax",
        center: { lat: 40.6197, lng: 47.15 },
        zoom: "10",
      },
      {
        value: "78",
        label: "Zaqatala",
        center: { lat: 41.6031, lng: 46.6383 },
        zoom: "10",
      },
      {
        value: "79",
        label: "Zəngilan",
        center: { lat: 39.0857, lng: 46.6525 },
        zoom: "10",
      },
      {
        value: "80",
        label: "Zərdab",
        center: { lat: 40.2148, lng: 47.7149 },
        zoom: "10",
      },
    ],
    region_options: [
      { value: "0", label: "Abşeron" },
      { value: "1", label: "Binəqədi" },
      { value: "2", label: "Nərimanov" },
      { value: "3", label: "Nəsimi" },
      { value: "4", label: "Nizami" },
      { value: "5", label: "Qaradağ" },
      { value: "6", label: "Sabunçu" },
      { value: "7", label: "Səbail" },
      { value: "8", label: "Suraxanı" },
      { value: "9", label: "Xətai" },
      { value: "10", label: "Xəzər" },
      { value: "11", label: "Yasamal" },
      { value: "12", label: "Pirallahı" },
    ],
    site_options: [
      { value: "ebaz.az", label: "ebaz.az" },
      { value: "tap.az", label: "tap.az" },
      { value: "bina.az", label: "bina.az" },
      { value: "lalafo.az", label: "lalafo.az" },
      { value: "emlak.az", label: "emlak.az" },
      { value: "arenda.az", label: "arenda.az" },
      { value: "yeniemlak.az", label: "yeniemlak.az" },
      { value: "rahatemlak.az", label: "rahatemlak.az" },
      { value: "ucuztap.az", label: "ucuztap.az" },
    ],
    room_options: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4 " },
      { value: "50", label: "5 +" },
    ],
    station_options: [
      { label: "İçərişəhər m.", value: "1" },
      { label: "Sahil m.", value: "2" },
      { label: "28 May m.", value: "3" },
      { label: "Gənclik m.", value: "4" },
      { label: "Nəriman Nərimanov m.", value: "5" },
      { label: "Bakmil m.", value: "6" },
      { label: "Ulduz m.", value: "7" },
      { label: "Koroğlu m.", value: "8" },
      { label: "Qara Qarayev m.", value: "9" },
      { label: "Neftçilər m.", value: "10" },
      { label: "Xalqlar Dostluğu m.", value: "11" },
      { label: "Əhmədli m.", value: "12" },
      { label: "Həzi Aslanov m.", value: "13" },
      { label: "Nizami Gəncəvi m.", value: "14" },
      { label: "Elmlər Akademiyası m.", value: "15" },
      { label: "İnşaatçılar m.", value: "16" },
      { label: "20 Yanvar m.", value: "17" },
      { label: "Memar Əcəmi m.", value: "18" },
      { label: "Nəsimi m.", value: "19" },
      { label: "Azadlıq prospekti m.", value: "20" },
      { label: "Dərnəgül m.", value: "21" },
      { label: "Cəfər Cabbarlı m.", value: "22" },
      { label: "Şah İsmayıl Xətai m.", value: "23" },
      { label: "Avtovağzal m.", value: "24" },
      { label: "8 Noyabr m.", value: "25" },
      { label: "Xocəsən m.", value: "26" },
    ],
    resident_options: [
      { value: "1", label: "1-ci mikrorayon", region: "Nəsimi" },
      { value: "2", label: "2-ci mikrorayon", region: "Nəsimi" },
      { value: "3", label: "20-ci sahə", region: "Səbail" },
      { value: "4", label: "28 may", region: "Binəqədi" },
      { value: "5", label: "3-cü mikrorayon", region: "Nəsimi" },
      { value: "6", label: "4-cü mikrorayon", region: "Nəsimi" },
      { value: "7", label: "5-ci mikrorayon", region: "Nəsimi" },
      { value: "8", label: "6-ci mikrorayon", region: "Binəqədi" },
      { value: "9", label: "6-cı parallel", region: "Binəqədi" },
      { value: "10", label: "7-ci mikrorayon", region: "Binəqədi" },
      { value: "11", label: "8 km", region: "Nizami" },
      { value: "12", label: "8-ci mikrorayon", region: "Binəqədi" },
      { value: "13", label: "9-cu mikrorayon", region: "Binəqədi" },
      { value: "14", label: "Ağ şəhər", region: "Xətai" },
      { value: "15", label: "Alatava 1", region: "Binəqədi" },
      { value: "16", label: "Alatava 2", region: "Binəqədi" },
      { value: "17", label: "Albalı", region: "Sabunçu" },
      { value: "18", label: "Atyalı", region: "Abşeron" },
      { value: "19", label: "Badamdar", region: "Səbail" },
      { value: "20", label: "Bahar", region: "Suraxanı" },
      { value: "21", label: "Bakıxanov", region: "Sabunçu" },
      { value: "22", label: "Balaxanı", region: "Sabunçu" },
      { value: "23", label: "Bayıl", region: "Səbail" },
      { value: "24", label: "Bibiheybət", region: "Səbail" },
      { value: "25", label: "Bilgəh", region: "Sabunçu" },
      { value: "26", label: "Biləcəri", region: "Binəqədi" },
      { value: "27", label: "Binə", region: "Xəzər" },
      { value: "28", label: "Binəqədi", region: "Binəqədi" },
      { value: "29", label: "Böyükşor", region: "Nərimanov" },
      { value: "30", label: "Bülbülə", region: "Suraxanı" },
      { value: "31", label: "Buzovna", region: "Xəzər" },
      { value: "32", label: "Çermet", region: "" },
      { value: "33", label: "Ceyranbatan", region: "Abşeron" },
      { value: "34", label: "Çiçək", region: "Abşeron" },
      { value: "35", label: "Corat", region: "" },
      { value: "36", label: "Digah", region: "Abşeron" },
      { value: "37", label: "Dübəndi bağları", region: "Xəzər" },
      { value: "38", label: "Dədə Qorqud", region: "Suraxanı" },
      { value: "39", label: "Fatmayı", region: "Abşeron" },
      { value: "40", label: "Görədil", region: "Abşeron" },
      { value: "41", label: "Gürgən", region: "Xəzər" },
      { value: "42", label: "Güzdək", region: "Abşeron" },
      { value: "43", label: "Hökməli", region: "Abşeron" },
      { value: "44", label: "Hövsan", region: "Suraxanı" },
      { value: "45", label: "Həzi Aslanov", region: "Xətai" },
      { value: "46", label: "Keşlə", region: "Nizami" },
      { value: "47", label: "Kimya şəhərciyi", region: "Nəsimi" },
      { value: "48", label: "Köhnə Günəşli", region: "Xətai" },
      { value: "49", label: "Kürdəxanı", region: "Sabunçu" },
      { value: "50", label: "Lökbatan", region: "Qaradağ" },
      { value: "51", label: "Ləhic Bağları", region: "Sabunçu" },
      { value: "52", label: "Masazır", region: "Abşeron" },
      { value: "53", label: "Maştağa", region: "Sabunçu" },
      { value: "54", label: "Mehdiabad", region: "Abşeron" },
      { value: "55", label: "Montin", region: "Nərimanov" },
      { value: "56", label: "Müşfiqabad", region: "Qaradağ" },
      { value: "57", label: "Məhəmmədli", region: "Abşeron" },
      { value: "58", label: "Mərdəkan", region: "Xəzər" },
      { value: "59", label: "Nardaran", region: "Sabunçu" },
      { value: "60", label: "Nasosnu", region: "" },
      { value: "61", label: "Novxanı", region: "Abşeron" },
      { value: "62", label: "Nübar", region: "" },
      { value: "63", label: "NZS", region: "Xətai" },
      { value: "64", label: "Papanin", region: "" },
      { value: "65", label: "Perekeşkül", region: "Abşeron" },
      { value: "66", label: "Pirşağı", region: "Sabunçu" },
      { value: "67", label: "Puta", region: "Qaradağ" },
      { value: "68", label: "Qala", region: "Xəzər" },
      { value: "69", label: "Qara şəhər", region: "" },
      { value: "70", label: "Qaraçuxur", region: "Suraxanı" },
      { value: "71", label: "Qızıldaş", region: "Qaradağ" },
      { value: "72", label: "Qobu", region: "Abşeron" },
      { value: "73", label: "Qobustan", region: "Qaradağ" },
      { value: "74", label: "Ramana", region: "Sabunçu" },
      { value: "75", label: "Rəsulzadə", region: "Binəqədi" },
      { value: "76", label: "Sabunçu", region: "Sabunçu" },
      { value: "77", label: "Şağan", region: "Xəzər" },
      { value: "78", label: "Sahil", region: "Qaradağ" },
      { value: "79", label: "Saray", region: "Abşeron" },
      { value: "80", label: "Savalan", region: "Sabunçu" },
      { value: "81", label: "Şıxov", region: "Səbail" },
      { value: "82", label: "Şubanı", region: "Qaradağ" },
      { value: "83", label: "Sulutəpə", region: "Binəqədi" },
      { value: "84", label: "Şuşa", region: "Sabunçu" },
      { value: "85", label: "Şüvəlan", region: "Xəzər" },
      { value: "86", label: "Səngəçal", region: "Qaradağ" },
      { value: "87", label: "Türkan", region: "Xəzər" },
      { value: "88", label: "Ümid", region: "Qaradağ" },
      { value: "89", label: "Xaşaxuna", region: "Xəzər" },
      { value: "90", label: "Xocəsən", region: "Binəqədi" },
      { value: "91", label: "Xutor", region: "Binəqədi" },
      { value: "92", label: "Yeni Günəşli", region: "Suraxanı" },
      { value: "93", label: "Yeni Ramana", region: "Sabunçu" },
      { value: "94", label: "Yeni Suraxanı", region: "Suraxanı" },
      { value: "95", label: "Yeni Yasamal", region: "Yasamal" },
      { value: "96", label: "Zabrat 1", region: "Sabunçu" },
      { value: "97", label: "Zabrat 2", region: "Sabunçu" },
      { value: "98", label: "Zağulba", region: "Abşeron" },
      { value: "99", label: "Zığ", region: "Suraxanı" },
      { value: "100", label: "Zirə", region: "Xəzər" },
      { value: "101", label: "Əhmədli", region: "Xətai" },
      { value: "102", label: "Ələt", region: "Qaradağ" },
      { value: "103", label: "Əmircan", region: "Suraxanı" },
    ],
    target_options: [
      { value: "1", label: "28 Mall", region: "Nəsimi" },
      { value: "2", label: "A.S.Puşkin parkı", region: "Nəsimi" },
      { value: "3", label: "AAF PARK", region: "Abşeron" },
      { value: "4", label: "ABŞ səfirliyi", region: "Nəsimi" },
      { value: "5", label: "Abşeron Gənclər Şəhərciyi", region: "Abşeron" },
      { value: "6", label: "Absheron Marriott otel", region: "Nəsimi" },
      { value: "7", label: "Abu Arena", region: "Nərimanov" },
      { value: "8", label: "ADA universiteti", region: "Nərimanov" },
      { value: "9", label: "AF Business House", region: "Nəsimi" },
      { value: "10", label: "AGA Business Center", region: "Xətai" },
      { value: "11", label: "Altes Plaza", region: "Yasamal" },
      { value: "12", label: "AMAY", region: "Xətai" },
      { value: "13", label: "Ambassador otel", region: "Nərimanov" },
      { value: "14", label: "ANS telekanalı", region: "Səbail" },
      { value: "15", label: "Aqua Park", region: "Nəsimi" },
      { value: "16", label: "Araz kinoteatrı", region: "Binəqədi" },
      { value: "17", label: "Asan Xidmət-1", region: "Nərimanov" },
      { value: "18", label: "Asan Xidmət-2", region: "Xətai" },
      { value: "19", label: "Asan Xidmət-3", region: "Yasamal" },
      { value: "20", label: "Asan Xidmət-4", region: "Sabunçu" },
      { value: "21", label: "Asan Xidmət-5", region: "Nizami" },
      { value: "22", label: "Asan Xidmət-6", region: "Xətai" },
      { value: "23", label: "ASK Plaza", region: "Nəsimi" },
      { value: "24", label: "Atatürk parkı", region: "Nərimanov" },
      { value: "25", label: "ATV telekanalı", region: "Yasamal" },
      {
        value: "26",
        label: "Milli Aviasiya Akademiyası Universiteti",
        region: "Xəzər",
      },
      { value: "27", label: "Avropa otel", region: "Nəsimi" },
      { value: "28", label: "Axundov bağı", region: "Səbail" },
      { value: "29", label: "Aygun City", region: "Sabunçu" },
      { value: "30", label: "Ayna Sultanova heykəli", region: "Nərimanov" },
      { value: "31", label: "Azadlıq meydanı", region: "Səbail" },
      { value: "32", label: "Azneft meydanı", region: "Səbail" },
      { value: "33", label: "AZTV telekanalı", region: "Səbail" },
      {
        value: "34",
        label: "Azərbaycan Dillər Universiteti",
        region: "Nəsimi",
      },
      { value: "35", label: "Azərbaycan kinoteatrı", region: "Yasamal" },
      {
        value: "36",
        label: "Azərbaycan Тurizm İnstitutu",
        region: "Nərimanov",
      },
      { value: "37", label: "Babək Plaza", region: "Xətai" },
      { value: "38", label: "Bakı Asiya Universiteti", region: "Nəsimi" },
      { value: "39", label: "Bakı Dövlət Universiteti", region: "Yasamal" },
      { value: "40", label: "Bakı Musiqi Akademiyası", region: "Nəsimi" },
      { value: "41", label: "Bakı Slavyan Universiteti", region: "Nəsimi" },
      { value: "42", label: "Bakı univermağı", region: "Nizami" },
      { value: "43", label: "Baku Mall", region: "Yasamal" },
      { value: "44", label: "Bayıl parkı", region: "Səbail" },
      { value: "45", label: "Beşmərtəbə", region: "Sabunçu" },
      { value: "46", label: "Binə ticarət mərkəzi ", region: "Qaradağ" },
      { value: "47", label: "Botanika bağı", region: "Səbail" },
      { value: "48", label: "Bridge Plaza", region: "Nəsimi" },
      { value: "49", label: "C.Cabbarlı heykəli", region: "Nəsimi" },
      {
        value: "50",
        label: "C.Naxçıvanski adına Hərbi Akademiya",
        region: "Xətai",
      },
      { value: "51", label: "Caspian Plaza", region: "Yasamal" },
      { value: "52", label: "Caspian Shopping Center", region: "Nərimanov" },
      { value: "53", label: "Cavanşir körpüsü", region: "Nəsimi" },
      { value: "54", label: "Çin səfirliyi", region: "Qaradağ" },
      { value: "55", label: "Çıraq Plaza", region: "Nəsimi" },
      { value: "56", label: "Crystal Plaza", region: "Xətai" },
      { value: "57", label: "Dağüstü parkı", region: "Səbail" },
      { value: "58", label: "Dalğa Plaza", region: "Səbail" },
      { value: "59", label: "Daxili İşlər Nazirliyi", region: "Səbail" },
      { value: "60", label: "Dostluq kinoteatrı", region: "Nəsimi" },
      { value: "61", label: "Dövlət İdarəçilik Akademiyası", region: "Səbail" },
      { value: "62", label: "Dövlət Statistika Komitəsi", region: "Yasamal" },
      { value: "63", label: "Dədə Qorqud parkı", region: "Nərimanov" },
      { value: "64", label: "Dəmirçi Plaza", region: "Xətai" },
      { value: "65", label: "Dənizkənarı Milli park", region: "Səbail" },
      {
        value: "66",
        label: "Ekologiya və Təbii Sərvətlər Nazirliyi",
        region: "Yasamal",
      },
      { value: "67", label: "Elit ticarət mərkəzi", region: "Binəqədi" },
      { value: "68", label: "Energetika Nazirliyi", region: "Nizami" },
      { value: "69", label: "Fairmont otel", region: "Səbail" },
      { value: "70", label: "Flame Towers", region: "Səbail" },
      { value: "71", label: "Fontanlar bağı", region: "Səbail" },
      { value: "72", label: "Four Seasons otel", region: "Səbail" },
      { value: "73", label: "Fövqəladə Hallar Nazirliyi", region: "Yasamal" },
      {
        value: "74",
        label: "Fövqəladə Hallar Nazirliyi Akademiyası",
        region: "Suraxanı",
      },
      { value: "75", label: "Fəvvarələr meydanı", region: "Səbail" },
      { value: "76", label: "Gürgən", region: "Pirallahı" },
      { value: "77", label: "Gənclik Mall", region: "Nərimanov" },
      { value: "78", label: "Gənclər və İdman Nazirliyi", region: "Nərimanov" },
      { value: "79", label: "Heydər Məscidi", region: "Binəqədi" },
      { value: "80", label: "Heydər Əliyev Mərkəzi", region: "Nərimanov" },
      { value: "81", label: "Heydər Əliyev sarayı", region: "Nəsimi" },
      { value: "82", label: "Hilton otel", region: "Səbail" },
      { value: "83", label: "Hüseyn Cavid parkı", region: "Yasamal" },
      { value: "84", label: "Hyatt Regency", region: "Yasamal" },
      { value: "85", label: "Hərbi Hospital", region: "Nəsimi" },
      { value: "86", label: "İctimai telekanalı", region: "Yasamal" },
      { value: "87", label: "İçəri Şəhər", region: "Səbail" },
      { value: "88", label: "İncəsənət və Mədəniyyət Un.", region: "Yasamal" },
      { value: "89", label: "İnşaat Universiteti", region: "Yasamal" },
      { value: "90", label: "İqtisadiyyat  Nazirliyi", region: "Nizami" },
      { value: "91", label: "İqtisadiyyat Universiteti", region: "Nizami" },
      {
        value: "92",
        label: "İran İslam Respublikası səfirliyi",
        region: "Səbail",
      },
      { value: "93", label: "ISR Plaza", region: "Nəsimi" },
      { value: "94", label: "İzmir parkı", region: "Yasamal" },
      { value: "95", label: "Keşlə bazarı", region: "Nərimanov" },
      { value: "96", label: "Koala parkı", region: "Nəsimi" },
      { value: "97", label: "Kooperasiya Universiteti", region: "Nərimanov" },
      { value: "98", label: "Koroğlu Parkı", region: "Nəsimi" },
      { value: "99", label: "Landmark", region: "Səbail" },
      { value: "100", label: "Lider telekanalı", region: "Yasamal" },
      { value: "101", label: "M.Hüseynzadə parkı", region: "Nəsimi" },
      { value: "102", label: "M.Ə.Sabir parkı", region: "Sabunçu" },
      { value: "103", label: "Maliyyə Nazirliyi", region: "Nəsimi" },
      { value: "104", label: "Megafun", region: "Xətai" },
      {
        value: "105",
        label: "Memarlıq və İnşaat Universiteti",
        region: "Yasamal",
      },
      { value: "106", label: "Metropark", region: "Nərimanov" },
      { value: "107", label: "Milli Konservatoriya", region: "Yasamal" },
      { value: "108", label: "Milli Məclis", region: "Səbail" },
      {
        value: "109",
        label: "Dövlət Təhlükəsizliyi Xidməti",
        region: "Yasamal",
      },
      { value: "110", label: "Molokan bağı", region: "Səbail" },
      { value: "111", label: "Montin adına park", region: "Nərimanov" },
      { value: "112", label: "Moskva univermağı", region: "Nəsimi" },
      { value: "113", label: "Müdafiə Sənayesi Nazirliyi", region: "Xətai" },
      { value: "114", label: "MUM", region: "Səbail" },
      { value: "115", label: "Musabəyov parkı", region: "Yasamal" },
      {
        value: "116",
        label: "Mərkəzi Neftçilər xəstxəxanası",
        region: "Xətai",
      },
      { value: "117", label: "Mərkəzi Univermaq", region: "Səbail" },
      { value: "118", label: "Nargiz ticarət mərkəzi", region: "Səbail" },
      { value: "119", label: "Neapol dairəsi", region: "Xətai" },
      {
        value: "120",
        label: "Azərbaycan Dövlət Neft və Sənaye Universiteti",
        region: "Nəsimi",
      },
      { value: "121", label: "Neftçi bazası", region: "Nərimanov" },
      { value: "122", label: "Neftçilər metrosu", region: "Nizami" },
      { value: "123", label: "Nizami kinoteatrı", region: "Nəsimi" },
      { value: "124", label: "Nəqliyyat Nazirliyi", region: "Səbail" },
      { value: "125", label: "Nəriman Nərimanov parkı", region: "Nərimanov" },
      { value: "126", label: "Nərimanov heykəli", region: "Yasamal" },
      { value: "127", label: "Nəsimi bazarı", region: "Nəsimi" },
      { value: "128", label: "Nəsimi heykəli", region: "Nəsimi" },
      { value: "129", label: "Odlar Yurdu Universiteti", region: "Nərimanov" },
      { value: "130", label: "Olimpia Stadionu", region: "Sabunçu" },
      { value: "131", label: "Olimpik Star", region: "Nərimanov" },
      { value: "132", label: "Oskar şadlıq sarayı", region: "Nizami" },
      { value: "133", label: "Özbəkistan səfirliyi", region: "Səbail" },
      { value: "134", label: "Park Azure", region: "Xətai" },
      { value: "135", label: "Park Bulvar", region: "Səbail" },
      { value: "136", label: "Park Inn", region: "Səbail" },
      { value: "137", label: "Park Zorge", region: "Nəsimi" },
      { value: "138", label: "Pedaqoji Universiteti", region: "Səbail" },
      { value: "139", label: "Perekeşkül", region: "Abşeron" },
      { value: "140", label: "Port Baku", region: "Nəsimi" },
      { value: "141", label: "Prezident parkı", region: "Xətai" },
      { value: "142", label: "Pullman Hotel", region: "Yasamal" },
      { value: "143", label: "Qafqaz Resort otel", region: "Nəsimi" },
      { value: "144", label: "Mühəndislik Universiteti", region: "Abşeron" },
      { value: "145", label: "Qış parkı", region: "Yasamal" },
      { value: "146", label: "Qız Qalası", region: "Səbail" },
      { value: "147", label: "Qubernator parkı", region: "Səbail" },
      {
        value: "148",
        label: "Qurtuluş - 93 Yaşayış Kompleksi",
        region: "Abşeron",
      },
      { value: "149", label: "Qələbə dairəsi", region: "Yasamal" },
      { value: "150", label: "Qərb Universiteti", region: "Səbail" },
      {
        value: "151",
        label: "Rabitə və Yüksək Texnologiyalar Nazirliyi",
        region: "Nəsimi",
      },
      { value: "152", label: "Respublika stadionu", region: "Nərimanov" },
      { value: "153", label: "Rusiya səfirliyi", region: "Nəsimi" },
      { value: "154", label: "Rəssamlıq Akademiyası", region: "Nərimanov" },
      { value: "155", label: "Sahil bağı", region: "Nərimanov" },
      { value: "156", label: "Sea Breeze Resort", region: "Sabunçu" },
      { value: "157", label: "Sevgi parkı", region: "Xətai" },
      { value: "158", label: "Sevil Qazıyeva parkı", region: "Nəsimi" },
      { value: "159", label: "Sevinc k/t", region: "Nizami" },
      { value: "160", label: "Sirk", region: "Nəsimi" },
      { value: "161", label: "Sovetski", region: "Yasamal" },
      { value: "162", label: "Space TV", region: "Yasamal" },
      { value: "163", label: "Şüvəlan Park ticarət mərkəzi", region: "Xəzər" },
      { value: "164", label: "Sədərək ticarət mərkəzi", region: "Qaradağ" },
      { value: "165", label: "Şəfa stadionu", region: "Nizami" },
      { value: "166", label: "Şəhidlər xiyabanı", region: "Səbail" },
      { value: "167", label: "Səhiyyə Nazirliyi", region: "Nəsimi" },
      { value: "168", label: "Şəlalə parkı", region: "Səbail" },
      { value: "169", label: "Səməd Vurğun parkı ", region: "Nəsimi" },
      { value: "170", label: "Sərhədçi İdman kompleksi", region: "Xətai" },
      { value: "171", label: "Şərq Bazarı", region: "Nərimanov" },
      { value: "172", label: "Tarqovu", region: "Səbail" },
      { value: "173", label: "Texniki Universiteti", region: "Yasamal" },
      { value: "174", label: "Tibb Universiteti", region: "Nəsimi" },
      { value: "175", label: "Tofiq Bəhramov stadionu", region: "Nərimanov" },
      { value: "176", label: "Türkiyə səfirliyi", region: "Nəsimi" },
      { value: "177", label: "Təfəkkür Universiteti", region: "Nərimanov" },
      { value: "178", label: "Təhsil Nazirliyi", region: "Nərimanov" },
      { value: "179", label: "Təzə bazar", region: "Nəsimi" },
      { value: "180", label: "Ukrayna dairəsi", region: "Xətai" },
      { value: "181", label: "Vergilər Nazirliyi", region: "Nizami" },
      { value: "182", label: "Vətən kinoteatrı", region: "Nəsimi" },
      { value: "183", label: "World Business Center", region: "Nəsimi" },
      { value: "184", label: "Xalça Muzeyi", region: "Səbail" },
      { value: "185", label: "Xarici İşlər Nazirliyi", region: "Yasamal" },
      { value: "186", label: "Xəqani ticarət mərkəzi", region: "Səbail" },
      { value: "187", label: "Xəzər Universiteti", region: "Nizami" },
      { value: "188", label: "Yasamal bazarı", region: "Yasamal" },
      { value: "189", label: "Yasamal parkı", region: "Yasamal" },
      { value: "190", label: "Yaşıl bazar", region: "Nərimanov" },
      { value: "191", label: "Zabitlər parkı", region: "Nəsimi" },
      { value: "192", label: "Zoopark", region: "Nərimanov" },
      { value: "193", label: "Zərifə Əliyeva adına park", region: "Binəqədi" },
      { value: "194", label: "Ədliyyə Nazirliyi", region: "Yasamal" },
      {
        value: "195",
        label: "Əmək və Əhalinin Sosial Müdafiəsi Nazirliyi",
        region: "Yasamal",
      },
    ],
    flat_type_options: [
      { value: "1", label: "Mənzil" },
      { value: "2", label: "Həyət evi/Bağ/Villa", label2: "Həyət" },
      { value: "3", label: "Ofis" },
      { value: "4", label: "Qaraj" },
      { value: "5", label: "Torpaq" },
      { value: "6", label: "Obyekt" },
    ],
    offer_type_options: [
      { value: "true", label: "Satış" },
      { value: "false", label: "Kirayə" },
    ],
    mortgage_options: [
      { value: "true", label: "Bəli" },
      { value: "false", label: "Xeyr" },
    ],
    rent_type_options: [
      { value: "1", label: "Aylıq" },
      { value: "2", label: "Günlük" },
    ],
    type_of_flat: [
      { value: "1", label: "Biznes mərkəzi" },
      { value: "2", label: "Ev / Mənzil" },
    ],
    filter_options: [
      { value: "dn", label: "Tarix (Yeni-Köhnə)" },
      { value: "do", label: "Tarix (Köhnə-Yeni)" },
      { value: "pl", label: "Qiymət (ucuzdan bahaya)" },
      { value: "ph", label: "Qiymət (bahadan ucuza)" },
    ],
    filter_options_blog: [
      { value: "dn", label: "Tarix (Yeni - Köhnə)" },
      { value: "do", label: "Tarix (Köhnə - Yeni)" },
      { value: "pl", label: "Baxış (Azdan - Çoxa)" },
      { value: "ph", label: "Baxış (Çoxdan - Aza)" },
    ],
    packets: {
      boost: [
        { dayOrTime: "1", price: "0.49" },
        { dayOrTime: "5", price: "1.99" },
        { dayOrTime: "10", price: "3.99" },
      ],
      frame: [
        { dayOrTime: "1", price: "0.99" },
        { dayOrTime: "5", price: "2.49" },
        { dayOrTime: "10", price: "4.49" },
      ],
      urgent: [
        { dayOrTime: "1", price: "1.99" },
        { dayOrTime: "5", price: "5.99" },
        { dayOrTime: "10", price: "9.99" },
      ],
      premium: [
        { dayOrTime: "1", price: "2.49" },
        { dayOrTime: "5", price: "10.99" },
        { dayOrTime: "10", price: "19.99" },
        { dayOrTime: "30", price: "39.99" },
      ],
    },
    blog:"Blog",
    blogAll:"Hamısı",
    blogMore:"Daha çox...",
    blogNotFound:"Blog tapılmadı"
  },
  ru: {
    allFavouriteOffersTitle: "Ebaz.az - Все избранные объявления",
    allPremiumOffersTitle: "Ebaz.az - Все премиум объявления",
    loginTitle: "Ebaz.az - Вход",
    registrationTitle: "Ebaz.az - Регистрация",
    myOffersTitle: "Ebaz.az - Профиль",
    newOffersTitle: "Ebaz.az - Новое объявление",
    notFoundTitle: "Ebaz.az - Ошибка 404",
    paymentErrorTitle: "Ebaz.az - Ошибка платежа!",
    paymentSuccessTitle: "Ebaz.az - Успешный платеж!",
    newOfferTitle: "Ebaz.az - Обнавление объявления",
    findOffer: "Найти объявление",
    offersCommon: "Объявления",
    favourites: "Избранные",
    profile: "Профиль",
    signIn: "Войти",
    uploadOffer: "Разместить объявление",
    sale: "Продажа",
    rent: "Аренда",
    day: "Посуточно",
    clean: "Очистить",
    add: "Добавить",
    cityName: "Город",
    flatTypeName: "Тип недвижимости",
    roomsCountName: "Количество комнат",
    priceName: "Цена:",
    pricePerM2Name: "Цена кв. метра: AZN/м2.",
    pricePerSotName: "Цена a.:  AZN/a",
    min: "мин.",
    max: "макс.",
    areaName: "Площадь",
    sot: "а",
    m2: "м²",
    regionName: "Район",
    residentName: "Городок",
    stationName: "Метро",
    targetName: "Ориентиры",
    floorName: "Этаж:",
    siteName: "Сайты",
    siteNameEnter: "Выберите сайты",
    withGoodsName: "С мебелью",
    withoutGoodsName: "Без мебели",
    repairName: "С ремонтом",
    withoutRepairName: "Без ремонта",
    withDocumentsName: "С документами",
    withoutDocumentsName: "Без документов",
    mortgageName: "Ипотека",
    showOffersName: " объявлений",
    searchName: "Поиск",
    historyName: "История поиска",
    additionalsearchName: "Обширный поиск",
    lastFiveSearchName: "Последние 5 поисков",
    allFloorNumberName: "Количество этажей",
    sale2: "Продажа",
    clean2: "Очистить",
    sloqan: "Все недвижимости в одном месте!",
    usersAgree: "Пользовательское соглашение",
    privicy: "Политика конфиденциальности",
    services: "Услуги",
    connect: "Контакты",
    analytics: "Аналитика",
    predicted: "Ожидается",
    predictedHigh: "увеличение",
    predictedLow: "понижение",
    disclaimer:
      "*Администрация сайта не несет ответственности за содержание рекламных баннеров и размещенных объявлений.",
    enterAddress: "Введите адрес",
    showAll: "Показать все",
    premiumOffers: "Премиум объявления",
    saleFlats: "Продажа квартир",
    rentFlats: "Аренда квартир",
    saleCountry: "Продажа домов/дач/вилл",
    rentCountry: "Аренда домов/дач/вилл",
    saleOffice: "Продажа офисов",
    rentOffice: "Аренда офисов",
    saleGarage: "Продажа гаражей",
    rentGarage: "Аренда гаражей",
    login: "Вход",
    saleLand: "Продажа земли",
    rentLand: "Аренда земли",
    saleCommercial: "Продажа коммерческой недвижимости",
    rentCommercial: "Аренда коммерческой недвижимости",
    searchOffersLoading: "Поиск объявлений",
    logout: "Выход",
    myOffersName: "Мои объявления",
    myOffersActiveName: "Активные",
    myOffersDeactiveName: "Неактивные",
    myOffersReductName: "Редактировать",
    myOffersDeleteFromOffersName: "Удалить из объявлений",
    myOffersRefreshOfferName: "Заново опубкликовать объявление",
    myOffersDontHaveOffersName: "У вас нет объявлений.",
    myOffersDontHaveActiveOffersName: "У вас нет активных объявлений.",
    myOffersDontHaveDeactiveOffersName: "У вас нет неактивных объявлений.",
    myOffersNameSurnameChangeName: "Изменить Имя и Фамилию",
    myOffersNameChangeName: "Имя",
    myOffersLastnameChangeName: "Фамилия",
    myOffersNameChangeRequareName: "*Пожалуйста, укажите ваше имя",
    myOffersLastnameChangeRequareName: "*Пожалуйста, укажите вашу фамилию",
    myOffersSetNameChangeName: "Введите ваше имя",
    myOffersSetLastnameChangeName: "Введите вашу фамилию",
    myOffersNameLastnameChangedSuccessfullyName: "Успешно изменено",
    myOffersNameLastnameChangeButtonName: "Изменить",
    residentsSmall: " городок",
    WithRoomsSmall: " комн.",
    floorSmall: "этаж",
    todaySmall: "Сегодня",
    yesterdaySmall: "Вчера",
    searchSmall: "Поиск",
    newOfferSmall: "Разместить",
    cabinetSmall: "Кабинет",
    moreSmall: "Больше",
    menuSmall: "Меню",
    languageSmall: "Язык",
    languageBig: "Русский",
    languageAZ: "Azərbaycan",
    languageRU: "Русский",
    languageEN: "English",
    yesSmall: "Да",
    noSmall: "Нет",
    aboutOffer: "О недвижимости",
    urgentSmall: "Срочно",
    offersFilterSloqan:
      "Город, Недвижимость, Цена, Площадь, Комнаты, Ремонт...",
    offersFound: "Объявлений",
    listSmall: "Список",
    mapSmall: "Карта",
    mapSmallShow: "Показать карту",
    mapSmallDontShow: "Скрыть карту",
    notFoundOffers: "К сожалению, объявлений по вашему запросу не найдено.",
    writeLinkForSearch: "Введите ссылку объявления для поиска",
    supportedSitesSearch:
      "Поддерживаемые сайты: tap.az, bina.az, lalafo.az, arenda.az, emlak.az, yeniemlak.az, rahatemlak.az, ucuztap.az",
    showOfferSearch: "Показать объявление",
    favouriteOffersCount: "Избранные объявления",
    notFavouritesOffers: "Нет избранных объявлений.",
    seeAllOffers: "Просмотреть все объявления",
    phoneNumberName: "Мобильный номер",
    writePhoneNumber: "*Пожалуйста, укажите ваш номер",
    sendSMStoPhone: "На ваш номер отправлен SMS-код",
    sendRepeatSMStoPhone: "Отправить SMS-код повторно",
    pleaseWriteSMSCode: "*Пожалуйста, введите SMS-код",
    pleaseWriteSMSCodeCorrect: "*Пожалуйста, введите корректный SMS-код",
    saveUser: "Сохранить",
    userNotFound: "*Пользователь не найден",
    loginWithBigI: "ВОЙТИ",
    notProfileRegistration: "Нет аккаунта? Пройти регистрацию",
    profileExistLogin: "Есть аккаунт? Войти",
    registration: "Регистрация",
    setEmail: "Email адрес",
    userAlreadyExist: "*Этот пользователь уже зарегистрирован",
    doRegistration: "Зарегистрироваться",
    newOffer_flatType: "Тип недвижимости, ",
    newOffer_offerType: "Тип объявления, ",
    newOffer_rentPeriod: "Срок аренды, ",
    newOffer_AreaM2: "Площадь м², ",
    newOffer_AreaSot: "Площадь а, ",
    newOffer_City: "Город, ",
    newOffer_Price: "Цена, ",
    newOffer_Desc: "Описание, ",
    newOffer_Images:
      "Изображения (минимум 1 изображение, максимум 10 изображений), ",
    newOffer_Name: "Имя, ",
    newOffer_Email: "E-mail, ",
    newOffer_PhoneNum: "Пожалуйста, укажите корректный мобильный номер, ",
    videoSmall: "Видео",
    addVideoSmall: "+Добавить видео",
    watchVideo:"Смотреть видео",
    imagesSmall: "Изображения",
    addImagesSmall: "+Добавить изображение",
    addImagesMaxCountSmall: "*Максимум 10 изображений",
    writeInfoInThisFields: "введите информацию в эти поля.",
    sendOffer: "Отправить объявление",
    simpleRules: "Простые правила Ebaz.az",
    simpleRules1: "Не публикуйте одно и то же объявление несколько раз.",
    simpleRules2:
      "Не указывайте номера телефонов, электронные адреса или веб-сайты в описании или на изображениях.",
    simpleRules3:
      "Не указывайте цену в поле 'Имя' - для этого есть отдельное поле.",
    simpleRules4: "Разместите свое первое объявление и получите от нас \"В рамке\" и \"Поднять\" в течение 2 дней.",
    offerView: "Вид объявления",
    pageNotFoundSmall: "Страница не найдена",
    mainPageSmall: "Главная страница.",
    boostNameSmall: "Выделить",
    makePremiumNameSmall: "Премиум",
    deleteFromFavouritesNameSmall: "Удалить из избранных",
    positionOfOffer: "Расположение",
    addToFavouritesNameSmall: "Добавить в избранное",
    priceByAgreement: "По договоренности",
    linkCopied: "Ссылка скопирована",
    similarOffers: "Похожие объявления",
    boostOffer: "Поднять",
    frameOffer: "В рамке",
    fromPrice1: "От 0.49 AZN",
    fromPrice2: "От 0.99 AZN",
    fromPrice3: "От 1.99 AZN",
    boostOfferDescription:
      "Объявление будет поднято на первое место среди всех объявлений.",
    frameOfferDescription:
      "Объявление будет выделено рамкой и поднято на первое место среди всех объявлений.",
    urgentOfferDescription:
      "Объявление будет выделено красной рамкой и помечено как 'Срочное', также будет поднято на первое место среди всех объявлений.",
    paidUntill: "до данной даты оплачено",
    serviceTime: "Срок предоставления услуги",
    times: " раз",
    boostFor24Hours: "(Каждые 24 часа подъем)",
    boostName: "(+ Поднять)",
    paymentMethod: "Метод оплаты",
    bankCard: "Банковская карта",
    addressSmall: "Адрес",
    cancelPayment: "Отмена",
    makePayment: "Оплатить",
    paymentAgreement:
      'Нажимая кнопку "Оплатить", вы соглашаетесь с Пользовательским соглашением и Офертой сайта ebaz.az.',
    makeOfferPremiumName: "Сделать объявление премиум",
    makePremiumDescription:
      "Объявление будет отображаться в разделе премиум-объявлений на главной странице в течение всего срока действия услуги и будет продвигаться бесплатно.",
    days: " дней",
    perDays: "/день",
    errorName: "Ошибка",
    errorDescription:
      "Произошла ошибка при оплате, пожалуйста, попробуйте снова.",
    errorDescription2: `Произошла ошибка. Пожалуйста, попробуйте снова.`,
    successName: "Успешная оплата",
    successDescription:
      "Ваша оплата успешно зафиксирована, ваше объявление будет обновлено в ближайшее время.",
    successName2: "Успешно",
    successDescription2UpdateOffer: "Объявление успешно обновлено.",
    successDescription2DeleteOffer: "Объявление удалено.",
    successDescription2RefreshOffer: "Объявление успешно обновлено.",
    successDescription2RegistrationOffer: "Вы успешно зарегистрировались.",
    successGoToOffers: "Перейти к вашим объявлениям.",
    updateOfferName: "Обновить объявление",
    allRightsReserved: "Все права защищены.",
    siteMap: "Карта сайта",
    notUploadedOffer:"Нет зашруженного объявления.",
    uploadedOffer:"Ваше объявление опубликовано!",
    showToMorePeople:"Пусть больше людей увидят объявление!",
    miniBannerWords:["Разместите свое первое объявление и получите от нас \"Фрейминг\" и \"Продвижение\" в течение 2 дней."],
    firstOfferWord:"Так как это ваше первое объявление, мы подарили вам \"Фрейминг\" и \"Продвижение\" на 2 дня!",
    saleDeleted:"Снято с продажи",
    rentDeleted:"Снято с аренды",
    cities: [
      {
        value: "0",
        label: "Агджабеди",
        center: { lat: 40.053, lng: 47.4554 },
        zoom: "10",
      },
      {
        value: "1",
        label: "Агдам",
        center: { lat: 39.9911, lng: 46.9297 },
        zoom: "10",
      },
      {
        value: "2",
        label: "Агдаш",
        center: { lat: 40.6335, lng: 47.4674 },
        zoom: "10",
      },
      {
        value: "3",
        label: "Агдере",
        center: { lat: 40.2116, lng: 46.8237 },
        zoom: "10",
      },
      {
        value: "4",
        label: "Агстафа",
        center: { lat: 41.1125, lng: 45.4477 },
        zoom: "10",
      },
      {
        value: "5",
        label: "Агсу",
        center: { lat: 40.5283, lng: 48.3651 },
        zoom: "10",
      },
      {
        value: "6",
        label: "Астара",
        center: { lat: 38.4688, lng: 48.8728 },
        zoom: "10",
      },
      {
        value: "7",
        label: "Бабек",
        center: { lat: 39.1508, lng: 45.4485 },
        zoom: "10",
      },
      {
        value: "8",
        label: "Баку",
        center: { lat: 40.3953, lng: 49.8822 },
        zoom: "10",
      },
      {
        value: "9",
        label: "Балакен",
        center: { lat: 41.7038, lng: 46.4044 },
        zoom: "10",
      },
      {
        value: "10",
        label: "Бейлаган",
        center: { lat: 39.7723, lng: 47.6154 },
        zoom: "10",
      },
      {
        value: "11",
        label: "Бердя",
        center: { lat: 40.3707, lng: 47.1379 },
        zoom: "10",
      },
      {
        value: "12",
        label: "Билясувар",
        center: { lat: 39.4599, lng: 48.551 },
        zoom: "10",
      },
      {
        value: "13",
        label: "Джебраил",
        center: { lat: 39.3987, lng: 47.0245 },
        zoom: "10",
      },
      {
        value: "14",
        label: "Джалилабад",
        center: { lat: 39.2052, lng: 48.5101 },
        zoom: "10",
      },
      {
        value: "15",
        label: "Джульфа",
        center: { lat: 38.9605, lng: 45.6293 },
        zoom: "10",
      },
      {
        value: "16",
        label: "Дашкесан",
        center: { lat: 40.5202, lng: 46.0779 },
        zoom: "10",
      },
      {
        value: "17",
        label: "Делимамедли",
        center: { lat: 40.6844, lng: 46.5728 },
        zoom: "10",
      },
      {
        value: "18",
        label: "Физули",
        center: { lat: 39.6025, lng: 47.1392 },
        zoom: "10",
      },
      {
        value: "19",
        label: "Гедебей",
        center: { lat: 40.57, lng: 45.8107 },
        zoom: "10",
      },
      {
        value: "20",
        label: "Гянджа",
        center: { lat: 40.6579, lng: 46.3523 },
        zoom: "10",
      },
      {
        value: "21",
        label: "Горанбой",
        center: { lat: 40.6073, lng: 46.7807 },
        zoom: "10",
      },
      {
        value: "22",
        label: "Гейджа",
        center: { lat: 40.6236, lng: 47.7403 },
        zoom: "10",
      },
      {
        value: "23",
        label: "Гейгель",
        center: { lat: 40.5895, lng: 46.3271 },
        zoom: "10",
      },
      {
        value: "24",
        label: "Гейтепе",
        center: { lat: 39.1171, lng: 48.5844 },
        zoom: "10",
      },
      {
        value: "25",
        label: "Гаджигабул",
        center: { lat: 40.0394, lng: 48.9203 },
        zoom: "10",
      },
      {
        value: "26",
        label: "Горадиз",
        center: { lat: 39.4511, lng: 47.3399 },
        zoom: "10",
      },
      {
        value: "27",
        label: "Хачмаз",
        center: { lat: 41.4591, lng: 48.8021 },
        zoom: "10",
      },
      {
        value: "28",
        label: "Ханкенди",
        center: { lat: 39.8265, lng: 46.7656 },
        zoom: "10",
      },
      {
        value: "29",
        label: "Ходжалы",
        center: { lat: 39.9133, lng: 46.7943 },
        zoom: "10",
      },
      {
        value: "30",
        label: "Ходжавенд",
        center: { lat: 39.7915, lng: 47.1101 },
        zoom: "10",
      },
      {
        value: "31",
        label: "Хызы",
        center: { lat: 40.9109, lng: 49.0729 },
        zoom: "10",
      },
      {
        value: "32",
        label: "Хырдалан",
        center: { lat: 40.4529, lng: 49.7333 },
        zoom: "10",
      },
      {
        value: "33",
        label: "Гудат",
        center: { lat: 41.6253, lng: 48.6764 },
        zoom: "10",
      },
      {
        value: "34",
        label: "Имышли",
        center: { lat: 39.8695, lng: 48.0665 },
        zoom: "10",
      },
      {
        value: "35",
        label: "Исмаиллы",
        center: { lat: 40.7871, lng: 48.1523 },
        zoom: "10",
      },
      {
        value: "36",
        label: "Кельбаджар",
        center: { lat: 40.1024, lng: 46.0365 },
        zoom: "10",
      },
      {
        value: "37",
        label: "Курдамыр",
        center: { lat: 40.3699, lng: 48.1645 },
        zoom: "10",
      },
      {
        value: "38",
        label: "Кенгерли",
        center: { lat: 39.3872, lng: 45.164 },
        zoom: "10",
      },
      {
        value: "39",
        label: "Ках",
        center: { lat: 41.4207, lng: 46.932 },
        zoom: "10",
      },
      {
        value: "40",
        label: "Газах",
        center: { lat: 41.0971, lng: 45.3516 },
        zoom: "10",
      },
      {
        value: "41",
        label: "Габала",
        center: { lat: 40.9982, lng: 47.87 },
        zoom: "10",
      },
      {
        value: "42",
        label: "Гобустан",
        center: { lat: 40.5326, lng: 48.9274 },
        zoom: "10",
      },
      {
        value: "43",
        label: "Говлар",
        center: { lat: 40.9364, lng: 45.7475 },
        zoom: "10",
      },
      {
        value: "44",
        label: "Куба",
        center: { lat: 41.3643, lng: 48.5261 },
        zoom: "10",
      },
      {
        value: "45",
        label: "Губадли",
        center: { lat: 39.3453, lng: 46.5789 },
        zoom: "10",
      },
      {
        value: "46",
        label: "Кусар",
        center: { lat: 41.4275, lng: 48.4302 },
        zoom: "10",
      },
      {
        value: "47",
        label: "Лачин",
        center: { lat: 39.6383, lng: 46.5461 },
        zoom: "10",
      },
      {
        value: "48",
        label: "Лерик",
        center: { lat: 38.7736, lng: 48.4151 },
        zoom: "10",
      },
      {
        value: "49",
        label: "Ленкеран",
        center: { lat: 38.7529, lng: 48.8475 },
        zoom: "10",
      },
      {
        value: "50",
        label: "Лиман",
        center: { lat: 41.312, lng: 72.9278 },
        zoom: "10",
      },
      {
        value: "51",
        label: "Масаллы",
        center: { lat: 39.0341, lng: 48.6589 },
        zoom: "10",
      },
      {
        value: "52",
        label: "Мингечаур",
        center: { lat: 40.7703, lng: 47.0496 },
        zoom: "10",
      },
      {
        value: "53",
        label: "Нафталан",
        center: { lat: 40.5076, lng: 46.824 },
        zoom: "10",
      },
      {
        value: "54",
        label: "Нахичевань",
        center: { lat: 39.2009, lng: 45.4082 },
        zoom: "10",
      },
      {
        value: "55",
        label: "Нефтчала",
        center: { lat: 39.3881, lng: 49.2414 },
        zoom: "10",
      },
      {
        value: "56",
        label: "Огуз",
        center: { lat: 41.0728, lng: 47.4651 },
        zoom: "10",
      },
      {
        value: "57",
        label: "Ордубад",
        center: { lat: 38.9022, lng: 46.0238 },
        zoom: "10",
      },
      {
        value: "58",
        label: "Саатлы",
        center: { lat: 39.9096, lng: 48.3595 },
        zoom: "10",
      },
      {
        value: "59",
        label: "Сабирабад",
        center: { lat: 39.9871, lng: 48.4693 },
        zoom: "10",
      },
      {
        value: "60",
        label: "Салян",
        center: { lat: 39.5779, lng: 48.9719 },
        zoom: "10",
      },
      {
        value: "61",
        label: "Самух",
        center: { lat: 40.7605, lng: 46.4063 },
        zoom: "10",
      },
      {
        value: "62",
        label: "Садарак",
        center: { lat: 39.7105, lng: 44.8864 },
        zoom: "10",
      },
      {
        value: "63",
        label: "Сиазань",
        center: { lat: 41.0784, lng: 49.1118 },
        zoom: "10",
      },
      {
        value: "64",
        label: "Сумгаит",
        center: { lat: 40.5855, lng: 49.6317 },
        zoom: "10",
      },
      {
        value: "65",
        label: "Шабран",
        center: { lat: 41.216, lng: 48.9946 },
        zoom: "10",
      },
      {
        value: "66",
        label: "Шахбуз",
        center: { lat: 39.4056, lng: 45.5691 },
        zoom: "10",
      },
      {
        value: "67",
        label: "Шамахи",
        center: { lat: 40.6319, lng: 48.6364 },
        zoom: "10",
      },
      {
        value: "68",
        label: "Шеки",
        center: { lat: 41.1975, lng: 47.1571 },
        zoom: "10",
      },
      {
        value: "69",
        label: "Шамкир",
        center: { lat: 40.8288, lng: 46.0167 },
        zoom: "10",
      },
      {
        value: "70",
        label: "Шерур",
        center: { lat: 39.5536, lng: 44.9846 },
        zoom: "10",
      },
      {
        value: "71",
        label: "Ширван",
        center: { lat: 39.9266, lng: 48.9206 },
        zoom: "10",
      },
      {
        value: "72",
        label: "Шуша",
        center: { lat: 39.7537, lng: 46.7465 },
        zoom: "10",
      },
      {
        value: "73",
        label: "Тертер",
        center: { lat: 40.3444, lng: 46.9377 },
        zoom: "10",
      },
      {
        value: "74",
        label: "Товуз",
        center: { lat: 40.9955, lng: 45.6166 },
        zoom: "10",
      },
      {
        value: "75",
        label: "Уджар",
        center: { lat: 40.5068, lng: 47.649 },
        zoom: "10",
      },
      {
        value: "76",
        label: "Ярдымлы",
        center: { lat: 38.9059, lng: 48.2496 },
        zoom: "10",
      },
      {
        value: "77",
        label: "Евлах",
        center: { lat: 40.6197, lng: 47.15 },
        zoom: "10",
      },
      {
        value: "78",
        label: "Закатала",
        center: { lat: 41.6031, lng: 46.6383 },
        zoom: "10",
      },
      {
        value: "79",
        label: "Зангелан",
        center: { lat: 39.0857, lng: 46.6525 },
        zoom: "10",
      },
      {
        value: "80",
        label: "Зардаб",
        center: { lat: 40.2148, lng: 47.7149 },
        zoom: "10",
      },
    ],
    region_options: [
      { value: "0", label: "Абшерон" },
      { value: "1", label: "Бинагади" },
      { value: "2", label: "Нариманов" },
      { value: "3", label: "Насими" },
      { value: "4", label: "Низами" },
      { value: "5", label: "Карадаг" },
      { value: "6", label: "Сабунчу" },
      { value: "7", label: "Себайл" },
      { value: "8", label: "Сураханы" },
      { value: "9", label: "Хатаи" },
      { value: "10", label: "Хезер" },
      { value: "11", label: "Ясамаль" },
      { value: "12", label: "Пираллахы" },
    ],
    site_options: [
      { value: "ebaz.az", label: "ebaz.az" },
      { value: "tap.az", label: "tap.az" },
      { value: "bina.az", label: "bina.az" },
      { value: "lalafo.az", label: "lalafo.az" },
      { value: "emlak.az", label: "emlak.az" },
      { value: "arenda.az", label: "arenda.az" },
      { value: "yeniemlak.az", label: "yeniemlak.az" },
      { value: "rahatemlak.az", label: "rahatemlak.az" },
      { value: "ucuztap.az", label: "ucuztap.az" },
    ],
    room_options: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "50", label: "5 +" },
    ],
    station_options: [
      { label: "Ичеришехер м.", value: "1" },
      { label: "Сахил м.", value: "2" },
      { label: "28 Мая м.", value: "3" },
      { label: "Гянджлик м.", value: "4" },
      { label: "Нариман Нариманов м.", value: "5" },
      { label: "Бакмил м.", value: "6" },
      { label: "Улдуз м.", value: "7" },
      { label: "Короглу м.", value: "8" },
      { label: "Кара Карай м.", value: "9" },
      { label: "Нефтчилер м.", value: "10" },
      { label: "Дружба народов м.", value: "11" },
      { label: "Ахмедли м.", value: "12" },
      { label: "Гези Асланов м.", value: "13" },
      { label: "Низами Гянджеви м.", value: "14" },
      { label: "Академия Наук м.", value: "15" },
      { label: "Строители м.", value: "16" },
      { label: "20 Января м.", value: "17" },
      { label: "Мемар Аджеми м.", value: "18" },
      { label: "Насими м.", value: "19" },
      { label: "Проспект Свободы м.", value: "20" },
      { label: "Дернегюль м.", value: "21" },
      { label: "Джафар Джаббарли м.", value: "22" },
      { label: "Шах Исмаил Хатаи м.", value: "23" },
      { label: "Автовокзал м.", value: "24" },
      { label: "8 Ноября м.", value: "25" },
      { label: "Ходжасан м.", value: "26" },
    ],
    resident_options: [
      { value: "1", label: "1-й микрорайон", region: "Насими" },
      { value: "2", label: "2-й микрорайон", region: "Насими" },
      { value: "3", label: "20-я площадь", region: "Себайл" },
      { value: "4", label: "28 мая", region: "Бинагади" },
      { value: "5", label: "3-й микрорайон", region: "Насими" },
      { value: "6", label: "4-й микрорайон", region: "Насими" },
      { value: "7", label: "5-й микрорайон", region: "Насими" },
      { value: "8", label: "6-й микрорайон", region: "Бинагади" },
      { value: "9", label: "6-я параллель", region: "Бинагади" },
      { value: "10", label: "7-й микрорайон", region: "Бинагади" },
      { value: "11", label: "8 км", region: "Низами" },
      { value: "12", label: "8-й микрорайон", region: "Бинагади" },
      { value: "13", label: "9-й микрорайон", region: "Бинагади" },
      { value: "14", label: "Аг шахар", region: "Хатаи" },
      { value: "15", label: "Алатава 1", region: "Бинагади" },
      { value: "16", label: "Алатава 2", region: "Бинагади" },
      { value: "17", label: "Албали", region: "Сабунчу" },
      { value: "18", label: "Атялы", region: "Абшерон" },
      { value: "19", label: "Бадамдар", region: "Себайл" },
      { value: "20", label: "Бахар", region: "Сураханы" },
      { value: "21", label: "Бакиханов", region: "Сабунчу" },
      { value: "22", label: "Балакяны", region: "Сабунчу" },
      { value: "23", label: "Байыл", region: "Себайл" },
      { value: "24", label: "Бибихейбат", region: "Себайл" },
      { value: "25", label: "Бильгях", region: "Сабунчу" },
      { value: "26", label: "Биледжери", region: "Бинагади" },
      { value: "27", label: "Биня", region: "Каспий" },
      { value: "28", label: "Бинагади", region: "Бинагади" },
      { value: "29", label: "Бёюкшор", region: "Нариманов" },
      { value: "30", label: "Бюльбюля", region: "Сураханы" },
      { value: "31", label: "Бузовна", region: "Каспий" },
      { value: "32", label: "Чермет", region: "" },
      { value: "33", label: "Джейранбатан", region: "Абшерон" },
      { value: "34", label: "Чичек", region: "Абшерон" },
      { value: "35", label: "Чорат", region: "" },
      { value: "36", label: "Дига", region: "Абшерон" },
      { value: "37", label: "Сады Дюбенди", region: "Каспий" },
      { value: "38", label: "Деде Горгуд", region: "Сураханы" },
      { value: "39", label: "Фатмайы", region: "Абшерон" },
      { value: "40", label: "Горадил", region: "Абшерон" },
      { value: "41", label: "Гюргян", region: "Каспий" },
      { value: "42", label: "Гюздек", region: "Абшерон" },
      { value: "43", label: "Гекмели", region: "Абшерон" },
      { value: "44", label: "Гюздек", region: "Сураханы" },
      { value: "45", label: "Гези Асланов", region: "Хатаи" },
      { value: "46", label: "Кешле", region: "Низами" },
      { value: "47", label: "Химический городок", region: "Насими" },
      { value: "48", label: "Старый Гюнешли", region: "Хатаи" },
      { value: "49", label: "Кюрдехани", region: "Сабунчу" },
      { value: "50", label: "Локбатан", region: "Карадаг" },
      { value: "51", label: "Лахичские сады", region: "Сабунчу" },
      { value: "52", label: "Масазыр", region: "Абшерон" },
      { value: "53", label: "Мастава", region: "Сабунчу" },
      { value: "54", label: "Мехдиабад", region: "Абшерон" },
      { value: "55", label: "Монтин", region: "Нариманов" },
      { value: "56", label: "Мушфигабад", region: "Карадаг" },
      { value: "57", label: "Мехмедли", region: "Абшерон" },
      { value: "58", label: "Мердекан", region: "Каспий" },
      { value: "59", label: "Нардаран", region: "Сабунчу" },
      { value: "60", label: "Насосно", region: "" },
      { value: "61", label: "Новханы", region: "Абшерон" },
      { value: "62", label: "Нубар", region: "" },
      { value: "63", label: "НЗС", region: "Хатаи" },
      { value: "64", label: "Папанин", region: "" },
      { value: "65", label: "Перекешкюль", region: "Абшерон" },
      { value: "66", label: "Пиршаги", region: "Сабунчу" },
      { value: "67", label: "Пута", region: "Карадаг" },
      { value: "68", label: "Кала", region: "Каспий" },
      { value: "69", label: "Черный город", region: "" },
      { value: "70", label: "Караджур", region: "Сураханы" },
      { value: "71", label: "Гизилджа", region: "Карадаг" },
      { value: "72", label: "Гобустан", region: "Карадаг" },
      { value: "73", label: "Рамана", region: "Сабунчу" },
      { value: "74", label: "Ресульзаде", region: "Бинагади" },
      { value: "75", label: "Сабунчу", region: "Сабунчу" },
      { value: "76", label: "Шаган", region: "Каспий" },
      { value: "77", label: "Сахил", region: "Карадаг" },
      { value: "78", label: "Сарай", region: "Абшерон" },
      { value: "79", label: "Савалан", region: "Сабунчу" },
      { value: "80", label: "Шаган", region: "Сураханы" },
      { value: "81", label: "Шихов", region: "Себайл" },
      { value: "82", label: "Шубаны", region: "Карадаг" },
      { value: "83", label: "Сулутепе", region: "Бинагади" },
      { value: "84", label: "Шуша", region: "Сабунчу" },
      { value: "85", label: "Шувалан", region: "Каспий" },
      { value: "86", label: "Сенгечал", region: "Карадаг" },
      { value: "87", label: "Туркан", region: "Каспий" },
      { value: "88", label: "Умид", region: "Карадаг" },
      { value: "89", label: "Хашахуна", region: "Каспий" },
      { value: "90", label: "Ходжасан", region: "Бинагади" },
      { value: "91", label: "Хутор", region: "Бинагади" },
      { value: "92", label: "Новый Гюнешли", region: "Сураханы" },
      { value: "93", label: "Новая Рамана", region: "Сабунчу" },
      { value: "94", label: "Новая Сураханы", region: "Сураханы" },
      { value: "95", label: "Новый Ясамаль", region: "Ясамаль" },
      { value: "96", label: "Забрат 1", region: "Сабунчу" },
      { value: "97", label: "Забрат 2", region: "Сабунчу" },
      { value: "98", label: "Загульба", region: "Абшерон" },
      { value: "99", label: "Зыг", region: "Сураханы" },
      { value: "100", label: "Зире", region: "Каспий" },
      { value: "101", label: "Ахмедли", region: "Хатаи" },
      { value: "102", label: "Алет", region: "Карадаг" },
      { value: "103", label: "Амирджан", region: "Сураханы" },
    ],
    target_options: [
      { value: "1", label: "28 Mall", region: "Насими" },
      { value: "2", label: "Парк им. А.С. Пушкина", region: "Насими" },
      { value: "3", label: "AAF PARK", region: "Абшерон" },
      { value: "4", label: "Посольство США", region: "Насими" },
      {
        value: "5",
        label: "Абшеронская городская молодежная площадка",
        region: "Абшерон",
      },
      { value: "6", label: "Отель Absheron Marriott", region: "Насими" },
      { value: "7", label: "Стадион Abu Arena", region: "Нариманов" },
      { value: "8", label: "Университет ADA", region: "Нариманов" },
      {
        value: "9",
        label: "Деловой центр AF Business House",
        region: "Насими",
      },
      { value: "10", label: "Бизнес-центр AGA", region: "Хатаи" },
      { value: "11", label: "Altes Plaza", region: "Ясамал" },
      { value: "12", label: "AMAY", region: "Хатаи" },
      { value: "13", label: "Отель Ambassador", region: "Нариманов" },
      { value: "14", label: "Телеканал ANS", region: "Себайл" },
      { value: "15", label: "Аквапарк", region: "Насими" },
      { value: "16", label: "Кинотеатр Араз", region: "Бинагади" },
      { value: "17", label: "Asan Xidmət-1", region: "Нариманов" },
      { value: "18", label: "Asan Xidmət-2", region: "Хатаи" },
      { value: "19", label: "Asan Xidmət-3", region: "Ясамал" },
      { value: "20", label: "Asan Xidmət-4", region: "Сабунчу" },
      { value: "21", label: "Asan Xidmət-5", region: "Низами" },
      { value: "22", label: "Asan Xidmət-6", region: "Хатаи" },
      { value: "23", label: "Плаза ASK", region: "Насими" },
      { value: "24", label: "Парк Ататюрка", region: "Нариманов" },
      { value: "25", label: "Телеканал ATV", region: "Ясамал" },
      {
        value: "26",
        label: "Университет национальной авиации",
        region: "Хазар",
      },
      { value: "27", label: "Отель Europa", region: "Насими" },
      { value: "28", label: "Сад Ахундова", region: "Себайл" },
      {
        value: "29",
        label: "Городской комплекс Aygun City",
        region: "Сабунчу",
      },
      { value: "30", label: "Памятник Айна Султановой", region: "Нариманов" },
      { value: "31", label: "Площадь Свободы", region: "Себайл" },
      { value: "32", label: "Площадь Азнефть", region: "Себайл" },
      { value: "33", label: "Телеканал AZTV", region: "Себайл" },
      { value: "34", label: "Университет языков", region: "Насими" },
      { value: "35", label: "Кинотеатр Азербайджан", region: "Ясамал" },
      {
        value: "36",
        label: "Институт туризма Азербайджана",
        region: "Нариманов",
      },
      { value: "37", label: "Плаза Babek", region: "Хатаи" },
      { value: "38", label: "Университет Baku Asia", region: "Насими" },
      {
        value: "39",
        label: "Бакинский государственный университет",
        region: "Ясамал",
      },
      { value: "40", label: "Академия музыки Баку", region: "Насими" },
      { value: "41", label: "Славянский университет Баку", region: "Насими" },
      { value: "42", label: "Бакинский универмаг", region: "Низами" },
      { value: "43", label: "Торговый центр Baku Mall", region: "Ясамал" },
      { value: "44", label: "Парк Байл", region: "Себайл" },
      { value: "45", label: "Пятиэтажка", region: "Сабунчу" },
      { value: "46", label: "Торговый центр Бинагади", region: "Гарадаг" },
      { value: "47", label: "Ботанический сад", region: "Себайл" },
      { value: "48", label: "Мостовая плаза", region: "Насими" },
      { value: "49", label: "Памятник Джавида", region: "Насими" },
      {
        value: "50",
        label: "Военная академия имени Нахчыванского",
        region: "Хатаи",
      },
      { value: "51", label: "Плаза Каспий", region: "Ясамал" },
      {
        value: "52",
        label: "Торговый центр Caspian Shopping Center",
        region: "Нариманов",
      },
      { value: "53", label: "Мост Чаваншир", region: "Насими" },
      { value: "54", label: "Посольство Китая", region: "Гарадаг" },
      { value: "55", label: "Плаза Чыраг", region: "Насими" },
      { value: "56", label: "Плаза Crystal", region: "Хатаи" },
      { value: "57", label: "Парк Дагустан", region: "Себайл" },
      { value: "58", label: "Плаза Далга", region: "Себайл" },
      { value: "59", label: "Министерство внутренних дел", region: "Себайл" },
      { value: "60", label: "Кинотеатр Дружба", region: "Насими" },
      {
        value: "61",
        label: "Академия государственного управления",
        region: "Себайл",
      },
      { value: "62", label: "Комитет по статистике", region: "Ясамал" },
      { value: "63", label: "Парк Дадаша Горгуда", region: "Нариманов" },
      { value: "64", label: "Плаза Демирчи", region: "Хатаи" },
      { value: "65", label: "Национальный парк у моря", region: "Себайл" },
      {
        value: "66",
        label: "Министерство экологии и природных ресурсов",
        region: "Ясамал",
      },
      { value: "67", label: "Торговый центр Elite", region: "Бинагади" },
      { value: "68", label: "Министерство энергетики", region: "Низами" },
      { value: "69", label: "Отель Fairmont", region: "Себайл" },
      { value: "70", label: "Башни Flame Towers", region: "Себайл" },
      { value: "71", label: "Фонтанный сад", region: "Себайл" },
      { value: "72", label: "Отель Four Seasons", region: "Себайл" },
      {
        value: "73",
        label: "Министерство чрезвычайных ситуаций",
        region: "Ясамал",
      },
      { value: "74", label: "Академия МЧС", region: "Сурахани" },
      { value: "75", label: "Площадь Февраль", region: "Себайл" },
      { value: "76", label: "Гурген", region: "Пирали" },
      {
        value: "77",
        label: "Торговый центр Genclik Mall",
        region: "Нариманов",
      },
      {
        value: "78",
        label: "Министерство молодежи и спорта",
        region: "Нариманов",
      },
      { value: "79", label: "Мечеть Гейдара", region: "Бинагади" },
      { value: "80", label: "Центр имени Гейдара Алиева", region: "Нариманов" },
      { value: "81", label: "Дворец Гейдара Алиева", region: "Насими" },
      { value: "82", label: "Отель Hilton", region: "Себайл" },
      { value: "83", label: "Парк Гусейна Джавида", region: "Ясамал" },
      { value: "84", label: "Отель Hyatt Regency", region: "Ясамал" },
      { value: "85", label: "Военный госпиталь", region: "Насими" },
      { value: "86", label: "Телеканал Ичмайы", region: "Ясамал" },
      { value: "87", label: "Старый город", region: "Себайл" },
      {
        value: "88",
        label: "Институт изящных искусств и культуры",
        region: "Ясамал",
      },
      { value: "89", label: "Университет строительства", region: "Ясамал" },
      { value: "90", label: "Министерство экономики", region: "Низами" },
      { value: "91", label: "Университет экономики", region: "Низами" },
      {
        value: "92",
        label: "Посольство Исламской Республики Иран",
        region: "Себайл",
      },
      { value: "93", label: "Плаза ИСР", region: "Насими" },
      { value: "94", label: "Измировский парк", region: "Ясамал" },
      { value: "95", label: "Кешле базар", region: "Нариманов" },
      { value: "96", label: "Парк коал", region: "Насими" },
      { value: "97", label: "Кооперативный университет", region: "Нариманов" },
      { value: "98", label: "Замок Короглу", region: "Насими" },
      { value: "99", label: "Landmark", region: "Себайл" },
      { value: "100", label: "Лидерский телеканал", region: "Ясамал" },
      { value: "101", label: "Парк Мирзы Хусейна", region: "Насими" },
      { value: "102", label: "Парк Мирзы Сабира", region: "Сабунчу" },
      { value: "103", label: "Министерство финансов", region: "Насими" },
      { value: "104", label: "Megafun", region: "Хатаи" },
      {
        value: "105",
        label: "Университет строительства и архитектуры",
        region: "Ясамал",
      },
      { value: "106", label: "Метропарк", region: "Нариманов" },
      { value: "107", label: "Национальная консерватория", region: "Ясамал" },
      { value: "108", label: "Милли меджлис", region: "Себайл" },
      { value: "109", label: "Служба госбезопасности", region: "Ясамал" },
      { value: "110", label: "Молоканский двор", region: "Себайл" },
      { value: "111", label: "Парк Монтина", region: "Нариманов" },
      { value: "112", label: "Московский университет", region: "Насими" },
      {
        value: "113",
        label: "Министерство обороны промышленности",
        region: "Хатаи",
      },
      { value: "114", label: "MUM", region: "Себайл" },
      { value: "115", label: "Парк Мусабаева", region: "Ясамал" },
      {
        value: "116",
        label: "Центральная больница нефтяников",
        region: "Хатаи",
      },
      { value: "117", label: "Центральный универмаг", region: "Себайл" },
      { value: "118", label: "Торговый центр Наргиз", region: "Себайл" },
      { value: "119", label: "Напольное кольцо", region: "Хатаи" },
      {
        value: "120",
        label:
          "Азербайджанский Государственный Университет Нефти и Промышленности",
        region: "Насими",
      },
      { value: "121", label: "База нефтяников", region: "Нариманов" },
      { value: "122", label: "Метро нефтяников", region: "Низами" },
      { value: "123", label: "Кинотеатр Низами", region: "Насими" },
      { value: "124", label: "Министерство транспорта", region: "Нариманов" },
      { value: "125", label: "Парк Наримана Нариманова", region: "Нариманов" },
      { value: "126", label: "Памятник Нариманову", region: "Ясамал" },
      { value: "127", label: "Базар Насими", region: "Насими" },
      { value: "128", label: "Памятник Насими", region: "Насими" },
      {
        value: "129",
        label: "Университет Огненной Земли",
        region: "Нариманов",
      },
      { value: "130", label: "Олимпийский стадион", region: "Сабунчу" },
      { value: "131", label: "Олимпийская звезда", region: "Нариманов" },
      { value: "132", label: "Дворец гостеприимства Оскар", region: "Низами" },
      { value: "133", label: "Посольство Узбекистана", region: "Сабаил" },
      { value: "134", label: "Парк Азур", region: "Хатаи" },
      { value: "135", label: "Парк Бульвар", region: "Сабаил" },
      { value: "136", label: "Отель Парк Инн", region: "Сабаил" },
      { value: "137", label: "Парк Зорге", region: "Насими" },
      { value: "138", label: "Педагогический университет", region: "Сабаил" },
      { value: "139", label: "Перекешкуль", region: "Абшерон" },
      { value: "140", label: "Порт Баку", region: "Насими" },
      { value: "141", label: "Парк Президента", region: "Хатаи" },
      { value: "142", label: "Отель Пульман", region: "Ясамал" },
      { value: "143", label: "Отели курорта Гянджа", region: "Насими" },
      { value: "144", label: "Университет инженерии", region: "Абшерон" },
      { value: "145", label: "Зимний парк", region: "Ясамал" },
      { value: "146", label: "Девичья башня", region: "Сабаил" },
      { value: "147", label: "Парк Губернатора", region: "Сабаил" },
      {
        value: "148",
        label: "Жилой комплекс 'Освобождение - 93'",
        region: "Абшерон",
      },
      { value: "149", label: "Круг Победы", region: "Ясамал" },
      { value: "150", label: "Университет Запада", region: "Сабаил" },
      {
        value: "151",
        label: "Министерство связи и высоких технологий",
        region: "Насими",
      },
      { value: "152", label: "Республиканский стадион", region: "Нариманов" },
      { value: "153", label: "Посольство России", region: "Насими" },
      { value: "154", label: "Академия живописи", region: "Нариманов" },
      { value: "155", label: "Набережная", region: "Нариманов" },
      { value: "156", label: "Курорт 'Морской бриз'", region: "Сабунчу" },
      { value: "157", label: "Парк Любви", region: "Хатаи" },
      { value: "158", label: "Парк имени Севиль Казиевой", region: "Насими" },
      { value: "159", label: "Жилой комплекс 'Севиндж'", region: "Низами" },
      { value: "160", label: "Цирк", region: "Насими" },
      { value: "161", label: "Советский", region: "Ясамал" },
      { value: "162", label: "Space TV", region: "Ясамал" },
      { value: "163", label: "Торговый центр 'Шувелан Парк'", region: "Хазар" },
      { value: "164", label: "Торговый центр 'Седерек'", region: "Гарадаг" },
      { value: "165", label: "Стадион 'Шафа'", region: "Низами" },
      { value: "166", label: "Аллея Погибших", region: "Сабаил" },
      { value: "167", label: "Министерство здравоохранения", region: "Насими" },
      { value: "168", label: "Парк 'Шелале'", region: "Сабаил" },
      { value: "169", label: "Парк имени Самеда Вургуна", region: "Насими" },
      { value: "170", label: "Спортивный комплекс 'Граница'", region: "Хатаи" },
      { value: "171", label: "Восточный рынок", region: "Нариманов" },
      { value: "172", label: "Таркову", region: "Сабаил" },
      { value: "173", label: "Технический университет", region: "Ясамал" },
      { value: "174", label: "Медицинский университет", region: "Насими" },
      { value: "175", label: "Стадион 'Тофик Бахрамов'", region: "Нариманов" },
      { value: "176", label: "Посольство Турции", region: "Насими" },
      { value: "177", label: "Университет мышления", region: "Нариманов" },
      { value: "178", label: "Министерство образования", region: "Нариманов" },
      { value: "179", label: "Новый рынок", region: "Насими" },
      { value: "180", label: "Посольство Украины", region: "Хатаи" },
      { value: "181", label: "Министерство налогов", region: "Низами" },
      { value: "182", label: "Кинотеатр 'Родина'", region: "Насими" },
      { value: "183", label: "Деловой центр 'Мировой'", region: "Насими" },
      { value: "184", label: "Музей ковров", region: "Сабаил" },
      { value: "185", label: "Министерство иностранных дел", region: "Ясамал" },
      { value: "186", label: "Торговый центр 'Хагани'", region: "Сабаил" },
      { value: "187", label: "Университет Хазар", region: "Низами" },
      { value: "188", label: "Ясамальский рынок", region: "Ясамал" },
      { value: "189", label: "Парк Ясамаль", region: "Ясамал" },
      { value: "190", label: "Зеленый рынок", region: "Нариманов" },
      { value: "191", label: "Парк 'Забитлер'", region: "Насими" },
      { value: "192", label: "Зоопарк", region: "Нариманов" },
      { value: "193", label: "Парк имени Зарифы Алиевой", region: "Бинагади" },
      { value: "194", label: "Министерство юстиции", region: "Ясамал" },
      {
        value: "195",
        label: "Министерство труда и социальной защиты населения",
        region: "Ясамал",
      },
    ],
    flat_type_options: [
      { value: "1", label: "Квартира" },
      { value: "2", label: "Частный дом/Сад/Вилла", label2: "Частный" },
      { value: "3", label: "Офис" },
      { value: "4", label: "Гараж" },
      { value: "5", label: "Земельный участок" },
      { value: "6", label: "Объект" },
    ],
    offer_type_options: [
      { value: "true", label: "Продажа" },
      { value: "false", label: "Аренда" },
    ],
    mortgage_options: [
      { value: "true", label: "Да" },
      { value: "false", label: "Нет" },
    ],
    rent_type_options: [
      { value: "1", label: "Aylıq" },
      { value: "2", label: "Günlük" },
    ],
    filter_options: [
      { value: "dn", label: "По дата (Сначало новые)" },
      { value: "do", label: "По дата (Сначало старые)" },
      { value: "pl", label: "По цена (Сначало дешевые)" },
      { value: "ph", label: "По цена (Сначало дорогие)" },
    ],
    filter_options_blog: [
      { value: "dn", label: "По дата (Сначало новые)" },
      { value: "do", label: "По дата (Сначало старые)" },
      { value: "pl", label: "Просмотры (Сначало меньше)" },
      { value: "ph", label: "Просмотры (Сначало больше)" },
    ],
    type_of_flat: [
      { value: "1", label: "Ежемесячно" },
      { value: "2", label: "По дням" },
    ],
    packets: {
      boost: [
        { dayOrTime: "1", price: "0.49" },
        { dayOrTime: "5", price: "1.99" },
        { dayOrTime: "10", price: "3.99" },
      ],
      frame: [
        { dayOrTime: "1", price: "0.99" },
        { dayOrTime: "5", price: "2.49" },
        { dayOrTime: "10", price: "4.49" },
      ],
      urgent: [
        { dayOrTime: "1", price: "1.99" },
        { dayOrTime: "5", price: "5.99" },
        { dayOrTime: "10", price: "9.99" },
      ],
      premium: [
        { dayOrTime: "1", price: "2.49" },
        { dayOrTime: "5", price: "10.99" },
        { dayOrTime: "10", price: "19.99" },
        { dayOrTime: "30", price: "39.99" },
      ],
    },
    blog:"Блог",
    blogAll:"Все",
    blogMore:"Подробнее...",
    blogNotFound:"Блог не найден"
  },
  en: {
    allFavouriteOffersTitle: "Ebaz.az - All selected listings",
    allPremiumOffersTitle: "Ebaz.az - All premium listings",
    loginTitle: "Ebaz.az - Log in",
    registrationTitle: "Ebaz.az - Registration",
    myOffersTitle: "Ebaz.az - Profile",
    newOffersTitle: "Ebaz.az - New listing",
    notFoundTitle: "Ebaz.az - Error 404",
    paymentErrorTitle: "Ebaz.az - Payment unsuccessful!",
    paymentSuccessTitle: "Ebaz.az - Payment successful!",
    newOfferTitle: "Ebaz.az - Change listing",
    findOffer: "Find listing",
    offersCommon: "Listings",
    favourites: "Favorites",
    profile: "Profile",
    signIn: "Sign In",
    uploadOffer: "Upload listing",
    sale: "For Sale",
    rent: "For Rent",
    day: "For Day",
    clean: "Clean",
    add: "Add",
    cityName: "City",
    flatTypeName: "Property Type",
    roomsCountName: "Rooms",
    priceName: "Price:",
    pricePerM2Name: "Square meter price: AZN/m2",
    pricePerSotName: "Acr price:  AZN/acr",
    min: "min.",
    max: "max.",
    areaName: "Area",
    sot: "acr",
    m2: "m²",
    regionName: "Region",
    residentName: "Resident",
    stationName: "Station",
    targetName: "Target",
    floorName: "Floor:",
    siteName: "Websites",
    siteNameEnter: "Select websites",
    withGoodsName: "Furnished",
    withoutGoodsName: "Unfurnished",
    repairName: "With Repair",
    withoutRepairName: "Without Repair",
    withDocumentsName: "With Documents",
    withoutDocumentsName: "Without Documents",
    mortgageName: "Mortgage",
    showOffersName: "Show listings",
    searchName: "Search",
    historyName: "Search History",
    additionalsearchName: "Advanced Search",
    lastFiveSearchName: "Last 5 Searches",
    allFloorNumberName: "Total number of floors",
    sale2: "Sale",
    clean2: "Clear",
    sloqan: "All real estate in one place!",
    usersAgree: "User agreement",
    privicy: "Privacy policy",
    services: "Services",
    connect: "Contact",
    analytics: "Analytics",
    predicted: "Expected",
    predictedHigh: "increase",
    predictedLow: "low",
    disclaimer:
      "*The site administration is not responsible for the content of advertising banners and placed listings.",
    enterAddress: "Enter address",
    showAll: "Show all",
    premiumOffers: "Premium listings",
    saleFlats: "Sale flats",
    rentFlats: "Rent flats",
    saleCountry: "Sale houses/cottages/villas",
    rentCountry: "Rent houses/cottages/villas",
    saleOffice: "Sale offices",
    rentOffice: "Rent offices",
    saleGarage: "Sale garages",
    rentGarage: "Rent garages",
    login: "Login",
    saleLand: "Sale land",
    rentLand: "Rent land",
    saleCommercial: "Sale commercial property",
    rentCommercial: "Rent commercial property",
    searchOffersLoading: "Searching listings",
    logout: "Logout",
    myOffersName: "My listings",
    myOffersActiveName: "Active",
    myOffersDeactiveName: "Deactivated",
    myOffersReductName: "Edit",
    myOffersDeleteFromOffersName: "Remove from listings",
    myOffersRefreshOfferName: "Refresh listing",
    myOffersDontHaveOffersName: "You don't have any listings.",
    myOffersDontHaveActiveOffersName: "You don't have any active listings.",
    myOffersDontHaveDeactiveOffersName:
      "You don't have any deactivated listings.",
    myOffersNameSurnameChangeName: "Change Name and Surname",
    myOffersNameChangeName: "Name",
    myOffersLastnameChangeName: "Surname",
    myOffersNameChangeRequareName: "*Please enter your name",
    myOffersLastnameChangeRequareName: "*Please enter your surname",
    myOffersSetNameChangeName: "Enter your name",
    myOffersSetLastnameChangeName: "Enter your surname",
    myOffersNameLastnameChangedSuccessfullyName: "Successfully updated",
    myOffersNameLastnameChangeButtonName: "Change",
    residentsSmall: " res.",
    WithRoomsSmall: " rooms",
    floorSmall: "floor",
    todaySmall: "Today",
    yesterdaySmall: "Yesterday",
    searchSmall: "Search",
    newOfferSmall: "New listing",
    cabinetSmall: "Cabinet",
    moreSmall: "More",
    menuSmall: "Menu",
    languageSmall: "Language",
    languageBig: "English",
    languageAZ: "Azərbaycan",
    languageRU: "Русский",
    languageEN: "English",
    yesSmall: "Yes",
    noSmall: "No",
    aboutOffer: "About the property",
    urgentSmall: "Urgent",
    offersFilterSloqan: "City, Real Estate, Price, Area, Rooms, Repair...",
    offersFound: "Listings ",
    listSmall: "List",
    mapSmall: "Map",
    mapSmallShow: "Show map",
    mapSmallDontShow: "Hide map",
    notFoundOffers: "Unfortunately, no listings were found for your search.",
    writeLinkForSearch: "Enter the link of the ad you want to search",
    supportedSitesSearch:
      "Supported sites: tap.az, bina.az, lalafo.az, arenda.az, emlak.az, yeniemlak.az, rahatemlak.az, ucuztap.az",
    showOfferSearch: "Show ad",
    favouriteOffersCount: "Favorite listings",
    notFavouritesOffers: "No favorite listings.",
    seeAllOffers: "See all listings",
    phoneNumberName: "Mobile number",
    writePhoneNumber: "*Please enter your number",
    sendSMStoPhone: "An SMS code has been sent to your number",
    sendRepeatSMStoPhone: "Resend SMS code",
    pleaseWriteSMSCode: "*Please enter the SMS code",
    pleaseWriteSMSCodeCorrect: "*Please enter the correct SMS code",
    saveUser: "Save",
    userNotFound: "*User not found",
    loginWithBigI: "LOG IN",
    notProfileRegistration: "Don't have an account? Register",
    profileExistLogin: "Already have an account? Log in",
    registration: "Registration",
    setEmail: "Email Address",
    userAlreadyExist: "*This user is already registered",
    doRegistration: "Register",
    newOffer_flatType: "Property type, ",
    newOffer_offerType: "Listing type, ",
    newOffer_rentPeriod: "Rent period, ",
    newOffer_AreaM2: "Area m², ",
    newOffer_AreaSot: "Area acr, ",
    newOffer_City: "City, ",
    newOffer_Price: "Price, ",
    newOffer_Desc: "Description, ",
    newOffer_Images: "Images (minimum 1 image, maximum 10 images), ",
    newOffer_Name: "Name, ",
    newOffer_Email: "E-mail, ",
    newOffer_PhoneNum: "Please enter a correct mobile number, ",
    videoSmall: "Video",
    addVideoSmall: "+Add video",
    watchVideo:"Watch video",
    imagesSmall: "Images",
    addImagesSmall: "+Add image",
    addImagesMaxCountSmall: "*Maximum 10 images",
    writeInfoInThisFields: "enter information in these fields.",
    sendOffer: "Send listing",
    simpleRules: "Simple rules",
    simpleRules1: "Do not post the same listing multiple times.",
    simpleRules2:
      "Do not include phone numbers, email addresses, or website URLs in the description or images.",
    simpleRules3:
      "Do not include the price in the 'Name' field - there is a separate field for that.",
    simpleRules4: "Post your first ad and get \"Framing\" and \"Boost\" from us within 2 days.",
    offerView: "Listing view",
    pageNotFoundSmall: "Page not found",
    mainPageSmall: "Main page.",
    boostNameSmall: "Boost",
    makePremiumNameSmall: "Premium",
    deleteFromFavouritesNameSmall: "Remove from favorites",
    positionOfOffer: "Position of the offer",
    addToFavouritesNameSmall: "Add to favorites",
    priceByAgreement: "Price by agreement",
    linkCopied: "Link copied",
    similarOffers: "Similar listings",
    boostOffer: "Boost",
    frameOffer: "Framed",
    fromPrice1: "From 0.49 AZN",
    fromPrice2: "From 0.99 AZN",
    fromPrice3: "From 1.99 AZN",
    boostOfferDescription:
      "The listing will be boosted to the top among all listings.",
    frameOfferDescription:
      "The listing will be framed and boosted to the top among all listings.",
    urgentOfferDescription:
      "The listing will be framed in red and marked as 'Urgent', it will also be boosted to the top among all listings.",
    paidUntill: "Paid until",
    serviceTime: "Service time",
    times: " times",
    boostFor24Hours: "(Boost every 24 hours)",
    boostName: "(+ Boost)",
    paymentMethod: "Payment method",
    bankCard: "Bank card",
    addressSmall: "Address",
    cancelPayment: "Cancel payment",
    makePayment: "Make payment",
    paymentAgreement:
      'By clicking "Make payment", you agree to the User Agreement and Offer of the ebaz.az website.',
    makeOfferPremiumName: "Make ad premium",
    makePremiumDescription:
      "The listing will be displayed in the premium listings section on the main page for the duration of the service and will be promoted for free.",
    days: " days",
    perDays: "/day",
    errorName: "Error",
    errorDescription: "There was an error in your payment, please try again.",
    errorDescription2: "An error occurred. Please try again.",
    successName: "Successful Payment",
    successDescription:
      "Your payment has been successfully recorded, your listing will be updated shortly.",
    successName2: "Successful",
    successDescription2UpdateOffer: "The listing was successfully updated.",
    successDescription2DeleteOffer: "The listing was deleted.",
    successDescription2RefreshOffer: "The listing was successfully refreshed.",
    successDescription2RegistrationOffer: "You have successfully registered.",
    successGoToOffers: "Go to your listings.",
    updateOfferName: "Update listing",
    allRightsReserved: "All rights reserved.",
    siteMap: "Site map",
    notUploadedOffer:"The listing has not been uploaded.",
    uploadedOffer:"Your listing has been shared!",
    showToMorePeople: "Get more people to see your listing!",
    miniBannerWords: ["Post your first listing and get \"Framing\" and \"Boost\" from us within 2 days."],
    firstOfferWord: "Since it's your first listing, we gave you a gift of \"Framing\" and \"Boost\" for 2 days!",
    saleDeleted:"Removed from sale",
    rentDeleted:"Removed from rent",
    cities: [
      {
        value: "0",
        label: "Agcabedi",
        center: { lat: 40.053, lng: 47.4554 },
        zoom: "10",
      },
      {
        value: "1",
        label: "Agdam",
        center: { lat: 39.9911, lng: 46.9297 },
        zoom: "10",
      },
      {
        value: "2",
        label: "Agdash",
        center: { lat: 40.6335, lng: 47.4674 },
        zoom: "10",
      },
      {
        value: "3",
        label: "Agdere",
        center: { lat: 40.2116, lng: 46.8237 },
        zoom: "10",
      },
      {
        value: "4",
        label: "Agstafa",
        center: { lat: 41.1125, lng: 45.4477 },
        zoom: "10",
      },
      {
        value: "5",
        label: "Agsu",
        center: { lat: 40.5283, lng: 48.3651 },
        zoom: "10",
      },
      {
        value: "6",
        label: "Astara",
        center: { lat: 38.4688, lng: 48.8728 },
        zoom: "10",
      },
      {
        value: "7",
        label: "Babek",
        center: { lat: 39.1508, lng: 45.4485 },
        zoom: "10",
      },
      {
        value: "8",
        label: "Baku",
        center: { lat: 40.3953, lng: 49.8822 },
        zoom: "10",
      },
      {
        value: "9",
        label: "Balakan",
        center: { lat: 41.7038, lng: 46.4044 },
        zoom: "10",
      },
      {
        value: "10",
        label: "Beylagan",
        center: { lat: 39.7723, lng: 47.6154 },
        zoom: "10",
      },
      {
        value: "11",
        label: "Barda",
        center: { lat: 40.3707, lng: 47.1379 },
        zoom: "10",
      },
      {
        value: "12",
        label: "Bilasuvar",
        center: { lat: 39.4599, lng: 48.551 },
        zoom: "10",
      },
      {
        value: "13",
        label: "Jabrayil",
        center: { lat: 39.3987, lng: 47.0245 },
        zoom: "10",
      },
      {
        value: "14",
        label: "Jalilabad",
        center: { lat: 39.2052, lng: 48.5101 },
        zoom: "10",
      },
      {
        value: "15",
        label: "Julfa",
        center: { lat: 38.9605, lng: 45.6293 },
        zoom: "10",
      },
      {
        value: "16",
        label: "Dashkasan",
        center: { lat: 40.5202, lng: 46.0779 },
        zoom: "10",
      },
      {
        value: "17",
        label: "Delimammadli",
        center: { lat: 40.6844, lng: 46.5728 },
        zoom: "10",
      },
      {
        value: "18",
        label: "Fuzuli",
        center: { lat: 39.6025, lng: 47.1392 },
        zoom: "10",
      },
      {
        value: "19",
        label: "Gedabey",
        center: { lat: 40.57, lng: 45.8107 },
        zoom: "10",
      },
      {
        value: "20",
        label: "Ganja",
        center: { lat: 40.6579, lng: 46.3523 },
        zoom: "10",
      },
      {
        value: "21",
        label: "Goranboy",
        center: { lat: 40.6073, lng: 46.7807 },
        zoom: "10",
      },
      {
        value: "22",
        label: "Goychay",
        center: { lat: 40.6236, lng: 47.7403 },
        zoom: "10",
      },
      {
        value: "23",
        label: "Goygol",
        center: { lat: 40.5895, lng: 46.3271 },
        zoom: "10",
      },
      {
        value: "24",
        label: "Goytepe",
        center: { lat: 39.1171, lng: 48.5844 },
        zoom: "10",
      },
      {
        value: "25",
        label: "Hajigabul",
        center: { lat: 40.0394, lng: 48.9203 },
        zoom: "10",
      },
      {
        value: "26",
        label: "Horadiz",
        center: { lat: 39.4511, lng: 47.3399 },
        zoom: "10",
      },
      {
        value: "27",
        label: "Khachmaz",
        center: { lat: 41.4591, lng: 48.8021 },
        zoom: "10",
      },
      {
        value: "28",
        label: "Stepanakert",
        center: { lat: 39.8265, lng: 46.7656 },
        zoom: "10",
      },
      {
        value: "29",
        label: "Khojaly",
        center: { lat: 39.9133, lng: 46.7943 },
        zoom: "10",
      },
      {
        value: "30",
        label: "Khojavend",
        center: { lat: 39.7915, lng: 47.1101 },
        zoom: "10",
      },
      {
        value: "31",
        label: "Khyzy",
        center: { lat: 40.9109, lng: 49.0729 },
        zoom: "10",
      },
      {
        value: "32",
        label: "Khirdalan",
        center: { lat: 40.4529, lng: 49.7333 },
        zoom: "10",
      },
      {
        value: "33",
        label: "Khudat",
        center: { lat: 41.6253, lng: 48.6764 },
        zoom: "10",
      },
      {
        value: "34",
        label: "Imishli",
        center: { lat: 39.8695, lng: 48.0665 },
        zoom: "10",
      },
      {
        value: "35",
        label: "Ismayilli",
        center: { lat: 40.7871, lng: 48.1523 },
        zoom: "10",
      },
      {
        value: "36",
        label: "Kelbajar",
        center: { lat: 40.1024, lng: 46.0365 },
        zoom: "10",
      },
      {
        value: "37",
        label: "Kurdamir",
        center: { lat: 40.3699, lng: 48.1645 },
        zoom: "10",
      },
      {
        value: "38",
        label: "Kengerli",
        center: { lat: 39.3872, lng: 45.164 },
        zoom: "10",
      },
      {
        value: "39",
        label: "Qakh",
        center: { lat: 41.4207, lng: 46.932 },
        zoom: "10",
      },
      {
        value: "40",
        label: "Qazakh",
        center: { lat: 41.0971, lng: 45.3516 },
        zoom: "10",
      },
      {
        value: "41",
        label: "Gabala",
        center: { lat: 40.9982, lng: 47.87 },
        zoom: "10",
      },
      {
        value: "42",
        label: "Gobustan",
        center: { lat: 40.5326, lng: 48.9274 },
        zoom: "10",
      },
      {
        value: "43",
        label: "Goblar",
        center: { lat: 40.9364, lng: 45.7475 },
        zoom: "10",
      },
      {
        value: "44",
        label: "Quba",
        center: { lat: 41.3643, lng: 48.5261 },
        zoom: "10",
      },
      {
        value: "45",
        label: "Qubadli",
        center: { lat: 39.3453, lng: 46.5789 },
        zoom: "10",
      },
      {
        value: "46",
        label: "Qusar",
        center: { lat: 41.4275, lng: 48.4302 },
        zoom: "10",
      },
      {
        value: "47",
        label: "Lachin",
        center: { lat: 39.6383, lng: 46.5461 },
        zoom: "10",
      },
      {
        value: "48",
        label: "Lerik",
        center: { lat: 38.7736, lng: 48.4151 },
        zoom: "10",
      },
      {
        value: "49",
        label: "Lenkaran",
        center: { lat: 38.7529, lng: 48.8475 },
        zoom: "10",
      },
      {
        value: "50",
        label: "Liman",
        center: { lat: 41.312, lng: 72.9278 },
        zoom: "10",
      },
      {
        value: "51",
        label: "Masalli",
        center: { lat: 39.0341, lng: 48.6589 },
        zoom: "10",
      },
      {
        value: "52",
        label: "Mingachevir",
        center: { lat: 40.7703, lng: 47.0496 },
        zoom: "10",
      },
      {
        value: "53",
        label: "Naftalan",
        center: { lat: 40.5076, lng: 46.824 },
        zoom: "10",
      },
      {
        value: "54",
        label: "Nakhchivan",
        center: { lat: 39.2009, lng: 45.4082 },
        zoom: "10",
      },
      {
        value: "55",
        label: "Neftchala",
        center: { lat: 39.3881, lng: 49.2414 },
        zoom: "10",
      },
      {
        value: "56",
        label: "Oghuz",
        center: { lat: 41.0728, lng: 47.4651 },
        zoom: "10",
      },
      {
        value: "57",
        label: "Ordubad",
        center: { lat: 38.9022, lng: 46.0238 },
        zoom: "10",
      },
      {
        value: "58",
        label: "Saatli",
        center: { lat: 39.9096, lng: 48.3595 },
        zoom: "10",
      },
      {
        value: "59",
        label: "Sabirabad",
        center: { lat: 39.9871, lng: 48.4693 },
        zoom: "10",
      },
      {
        value: "60",
        label: "Salyan",
        center: { lat: 39.5779, lng: 48.9719 },
        zoom: "10",
      },
      {
        value: "61",
        label: "Samukh",
        center: { lat: 40.7605, lng: 46.4063 },
        zoom: "10",
      },
      {
        value: "62",
        label: "Sederek",
        center: { lat: 39.7105, lng: 44.8864 },
        zoom: "10",
      },
      {
        value: "63",
        label: "Siazan",
        center: { lat: 41.0784, lng: 49.1118 },
        zoom: "10",
      },
      {
        value: "64",
        label: "Sumqayit",
        center: { lat: 40.5855, lng: 49.6317 },
        zoom: "10",
      },
      {
        value: "65",
        label: "Shabran",
        center: { lat: 41.216, lng: 48.9946 },
        zoom: "10",
      },
      {
        value: "66",
        label: "Shahbuz",
        center: { lat: 39.4056, lng: 45.5691 },
        zoom: "10",
      },
      {
        value: "67",
        label: "Shamakhi",
        center: { lat: 40.6319, lng: 48.6364 },
        zoom: "10",
      },
      {
        value: "68",
        label: "Sheki",
        center: { lat: 41.1975, lng: 47.1571 },
        zoom: "10",
      },
      {
        value: "69",
        label: "Shamkir",
        center: { lat: 40.8288, lng: 46.0167 },
        zoom: "10",
      },
      {
        value: "70",
        label: "Shirur",
        center: { lat: 39.5536, lng: 44.9846 },
        zoom: "10",
      },
      {
        value: "71",
        label: "Shirvan",
        center: { lat: 39.9266, lng: 48.9206 },
        zoom: "10",
      },
      {
        value: "72",
        label: "Shusha",
        center: { lat: 39.7537, lng: 46.7465 },
        zoom: "10",
      },
      {
        value: "73",
        label: "Tartar",
        center: { lat: 40.3444, lng: 46.9377 },
        zoom: "10",
      },
      {
        value: "74",
        label: "Tovuz",
        center: { lat: 40.9955, lng: 45.6166 },
        zoom: "10",
      },
      {
        value: "75",
        label: "Ujar",
        center: { lat: 40.5068, lng: 47.649 },
        zoom: "10",
      },
      {
        value: "76",
        label: "Yardimli",
        center: { lat: 38.9059, lng: 48.2496 },
        zoom: "10",
      },
      {
        value: "77",
        label: "Yevlakh",
        center: { lat: 40.6197, lng: 47.15 },
        zoom: "10",
      },
      {
        value: "78",
        label: "Zaqatala",
        center: { lat: 41.6031, lng: 46.6383 },
        zoom: "10",
      },
      {
        value: "79",
        label: "Zangilan",
        center: { lat: 39.0857, lng: 46.6525 },
        zoom: "10",
      },
      {
        value: "80",
        label: "Zardab",
        center: { lat: 40.2148, lng: 47.7149 },
        zoom: "10",
      },
    ],
    region_options: [
      { value: "0", label: "Absheron" },
      { value: "1", label: "Binagadi" },
      { value: "2", label: "Narimanov" },
      { value: "3", label: "Nasimi" },
      { value: "4", label: "Nizami" },
      { value: "5", label: "Qaradag" },
      { value: "6", label: "Sabunchu" },
      { value: "7", label: "Sabail" },
      { value: "8", label: "Surakhani" },
      { value: "9", label: "Khatai" },
      { value: "10", label: "Khazar" },
      { value: "11", label: "Yasamal" },
      { value: "12", label: "Pirallahi" },
    ],
    site_options: [
      { value: "ebaz.az", label: "ebaz.az" },
      { value: "tap.az", label: "tap.az" },
      { value: "bina.az", label: "bina.az" },
      { value: "lalafo.az", label: "lalafo.az" },
      { value: "emlak.az", label: "emlak.az" },
      { value: "arenda.az", label: "arenda.az" },
      { value: "yeniemlak.az", label: "yeniemlak.az" },
      { value: "rahatemlak.az", label: "rahatemlak.az" },
      { value: "ucuztap.az", label: "ucuztap.az" },
    ],
    room_options: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "50", label: "5 +" },
    ],
    station_options: [
      { label: "Icherisheher metro station", value: "1" },
      { label: "Sahil metro station", value: "2" },
      { label: "28 May metro station", value: "3" },
      { label: "Ganjlik metro station", value: "4" },
      { label: "Nariman Narimanov metro station", value: "5" },
      { label: "Bakmil metro station", value: "6" },
      { label: "Ulduz metro station", value: "7" },
      { label: "Koroglu metro station", value: "8" },
      { label: "Qara Qarayev metro station", value: "9" },
      { label: "Neftchilar metro station", value: "10" },
      { label: "Xalqlar Dostluğu metro station", value: "11" },
      { label: "Ahmadli metro station", value: "12" },
      { label: "Hazi Aslanov metro station", value: "13" },
      { label: "Nizami Ganjavi metro station", value: "14" },
      { label: "Elmler Akademiyasi metro station", value: "15" },
      { label: "Inshaatchilar metro station", value: "16" },
      { label: "20 Yanvar metro station", value: "17" },
      { label: "Memar Ajami metro station", value: "18" },
      { label: "Nasimi metro station", value: "19" },
      { label: "Azadliq prospekti metro station", value: "20" },
      { label: "Dernegul metro station", value: "21" },
      { label: "Jafar Jabbarli metro station", value: "22" },
      { label: "Shah Ismayil Khatai metro station", value: "23" },
      { label: "Avtovagzal metro station", value: "24" },
      { label: "8 November metro station", value: "25" },
      { label: "Khatai metro station", value: "26" },
    ],
    resident_options: [
      { value: "1", label: "1st microdistrict", region: "Nasimi" },
      { value: "2", label: "2nd microdistrict", region: "Nasimi" },
      { value: "3", label: "20th sector", region: "Sahil" },
      { value: "4", label: "28 May", region: "Binagadi" },
      { value: "5", label: "3rd microdistrict", region: "Nasimi" },
      { value: "6", label: "4th microdistrict", region: "Nasimi" },
      { value: "7", label: "5th microdistrict", region: "Nasimi" },
      { value: "8", label: "6th microdistrict", region: "Binagadi" },
      { value: "9", label: "6th parallel", region: "Binagadi" },
      { value: "10", label: "7th microdistrict", region: "Binagadi" },
      { value: "11", label: "8 km", region: "Nizami" },
      { value: "12", label: "8th microdistrict", region: "Binagadi" },
      { value: "13", label: "9th microdistrict", region: "Binagadi" },
      { value: "14", label: "White City", region: "Khatai" },
      { value: "15", label: "Alatava 1", region: "Binagadi" },
      { value: "16", label: "Alatava 2", region: "Binagadi" },
      { value: "17", label: "Albaly", region: "Sabunchu" },
      { value: "18", label: "Atyali", region: "Absheron" },
      { value: "19", label: "Badamdar", region: "Sahil" },
      { value: "20", label: "Bahar", region: "Surakhani" },
      { value: "21", label: "Bakikhanov", region: "Sabunchu" },
      { value: "22", label: "Balakhany", region: "Sabunchu" },
      { value: "23", label: "Bayil", region: "Sahil" },
      { value: "24", label: "Bibiheybat", region: "Sahil" },
      { value: "25", label: "Bilgeh", region: "Sabunchu" },
      { value: "26", label: "Bilajari", region: "Binagadi" },
      { value: "27", label: "Bina", region: "Khazar" },
      { value: "28", label: "Binagadi", region: "Binagadi" },
      { value: "29", label: "Büyükshor", region: "Narimanov" },
      { value: "30", label: "Bulbul", region: "Surakhani" },
      { value: "31", label: "Buzovna", region: "Khazar" },
      { value: "32", label: "Chermet", region: "" },
      { value: "33", label: "Ceyranbatan", region: "Absheron" },
      { value: "34", label: "Chichek", region: "Absheron" },
      { value: "35", label: "Corat", region: "" },
      { value: "36", label: "Digah", region: "Absheron" },
      { value: "37", label: "Dubendi vineyards", region: "Khazar" },
      { value: "38", label: "Dede Gorgud", region: "Surakhani" },
      { value: "39", label: "Fatmai", region: "Absheron" },
      { value: "40", label: "Goradil", region: "Absheron" },
      { value: "41", label: "Gurgan", region: "Khazar" },
      { value: "42", label: "Guzdek", region: "Absheron" },
      { value: "43", label: "Hokmeli", region: "Absheron" },
      { value: "44", label: "Hovsan", region: "Surakhani" },
      { value: "45", label: "Heydar Aliyev", region: "Khatai" },
      { value: "46", label: "Keshla", region: "Nizami" },
      { value: "47", label: "Chemist town", region: "Nasimi" },
      { value: "48", label: "Old Gunesli", region: "Khatai" },
      { value: "49", label: "Kurdakhani", region: "Sabunchu" },
      { value: "50", label: "Lokbatan", region: "Garadagh" },
      { value: "51", label: "Lahic Gardens", region: "Sabunchu" },
      { value: "52", label: "Masazir", region: "Absheron" },
      { value: "53", label: "Mashtagha", region: "Sabunchu" },
      { value: "54", label: "Mehdiabad", region: "Absheron" },
      { value: "55", label: "Montin", region: "Narimanov" },
      { value: "56", label: "Mushfigabad", region: "Garadagh" },
      { value: "57", label: "Mekhamedli", region: "Absheron" },
      { value: "58", label: "Mardakan", region: "Khazar" },
      { value: "59", label: "Nardaran", region: "Sabunchu" },
      { value: "60", label: "Nasosnaya", region: "" },
      { value: "61", label: "Novkhani", region: "Absheron" },
      { value: "62", label: "Nubar", region: "" },
      { value: "63", label: "NZS", region: "Khatai" },
      { value: "64", label: "Papanin", region: "" },
      { value: "65", label: "Perekeskul", region: "Absheron" },
      { value: "66", label: "Pirshagi", region: "Sabunchu" },
      { value: "67", label: "Puta", region: "Garadagh" },
      { value: "68", label: "Fortress", region: "Khazar" },
      { value: "69", label: "Black City", region: "" },
      { value: "70", label: "Qarachukhur", region: "Surakhani" },
      { value: "71", label: "Qizildash", region: "Garadagh" },
      { value: "72", label: "Qobu", region: "Absheron" },
      { value: "73", label: "Gobustan", region: "Garadagh" },
      { value: "74", label: "Ramana", region: "Sabunchu" },
      { value: "75", label: "Rasulzade", region: "Binagadi" },
      { value: "76", label: "Sabunchu", region: "Sabunchu" },
      { value: "77", label: "Shagan", region: "Khazar" },
      { value: "78", label: "Coastal", region: "Garadagh" },
      { value: "79", label: "Palace", region: "Absheron" },
      { value: "80", label: "Savalan", region: "Sabunchu" },
      { value: "81", label: "Shikhov", region: "Sahil" },
      { value: "82", label: "Shubani", region: "Garadagh" },
      { value: "83", label: "Sulutepe", region: "Binagadi" },
      { value: "84", label: "Shusha", region: "Sabunchu" },
      { value: "85", label: "Shuvalan", region: "Khazar" },
      { value: "86", label: "Sengechal", region: "Garadagh" },
      { value: "87", label: "Turkan", region: "Khazar" },
      { value: "88", label: "Umid", region: "Garadagh" },
      { value: "89", label: "Khashakhuna", region: "Khazar" },
      { value: "90", label: "Khodjasan", region: "Binagadi" },
      { value: "91", label: "Khyutor", region: "Binagadi" },
      { value: "92", label: "New Gunesli", region: "Surakhani" },
      { value: "93", label: "New Ramana", region: "Sabunchu" },
      { value: "94", label: "New Surakhani", region: "Surakhani" },
      { value: "95", label: "New Yasamal", region: "Yasamal" },
      { value: "96", label: "Zabrat 1", region: "Sabunchu" },
      { value: "97", label: "Zabrat 2", region: "Sabunchu" },
      { value: "98", label: "Zagulba", region: "Absheron" },
      { value: "99", label: "Zig", region: "Surakhani" },
      { value: "100", label: "Zire", region: "Khazar" },
      { value: "101", label: "Ahmadli", region: "Khatai" },
      { value: "102", label: "Alet", region: "Garadagh" },
      { value: "103", label: "Amirjan", region: "Surakhani" },
    ],
    target_options: [
      { value: "1", label: "28 Mall", region: "Nasimi" },
      { value: "2", label: "A.S.Pushkin Park", region: "Nasimi" },
      { value: "3", label: "AAF PARK", region: "Absheron" },
      { value: "4", label: "US Embassy", region: "Nasimi" },
      { value: "5", label: "Absheron Youth City", region: "Absheron" },
      { value: "6", label: "Absheron Marriott Hotel", region: "Nasimi" },
      { value: "7", label: "Abu Arena", region: "Narimanov" },
      { value: "8", label: "ADA University", region: "Narimanov" },
      { value: "9", label: "AF Business House", region: "Nasimi" },
      { value: "10", label: "AGA Business Center", region: "Khatai" },
      { value: "11", label: "Altes Plaza", region: "Yasamal" },
      { value: "12", label: "AMAY", region: "Khatai" },
      { value: "13", label: "Ambassador Hotel", region: "Narimanov" },
      { value: "14", label: "ANS TV Channel", region: "Sabail" },
      { value: "15", label: "Aqua Park", region: "Nasimi" },
      { value: "16", label: "Araz Cinema", region: "Binegedi" },
      { value: "17", label: "ASAN Service-1", region: "Narimanov" },
      { value: "18", label: "ASAN Service-2", region: "Khatai" },
      { value: "19", label: "ASAN Service-3", region: "Yasamal" },
      { value: "20", label: "ASAN Service-4", region: "Sabunchu" },
      { value: "21", label: "ASAN Service-5", region: "Nizami" },
      { value: "22", label: "ASAN Service-6", region: "Khatai" },
      { value: "23", label: "ASK Plaza", region: "Nasimi" },
      { value: "24", label: "Ataturk Park", region: "Narimanov" },
      { value: "25", label: "ATV TV Channel", region: "Yasamal" },
      {
        value: "26",
        label: "National Aviation Academy University",
        region: "Khazar",
      },
      { value: "27", label: "Europe Hotel", region: "Nasimi" },
      { value: "28", label: "Axundov Garden", region: "Sabail" },
      { value: "29", label: "Aygun City", region: "Sabunchu" },
      { value: "30", label: "Ayna Sultanova Monument", region: "Narimanov" },
      { value: "31", label: "Azadlig Square", region: "Sabail" },
      { value: "32", label: "Azneft Square", region: "Sabail" },
      { value: "33", label: "AZTV TV Channel", region: "Sabail" },
      {
        value: "34",
        label: "Azerbaijan Languages University",
        region: "Nasimi",
      },
      { value: "35", label: "Azerbaijan Cinema", region: "Yasamal" },
      {
        value: "36",
        label: "Azerbaijan Tourism Institute",
        region: "Narimanov",
      },
      { value: "37", label: "Babek Plaza", region: "Khatai" },
      { value: "38", label: "Baku Asia University", region: "Nasimi" },
      { value: "39", label: "Baku State University", region: "Yasamal" },
      { value: "40", label: "Baku Music Academy", region: "Nasimi" },
      { value: "41", label: "Baku Slavic University", region: "Nasimi" },
      { value: "42", label: "Baku University", region: "Nizami" },
      { value: "43", label: "Baku Mall", region: "Yasamal" },
      { value: "44", label: "Bayil Park", region: "Sabail" },
      { value: "45", label: "Five-Story", region: "Sabunchu" },
      { value: "46", label: "Bina Trade Center", region: "Garadagh" },
      { value: "47", label: "Botanical Garden", region: "Sabail" },
      { value: "48", label: "Bridge Plaza", region: "Nasimi" },
      { value: "49", label: "J.Jabbarli Monument", region: "Nasimi" },
      {
        value: "50",
        label: "Military Academy named after Heydar Aliyev",
        region: "Khatai",
      },
      { value: "51", label: "Caspian Plaza", region: "Yasamal" },
      { value: "52", label: "Caspian Shopping Center", region: "Narimanov" },
      { value: "53", label: "Youth Bridge", region: "Nasimi" },
      { value: "54", label: "Chinese Embassy", region: "Garadagh" },
      { value: "55", label: "Chirag Plaza", region: "Nasimi" },
      { value: "56", label: "Crystal Plaza", region: "Khatai" },
      { value: "57", label: "Mountain Park", region: "Sabail" },
      { value: "58", label: "Wave Plaza", region: "Sabail" },
      { value: "59", label: "Ministry of Internal Affairs", region: "Sabail" },
      { value: "60", label: "Friendship Cinema", region: "Nasimi" },
      {
        value: "61",
        label: "Academy of Public Administration",
        region: "Sabail",
      },
      { value: "62", label: "State Statistics Committee", region: "Yasamal" },
      { value: "63", label: "Grandfather Koroglu Park", region: "Narimanov" },
      { value: "64", label: "Demirchi Plaza", region: "Khatai" },
      { value: "65", label: "Seaside National Park", region: "Sabail" },
      {
        value: "66",
        label: "Ministry of Ecology and Natural Resources",
        region: "Yasamal",
      },
      { value: "67", label: "Elite Shopping Center", region: "Binegedi" },
      { value: "68", label: "Ministry of Energy", region: "Nizami" },
      { value: "69", label: "Fairmont Hotel", region: "Sabail" },
      { value: "70", label: "Flame Towers", region: "Sabail" },
      { value: "71", label: "Fountain Garden", region: "Sabail" },
      { value: "72", label: "Four Seasons Hotel", region: "Sabail" },
      {
        value: "73",
        label: "Ministry of Emergency Situations",
        region: "Yasamal",
      },
      {
        value: "74",
        label: "Academy of the Ministry of Emergency Situations",
        region: "Surakhani",
      },
      { value: "75", label: "Heroes Square", region: "Sabail" },
      { value: "76", label: "Gurgen", region: "Pirallahi" },
      { value: "77", label: "Ganjlik Mall", region: "Narimanov" },
      {
        value: "78",
        label: "Ministry of Youth and Sports",
        region: "Narimanov",
      },
      { value: "79", label: "Heydar Mosque", region: "Binegedi" },
      { value: "80", label: "Heydar Aliyev Center", region: "Narimanov" },
      { value: "81", label: "Heydar Aliyev Palace", region: "Nasimi" },
      { value: "82", label: "Hilton Hotel", region: "Sabail" },
      { value: "83", label: "Huseyn Javid Park", region: "Yasamal" },
      { value: "84", label: "Hyatt Regency", region: "Yasamal" },
      { value: "85", label: "Military Hospital", region: "Nasimi" },
      { value: "86", label: "Public TV Channel", region: "Yasamal" },
      { value: "87", label: "Inner City", region: "Sabail" },
      {
        value: "88",
        label: "Fine Arts and Culture University",
        region: "Yasamal",
      },
      { value: "89", label: "Construction University", region: "Yasamal" },
      { value: "90", label: "Ministry of Economy", region: "Nizami" },
      { value: "91", label: "Economic University", region: "Nizami" },
      {
        value: "92",
        label: "Embassy of the Islamic Republic of Iran",
        region: "Sabail",
      },
      { value: "93", label: "ISR Plaza", region: "Nasimi" },
      { value: "94", label: "Izmir Park", region: "Yasamal" },
      { value: "95", label: "Keshla Market", region: "Narimanov" },
      { value: "96", label: "Koala Park", region: "Nasimi" },
      { value: "97", label: "Cooperation University", region: "Narimanov" },
      { value: "98", label: "Koroglu Park", region: "Nasimi" },
      { value: "99", label: "Landmark", region: "Sabail" },
      { value: "100", label: "Lider TV Channel", region: "Yasamal" },
      { value: "101", label: "M. Husseynzade Park", region: "Nasimi" },
      { value: "102", label: "M.A.Sabir Park", region: "Sabunchu" },
      { value: "103", label: "Ministry of Finance", region: "Nasimi" },
      { value: "104", label: "Megafun", region: "Khatai" },
      {
        value: "105",
        label: "Architecture and Construction University",
        region: "Yasamal",
      },
      { value: "106", label: "Metropark", region: "Narimanov" },
      { value: "107", label: "National Conservatory", region: "Yasamal" },
      { value: "108", label: "National Assembly", region: "Sabail" },
      {
        value: "109",
        label: "State Security Service",
        region: "Yasamal",
      },
      { value: "110", label: "Molokan Garden", region: "Sabail" },
      { value: "111", label: "Montin Park", region: "Narimanov" },
      { value: "112", label: "Moscow University", region: "Nasimi" },
      { value: "113", label: "Ministry of Defense Industry", region: "Khatai" },
      { value: "114", label: "MUM", region: "Sabail" },
      { value: "115", label: "Musabayov Park", region: "Yasamal" },
      {
        value: "116",
        label: "Central Oil Workers Hospital",
        region: "Khatai",
      },
      { value: "117", label: "Central Univermag", region: "Sabail" },
      { value: "118", label: "Nargiz Shopping Center", region: "Sabail" },
      { value: "119", label: "Neapol Circle", region: "Khatai" },
      {
        value: "120",
        label: "Azerbaijan State Oil and Industry University",
        region: "Nasimi",
      },
      { value: "121", label: "Neftchi Base", region: "Narimanov" },
      { value: "122", label: "Neftchilar Metro", region: "Nizami" },
      { value: "123", label: "Nizami Cinema", region: "Nasimi" },
      { value: "124", label: "Ministry of Transport", region: "Sabail" },
      { value: "125", label: "Nariman Narimanov Park", region: "Narimanov" },
      { value: "126", label: "Narimanov Statue", region: "Yasamal" },
      { value: "127", label: "Nasimi Bazaar", region: "Nasimi" },
      { value: "128", label: "Nasimi Statue", region: "Nasimi" },
      { value: "129", label: "Land of Fire University", region: "Narimanov" },
      { value: "130", label: "Olympic Stadium", region: "Sabunchu" },
      { value: "131", label: "Olimpik Star", region: "Narimanov" },
      { value: "132", label: "Oscar Wedding Palace", region: "Nizami" },
      { value: "133", label: "Embassy of Uzbekistan", region: "Sabail" },
      { value: "134", label: "Park Azure", region: "Khatai" },
      { value: "135", label: "Park Bulvar", region: "Sabail" },
      { value: "136", label: "Park Inn", region: "Sabail" },
      { value: "137", label: "Park Zorge", region: "Nasimi" },
      { value: "138", label: "Pedagogical University", region: "Sabail" },
      { value: "139", label: "Perekechku", region: "Absheron" },
      { value: "140", label: "Port Baku", region: "Nasimi" },
      { value: "141", label: "Presidential Park", region: "Khatai" },
      { value: "142", label: "Pullman Hotel", region: "Yasamal" },
      { value: "143", label: "Caucasus Resort Hotel", region: "Nasimi" },
      { value: "144", label: "Engineering University", region: "Absheron" },
      { value: "145", label: "Winter Park", region: "Yasamal" },
      { value: "146", label: "Maiden Tower", region: "Sabail" },
      { value: "147", label: "Governor Park", region: "Sabail" },
      {
        value: "148",
        label: "Liberation - 93 Residential Complex",
        region: "Absheron",
      },
      { value: "149", label: "Victory Circle", region: "Yasamal" },
      { value: "150", label: "West University", region: "Sabail" },
      {
        value: "151",
        label: "Ministry of Transport, Communications and High Technologies",
        region: "Nasimi",
      },
      { value: "152", label: "Republic Stadium", region: "Narimanov" },
      { value: "153", label: "Russian Embassy", region: "Nasimi" },
      { value: "154", label: "Academy of Painting", region: "Narimanov" },
      { value: "155", label: "Coastal Garden", region: "Narimanov" },
      { value: "156", label: "Sea Breeze Resort", region: "Sabunchu" },
      { value: "157", label: "Love Park", region: "Khatai" },
      { value: "158", label: "Sevil Gaziyeva Park", region: "Nasimi" },
      { value: "159", label: "Sevinc Shopping Center", region: "Nizami" },
      { value: "160", label: "Circus", region: "Nasimi" },
      { value: "161", label: "Sovetski", region: "Yasamal" },
      { value: "162", label: "Space TV", region: "Yasamal" },
      {
        value: "163",
        label: "Shuvalan Park Shopping Center",
        region: "Khatai",
      },
      { value: "164", label: "Saderat Shopping Center", region: "Qaradag" },
      { value: "165", label: "Shafa Stadium", region: "Nizami" },
      { value: "166", label: "Martyrs Alley", region: "Sabail" },
      { value: "167", label: "Ministry of Health", region: "Nasimi" },
      { value: "168", label: "Waterfall Park", region: "Sabail" },
      { value: "169", label: "Samed Vurgun Park", region: "Nasimi" },
      { value: "170", label: "Border Sports Complex", region: "Khatai" },
      { value: "171", label: "East Bazaar", region: "Narimanov" },
      { value: "172", label: "Tarkov", region: "Sabail" },
      { value: "173", label: "Technical University", region: "Yasamal" },
      { value: "174", label: "Medical University", region: "Nasimi" },
      { value: "175", label: "Tofik Bakhramov Stadium", region: "Narimanov" },
      { value: "176", label: "Embassy of Turkey", region: "Nasimi" },
      { value: "177", label: "Thinking University", region: "Narimanov" },
      { value: "178", label: "Ministry of Education", region: "Narimanov" },
      { value: "179", label: "New Market", region: "Nasimi" },
      { value: "180", label: "Ukraine Department", region: "Khatai" },
      { value: "181", label: "Ministry of Taxes", region: "Nizami" },
      { value: "182", label: "Homeland Cinema", region: "Nasimi" },
      { value: "183", label: "World Business Center", region: "Nasimi" },
      { value: "184", label: "Carpet Museum", region: "Sabail" },
      { value: "185", label: "Ministry of Foreign Affairs", region: "Yasamal" },
      { value: "186", label: "Hagani Trade Center", region: "Sabail" },
      { value: "187", label: "Caspian University", region: "Nizami" },
      { value: "188", label: "Yasamal Bazaar", region: "Yasamal" },
      { value: "189", label: "Yasamal Park", region: "Yasamal" },
      { value: "190", label: "Green Market", region: "Narimanov" },
      { value: "191", label: "Soldiers Park", region: "Nasimi" },
      { value: "192", label: "Zoo", region: "Narimanov" },
      { value: "193", label: "Zarifa Aliyeva Park", region: "Binagadi" },
      { value: "194", label: "Ministry of Justice", region: "Yasamal" },
      {
        value: "195",
        label: "Ministry of Labor and Social Protection of the Population",
        region: "Yasamal",
      },
    ],
    flat_type_options: [
      { value: "1", label: "Apartment" },
      { value: "2", label: "House/Cottage/Villa", label2: "House" },
      { value: "3", label: "Office" },
      { value: "4", label: "Garage" },
      { value: "5", label: "Land" },
      { value: "6", label: "Property" },
    ],
    offer_type_options: [
      { value: "true", label: "Sale" },
      { value: "false", label: "Rent" },
    ],
    mortgage_options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
    rent_type_options: [
      { value: "1", label: "Monthly" },
      { value: "2", label: "Daily" },
    ],
    filter_options: [
      { value: "dn", label: "Date (New-Old)" },
      { value: "do", label: "Date (Old-New)" },
      { value: "pl", label: "Price (Low to High)" },
      { value: "ph", label: "Price (High to Low)" },
    ],
    filter_options_blog: [
      { value: "dn", label: "Date (New - Old)" },
      { value: "do", label: "Date (Old - New)" },
      { value: "pl", label: "Views (Few - Many)" },
      { value: "ph", label: "Views (Many - Few)" },
    ],
    type_of_flat: [
      { value: "1", label: "Business center" },
      { value: "2", label: "House / Apartment" },
    ],
    packets: {
      boost: [
        { dayOrTime: "1", price: "0.49" },
        { dayOrTime: "5", price: "1.99" },
        { dayOrTime: "10", price: "3.99" },
      ],
      frame: [
        { dayOrTime: "1", price: "0.99" },
        { dayOrTime: "5", price: "2.49" },
        { dayOrTime: "10", price: "4.49" },
      ],
      urgent: [
        { dayOrTime: "1", price: "1.99" },
        { dayOrTime: "5", price: "5.99" },
        { dayOrTime: "10", price: "9.99" },
      ],
      premium: [
        { dayOrTime: "1", price: "2.49" },
        { dayOrTime: "5", price: "10.99" },
        { dayOrTime: "10", price: "19.99" },
        { dayOrTime: "30", price: "39.99" },
      ],
    },
    blog:"Blog",
    blogAll:"All",
    blogMore:"Read More...",
    blogNotFound:"Blog not found"
  },
};
