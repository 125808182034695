import React from 'react'

const OfisImage = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.41719 22.4V6.44909C2.41719 6.11491 2.6249 5.81594 2.93808 5.69935L14.0756 1.55318C14.5983 1.35859 15.1547 1.74516 15.1547 2.30291V22.4C15.1547 22.8418 14.7965 23.2 14.3547 23.2H3.21719C2.77536 23.2 2.41719 22.8418 2.41719 22.4Z" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.38672 8.48438H11.1867" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.38672 12.0845H11.1867" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.38672 15.6846H11.1867" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.38672 19.2842H11.1867" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.9828 8.79902H20.7828C21.2246 8.79902 21.5828 9.1572 21.5828 9.59902V22.399C21.5828 22.8409 21.2246 23.199 20.7828 23.199H15.9828C15.541 23.199 15.1828 22.8409 15.1828 22.399V9.59902C15.1828 9.1572 15.541 8.79902 15.9828 8.79902Z" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
<rect y="22.3999" width="24" height="1.6" rx="0.8" fill={color}/>
</svg>

  )
}

export default OfisImage